import { AddManualContact } from '@/components/Project/AddManualContact/AddManualContact';
import { Contact } from '@/components/Project/ContactsList/ContactsList';
import { CreateProjectFlowFormValues } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { SearchEmployee } from '@/components/SearchEmployee/SearchEmployee';
import { Employee } from '@/models/Employee';
import { getEmployees } from '@/services/auth';
import { hasNestedErrors } from '@/utils/form';
import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Container,
  Group,
  Input,
  InputLabel,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPlus } from '@tabler/icons-react';
import { useCallback, useState } from 'react';

const FORM_KEY = 'notificationStep' as const satisfies keyof CreateProjectFlowFormValues;

export const CreateProjectFlowNotificationStep = ({
  form,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
}) => {
  const { instance } = useMsal();
  const fetchEmployees = useCallback(
    async (query: string): Promise<Employee[]> => {
      return await getEmployees(instance, query);
    },
    [instance]
  );

  return (
    <Container size={'sm'}>
      <Stack gap="xl">
        <AlarmNotificationContactsSection form={form} fetchEmployees={fetchEmployees} />
      </Stack>
    </Container>
  );
};

const AlarmNotificationContactsSection = ({
  form,
  fetchEmployees,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
  fetchEmployees: (query: string) => Promise<Employee[]>;
}) => {
  const [alarmNotificationContactMode, setAlarmNotificationContactMode] = useState<
    'search' | 'manual'
  >('search');
  const alarmNotificationContacts = form.getValues()[FORM_KEY].alarmContacts;

  const handleAddContact = (value: Employee) => {
    form.insertListItem(`${FORM_KEY}.alarmContacts`, {
      name: value.name,
      email: value.email,
      phone: value.phone,
      external: value.externalUserId ? false : true,
      externalUserId: value.externalUserId,
      timeZoneId: 'Central European Time', // TODO: timezone?
      timeFrames: [
        {
          start: '00:00:00',
          end: '23:59:00',
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        },
      ],

      smsEnabled: !!value.phone,
      emailEnabled: !!value.email,
      batteryAlarmNotificationEnabled: false,
      id: '', // required in typings - but won't be sent to the backend in this flow
      WorkZoneId: '', // required in typings - but won't be sent to the backend in this flow
    });
    setAddAnotherContact(false);
  };

  const [addAnotherContact, setAddAnotherContact] = useState(false);

  return (
    <Stack gap={'xs'}>
      <Title order={5} mb={'xs'}>
        Alarm notification contact
      </Title>
      <Text>
        This contact will receive the notifications triggered by anything happening in the project.
      </Text>

      <Paper mt={'md'} p={'lg'}>
        <Stack gap="md">
          <Input.Wrapper
            w={'100%'}
            styles={{
              label: {
                fontWeight: 600,
                display: 'block',
              },
            }}
            error={
              hasNestedErrors(form.errors, `${FORM_KEY}.alarmContacts`)
                ? 'Invalid alarm contact information'
                : ''
            }
          >
            <Stack gap="md">
              {alarmNotificationContacts.map((singleAlarmContact, idx) => (
                <Contact
                  key={idx}
                  contact={singleAlarmContact}
                  onAddTimeFrame={(timeFrame) => {
                    form.insertListItem(`${FORM_KEY}.alarmContacts.${idx}.timeFrames`, timeFrame);
                  }}
                  onUpdateTimeFrame={(existingTimeFrame, newTimeFrame) => {
                    const timeframeIdx = form
                      .getValues()
                      [
                        FORM_KEY
                      ].alarmContacts[idx]?.timeFrames?.findIndex((tf) => tf === existingTimeFrame);
                    if (timeframeIdx === undefined) return;
                    form.setFieldValue(
                      `${FORM_KEY}.alarmContacts.${idx}.timeFrames.${timeframeIdx}`,
                      newTimeFrame
                    );
                  }}
                  onDeleteTimeFrame={(timeFrame) => {
                    const timeframeIdx = form
                      .getValues()
                      [
                        FORM_KEY
                      ].alarmContacts[idx]?.timeFrames?.findIndex((tf) => tf === timeFrame);
                    if (timeframeIdx === undefined) return;
                    form.removeListItem(
                      `${FORM_KEY}.alarmContacts.${idx}.timeFrames`,
                      timeframeIdx
                    );
                  }}
                  onToggleSms={() => {
                    form.setFieldValue(
                      `${FORM_KEY}.alarmContacts.${idx}.smsEnabled`,
                      !form.getValues()[FORM_KEY].alarmContacts[idx].smsEnabled
                    );
                  }}
                  onToggleEmail={() => {
                    form.setFieldValue(
                      `${FORM_KEY}.alarmContacts.${idx}.emailEnabled`,
                      !form.getValues()[FORM_KEY].alarmContacts[idx].emailEnabled
                    );
                  }}
                  onDelete={() => form.removeListItem(`${FORM_KEY}.alarmContacts`, idx)}
                  onToggleBatteryWarning={() => {
                    form.setFieldValue(
                      `${FORM_KEY}.alarmContacts.${idx}.batteryAlarmNotificationEnabled`,
                      !form.getValues()[FORM_KEY].alarmContacts[idx].batteryAlarmNotificationEnabled
                    );
                  }}
                />
              ))}

              {(addAnotherContact || alarmNotificationContacts.length === 0) && (
                <>
                  <SegmentedControl
                    name="alarmNotificationContactMode"
                    value={alarmNotificationContactMode}
                    onChange={(value) =>
                      setAlarmNotificationContactMode(value as typeof alarmNotificationContactMode)
                    }
                    data={[
                      { label: 'Search for a colleague', value: 'search' },
                      { label: 'Manual add', value: 'manual' },
                    ]}
                    styles={{
                      label: {
                        fontWeight: 600,
                      },
                    }}
                    w={'100%'}
                  />
                  {alarmNotificationContactMode === 'search' && (
                    <Box>
                      <InputLabel>Search name</InputLabel>
                      <SearchEmployee
                        width="100%"
                        onChange={(value) => {
                          handleAddContact(value);
                        }}
                        onClose={() => {}}
                        fetchEmployees={async (search: string) => {
                          const results = await fetchEmployees(search);
                          // Prevent same employee being added more than once
                          return results.filter(
                            (employee) =>
                              !alarmNotificationContacts.some(
                                (contact) => contact.externalUserId === employee.externalUserId
                              )
                          );
                        }}
                      />
                    </Box>
                  )}
                  {alarmNotificationContactMode === 'manual' && (
                    <AddManualContact
                      width="100%"
                      onCreate={(value) => {
                        handleAddContact(value);
                      }}
                    />
                  )}
                </>
              )}

              {!addAnotherContact && alarmNotificationContacts.length > 0 && (
                <Group justify="flex-start">
                  <Button
                    leftSection={<IconPlus size={18} />}
                    type="button"
                    variant="default"
                    onClick={() => {
                      setAddAnotherContact(true);
                    }}
                  >
                    Add another contact
                  </Button>
                </Group>
              )}
            </Stack>
          </Input.Wrapper>
        </Stack>
      </Paper>
    </Stack>
  );
};
