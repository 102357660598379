// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AddRecipiant } from '@/components/Project/AddRecipiant/AddRecipiant';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { parsePhoneNumber } from 'libphonenumber-js/min';
import { Button, Checkbox, CSSProperties, Group, Stack, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

interface Props {
  width: string;
  onCreate: (props: { name: string; phone: string | undefined; email: string | undefined }) => void;
}

const manualContactSchema = z
  .object({
    name: z.string().min(1, 'You must provide a name'),
    phone: z
      .string()
      .transform((s, ctx) => {
        if (s) {
          try {
            const phoneNumber = parsePhoneNumber(s);
            if (!phoneNumber || !phoneNumber.isValid()) {
              ctx.addIssue({
                message: 'Invalid phone number format',
                code: 'custom',
              });
            }

            return s;
          } catch (e) {
            ctx.addIssue({
              message: 'Invalid phone number format',
              code: 'custom',
            });
          }
        }
        return undefined;
      })
      .optional(),
    email: z.union([z.string().email('Invalid email format'), z.literal('')]).optional(),
    consent: z
      .boolean()
      .refine((value) => value, { message: 'You must confirm consent to add this recipient' }),
  })
  .superRefine((data, ctx) => {
    if (!data.phone && !data.email) {
      ctx.addIssue({
        message: 'Please provide either a valid phone number or email address',
        code: 'custom',
        path: ['phone'],
      });
      ctx.addIssue({
        message: 'Please provide either a valid phone number or email address',
        code: 'custom',
        path: ['email'],
      });
    }
  });

const labelStyles: CSSProperties = {
  fontWeight: 600,
};
type FormValues = z.infer<typeof manualContactSchema>;
/**
 * Similar to {@link AddRecipiant} but with new styling requirements.
 * Recommend to deprecate {@link AddRecipiant} and use this instead going forward.
 */
export const AddManualContact = ({ onCreate, width }: Props) => {
  const form = useForm<FormValues>({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
      phone: '',
      email: '',
      consent: false,
    },
    validate: zodResolver(manualContactSchema),
  });

  // * Note: this doesn't use a <form> element, because it prevents nesting this within other forms

  return (
    <Stack w={width}>
      <TextInput
        label="Contact name"
        withAsterisk
        key={form.key('name')}
        {...form.getInputProps('name')}
        styles={{
          label: labelStyles,
        }}
      />
      <TextInput
        label="Phone number"
        description="Must include country code (e.g. +46)"
        key={form.key('phone')}
        {...form.getInputProps('phone')}
        onBlur={() => {
          form.validateField('phone');
          form.validateField('email');
        }}
        styles={{
          label: labelStyles,
        }}
      />
      <TextInput
        label="Email address"
        key={form.key('email')}
        {...form.getInputProps('email')}
        onBlur={() => {
          form.validateField('phone');
          form.validateField('email');
        }}
        styles={{
          label: labelStyles,
        }}
      />

      <Checkbox
        label="I confirm that I have obtained consent from this individual."
        key={form.key('consent')}
        {...form.getInputProps('consent', { type: 'checkbox' })}
      />

      <Group justify="flex-start" mt="md">
        <Button
          leftSection={<IconPlus size={18} />}
          type="button"
          onClick={() => {
            const handleSubmit = form.onSubmit(
              (values) => {
                onCreate({
                  name: values.name,
                  phone: values.phone ? values.phone : undefined,
                  email: values.email ? values.email : undefined,
                });
              },
              (errors, values, event) => {
                console.error(errors, values, event);
              }
            );

            handleSubmit();
          }}
        >
          Add Contact
        </Button>
      </Group>
    </Stack>
  );
};
