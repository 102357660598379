import { useEffect } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { WorkZone } from '@/models/Project';
import { useMantineTheme } from '@mantine/core';
import { useMapZoomLevel } from '@/hooks/useMapZoomLevel';

export interface Props {
  area: WorkZone['area'];
  map: google.maps.Map | null;
  project: WorkZone;
  selectedProject: WorkZone | null;
  onClick: (id: string) => void;
}

export const ProjectArea = ({ area, map, project, selectedProject, onClick }: Props) => {
  const maps = useMapsLibrary('maps');
  const theme = useMantineTheme();
  const isSelected = selectedProject?.id === project.id;

  const { isAtZoomLevel } = useMapZoomLevel({
    map,
    zoomLevel: 'close',
  });

  useEffect(() => {
    if (isAtZoomLevel && maps && area) {
      const polygon = new maps.Polygon({
        paths: area.coordinates[0].map((c) => ({ lat: c[1], lng: c[0] })),
        fillColor: isSelected ? theme.colors.successGreen[0] : '#173232',
        fillOpacity: 0.15,
        strokeColor: isSelected ? theme.colors.successGreen[0] : '#173232',
        strokeOpacity: 0.4,
        strokeWeight: isSelected ? 4 : 2,
      });

      polygon.setMap(map);

      const handleClick = () => {
        onClick(project.id);
      };

      polygon.addListener('click', handleClick);

      return () => {
        google.maps.event.clearInstanceListeners(polygon);
        polygon.setMap(null);
      };
    }
  }, [
    maps,
    area,
    map,
    onClick,
    project.id,
    isSelected,
    theme.colors.linkBlue,
    theme.colors.successGreen,
    isAtZoomLevel,
  ]);

  return null;
};
