import { useQuery } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import { DeviceEvent, DeviceEventType, isValidEventType } from '../../../models/DeviceEvent';
import { prepareHeaders } from '../../api/apiHelpers';

const baseUrl = import.meta.env.VITE_API_BASE_PATH;

interface DeviceEventsResponse {
  data: Array<{
    id: string;
    deviceId: string;
    timestamp: string;
    eventType: string;
    eventData: Record<string, unknown>;
    referenceId: string;
  }>;
  pageSize: number;
  pageNumber: number;
  totalCount: number;
}

// Simplified mapping function
const mapToDeviceEvents = (response: DeviceEventsResponse): DeviceEvent[] => {
  return response.data.map((item) => {
    const eventType = isValidEventType(item.eventType)
      ? (item.eventType as DeviceEventType)
      : DeviceEventType.Unknown;

    const result = {
      id: item.id,
      deviceId: item.deviceId,
      type: eventType,
      timestamp: item.timestamp,
      eventType: item.eventType,
      referenceId: item.referenceId,
      eventData: {
        Timestamp: (item.eventData?.Timestamp as string) || item.timestamp,
        DeviceId: item.eventData?.DeviceId as string,
      },
    } as DeviceEvent;
    return result;
  });
};

interface DeviceEventsParams {
  deviceId: string | null;
  projectId?: string | null;
  eventTypes?: DeviceEventType[];
}

// Hook to fetch device events
const useDeviceEvents = (
  { deviceId, eventTypes, projectId }: DeviceEventsParams,
  instance: IPublicClientApplication
) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['device-events', deviceId, eventTypes, projectId],
    queryFn: async () => {
      if (!deviceId || !projectId) return { events: [] };

      // Build URL manually to ensure proper format for EventTypes
      let url = `${baseUrl}/Device/events-for-project?DeviceIds=${encodeURIComponent(deviceId)}`;

      // Add each event type as a separate query parameter
      if (eventTypes && eventTypes.length > 0) {
        eventTypes.forEach((eventType) => {
          url += `&EventTypes=${encodeURIComponent(eventType)}`;
        });
      }

      url += `&ProjectId=${encodeURIComponent(projectId)}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: await prepareHeaders(instance),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch device events');
      }
      const responseData: DeviceEventsResponse = await response.json();
      return { events: mapToDeviceEvents(responseData) };
    },
    enabled: !!deviceId,
    staleTime: 5 * 60 * 1000,
    gcTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    events: data?.events ?? [],
    isLoading,
    error,
    refetch,
  };
};

export default useDeviceEvents;
