import { DeviceType } from '@/models/Device';
import { DeviceDefaultImage } from '@/components/Map/InfoCard/DeviceInfo/DeviceDefaultImage';

interface Props {
  deviceType: DeviceType;
}

export const GenericDeviceMarker = ({ deviceType }: Props) => {
  return (
    <>
      <div
        style={{
          borderRadius: '4px',
          zIndex: '5',
          position: 'relative',
          width: 'fit-content',
          height: 'fit-content',
          padding: '2px',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid #000',
          color: '#000',
        }}
      >
        <DeviceDefaultImage size={30} deviceType={deviceType} />
      </div>
      <div
        style={{
          width: '5px',
          height: '100%',
          position: 'absolute',
          top: '30%',
          backgroundColor: 'rgb(60, 60, 60)',
          borderRadius: '2px',
        }}
      ></div>
    </>
  );
};
