import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Button, Flex, Group, Loader, Space, Stack } from '@mantine/core';
import { IconScan, IconBarrierBlockOff } from '@tabler/icons-react';

import { PWAContext } from '../../providers/PWAProvider';
import Guide from '../../components/Guide';
import useSnoozeProject from '../../../data/hooks/Project/useSnoozeProjectHook';
import useUnSnoozeProject from '../../../data/hooks/Project/useUnSnoozeProjectHook';
import useProjectDevices from '../../../data/hooks/Device/useProjectDevicesHook';
import useProject from '../../../data/hooks/Project/useProjectHook';
import PwaPageHeading from '../../components/PwaPageHeading';
import MainContent from './components/MainContent';
import { formatDateTime } from '../../../utils/utils';
import { ProjectOverviewMessage } from './components/ProjectOverviewMessage';
import SnoozeButton from '../../../components/Project/SnoozeButton/SnoozeButton';
import UnSnoozeButton from '../../../components/Project/UnSnoozeButton/UnSnoozeButton';
import SnoozeStatus from '../../../components/Project/SnoozeStatus/SnoozeStatus';
import { isSnoozeActive, isSnoozeScheduled } from '../../../utils/snoozeUtils';

export const PwaProjectOverviewPage = () => {
  const { instance } = useMsal();
  const { setMessage } = useContext(PWAContext);
  const { projectId } = useParams<{ projectId: string }>();

  const { mutateAsync: snoozeProject } = useSnoozeProject(instance);
  const { mutateAsync: unsnoozeProject } = useUnSnoozeProject(instance);
  const { project } = useProject({ instance, workZoneId: projectId ?? '' });

  const { projectDevices, isLoading } = useProjectDevices({
    instance,
    projectIds: [projectId ? projectId : ''],
  });

  const handleSnooze = async (params: { id: string; snoozedUntil: Date; snoozedFrom: Date }) => {
    if (!project || !project.id) return;

    snoozeProject(params).catch(() =>
      setMessage({
        title: 'Failed to snooze alarms',
        text: 'Something went wrong when trying to snooze the projects alarms',
        type: 'error',
      })
    );
  };

  const renderProjectContent = () => {
    if (project?.isClosed) {
      return (
        <ProjectOverviewMessage
          Icon={IconBarrierBlockOff}
          title="Closed project"
          description={`This project closed at: ${formatDateTime(project?.closedAt)}`}
        />
      );
    }

    if (isLoading) {
      return (
        <Flex flex={1} align="center" justify="center">
          <Space h={200} />
          <Loader />
        </Flex>
      );
    }

    return project && projectId ? (
      <MainContent projectId={projectId} deviceList={projectDevices[project.id]} />
    ) : null;
  };

  return (
    <>
      <Guide />

      <Stack flex={1} style={{ overflow: 'auto' }} justify="space-between" mb="lg">
        <Stack>
          <Group gap="sm" justify="space-between">
            <PwaPageHeading label="Project">
              {project?.name || 'No project selected'}
            </PwaPageHeading>

            <Link to="/pwa/projects" state={{ prevPath: 'overview' }}>
              <Button size="md" variant="default">
                Change
              </Button>
            </Link>
          </Group>

          {/* Project status and Snooze/Unsnooze buttons */}
          {project && !project.isClosed && (
            <>
              {isSnoozeActive(project) || isSnoozeScheduled(project) ? (
                <Stack gap={5}>
                  <SnoozeStatus
                    typeOfSnooze={isSnoozeActive(project) ? 'Active' : 'Scheduled'}
                    isSnoozed={project.isSnoozed}
                    snoozedFrom={project.snoozedFrom}
                    snoozedUntil={project.snoozedUntil}
                  />

                  <UnSnoozeButton
                    project={project}
                    unsnoozeProject={unsnoozeProject}
                    isPwa={true}
                  />
                </Stack>
              ) : (
                // Show snooze button if project is not snoozed
                <SnoozeButton projectId={project.id} snoozeProject={handleSnooze} isPwa={true} />
              )}
            </>
          )}
        </Stack>

        {renderProjectContent()}

        <Link to="/pwa/projects/scanner">
          <Button
            size="lg"
            variant="filled"
            w="100%"
            leftSection={<IconScan />}
            disabled={!project || project.isClosed}
          >
            Add/Remove device
          </Button>
        </Link>
      </Stack>
    </>
  );
};
