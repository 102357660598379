import { Title } from '@mantine/core';

interface Props {
  children: string;
  mb?: string;
}

export const TabTitle = ({ children, mb }: Props) => {
  return (
    <Title order={3} size="h4" mb={mb}>
      {children}
    </Title>
  );
};
