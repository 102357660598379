import { Loader, Stack } from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import { DatePickerInput } from '@mantine/dates';
import { WorkZone } from '../../../models/Project';
import ReportOverview from './components/ReportOverview';
import useGetSupervisionReport from '../../../data/hooks/SupervisionReport/useGetSupervisionReportHook';
import { supervisionReportIntervals } from '../../../config/supervisionReportIntervals';
import useDepots from '../../../data/hooks/Depots/useDepots';
import { getInitialReportDates, useDatepickerDates } from './hooks/useDatepickerDates';
import useGetSnoozeDurations from '@/data/hooks/SupervisionReport/useGetSnoozeDurationsHook';

interface Props {
  project: WorkZone;
}

export default function Reports({ project }: Props) {
  const { instance } = useMsal();
  const { depotsNameMap } = useDepots(instance);
  const initialDates = getInitialReportDates(project);
  const { dateValue, setDateValue, startTime, endTime, startDate, endDate } = useDatepickerDates(
    initialDates.startDate,
    initialDates.endDate
  );
  const intervalInHours = supervisionReportIntervals[project.countryCode] ?? '08:00:00';
  const smartCableSolarInterval = '1.00:00:00';

  const { supervisionReport, isLoading: supervisionReportsLoading } = useGetSupervisionReport({
    instance,
    workZoneId: project.id,
    startTime,
    endTime,
    interval: intervalInHours,
    smartCableSolarInterval,
  });

  const { snoozeDurations, isLoading: snoozeDurationsLoading } = useGetSnoozeDurations(instance, {
    projectId: project.id,
    from: startTime,
    until: endTime,
  });

  return (
    <Stack py="md">
      <DatePickerInput
        maw="300px"
        type="range"
        label="Select date range"
        placeholder="Pick date range"
        value={dateValue}
        onChange={(val) => setDateValue(val)}
        maxDate={new Date()}
        allowSingleDateInRange
      />
      {supervisionReportsLoading || snoozeDurationsLoading ? (
        <Loader mt="xl" mx="auto" />
      ) : (
        <ReportOverview
          projectName={project.name}
          projectId={project.id}
          reportData={supervisionReport}
          depotName={depotsNameMap.get(project.depotId)}
          intervalInHours={intervalInHours}
          dateValue={dateValue}
          startDate={startDate}
          endDate={endDate}
          countryCode={project.countryCode}
          customerName={project.customerName ?? ''}
          snoozeDurations={snoozeDurations ?? []}
        />
      )}
    </Stack>
  );
}
