import { useQuery } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import { UserAccess } from '../../../models/UserAccess';
import { prepareHeaders } from '../../api/apiHelpers';
import { useNavigate } from 'react-router-dom';

interface Params {
  instance: IPublicClientApplication;
}
const path = `/UserAccess`;
const baseUrl = import.meta.env.VITE_API_BASE_PATH;

const useMyUserAccesses = ({ instance }: Params) => {
  const navigate = useNavigate();
  const { data, isError, isLoading, isFetching } = useQuery<UserAccess>({
    queryKey: ['UserAccess', 'me'],
    queryFn: async () => {
      const response = await fetch(`${baseUrl}${path}/me`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (response.status == 404 || response.status == 401 || response.status == 403) {
        navigate('/access-denied');
      }
      return response.json();
    },
  });

  // Calculate if user has any form of project access
  const hasProjectAccess =
    data && (data.isAdmin || data.countries.length > 0 || data.projects.length > 0);

  return {
    userAccesses: data,
    hasProjectAccess,
    isLoading,
    isFetching,
    isError,
  };
};

export default useMyUserAccesses;
