import { ArrowDirection, DeviceModel } from '@/models/Device';
import { PoweredEquipment } from '@/models/enums/DeviceEnums';
import { IconPlugConnected } from '@tabler/icons-react';

// Import all image assets
import arrow_left from '@/assets/ArrowBoardIcons/arrow_board_left.png';
import arrow_right from '@/assets/ArrowBoardIcons/arrow_board_right.png';
import arrow_left_right from '@/assets/ArrowBoardIcons/arrow_board_double_arrow.png';
import arrow_bar from '@/assets/ArrowBoardIcons/arrow_board_bar_only.png';
import arrow_off from '@/assets/ArrowBoardIcons/arrow_board_off.png';
import arrow_unknown from '@/assets/ArrowBoardIcons/arrow_board_unkown.png';
import psa_blue from '@/assets/PSA/psa_blue.png';
import psa_red from '@/assets/PSA/psa_red.png';
import psa_orange from '@/assets/PSA/psa_orange.png';
import psa_yellow from '@/assets/PSA/psa_yellow.png';
import SequentialLights from '@/assets/PoweredEquipment/sequential-lights.png';
import Buffer from '@/assets/PoweredEquipment/buffer.png';
import TrafficLight from '@/assets/PoweredEquipment/traffic-light.png';
import WarningLight from '@/assets/PoweredEquipment/warning-light.png';
import scenario1 from '@/assets/PSA/Scenario/Scenario1.jpg';
import scenario2 from '@/assets/PSA/Scenario/Scenario2.jpg';
import manual_setup from '@/assets/PSA/Setup/ManualSetup.png';
import geozone_setup from '@/assets/PSA/Setup/GeozoneSetup.png';
import psa_vector from '@/assets/VectorizedDevices/psa_red_vectorized.svg';
import intellitag_vector from '@/assets/VectorizedDevices/intellitag_vectorized.svg';
import arrowboard_vector from '@/assets/VectorizedDevices/arrowboard_vectorized.svg';

import { ScenarioType } from '@/models/enums/ScenarioEnums';
import { PsaSetupType } from '@/models/enums/ProjectEnums';
import { AllProjectCreationFlows } from '@/components/Project/CreateProjectFlow/createProjectConstants';

export const ARROW_DIRECTION_IMAGE_MAP = {
  [ArrowDirection.Left]: arrow_left,
  [ArrowDirection.Right]: arrow_right,
  [ArrowDirection.LeftRight]: arrow_left_right,
  [ArrowDirection.Bar]: arrow_bar,
  [ArrowDirection.Off]: arrow_off,
  [ArrowDirection.Unknown]: arrow_unknown,
} as const;

export const POWERED_EQUIPMENT_IMAGE_MAP = {
  [PoweredEquipment.Buffer]: Buffer,
  [PoweredEquipment.Other]: IconPlugConnected,
  [PoweredEquipment.SequentialLight]: SequentialLights,
  [PoweredEquipment.TrafficLight]: TrafficLight,
  [PoweredEquipment.WarningLight]: WarningLight,
  [PoweredEquipment.Unknown]: IconPlugConnected,
} as const;

export const PSA_IMAGE_MAP = {
  [DeviceModel.PsaBlue]: psa_blue,
  [DeviceModel.PsaRed]: psa_red,
  [DeviceModel.PsaOrange]: psa_orange,
  [DeviceModel.PsaYellow]: psa_yellow,
} as const;

export const ROAD_SIGN_BASE_URL =
  'https://globalplatformprodblob.blob.core.windows.net/roadsigns-20240710';

export const SCENARIO_IMAGE_MAP: Record<ScenarioType, string> = {
  [ScenarioType.Scenario1]: scenario1,
  [ScenarioType.Scenario2]: scenario2,
};

export const PSA_SETUP_IMAGE_MAP: Record<PsaSetupType, string> = {
  [PsaSetupType.Manual]: manual_setup,
  [PsaSetupType.Geozone]: geozone_setup,
};

export const PROJECT_CREATION_FLOWS_IMAGE_MAP: Record<AllProjectCreationFlows, string[]> = {
  [AllProjectCreationFlows.psa]: [psa_vector],
  [AllProjectCreationFlows.default]: [arrowboard_vector, intellitag_vector],
};
