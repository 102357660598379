import { useNavigate } from 'react-router-dom';
import { Device } from '../../../models/Device';
import { WorkZone } from '../../../models/Project';
import { panTo } from '../../../utils/utils';
import { WorkzoneInfoCardComponent } from './WorkzoneInfoCard';
import { BaseDeviceInfoWrapper } from './DeviceInfo/BaseDeviceInfoWrapper';

type Props = {
  project: WorkZone | null;
  device: Device | null | undefined;
  projectDevices: Device[];
  projectName: string;
  depotName: string;
  onClose: () => void;
};

export const InfoCardWrapper = ({
  project,
  device,
  onClose,
  projectDevices,
  projectName,
  depotName,
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {project && (
        <WorkzoneInfoCardComponent
          workZone={project}
          devices={projectDevices}
          depotName={depotName}
          onClose={onClose}
          panTo={panTo}
          onNavigateToProject={(projectId: string) => navigate(`/projects/${projectId}`)}
        />
      )}
      {device && (
        <BaseDeviceInfoWrapper
          device={device}
          projectName={projectName}
          onClose={onClose}
          onNavigate={(projectId: string, deviceId: string) =>
            navigate(`/projects/${projectId}?ref=${deviceId}`)
          }
        />
      )}
    </>
  );
};
