import { Map, useApiIsLoaded, useMap } from '@vis.gl/react-google-maps';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { IconReload } from '@tabler/icons-react';
import { panTo } from '../../utils/utils';
import { Device } from '../../models/Device';
import { WorkZone } from '../../models/Project';
import useDevicesInArea from '../../data/hooks/Device/useDevicesInArea';
import { Button, Group, Loader, Paper, Stack, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import useProjectsInArea from '../../data/hooks/Project/useProjectsInArea';
import { Mapbounds } from '../../shared/types';
import { InfoCardWrapper } from './InfoCard/InfoCardWrapper';
import useProjectDevices from '../../data/hooks/Device/useProjectDevicesHook';
import useDepots from '../../data/hooks/Depots/useDepots';
import useDevice from '@/data/hooks/Device/useDeviceHook';
import { Markers } from './Markers/Markers';
import { ViewMode } from '@/pages/MapPage';
import { MapConstants } from './MapConstants';
import { FilterOptions } from './FilterDrawer/MapFilterDrawer';

interface Props {
  viewMode: ViewMode;
  filters: FilterOptions;
}

export const GoogleMap = ({ viewMode, filters }: Props) => {
  const viewModeRef = useRef<ViewMode>(viewMode);
  const map = useMap();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const apiIsLoaded = useApiIsLoaded();
  const queryClient = useQueryClient();
  const [selectedProject, setSelectedProject] = useState<WorkZone | null>(null);

  const {
    data: projects,
    handleBoundsChange: handleBoundsChangeProjects,
    isPending: projectsPending,
  } = useProjectsInArea(instance, filters);

  const {
    data: devices,
    handleBoundsChange: handleBoundsChangeDevices,
    isPending,
  } = useDevicesInArea(instance, filters);

  const projectIds = (projects ? projects : []).map((wz) => wz.id) || [];
  const { projectDevices, isLoading } = useProjectDevices({
    instance,
    projectIds,
  });
  const projectIdToNameMap = (projects ? projects : []).reduce(function (
    map: { [projectId: string]: string },
    project: WorkZone
  ) {
    map[project.id] = project.name;
    return map as { [projectId: string]: string };
  }, {});

  const { depotsNameMap } = useDepots(instance);

  const selectedDeviceId = useMemo(() => searchParams.get('device') || null, [searchParams]);
  const setSelectedDeviceId = useCallback(
    (new_id: string | null) => {
      navigate(
        {
          search: new_id ? `?device=${new_id}` : '',
        },
        {
          replace: true,
        }
      );
    },
    [navigate]
  );

  // Selected device might not be on screen, so we need to fetch it from the API
  const { device: selectedDevice } = useDevice(
    { instance, deviceId: selectedDeviceId! },
    { enabled: !!selectedDeviceId }
  );

  const hasAlreadyPannedOnLoad = useRef(false);

  useEffect(() => {
    // should only pan on initial page load, not every re-selection
    if (apiIsLoaded && map && selectedDevice && !hasAlreadyPannedOnLoad.current) {
      hasAlreadyPannedOnLoad.current = true; // we don't want to re-pan to the next selected device if the current one has an invalid position
      if (selectedDevice.position) {
        panTo(selectedDevice.position.coordinates[1], selectedDevice.position.coordinates[0], map);
      }
    }
  }, [map, apiIsLoaded, selectedDevice]);

  const getMapBounds = useCallback((): Mapbounds | null => {
    const mapBounds = map?.getBounds();
    if (mapBounds) {
      return {
        northEastLatitude: mapBounds.getNorthEast().lat(),
        northEastLongitude: mapBounds.getNorthEast().lng(),
        southWestLatitude: mapBounds.getSouthWest().lat(),
        southWestLongitude: mapBounds.getSouthWest().lng(),
      };
    }
    return null;
  }, [map]);

  const onClick = (device: Device | null, project: WorkZone | null): void => {
    if (device) {
      setSelectedProject(null);
      setSelectedDeviceId(device.id);
    } else {
      setSelectedDeviceId(null);
      setSelectedProject(project);
    }
  };

  const updateMapMarkers = useCallback(() => {
    if (map) {
      const currentZoomLevel = map.getZoom();
      const viewportBounds = getMapBounds();
      if (viewportBounds && currentZoomLevel) {
        if (viewMode === 'devices' || currentZoomLevel > MapConstants.DEVICE_ZOOM) {
          handleBoundsChangeDevices(viewportBounds);
        } else if (viewMode === 'projects') {
          handleBoundsChangeProjects(viewportBounds);
        }
      }
    }
  }, [map, getMapBounds, viewMode, handleBoundsChangeDevices, handleBoundsChangeProjects]);

  useEffect(() => {
    if (map) {
      const listener = map?.addListener('idle', updateMapMarkers);
      return () => {
        listener.remove();
      };
    }
  }, [map, updateMapMarkers]);

  useEffect(() => {
    // Trigger updateMapMarkers when viewMode changes
    if (viewMode !== viewModeRef.current) {
      updateMapMarkers();
    }
  }, [map, updateMapMarkers, viewMode]);

  return (
    <div
      style={{
        position: 'relative',
        width: `calc(100vw- var(--mantine-aside-width, 0px) - var(--mantine-navbar-width, 0px))`,
        height: `calc(100vh - var(--app-shell-header-offset, 0rem) + var(--app-shell-padding))`,
      }}
    >
      <InfoCardWrapper
        device={selectedDevice}
        project={selectedProject}
        projectDevices={selectedProject ? projectDevices[selectedProject.id] : []}
        projectName={selectedDevice ? projectIdToNameMap[selectedDevice?.workZoneId] : '-'}
        depotName={depotsNameMap.get(selectedProject?.depotId)}
        onClose={() => {
          setSelectedDeviceId(null);
          setSelectedProject(null);
        }}
      />

      <div className="loadMoreBox">
        <Stack align="center" w="fit-content">
          <Button
            size="xs"
            onClick={() => {
              queryClient.invalidateQueries({
                queryKey: [viewMode],
              });
            }}
            rightSection={<IconReload />}
            variant="default"
          >
            {viewMode === 'devices'
              ? 'Refetch devices in this area'
              : 'Refetch projects in this area'}
          </Button>
          {(isPending || projectsPending || isLoading) && (
            <Paper w="fit-content" shadow="md" bg="white" p="sm">
              <Group>
                <Text size="sm">Loading...</Text>
                <Loader color="deepGreen.9" size="xs" />
              </Group>
            </Paper>
          )}
        </Stack>
      </div>

      <Map
        style={{ position: 'absolute', inset: '0px' }}
        defaultCenter={{ lat: 59.32709, lng: 18.06788 }}
        defaultZoom={4}
        gestureHandling="greedy"
        disableDefaultUI={false}
        mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
      >
        <Markers
          viewMode={viewMode}
          projects={projects ?? []}
          projectDevices={projectDevices}
          onClick={(id, idType) => onClick(id, idType)}
          map={map}
          devices={devices ?? []}
          selectedDevice={selectedDevice?.id}
          selectedProject={selectedProject}
        />
      </Map>
    </div>
  );
};
