import { FixedMapControl } from '@/components/Map/FixedMapControl';
import { Polygon } from '@/components/Map/Polygon';
import { simplify } from '@turf/simplify';
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Notification,
  Popover,
  Stack,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useThrottledCallback } from '@mantine/hooks';
import {
  IconEdit,
  IconEye,
  IconHandGrab,
  IconMapPinMinus,
  IconPolygon,
  IconTrash,
  IconX,
  IconExclamationMark,
  IconRoute,
} from '@tabler/icons-react';
import {
  AdvancedMarker,
  ControlPosition,
  InfoWindow,
  Map,
  MapMouseEvent,
  useMapsLibrary,
  useMap,
} from '@vis.gl/react-google-maps';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CountryCodeToCountryNameMap } from '../MapConstants';
import useDevicesInArea from '@/data/hooks/Device/useDevicesInArea';
import { useMsal } from '@azure/msal-react';
import { DeviceMarker } from '../DeviceMarker/DeviceMarker';
import { doPolygonsIntersect, getBoundingBoxFromLatLngBounds } from '@/utils/mapUtils';
import { Device, DeviceType } from '@/models/Device';
import classes from './MapWithPolygonEditor.module.css';
import useIntersectingGeozones from '@/data/hooks/Setups/useIntersectingSetups';
import { Geozone } from '@/models/Setups';
import { AlarmStatus } from '@/models/enums/DeviceEnums';
import { PolygonEditingComponent } from './DrawingComponents/PolygonEditingComponent';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import { PointToPointComponent } from './DrawingComponents/PointToPointEditingComponent';

const NEARBY_WORKZONE_FILTER_KEY = 'geozone-workzone-filter';
const NEARBY_DEVICE_FILTER_KEY = 'geozone-device-filter';
const GEOZONE_SOFT_WARNING_AREA_M2 = 400_000;

/**
 * Renders a map with poylgon editing capabilities, including drawing, editing, removing points, and dragging the polygon.
 * @param currentPath - The current path of the polygon, as an array of LatLngLiteral objects.
 * @param onPathChange - Callback to be called when the path of the polygon changes.
 * @param countryCode - (Optional) the country code to center the map on.
 * @param children - Optional children to render on the map (markers, etc.)
 * @param defaultIsEditing - Flag to enable edit mode by default
 * @returns
 */
export const MapWithPolygonEditor = ({
  currentPath,
  onPathChange,
  countryCode,
  defaultIsEditing = false,
  setupId,
  children,
  onGeozoneClosed,
}: {
  currentPath: google.maps.LatLngLiteral[] | null;
  onPathChange: (path: google.maps.LatLngLiteral[] | null) => void;
  countryCode: string;
  defaultIsEditing?: boolean;
  setupId?: string;
  children?: ReactNode;
  onGeozoneClosed: (valid: boolean) => void;
}) => {
  const drawingLib = useMapsLibrary('drawing');
  const geoLib = useMapsLibrary('geocoding');
  const coreLib = useMapsLibrary('core');
  const map = useMap();

  const isPolygonDrawn = useMemo(() => !!currentPath && currentPath.length >= 3, [currentPath]);
  const [showFilters, setShowFilters] = useState(false);
  const [filterValue, setFilterValue] = useState<string[]>([NEARBY_WORKZONE_FILTER_KEY]);
  const [softGeozoneAreaWarning, setSoftGeozoneAreaWarning] = useState(false);
  const [overlappingGeozones, setOverlappingGeozones] = useState(false);
  const [latestWarningShown, setLatestWarningShown] = useState<'overlap' | 'area'>();
  const isMobile = useMobileDevice();
  const initialBounds = useRef<google.maps.LatLngBounds | null>(null);
  const { instance } = useMsal();
  const { data: devices, handleBoundsChange: handleDeviceBoundsChange } =
    useDevicesInArea(instance);
  const { data: geozones, handleBoundsChange: handleSetupBoundsChange } = useIntersectingGeozones(
    instance,
    countryCode
  );

  useEffect(() => {
    if (!coreLib) return;
    const DEFAULT_BOUNDS = new google.maps.LatLngBounds(
      new google.maps.LatLng(-85, -180),
      new google.maps.LatLng(85, 180)
    );

    // Only set bounds if it's the first time (initial load)
    if (!initialBounds.current && map) {
      let newBounds = new coreLib.LatLngBounds();

      // Use existing geozone if we have it
      if (currentPath) {
        currentPath.forEach((coords) => newBounds.extend({ lng: coords.lng, lat: coords.lat }));
        initialBounds.current = newBounds ?? DEFAULT_BOUNDS;
        map.fitBounds(newBounds, 100);

        return;
      }

      // Else use country code
      if (countryCode) {
        if (!geoLib) return;
        const Geo = new geoLib.Geocoder();
        Geo.geocode({
          address:
            CountryCodeToCountryNameMap[countryCode as keyof typeof CountryCodeToCountryNameMap],
          region: countryCode,
        })
          .then((res) => {
            if (res.results.length > 0 && res.results[0].geometry.viewport) {
              newBounds = res.results[0].geometry.viewport;
              initialBounds.current = newBounds;
              map.fitBounds(newBounds, 1);
            }
          })
          .catch(() => {
            map.fitBounds(DEFAULT_BOUNDS, 1);
          });
      }
    }
  }, [coreLib, countryCode, currentPath, geoLib, map]);

  useEffect(() => {
    if (!isPolygonDrawn) {
      setIsDraggingPoly(false);
      setIsEditing(false);
      setIsRemovingPoints(false);
    }
  }, [isPolygonDrawn]);

  const [drawingMode, setDrawingMode] = useState<'polygon' | 'points' | null>(null);
  const [isEditing, setIsEditing] = useState(defaultIsEditing);
  const [isRemovingPoints, setIsRemovingPoints] = useState(false);
  const [isDraggingPoly, setIsDraggingPoly] = useState(false);

  const polygon = useRef<google.maps.Polygon | null>(null);

  const [routePoints, setRoutePoints] = useState<{
    from: google.maps.LatLngLiteral | null;
    to: google.maps.LatLngLiteral | null;
  }>({
    from: null,
    to: null,
  });

  const handleMapClick = useCallback(
    (e: MapMouseEvent) => {
      if (drawingMode === 'points') {
        setRoutePoints((prev) => {
          if (prev.from === null) {
            return { from: e.detail.latLng, to: null };
          } else if (prev.from !== null && prev.to === null) {
            return { from: prev.from, to: e.detail.latLng };
          }
          return prev;
        });
      }
    },
    [drawingMode]
  );

  const handlePointsChanged = useThrottledCallback(() => {
    const newPath = polygon.current
      ?.getPath()
      ?.getArray()
      ?.map((point) => point.toJSON());
    if (newPath) {
      onPathChange(newPath);
    }
  }, 200);

  const removeVertex = useCallback(
    (vertex: number) => {
      const newPath = polygon.current
        ?.getPath()
        ?.getArray()
        ?.map((point) => point.toJSON());

      if (newPath) {
        newPath.splice(vertex, 1);
        if (newPath.length < 3) {
          onPathChange(null);
        } else {
          onPathChange(newPath);
        }
      }
    },
    [polygon, onPathChange]
  );

  useEffect(() => {
    if (!coreLib || !currentPath) return;

    if (geozones) {
      const newPolygon = new google.maps.Polygon({ paths: currentPath });
      const isOverlapping = geozones
        .filter((gz) => gz.id !== setupId)
        .some((geozone) => {
          const existingPolygon = new google.maps.Polygon({
            paths: geozone.geozone!.coordinates[0].map(([lng, lat]) => ({ lat, lng })),
          });

          // Check if the new polygon contains all points of the existing polygon (full containment)
          const isContained = geozone.geozone!.coordinates[0].every(([lng, lat]) =>
            google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), newPolygon)
          );

          // Check if the polygons have intersecting edges
          const edgesIntersect = doPolygonsIntersect(newPolygon, existingPolygon);

          return isContained || edgesIntersect;
        });

      if (isOverlapping) {
        setOverlappingGeozones(true);
        onGeozoneClosed(false);
        return;
      }
    }

    setOverlappingGeozones(false);

    const area = google.maps.geometry.spherical.computeArea(
      currentPath.map(({ lat, lng }) => new google.maps.LatLng(lat, lng))
    );

    setSoftGeozoneAreaWarning(area >= GEOZONE_SOFT_WARNING_AREA_M2);
    onGeozoneClosed(true);
  }, [coreLib, currentPath, geozones, onGeozoneClosed, setupId, isDraggingPoly]);

  const theme = useMantineTheme();

  const polygonColor = useMemo(() => {
    if (isRemovingPoints) return theme.colors.red[8];
    if (overlappingGeozones) return theme.colors.red[8];
    if (softGeozoneAreaWarning) return theme.colors.ramuddenOrange[0];
    return theme.colors.successGreen[0];
  }, [
    isRemovingPoints,
    overlappingGeozones,
    softGeozoneAreaWarning,
    theme.colors.ramuddenOrange,
    theme.colors.red,
    theme.colors.successGreen,
  ]);

  return (
    <Map
      onBoundsChanged={(e) => {
        const bounds = e.map.getBounds();
        if (e.detail.zoom >= 11 && bounds) {
          const boundingBox = getBoundingBoxFromLatLngBounds(bounds);
          if (filterValue.includes(NEARBY_DEVICE_FILTER_KEY)) {
            handleDeviceBoundsChange(boundingBox);
          }
          if (filterValue.includes(NEARBY_WORKZONE_FILTER_KEY)) {
            handleSetupBoundsChange(boundingBox);
          }
        }
      }}
      style={{ width: '100%', height: '100%' }}
      defaultZoom={4}
      gestureHandling={'greedy'}
      fullscreenControl
      mapTypeControl
      disableDefaultUI={true}
      mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
      draggableCursor={drawingMode === 'points' ? 'crosshair' : undefined}
      onClick={handleMapClick}
    >
      {children}
      {routePoints.from && <AdvancedMarker position={routePoints.from} />}
      {routePoints.to && <AdvancedMarker position={routePoints.to} />}
      <FixedMapControl position={ControlPosition.RIGHT_TOP}>
        <Stack gap="sm" miw="190px">
          <Popover
            opened={showFilters}
            onChange={setShowFilters}
            onClose={() => setShowFilters(false)}
          >
            <Popover.Target>
              <Button
                className={filterValue.length > 0 ? classes.filtersActive : undefined}
                variant={filterValue.length > 0 ? 'light' : 'default'}
                mr={10}
                onClick={() => {
                  setShowFilters((prev) => !prev);
                }}
                leftSection={<IconEye size={18} />}
                style={{
                  pointerEvents: 'all',
                }}
              >
                Toggle filters {filterValue.length > 0 ? `(${filterValue.length})` : null}
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Checkbox.Group label="View" onChange={setFilterValue} value={filterValue}>
                <Stack mt="xs" gap="xs">
                  <Checkbox
                    label="Nearby devices"
                    key={NEARBY_DEVICE_FILTER_KEY}
                    variant="outline"
                    color="black"
                    value={NEARBY_DEVICE_FILTER_KEY}
                  />
                  <Checkbox
                    label="Nearby Workzones"
                    key={NEARBY_WORKZONE_FILTER_KEY}
                    variant="outline"
                    color="black"
                    value={NEARBY_WORKZONE_FILTER_KEY}
                  />
                </Stack>
              </Checkbox.Group>
            </Popover.Dropdown>
          </Popover>
          <Group justify="end" gap="xs" mt="xs">
            {isPolygonDrawn ? (
              <>
                {isEditing && (
                  <>
                    <Tooltip label="Remove points by clicking their handles">
                      <ActionIcon
                        variant={isRemovingPoints ? 'filled' : 'default'}
                        onClick={() => {
                          setIsRemovingPoints((prev) => !prev);
                        }}
                        mr={10}
                        size="lg"
                        style={{ pointerEvents: 'all' }}
                      >
                        <IconMapPinMinus />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Drag/move geozone without changing it's shape">
                      <ActionIcon
                        variant={isDraggingPoly ? 'filled' : 'default'}
                        onClick={() => {
                          setIsDraggingPoly((prev) => !prev);
                        }}
                        mr={10}
                        size="lg"
                        style={{ pointerEvents: 'all' }}
                        disabled={isMobile}
                      >
                        <IconHandGrab />
                      </ActionIcon>
                    </Tooltip>
                  </>
                )}
                <Tooltip label="Enable/disable editing of geozone">
                  <ActionIcon
                    variant={isEditing ? 'filled' : 'default'}
                    onClick={() => setIsEditing((prev) => !prev)}
                    mr={10}
                    size="lg"
                    style={{ pointerEvents: 'all' }}
                  >
                    <IconEdit />
                  </ActionIcon>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip label="Start drawing a geozone">
                  <ActionIcon
                    variant={drawingMode === 'polygon' ? 'filled' : 'default'}
                    onClick={() =>
                      setDrawingMode((prev) => (prev === 'polygon' ? null : 'polygon'))
                    }
                    mr={10}
                    size="lg"
                    style={{ pointerEvents: 'all' }}
                  >
                    <IconPolygon />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Create geozone by selecting 2 points on the map">
                  <ActionIcon
                    variant={drawingMode === 'points' ? 'filled' : 'default'}
                    onClick={() => setDrawingMode((prev) => (prev === 'points' ? null : 'points'))}
                    mr={10}
                    size="lg"
                    style={{ pointerEvents: 'all' }}
                  >
                    <IconRoute />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            {isPolygonDrawn && (
              <Tooltip label="Clear current geozone and start again">
                <ActionIcon
                  variant={'default'}
                  onClick={() => {
                    onPathChange(null);
                    setDrawingMode(null);
                  }}
                  mr={10}
                  size="lg"
                  style={{ pointerEvents: 'all' }}
                >
                  <IconTrash />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
        </Stack>
      </FixedMapControl>
      {filterValue.includes(NEARBY_DEVICE_FILTER_KEY) && renderNearbyDevices(devices)}
      {filterValue.includes(NEARBY_WORKZONE_FILTER_KEY) &&
        renderNearbyGeozones(theme.colors.ramuddenBlue[9], geozones, setupId)}
      {latestWarningShown !== 'area' &&
        softGeozoneAreaWarning &&
        !overlappingGeozones &&
        currentPath && (
          <InfoWindow
            position={{ lat: currentPath[0].lat, lng: currentPath[0].lng }}
            headerDisabled
            onCloseClick={() => setLatestWarningShown('area')}
          >
            <Notification
              title="The Geozone has a large area"
              color="var(--mantine-color-ramuddenOrange-9)"
              icon={<IconExclamationMark size={20} />}
              onClick={() => setLatestWarningShown('area')}
            >
              Consider using manual connection or drawing a smaller Geozone to not block for future
              Geozones. Overlapping Geozones are not allowed.
            </Notification>
          </InfoWindow>
        )}
      {latestWarningShown !== 'overlap' && overlappingGeozones && currentPath && (
        <InfoWindow position={{ lat: currentPath[0].lat, lng: currentPath[0].lng }} headerDisabled>
          <Notification
            title="Overlapping Geozone"
            color="var(--mantine-color-dangerRed-9)"
            icon={<IconX size={20} />}
            onClick={() => setLatestWarningShown('overlap')}
          >
            Your Geozone cannot overlap another Geozone. Draw it in another place or consider using
            manual connection.
          </Notification>
        </InfoWindow>
      )}
      {isPolygonDrawn && (
        <Polygon
          ref={polygon}
          onPathSetAt={handlePointsChanged}
          onPathInsertAt={handlePointsChanged}
          onPathRemoveAt={handlePointsChanged}
          paths={currentPath!}
          strokeColor={polygonColor}
          fillColor={polygonColor}
          editable={isEditing}
          draggable={isDraggingPoly}
          zIndex={2}
          onClick={(evt) => {
            if (isRemovingPoints) {
              if (
                evt !== null &&
                typeof evt === 'object' &&
                'vertex' in evt &&
                evt.vertex !== null &&
                evt.vertex !== undefined &&
                typeof evt.vertex === 'number'
              ) {
                removeVertex(evt.vertex);
              }
            }
          }}
        />
      )}
      {!!drawingLib && (
        <PolygonEditingComponent
          drawingLib={drawingLib}
          isDrawing={drawingMode === 'polygon'}
          setIsDrawing={(isDrawing) =>
            isDrawing ? setDrawingMode('polygon') : setDrawingMode(null)
          }
          onCompleted={onPathChange}
        />
      )}
      {drawingMode === 'points' && !!routePoints.from && !!routePoints.to && (
        <PointToPointComponent
          from={routePoints.from}
          to={routePoints.to}
          countryCode={countryCode}
          onCompleted={(newPath) => {
            setRoutePoints({
              from: null,
              to: null,
            });
            setDrawingMode(null);

            const newGeoJson = simplify(
              {
                type: 'Polygon',
                coordinates: [newPath.map((p) => [p.lng, p.lat])],
              },
              { tolerance: 0.0001, highQuality: true }
            );
            onPathChange(newGeoJson.coordinates[0].map((p) => ({ lat: p[1], lng: p[0] })));
          }}
        />
      )}
    </Map>
  );
};

const renderNearbyDevices = (devices?: Device[]) =>
  devices
    ?.filter((d) => d.deviceType === DeviceType.PSAV1 && d.alarmStatus !== AlarmStatus.Off)
    .map((d) => (
      <DeviceMarker
        baseDevice={d}
        map={null}
        key={d.id}
        onClick={() => {}}
        visibleAtZoomLevel={'always'}
      />
    ));

const renderNearbyGeozones = (color: string, geozones?: Geozone[], originalSetupId?: string) =>
  geozones
    ?.filter((s) => s.id !== originalSetupId)
    .map((s) => (
      <Polygon
        key={`nearby-geozone-${s.id}`}
        paths={s.geozone!.coordinates[0].map(([lng, lat]) => ({ lat, lng }))}
        strokeColor={color}
        fillColor={color}
        fillOpacity={0.4}
        strokeWeight={2}
        editable={false}
        draggable={false}
      />
    ));
