import { DeviceModel, DeviceType, DeviceModelNames, DeviceTypeNames } from '@/models/Device';

/**
 * Parses a device model string into a DeviceModel enum value
 * @param modelString The string representation of the device model
 * @returns The corresponding DeviceModel enum value
 * @throws Error if the string doesn't match any known device model
 */
export function parseDeviceModel(modelString: string): DeviceModel {
  // Convert to lowercase for case-insensitive comparison
  const normalizedString = modelString.toLowerCase().trim();

  // Find the matching model by comparing normalized strings
  const matchingModel = Object.entries(DeviceModelNames).find(
    ([, name]) => name.toLowerCase() === normalizedString
  );

  if (!matchingModel) {
    throw new Error(
      `Invalid device model: ${modelString}. Valid values are: ${Object.values(DeviceModelNames).join(', ')}`
    );
  }

  return Number(matchingModel[0]) as DeviceModel;
}

/**
 * Parses a device type string into a DeviceType enum value
 * @param typeString The string representation of the device type
 * @returns The corresponding DeviceType enum value
 * @throws Error if the string doesn't match any known device type
 */
export function parseDeviceType(typeString: string): DeviceType {
  // Convert to lowercase for case-insensitive comparison
  const normalizedString = typeString.toLowerCase().trim();

  // Find the matching type by comparing normalized strings
  const matchingType = Object.entries(DeviceTypeNames).find(
    ([, name]) => name.toLowerCase() === normalizedString
  );

  if (!matchingType) {
    throw new Error(
      `Invalid device type: ${typeString}. Valid values are: ${Object.values(DeviceTypeNames).join(', ')}`
    );
  }

  return Number(matchingType[0]) as DeviceType;
}
