import { Table, ScrollArea, Checkbox, Center } from '@mantine/core';
import { useMobileDevice } from '../../../../../hooks/useMobileDevice';
import { ReactNode, CSSProperties } from 'react';
import { SnoozeDuration } from '@/models/SnoozeDurationsDto';
import getSnoozeBackgroundColor from './getSnoozeBackgroundColor';

export interface DashboardTableColumn<T> {
  header: ReactNode;
  minWidth?: string;
  render: (row: T, index: number) => ReactNode;
}

interface DashboardTableProps<T> {
  rows: T[];
  columns: DashboardTableColumn<T>[];
  selectedRows: number[];
  onSelectedRowsChange: (rows: number[]) => void;
  onRowClick?: (row: T) => void;
  snoozeDurations?: SnoozeDuration[];
  intervals: string[];
  showSnoozed: boolean;
}

export const DashboardTable = <T,>({
  rows,
  columns,
  selectedRows,
  onSelectedRowsChange,
  onRowClick,
  snoozeDurations,
  intervals,
  showSnoozed,
}: DashboardTableProps<T>) => {
  const isMobile = useMobileDevice();

  const handleCheckboxChange = (rowIndex: number, checked: boolean) => {
    onSelectedRowsChange(
      checked ? [...selectedRows, rowIndex] : selectedRows.filter((idx) => idx !== rowIndex)
    );
  };

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleCheckboxCellClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <ScrollArea.Autosize mah="55vh">
      <Table
        withRowBorders
        withTableBorder
        withColumnBorders
        highlightOnHover
        bg="white"
        stickyHeader
        id="pdf"
      >
        <Table.Thead bg="primaryGreen.6" c="white">
          <Table.Tr>
            <Table.Th ta="center" px={8} miw="10px" style={{ whiteSpace: 'nowrap' }}>
              Hide
            </Table.Th>
            {columns.map((column, index) =>
              !isMobile || index !== 1 ? ( // Skip Reference Id column on mobile
                <Table.Th key={index} ta="center" miw={column.minWidth} px={8}>
                  {column.header}
                </Table.Th>
              ) : null
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rows.map((row, rowIndex) => {
            // Row style with opacity for selected rows and cursor pointer for clickable rows
            const rowStyle: CSSProperties = {
              opacity: selectedRows.includes(rowIndex) ? 0.5 : 1,
              cursor: onRowClick ? 'pointer' : 'default',
            };

            return (
              <Table.Tr
                key={rowIndex}
                style={rowStyle}
                onClick={onRowClick ? () => handleRowClick(row) : undefined}
              >
                <Table.Td
                  ta="center"
                  onClick={handleCheckboxCellClick}
                  style={{ cursor: 'default' }}
                >
                  <Center>
                    <Checkbox
                      data-testid={`hideCheckbox-${rowIndex}`}
                      checked={selectedRows.includes(rowIndex)}
                      onChange={(event) => {
                        handleCheckboxChange(rowIndex, event.currentTarget.checked);
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </Center>
                </Table.Td>
                {columns.map((column, columnIndex) =>
                  !isMobile || columnIndex !== 1 ? ( // Skip Reference Id column on mobile
                    <Table.Td
                      key={columnIndex}
                      ta="center"
                      style={{
                        background: getSnoozeBackgroundColor({
                          snoozeDurations: snoozeDurations ?? [],
                          intervals,
                          columnIndex,
                          showSnoozed,
                        }),
                      }}
                    >
                      {column.render(row, columnIndex)}
                    </Table.Td>
                  ) : null
                )}
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </ScrollArea.Autosize>
  );
};
