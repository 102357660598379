import { Input, Select, Stack, Checkbox, Box, Group } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm, zodResolver } from '@mantine/form';
import { useEffect } from 'react';
import { Country } from '../../../models/Country';
import { Depot } from '../../../shared/types';
import { CreateWorkZone } from '../../../models/Project';
import { z } from 'zod';

interface Props {
  create: boolean;
  initialValues: CreateWorkZone | null;
  onSubmit: (data: CreateWorkZone | null) => void;
  countries?: Country[];
  depots?: Depot[];
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Project name is required')
    .max(100, 'Project name must not exceed 100 characters'),
  customer: z.string().min(1, 'Customer is required'),
  startDate: z.date({ required_error: 'Start date is required' }),
  endDate: z.date().nullable(),
  countryCode: z.string().min(1, 'Country is required'),
  depot: z.string().min(1, 'Depot is required'),
  isDemoProject: z.boolean(),
});

const schema = formSchema.refine(
  (data) => {
    if (!data.endDate) return true;
    return data.endDate > data.startDate;
  },
  {
    message: 'End date should be empty or greater than start date',
    path: ['endDate'],
  }
);

type FormValues = z.infer<typeof schema>;
export const EditAndCreateProjectInputForm = ({
  create = false,
  onSubmit,
  initialValues,
  countries,
  depots,
}: Props) => {
  const form = useForm<FormValues>({
    validate: zodResolver(schema),
    initialValues: initialValues
      ? {
          name: initialValues.name,
          customer: initialValues.customerName ?? '',
          startDate: initialValues.startDate ? new Date(initialValues.startDate) : new Date(),
          endDate: initialValues.endDate ? new Date(initialValues.endDate) : null,
          countryCode: initialValues.countryCode,
          depot: initialValues.depotId,
          isDemoProject: initialValues.isDemoProject,
        }
      : {
          name: '',
          customer: '',
          startDate: new Date(),
          endDate: null,
          countryCode: '',
          depot: '',
          isDemoProject: false,
        },
  });

  useEffect(() => {
    if (!create) return;
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  const handleSubmit = () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      onSubmit(null);
      return;
    }

    const formValues = form.getValues();
    const project: CreateWorkZone = {
      name: formValues.name,
      customerName: formValues.customer,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      countryCode: formValues.countryCode,
      depotId: formValues.depot,
      isDemoProject: formValues.isDemoProject,
      manualCenter: false,
    };
    onSubmit(project);
  };

  return (
    <Box>
      <Stack gap="md">
        <Input.Wrapper
          labelProps={{ fw: 600 }}
          label="Name"
          required
          error={form.errors.name}
          description="Maximum 100 characters"
        >
          <Input
            placeholder="Enter project name"
            required
            key={form.key('name')}
            {...form.getInputProps('name')}
          />
        </Input.Wrapper>

        <Checkbox
          label="This is a demo project"
          {...form.getInputProps('isDemoProject', { type: 'checkbox' })}
        />

        {countries != null && (
          <Input.Wrapper labelProps={{ fw: 600 }} label="Country" required>
            <Select
              placeholder="Select country"
              required
              key={form.key('countryCode')}
              data={countries.map((c) => ({
                label: c.name,
                value: c.code,
              }))}
              {...form.getInputProps('countryCode')}
              onChange={(c) => {
                form.setFieldValue('countryCode', c ?? '');
                form.setFieldValue('depot', '');
              }}
            />
          </Input.Wrapper>
        )}

        {depots && (
          <Input.Wrapper labelProps={{ fw: 600 }} label="Depot" required>
            <Select
              placeholder="Select depot"
              required
              key={form.key('depot')}
              data={depots
                .filter((d) => d.countryCode === form.getValues().countryCode)
                .map((d) => ({
                  label: d.name,
                  value: d.id,
                }))}
              {...form.getInputProps('depot')}
            />
          </Input.Wrapper>
        )}

        <Input.Wrapper labelProps={{ fw: 600 }} label="Customer" required>
          <Input
            placeholder="Enter customer name"
            required
            key={form.key('customer')}
            {...form.getInputProps('customer')}
          />
        </Input.Wrapper>

        <Group gap="md" grow>
          <DatePickerInput
            placeholder="Start date"
            miw={'140px'}
            label="Start date"
            required
            clearable
            key={form.key('startDate')}
            labelProps={{ fw: 600 }}
            {...form.getInputProps('startDate')}
          />
          <DatePickerInput
            placeholder="End date"
            miw={'140px'}
            label="End date"
            clearable
            key={form.key('endDate')}
            labelProps={{ fw: 600 }}
            {...form.getInputProps('endDate')}
          />
        </Group>
      </Stack>
    </Box>
  );
};
