import { ReportType, getReportDisplayName } from '../../../../models/enums/ReportType';
import {
  Modal,
  Stack,
  TextInput,
  Divider,
  Tabs,
  Checkbox,
  Badge,
  Group,
  Text,
  Transition,
} from '@mantine/core';
import { IconUsers, IconUserPlus } from '@tabler/icons-react';
import { useMobileDevice } from '../../../../hooks/useMobileDevice.tsx';
import { ConfirmButtonGroup } from '../../../../shared/ConfirmButtonGroup/ConfirmButtonGroup.tsx';
import { useState } from 'react';
import { SearchEmployee } from '../../../SearchEmployee/SearchEmployee';
import { Employee } from '../../../../models/Employee';
import { MODAL_ZINDEX } from '@/constants/styling.ts';
import { StatisticSection } from '../constants/enums.ts';

interface Props {
  isOpen: boolean;
  close: (reset?: boolean) => void;
  onExport: (types: ReportType[], contactPerson: ContactPerson, includeStatistics: boolean) => void;
  onStatisticsToggle?: (statIncludedList: StatisticsFilter) => void;
  hasIntellitagsData: boolean;
  hasArrowboardsData: boolean;
  hasSmartCablesData: boolean;
  hasSmartSolarData: boolean;
  projectContactPerson?: ContactPerson;
  fetchEmployees: (query: string) => Promise<Employee[]>;
}

export interface StatisticsIncluded {
  label: StatisticSection;
  checked: boolean;
  key: StatisticSection;
}

export interface StatisticsFilter {
  [StatisticSection.AllSigns]: boolean;
  [StatisticSection.Uptime]: boolean;
  [StatisticSection.SignsUsed]: boolean;
  [StatisticSection.AccAlarmsPerDay]: boolean;
  [StatisticSection.FallenOverEvents]: boolean;
  [StatisticSection.AdressedEvents]: boolean;
  [StatisticSection.MedianAdressedTime]: boolean;
  [StatisticSection.AccAlarmsPerSign]: boolean;
}

interface ContactPerson {
  name: string;
  email: string;
  phone: string;
}

export const ExportReportModal = ({
  isOpen,
  close,
  onExport,
  onStatisticsToggle,
  hasIntellitagsData,
  hasArrowboardsData,
  hasSmartCablesData,
  hasSmartSolarData,
  projectContactPerson,
  fetchEmployees,
}: Props) => {
  const isMobile = useMobileDevice();
  const [selectedDashboards, setSelectedDashboards] = useState<ReportType[]>(() => {
    const initial: ReportType[] = [];
    if (hasIntellitagsData) initial.push(ReportType.Intellitags);
    if (hasArrowboardsData) initial.push(ReportType.Arrowboards);
    if (hasSmartCablesData) initial.push(ReportType.SmartCables);
    if (hasSmartSolarData) initial.push(ReportType.SmartSolar);
    return initial;
  });

  const filterSections = {
    [StatisticSection.AllSigns]: false,
    [StatisticSection.Uptime]: false,
    [StatisticSection.SignsUsed]: false,
    [StatisticSection.AccAlarmsPerDay]: false,
    [StatisticSection.FallenOverEvents]: false,
    [StatisticSection.AdressedEvents]: false,
    [StatisticSection.MedianAdressedTime]: false,
    [StatisticSection.AccAlarmsPerSign]: false,
  };

  const [filter, setFilter] = useState(filterSections);
  const allChecked = Object.values(filter).every((value) => value);
  const indeterminate = Object.values(filter).some((value) => value) && !allChecked;

  const individualStatsCheckboxes = Object.entries(filter).map(([key, value]) => (
    <Checkbox
      ml={33}
      label={key}
      key={key}
      checked={value}
      onChange={(event) => {
        const change = { ...filter, [key]: event.currentTarget.checked };
        setFilter(change);
        onStatisticsToggle?.(change);
      }}
    />
  ));
  const [contactType, setContactType] = useState<'internal' | 'external'>('internal');
  const [internalContact, setInternalContact] = useState<ContactPerson>({
    name: '',
    email: '',
    phone: '',
  });
  const [externalContact, setExternalContact] = useState<ContactPerson>(
    projectContactPerson || {
      name: '',
      email: '',
      phone: '',
    }
  );

  const handleCheckboxChange = (value: ReportType) => {
    setSelectedDashboards((prev) => {
      let newSelection;
      if (prev.includes(value)) {
        newSelection = prev.filter((type) => type !== value);
      } else {
        newSelection = [...prev, value];
      }
      return newSelection;
    });
  };

  const handleExport = () => {
    close();
    const contactPerson = contactType === 'internal' ? internalContact : externalContact;
    onExport(selectedDashboards, contactPerson, indeterminate || allChecked);
    setFilter(filterSections);
  };

  return (
    <Modal.Root
      opened={isOpen}
      onClose={close}
      fullScreen={isMobile}
      size="md"
      centered
      zIndex={MODAL_ZINDEX}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Export pdf report</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack gap="md">
            <Stack gap="xs">
              <Divider label="Select dashboards" labelPosition="center" />
              <Checkbox
                value={ReportType.Intellitags}
                label={getReportDisplayName(ReportType.Intellitags)}
                disabled={!hasIntellitagsData}
                checked={selectedDashboards.includes(ReportType.Intellitags)}
                onChange={() => handleCheckboxChange(ReportType.Intellitags)}
              />
              <Checkbox
                value={ReportType.Arrowboards}
                label={getReportDisplayName(ReportType.Arrowboards)}
                disabled={!hasArrowboardsData}
                checked={selectedDashboards.includes(ReportType.Arrowboards)}
                onChange={() => handleCheckboxChange(ReportType.Arrowboards)}
              />
              <Checkbox
                value={ReportType.SmartCables}
                label={getReportDisplayName(ReportType.SmartCables)}
                disabled={!hasSmartCablesData}
                checked={selectedDashboards.includes(ReportType.SmartCables)}
                onChange={() => handleCheckboxChange(ReportType.SmartCables)}
              />
              <Checkbox
                value={ReportType.SmartSolar}
                label={getReportDisplayName(ReportType.SmartSolar)}
                disabled={!hasSmartSolarData}
                checked={selectedDashboards.includes(ReportType.SmartSolar)}
                onChange={() => handleCheckboxChange(ReportType.SmartSolar)}
              />
            </Stack>

            <Divider label="Statistics" labelPosition="center" />

            <Group align="center" gap={'xs'}>
              <Checkbox
                label={'Add statistics to report'}
                disabled={!selectedDashboards.includes(ReportType.Intellitags)}
                checked={allChecked}
                indeterminate={indeterminate}
                onChange={() => {
                  const change = {
                    [StatisticSection.AllSigns]: !allChecked,
                    [StatisticSection.Uptime]: !allChecked,
                    [StatisticSection.SignsUsed]: !allChecked,
                    [StatisticSection.AccAlarmsPerDay]: !allChecked,
                    [StatisticSection.FallenOverEvents]: !allChecked,
                    [StatisticSection.AdressedEvents]: !allChecked,
                    [StatisticSection.MedianAdressedTime]: !allChecked,
                    [StatisticSection.AccAlarmsPerSign]: !allChecked,
                  };
                  setFilter(change);
                  onStatisticsToggle?.(change);
                }}
              />
              <Badge variant="transparent" c="blue">
                Beta
              </Badge>
            </Group>

            <Transition
              transition="fade"
              duration={400}
              timingFunction="ease"
              mounted={allChecked || indeterminate}
            >
              {(styles) => (
                <Stack gap="sm" style={styles}>
                  {individualStatsCheckboxes}
                </Stack>
              )}
            </Transition>

            <Divider label="Contact Information (optional)" labelPosition="center" />
            <Text size="xs" c="greyText" ta="center">
              This allows you to display a contact person on the report.
            </Text>

            <Tabs
              value={contactType}
              onChange={(value) => setContactType(value as 'internal' | 'external')}
            >
              <Tabs.List>
                <Tabs.Tab value="internal" leftSection={<IconUsers size={16} />}>
                  Internal Contact
                </Tabs.Tab>
                <Tabs.Tab value="external" leftSection={<IconUserPlus size={16} />}>
                  External Contact
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="internal">
                <Stack mt="md">
                  <SearchEmployee
                    width="100%"
                    onChange={(employee) => {
                      setInternalContact({
                        name: employee.name ?? '',
                        email: employee.email ?? '',
                        phone: employee.phone ?? '',
                      });
                    }}
                    onClose={() => {}}
                    fetchEmployees={fetchEmployees}
                  />
                  {internalContact.name && (
                    <Stack gap="xs">
                      <TextInput
                        label="Contact Person"
                        value={internalContact.name}
                        onChange={(e) =>
                          setInternalContact((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                      />
                      <TextInput
                        placeholder="Phone"
                        value={internalContact.phone}
                        onChange={(e) =>
                          setInternalContact((prev) => ({
                            ...prev,
                            phone: e.target.value,
                          }))
                        }
                      />
                      <TextInput
                        placeholder="Email"
                        value={internalContact.email}
                        onChange={(e) =>
                          setInternalContact((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                      />
                    </Stack>
                  )}
                </Stack>
              </Tabs.Panel>

              <Tabs.Panel value="external">
                <Stack mt="md" gap="xs">
                  <TextInput
                    label="Contact Person"
                    placeholder="Name"
                    value={externalContact.name}
                    onChange={(e) => {
                      setExternalContact((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                  />
                  <TextInput
                    placeholder="Phone"
                    value={externalContact.phone}
                    onChange={(e) => {
                      setExternalContact((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }));
                    }}
                  />
                  <TextInput
                    placeholder="Email"
                    value={externalContact.email}
                    onChange={(e) => {
                      setExternalContact((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                    }}
                  />
                </Stack>
              </Tabs.Panel>
            </Tabs>
            <div
              style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'var(--mantine-color-body)',
                padding: 'var(--mantine-spacing-md)',
                borderTop: '1px solid var(--mantine-color-gray-3)',
                marginLeft: 'calc(var(--mantine-spacing-md) * -1)',
                marginRight: 'calc(var(--mantine-spacing-md) * -1)',
                marginBottom: 'calc(var(--mantine-spacing-md) * -1)',
              }}
            >
              <ConfirmButtonGroup
                disabled={selectedDashboards.length === 0}
                confirmBtnText="Export"
                onAbort={() => {
                  setFilter(filterSections);
                  close(true);
                }}
                onConfirm={handleExport}
              />
            </div>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ExportReportModal;
