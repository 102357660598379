import { ArrowDirection, ARROW_DIRECTION_NAMES } from '../../../../models/Device';
import { LabelValueText } from '../../../../shared/LabelValueText';

type Props = {
  arrowDirection: ArrowDirection;
};

export const ArrowboardInfo = ({ arrowDirection }: Props) => {
  return (
    <>
      <LabelValueText
        width="100%"
        label="Arrow Direction"
        value={ARROW_DIRECTION_NAMES[arrowDirection]}
      />
    </>
  );
};
