import { Tooltip } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';

interface Props {
  text: string;
  textBoxWidth: number;
  size?: number;
  iconColor?: string;
  textBoxColor?: string;
  textColor?: string;
}

export const InfoIcon = ({
  size = 24,
  text,
  iconColor = 'gray',
  textBoxWidth,
  textBoxColor = 'white',
  textColor = 'black',
}: Props) => {
  return (
    <Tooltip
      bg={textBoxColor}
      c={textColor}
      style={{ boxShadow: 'rgba(100, 100, 111, 0.4) 0px 7px 29px 0px' }}
      p={'sm'}
      multiline
      w={textBoxWidth}
      withArrow
      transitionProps={{ duration: 200 }}
      label={text}
      position="right"
      offset={5}
    >
      <IconHelp data-testid="infoIcon" size={size} color={iconColor} />
    </Tooltip>
  );
};
