import { useMutation, useQueryClient } from '@tanstack/react-query';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';
import { CreateProjectFlowFormValues } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { ScenarioType } from '@/models/enums/ScenarioEnums';
import { PsaSetupType } from '@/models/enums/ProjectEnums';
import { Polygon } from 'geojson';

const path = `/workZone/flow`;

export type CreateProjectFromFlowParams = CreateProjectFlowFormValues['informationStep'] &
  CreateProjectFlowFormValues['notificationStep'] & {
    psaSetup?: {
      scenario: ScenarioType;
      setupType: PsaSetupType;
      assignedPsas?: { id: string }[];
      geozoneGeometry?: Polygon;
    };
  };

const useCreateProjectFromFlow = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending, isError } = useMutation({
    mutationFn: (params: CreateProjectFromFlowParams) => {
      return postData<CreateProjectFromFlowParams>(path, params, instance);
    },
    onError: (error) => {
      console.error(`Error creating project`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
    isError,
  };
};

export default useCreateProjectFromFlow;
