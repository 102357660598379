import { useMemo } from 'react';
import { supervisionReportIntervals } from '../config/supervisionReportIntervals';
import { formatTimeByCountry } from '../utils/utils';
import dayjs from '../config/dayjsConfig';

/**
 * TimeSegment interface representing a consistent time display segment
 */
export interface TimeSegment {
  date: string; // Formatted date part (e.g., "Mon, 15/3")
  startTime: string; // The start time portion (e.g., "08:00")
  endTime: string; // The end time portion (e.g., "16:00")
  timestamp: string; // Original ISO timestamp from the data
}

/**
 * Creates time segments from timestamps using "wall clock time" approach
 * which provides consistent display regardless of DST transitions
 *
 * @param timestamps Array of ISO timestamp strings
 * @param intervalHours Number of hours per interval or string in format "HH:00:00"
 * @param countryCode Country code to use for formatting (e.g., 'SE')
 * @returns Array of TimeSegment objects
 */
export function createTimeSegments(
  timestamps: string[],
  intervalHours: number | string,
  countryCode: string
): TimeSegment[] {
  if (!timestamps || !timestamps.length) return [];

  let hours: number;
  if (typeof intervalHours === 'string') {
    hours = parseInt(intervalHours.split(':')[0], 10);
  } else if (countryCode) {
    const intervalStr =
      supervisionReportIntervals[countryCode] || supervisionReportIntervals['_'] || '08:00:00';
    hours = parseInt(intervalStr.split(':')[0], 10);
  } else {
    hours = intervalHours;
  }

  // Define segment hours using fixed boundaries
  const segmentHours: number[] = [];
  for (let i = 0; i < 24; i += hours) {
    segmentHours.push(i);
  }

  return timestamps.map((timestamp) => {
    // Convert UTC timestamp directly to local browser time
    const localTime = dayjs.utc(timestamp).local();

    // Get the hour in local time
    const hour = localTime.hour();

    // Find which segment this falls into in local time
    const segmentIndex =
      segmentHours.findIndex(
        (startHour, i) =>
          hour >= startHour && (i === segmentHours.length - 1 || hour < segmentHours[i + 1])
      ) || 0;

    // Get segment hours in local time
    const segmentStartHour = segmentHours[segmentIndex];
    const segmentEndHour = segmentHours[(segmentIndex + 1) % segmentHours.length];

    // Format segment times for display in local time
    const startTime = `${segmentStartHour.toString().padStart(2, '0')}:00`;
    const endTime = `${segmentEndHour.toString().padStart(2, '0')}:00`;

    // Use country-specific formatting if needed
    const formattedStartTime = countryCode
      ? formatTimeByCountry(startTime, countryCode)
      : startTime;

    const formattedEndTime = countryCode ? formatTimeByCountry(endTime, countryCode) : endTime;

    return {
      // Format date in local time
      date: localTime.format('ddd, D/M'),
      // Display segment boundaries in local time
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      timestamp,
    };
  });
}

/**
 * React hook that provides time segments for DST-aware time displays
 *
 * @param timestamps Array of ISO timestamp strings
 * @param intervalHours Number of hours per interval or string in format "HH:00:00"
 * @param countryCode Country code to determine timezone
 * @returns Memoized array of TimeSegment
 */
export function useTimeSegments(
  timestamps: string[] | undefined,
  intervalHours: number | string,
  countryCode: string
): TimeSegment[] {
  return useMemo(() => {
    if (!timestamps || !timestamps.length) return [];
    return createTimeSegments(timestamps, intervalHours, countryCode);
  }, [timestamps, intervalHours, countryCode]);
}

export default useTimeSegments;
