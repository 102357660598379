import { Point } from 'geojson';
import {
  AlarmStatus,
  BatteryStatus,
  EquipmentType,
  LifeCycleStatus,
  PoweredEquipment,
} from './enums/DeviceEnums';
import { ArrowDirection, ARROW_DIRECTION_NAMES, DeviceType } from './Device.ts';

export interface DeviceLog {
  additionalProperties: string;
  alarmStatus: AlarmStatus;
  arrowDirection: ArrowDirection;
  attachmentRef: string;
  batteryStatus: BatteryStatus;
  batteryVoltage: number;
  currentEquipmentType: EquipmentType;
  deviceId: string;
  referenceId: string;
  deviceType: DeviceType;
  externalBatteryVoltage: number | undefined;
  id: string;
  lifeCycleStatus: LifeCycleStatus;
  name: string;
  position: Point;
  poweredEquipment: PoweredEquipment;
  timestamp: string;
  workZoneId: string;
  workZoneName: string;
  workZoneSnoozed: boolean;
}

export function GetArrowDirectionFromDeviceLog(deviceLog: DeviceLog) {
  if (deviceLog.additionalProperties != null) {
    try {
      const parsedProperties = JSON.parse(deviceLog.additionalProperties);
      const arrowDirection = parsedProperties?.ArrowDirection as ArrowDirection;
      return ARROW_DIRECTION_NAMES[arrowDirection] ?? 'Unknown';
    } catch {
      return 'Invalid Data';
    }
  }
  return '-';
}
