import { Group, Paper, Stack, Text, Title } from '@mantine/core';

interface Props {
  data: { value: number | string; explanation: string }[];
  hide?: boolean;
}

export const NumberCard = ({ data, hide = false }: Props) => {
  if (!data.length) return;
  const content = data.map((d, i) => (
    <Stack key={i} align="center" w={'150px'}>
      <Title order={2}>{d.value}</Title>
      <Text size="sm" ta={'center'} c={'gray.7'}>
        {d.explanation}
      </Text>
    </Stack>
  ));
  return (
    <>
      <Paper display={hide ? 'none' : undefined} withBorder p="xl" w="fit-content">
        <Group h="100%" justify="center">
          {content}
        </Group>
      </Paper>
    </>
  );
};
