import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import { formatDate } from '@/utils/utils';
import { PdfBaseProps } from '@/models/PdfExport';
import {
  addCo2Graphs,
  addHeader,
  createDocumentName,
  createPdfDocument,
} from '../../../../workers/pdf/utils/pdfCommon';
import { getReportDisplayName, ReportType } from '@/models/enums/ReportType';

export interface PdfProps extends PdfBaseProps {
  barChartImg: string;
  totalCo2: string;
  appendToDoc?: jsPDF;
}

export async function createCo2PDF(props: PdfProps): Promise<void> {
  const doc = props.appendToDoc || createPdfDocument();
  const startDateString = dayjs(props.reportStartDate).format('DD MMM YYYY');
  const endDateString = dayjs(props.reportEndDate).format('DD MMM YYYY');

  return new Promise<void>((resolve) => {
    addHeader(doc, {
      projectName: props.projectName,
      title: getReportDisplayName(ReportType.Co2),
      startDate: startDateString,
      endDate: endDateString,
    });

    // Add statistics page if needed
    if (props.barChartImg) {
      addCo2Graphs(doc, {
        img: props.barChartImg,
        totalCo2: props.totalCo2,
      });
    }

    if (!props.appendToDoc) {
      doc.save(
        createDocumentName(
          props.projectName,
          formatDate(props.reportStartDate),
          formatDate(props.reportEndDate)
        )
      );
    }

    resolve();
  });
}
