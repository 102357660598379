import { useMemo, useState } from 'react';
import { Stack } from '@mantine/core';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { Device } from '@/models/Device';
import { DeviceMarkerWrapper } from './DeviceMarkerWrapper/DeviceMarkerWrapper';
import { AlarmIcon } from './AlarmIcon/AlarmIcon';
import classes from './DeviceMarker.module.css';
import {
  AlarmStatus,
  AlarmStatusNames,
  AlarmType,
  BatteryStatus,
  BatteryStatusNames,
} from '@/models/enums/DeviceEnums';
import { useMapZoomLevel, ZoomLevel } from '@/hooks/useMapZoomLevel';

const alertStyleMap = {
  [BatteryStatusNames[BatteryStatus.OUT_OF_BATTERY]]: {
    border: '2px solid rgb(158, 158, 158)',
    backgroundColor: 'rgba(158, 158, 158, 0.2)',
  },
  [AlarmStatusNames[AlarmStatus.Alarming]]: {
    border: '1px solid red',
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
  },
  BLUE_ALARM: {
    border: '1px solid rgb(var(--linkBlue))',
    backgroundColor: 'rgba(var(--linkBlue), 0.2)',
  },
  [BatteryStatusNames[BatteryStatus.CRITICAL]]: {
    border: '1px solid red',
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
  },
  [BatteryStatusNames[BatteryStatus.LOW]]: {
    border: '2px solid rgb(248, 220, 2)',
    backgroundColor: 'rgba(248, 220, 2, 0.2)',
  },
};

export interface Props {
  baseDevice: Device;
  map: google.maps.Map | null;
  onClick: (id: string) => void;
  visibleAtZoomLevel: ZoomLevel | ZoomLevel[];
  isSelected?: boolean;
  isHovered?: boolean;
  onHoverChange?: (deviceId: string | null) => void;
}

export const DeviceMarker = ({
  baseDevice,
  map,
  onClick,
  isSelected,
  visibleAtZoomLevel,
  isHovered = false,
  onHoverChange,
}: Props) => {
  const { isAtZoomLevel } = useMapZoomLevel({
    map,
    zoomLevel: visibleAtZoomLevel,
  });
  const [isLocalHovered, setIsLocalHovered] = useState(false);

  const effectivelyHovered = isHovered || isLocalHovered;

  // Alert background square style
  const alertType = useMemo(() => {
    if (baseDevice.batteryStatus === BatteryStatus.OUT_OF_BATTERY) {
      return BatteryStatusNames[BatteryStatus.OUT_OF_BATTERY] as 'OUT_OF_BATTERY';
    } else if (baseDevice.alarmStatus === AlarmStatus.Alarming) {
      if (baseDevice.alarmType === AlarmType.PsaBlueAlert) {
        return 'BLUE_ALARM';
      }
      return AlarmStatusNames[AlarmStatus.Alarming] as 'Alarming';
    } else if (baseDevice.batteryStatus === BatteryStatus.CRITICAL) {
      return BatteryStatusNames[BatteryStatus.CRITICAL] as 'CRITICAL';
    } else if (baseDevice.batteryStatus === BatteryStatus.LOW) {
      return BatteryStatusNames[BatteryStatus.LOW] as 'LOW';
    }
  }, [baseDevice]);

  // Add styling that combines selection and hover states
  const combinedStyles = useMemo(() => {
    let styles = {};

    // Apply alert styles based on the alertType returned from existing function
    if (alertType && alertStyleMap[alertType]) {
      styles = { ...styles, ...alertStyleMap[alertType] };
    }

    // Apply hover styles
    if (effectivelyHovered && !isSelected) {
      styles = {
        ...styles,
        transform: 'scale(1.1)',
        zIndex: 100,
        boxShadow: 'var(--mantine-shadow-md)',
      };
    }

    // Apply selected styles (these take precedence)
    if (isSelected) {
      styles = {
        ...styles,
        transform: 'scale(1.2)',
        zIndex: 200,
        boxShadow: 'var(--mantine-shadow-lg)',
      };
    }

    return styles;
  }, [alertType, isSelected, effectivelyHovered]);

  if (
    !baseDevice.position ||
    !baseDevice.position.coordinates ||
    baseDevice.position.coordinates.length < 2
  ) {
    return null;
  }

  const handleMouseEnter = () => {
    setIsLocalHovered(true);
    if (onHoverChange) {
      onHoverChange(baseDevice.id);
    }
  };

  const handleMouseLeave = () => {
    setIsLocalHovered(false);
    if (onHoverChange) {
      onHoverChange(null);
    }
  };

  return (
    <>
      {isAtZoomLevel && (
        <AdvancedMarker
          key={baseDevice.id}
          map={map}
          onClick={() => onClick(baseDevice.id || '')}
          position={{
            lat: baseDevice.position.coordinates[1],
            lng: baseDevice.position.coordinates[0],
          }}
        >
          <div
            className={classes.markerWrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isSelected && <div className={classes.triangleSelect} />}
            <div className={classes.markerContainer}>
              <Stack
                align="center"
                gap="4px"
                className={`${classes.deviceMarkerBase}`}
                style={combinedStyles}
              >
                <DeviceMarkerWrapper device={baseDevice} />
              </Stack>
              <AlarmIcon icon={alertType} />
            </div>
          </div>
        </AdvancedMarker>
      )}
    </>
  );
};
