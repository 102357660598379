import { SimpleGrid, Stack, Card, Text, Title, Image } from '@mantine/core';
import { IconCircle } from '@tabler/icons-react';
import { PoweredEquipment, PoweredEquipmentNames } from '../../../models/enums/DeviceEnums.ts';
import { POWERED_EQUIPMENT_IMAGE_MAP } from '@/constants/imageConstants.ts';

interface Props {
  poweredEquipment: PoweredEquipment;
  setPoweredEquipment: (poweredEquipment: PoweredEquipment) => void;
}

// Map each PoweredEquipment to a Tabler Icon
const equipmentIcons = {
  [PoweredEquipment.Other]: <IconCircle size={40} />,
  [PoweredEquipment.Buffer]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.Buffer]} w={40} />
  ),
  [PoweredEquipment.TrafficLight]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.TrafficLight]} w={40} />
  ),
  [PoweredEquipment.WarningLight]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.WarningLight]} w={40} />
  ),
  [PoweredEquipment.SequentialLight]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.SequentialLight]} w={40} />
  ),
};

export const SmartCableInput = ({ poweredEquipment, setPoweredEquipment }: Props) => {
  const equipmentList = Object.keys(PoweredEquipmentNames).map((key) => {
    const equipment = Number(key) as Exclude<PoweredEquipment, PoweredEquipment.Unknown>;
    if (Number(key) === PoweredEquipment.Unknown) return;
    return (
      <Card
        key={key}
        onClick={() => setPoweredEquipment(equipment)}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{
          cursor: 'pointer',
          borderColor: poweredEquipment === equipment ? 'blue' : 'transparent',
        }}
      >
        <Stack align="center">
          {equipmentIcons[equipment]}
          <Text ta="center" fw={500}>
            {PoweredEquipmentNames[equipment]}
          </Text>
        </Stack>
      </Card>
    );
  });

  return (
    <>
      <Title order={4}>Powered Equipment</Title>
      <Stack>
        <SimpleGrid cols={3} spacing="lg">
          {equipmentList}
        </SimpleGrid>
      </Stack>
    </>
  );
};
