import { Card, Text, Stack, Group, ActionIcon, Button, Divider, Title } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { Device, DeviceTypeNames } from '../../../../models/Device.ts';
import { FlexSpacer } from '../../../../shared/FlexSpacer.tsx';
import { LabelValueText } from '../../../../shared/LabelValueText.tsx';
import { DeviceSpecifcInfoWrapper } from './DeviceSpecificInfoWrapper.tsx';
import { DeviceImageWrapper } from './DeviceImageWrapper.tsx';
import { BadgeWrapper } from '../../../Badge/BadgeWrapper.tsx';
import { useDisclosure } from '@mantine/hooks';
import { formatDateTimeString } from '../../../../utils/utils.tsx';
import { AddDeviceToProjectModal } from '../../../Device/AddDeviceToProject/AddDeviceToProjectModal.tsx';
import useAssignDeviceToProject from '../../../../data/hooks/Device/useAssignDeviceToProjectHook.ts';
import { useMsal } from '@azure/msal-react';

interface Props {
  device: Device | null;
  projectName: string;
  onClose: () => void;
  onNavigate: (projectId: string, deviceId: string) => void;
}

export const BaseDeviceInfoWrapper = ({ device, projectName, onClose, onNavigate }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { instance } = useMsal();
  const { mutateAsync: assignDeviceToProject } = useAssignDeviceToProject(instance);

  if (!device) return;
  return (
    <>
      <Card className="infocard" p="md" shadow="lg">
        <Stack align="flex-start" gap={'2px'}>
          <Group w="100%" wrap="nowrap">
            <DeviceImageWrapper baseDevice={device} />
            <Stack gap="xs">
              <Title order={5} fw={400} lh={1.3}>
                {device.currentName ?? DeviceTypeNames[device.deviceType]}
              </Title>
              <Text c="gray.6" lh={1}>
                {device.referenceId}
              </Text>
            </Stack>
            <FlexSpacer />
            <ActionIcon mb={'auto'} c={'black'} variant="transparent" onClick={onClose}>
              <IconX />
            </ActionIcon>
          </Group>
          <Divider w={'100%'} label="Device" labelPosition="center" />
          <Group gap={'xs'} w={'100%'} align="center">
            {device.batteryVoltage ? (
              <>
                <Text size="md" fw={700}>
                  {device.batteryVoltage}
                </Text>
                (V)
              </>
            ) : null}
            <FlexSpacer />
            <BadgeWrapper device={device} />
          </Group>
          <LabelValueText
            width="100%"
            label="Latest sync"
            value={formatDateTimeString(device.latestUpdate)}
          />
          <LabelValueText
            width="100%"
            label="Device type"
            value={DeviceTypeNames[device.deviceType]}
          />
          <Divider w={'100%'} label="Current Allocation" labelPosition="center" />
          <LabelValueText width="100%" label="Project" value={projectName} />
          <DeviceSpecifcInfoWrapper device={device} />
          <Group w={'100%'} justify="flex-end" mt="md">
            {device.workZoneId ? (
              <Button
                variant="default"
                size="xs"
                onClick={() => onNavigate(device.workZoneId, device.id)}
              >
                Go to project
              </Button>
            ) : (
              <Button variant="default" size="xs" onClick={() => open()}>
                Assign to project
              </Button>
            )}
          </Group>
          <AddDeviceToProjectModal
            showModal={opened}
            initialProject={null}
            initialDevice={device}
            close={close}
            onSubmit={() =>
              assignDeviceToProject({
                projectId: device.workZoneId,
                deviceId: device.id,
                equipmentType: device.currentEquipmentType,
                currentName: device.currentName,
                attachmentRef: device.attachmentRef,
                poweredEquipment: device.poweredEquipment,
              })
            }
          ></AddDeviceToProjectModal>
        </Stack>
      </Card>
    </>
  );
};
