import { buildQueryString, prepareHeaders } from '@/data/api/apiHelpers';
import { APIResponseSingleItem } from '@/data/api/fetchDataSingleItem';
import { SnoozeDurationsDto } from '@/models/SnoozeDurationsDto';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useQuery } from '@tanstack/react-query';

interface Params {
  projectId: string;
  from: string;
  until: string;
}
const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const path = `/workzone/snoozedurations`;

const useGetSnoozeDurations = (instance: IPublicClientApplication, params: Params) => {
  const { data, isLoading, isError } = useQuery<APIResponseSingleItem<SnoozeDurationsDto>>({
    queryKey: ['snoozeDurations', params.projectId, params.from, params.until],
    queryFn: async () => {
      const queryString = buildQueryString(params);
      const response = await fetch(`${baseUrl}${path}${queryString}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (!response.ok) {
        throw await response.json();
      }
      const data = await response.json();
      return {
        item: data ?? null,
      };
    },
  });

  return {
    snoozeDurations: data?.item.snoozeDurations,
    isLoading,
    isError,
  };
};

export default useGetSnoozeDurations;
