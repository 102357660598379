import { Box, Button, Stack, Text, Title, Loader, Drawer } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useState, useEffect, useMemo } from 'react';
import { DeviceEventType } from '../../../models/DeviceEvent';
import { DeviceEventsFilter } from './DeviceEventsFilter';
import { DeviceEventList } from './DeviceEventList';
import useDeviceEvents from '../../../data/hooks/Device/useDeviceEventsHook';
import { useMsal } from '@azure/msal-react';

interface DeviceEventsSidebarProps {
  deviceId: string | null;
  onClose: () => void;
  title?: string;
  visible: boolean;
  isMobile?: boolean;
  projectStartDate?: Date;
  timeTravelDate?: Date | null;
  projectId?: string;
}

export const DeviceEventsSidebar = ({
  deviceId,
  onClose,
  title = 'Device events',
  visible = true,
  isMobile = false,
  projectStartDate,
  timeTravelDate,
  projectId,
}: DeviceEventsSidebarProps) => {
  const { instance } = useMsal();
  const [selectedFilters, setSelectedFilters] = useState<DeviceEventType[]>([]);
  const [availableEventTypes, setAvailableEventTypes] = useState<DeviceEventType[]>([]);

  // Fetch events
  const { events, isLoading, error } = useDeviceEvents(
    {
      deviceId,
      eventTypes: selectedFilters.length > 0 ? selectedFilters : undefined,
      projectId,
    },
    instance
  );

  // Filter events by date range
  const filteredEvents = useMemo(() => {
    return events.filter((event) => {
      const eventDate = new Date(event.timestamp);
      const startDate = projectStartDate ? new Date(projectStartDate) : null;
      const endDate = timeTravelDate ? new Date(timeTravelDate) : null;

      if (startDate && eventDate < startDate) return false;
      if (endDate && eventDate > endDate) return false;
      return true;
    });
  }, [events, projectStartDate, timeTravelDate]);

  // Update available event types based on filtered events
  useEffect(() => {
    if (deviceId && !isLoading) {
      if (filteredEvents.length > 0) {
        const uniqueTypes = Array.from(new Set(filteredEvents.map((event) => event.type)));
        setAvailableEventTypes(uniqueTypes);
      } else {
        setAvailableEventTypes([]);
      }
    }
  }, [deviceId, isLoading, filteredEvents]);

  // Reset filters when device changes
  useEffect(() => {
    setSelectedFilters([]);
  }, [deviceId]);

  // Content for the sidebar
  const content = (
    <Stack p="md" bg={'var(--mantine-color-lightGrey-0)'} h="100%">
      <Stack gap="sm">
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Title
            order={5}
            fw={500}
            style={{
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Title>
          <Button
            variant="subtle"
            p={0}
            size="sm"
            onClick={onClose}
            w={22}
            h={22}
            miw="unset"
            ml={8}
            style={{ flexShrink: 0 }}
          >
            <IconX />
          </Button>
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DeviceEventsFilter
            selectedFilters={selectedFilters}
            onFilterChange={setSelectedFilters}
            availableEventTypes={availableEventTypes}
            isLoading={isLoading}
          />
        </Box>
      </Stack>

      <Box style={{ flex: 1, overflow: 'hidden' }}>
        {isLoading && (
          <Stack align="center" justify="center" style={{ flex: 1 }}>
            <Loader size="sm" />
            <Text c="dimmed" size="sm">
              Loading events...
            </Text>
          </Stack>
        )}

        {error && !isLoading && (
          <Stack align="center" justify="center" style={{ flex: 1 }}>
            <Text c="dangerRed" size="sm">
              {error instanceof Error ? error.message : 'Failed to load device events'}
            </Text>
          </Stack>
        )}

        {!isLoading && !error && filteredEvents.length > 0 && (
          <DeviceEventList events={filteredEvents} />
        )}

        {!isLoading && !error && filteredEvents.length === 0 && (
          <Stack align="center" justify="center" style={{ flex: 1 }}>
            <Text c="dimmed" size="sm">
              {selectedFilters.length > 0
                ? 'No events found matching your filters'
                : 'No events found for this device'}
            </Text>
          </Stack>
        )}
      </Box>
    </Stack>
  );

  // For mobile, render content inside a Drawer
  if (isMobile) {
    return (
      <Drawer
        opened={visible}
        onClose={onClose}
        size={'100%'}
        position="left"
        withCloseButton={false}
        padding={0}
        zIndex={200}
      >
        <Box style={{ height: '100vh' }}>{content}</Box>
      </Drawer>
    );
  }

  return content;
};
