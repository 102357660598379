import { useMutation, useQueryClient } from '@tanstack/react-query';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';
import { notifications } from '@mantine/notifications';

interface Params {
  instance: IPublicClientApplication;
  id: string;
}

const enabledPath = `/workZone/enablePowerSavingMode`;
const disabledPath = `/workZone/disablePowerSavingMode`;

const useTogglePowerSavingMode = ({ instance, id }: Params) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending } = useMutation({
    mutationFn: (enablePowerSaving: boolean) => {
      return postData(enablePowerSaving ? enabledPath : disabledPath, { id }, instance);
    },
    onError: (error) => {
      console.error('Toggle power saving mode failed:', error);
      notifications.show({
        title: 'Toggle power saving mode failed',
        message: `Message: ${error.message}`,
        color: 'var(--mantine-color-dangerRed-9)',
        autoClose: 10000,
        position: 'bottom-center',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects', id] });
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
  };
};

export default useTogglePowerSavingMode;
