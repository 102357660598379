import dayjs from 'dayjs';
import { Device } from '../models/Device';
import QRCode from 'qrcode';

export const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const WEEKDAYS_LONG = [
  'Sunday',
  'Monday',
  'Thusday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const dateTimeFormatter = new Intl.DateTimeFormat('en-SE', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const dateFormatter = new Intl.DateTimeFormat('en-SE', {
  dateStyle: 'short',
});

export function formatDate(date?: Date): string {
  if (date == null) {
    return '-';
  }
  try {
    return dateFormatter.format(new Date(date));
  } catch (e) {
    return '-';
  }
}

export function formatDateTimeString(dateString?: string): string {
  if (dateString == null) {
    return '-';
  }
  try {
    return dateTimeFormatter.format(new Date(dateString));
  } catch (e) {
    return '-';
  }
}

export function formatDateTime(dateString?: Date): string {
  if (dateString == null) {
    return '-';
  }
  try {
    return dateTimeFormatter.format(new Date(dateString));
  } catch (e) {
    return '-';
  }
}

export function getEndOfDay(date: Date): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999); // Set time to the very end of the day
  return endOfDay;
}

export const calcNumberOfDays = (from: Date, to: Date): number => {
  const timeDifference = to.getTime() - from.getTime();
  return timeDifference / (1000 * 60 * 60 * 24);
};

export const isBetween = (value: number, min: number, max: number): boolean => {
  return value >= min && value <= max;
};

export const panTo = (lat: number, lng: number, map: google.maps.Map | null): void => {
  map?.panTo({ lat: lat, lng: lng });
  map?.setZoom(17);
};

export const generateQrCode = async (device: Device) => {
  const referenceId = device?.referenceId;
  const json = `{"imei": "${device?.imei}", "referenceId": "${referenceId}", "version": "1.0"}`;
  // Generate QR code
  const qrCodeDataURL = await QRCode.toDataURL(json);

  // Create canvas
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const qrImage = new Image();

  qrImage.onload = () => {
    // Set canvas dimensions
    canvas.width = qrImage.width;
    canvas.height = qrImage.height + 30;

    if (!ctx) {
      return;
    }

    // Fill the background with white
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw QR code onto canvas
    ctx.drawImage(qrImage, 0, 0);

    // Add reference ID below the QR code
    ctx.font = '20px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'black';
    ctx.fillText(referenceId, qrImage.width / 2, qrImage.height + 20);

    // Convert canvas to PNG and trigger download
    const pngDataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = pngDataURL;
    link.download = `qr_code_${device?.referenceId}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  qrImage.src = qrCodeDataURL;
};

export function getEstimatedBatteryLifetime(estimatedBatteryEndDate?: string): string {
  if (!estimatedBatteryEndDate) {
    return ''; // Handle case where estimatedBatteryEndDate is not provided
  }

  const daysRemaining = calcNumberOfDays(
    new Date(),
    new Date(formatDateTimeString(estimatedBatteryEndDate))
  );
  if (daysRemaining <= 0) {
    return 'Out of battery';
  }
  const monthsRemaining = Math.floor(daysRemaining / 30);

  if (monthsRemaining < 11) {
    if (monthsRemaining < 1) {
      return 'Less than a month';
    } else {
      return `Less than ${monthsRemaining + 1} months`;
    }
  } else {
    const yearsRemaining = Math.floor(monthsRemaining / 12);
    return yearsRemaining < 1 ? 'Less than a year' : `Less than ${yearsRemaining + 1} years`;
  }
}

export function createDateRanges(
  startDate: Date,
  endDate: Date,
  rangeDays: number[],
  pagesDueToPageBreaks: number
) {
  const dateRanges = [];
  let rangeIndex = 0;

  const currentStartDate = new Date(startDate.getTime());
  const originalEndDate = new Date(endDate.getTime());

  // Loop to create ranges
  while (currentStartDate <= originalEndDate) {
    // Get the current range days from the list
    const currentRangeDays = rangeDays[rangeIndex % rangeDays.length];

    // Calculate the range end by adding currentRangeDays
    let rangeEnd = new Date(currentStartDate);
    rangeEnd.setDate(rangeEnd.getDate() + currentRangeDays - 1);

    // Make sure rangeEnd doesn't go past the end date
    if (rangeEnd > originalEndDate) {
      rangeEnd = new Date(originalEndDate);
    }

    // Add range to the array
    dateRanges.push({
      start: new Date(currentStartDate),
      end: new Date(rangeEnd),
    });

    // Move startDate to the day after the rangeEnd
    currentStartDate.setDate(rangeEnd.getDate() + 1);

    // Move to the next rangeDays value
    rangeIndex++;
  }

  return dateRanges.map((range) => Array(pagesDueToPageBreaks).fill(range)).flat(1);
}

export function formatTimeByCountry(timeStr: string, countryCode: string): string {
  // Parse hours and minutes from either "HH:MM" or a Date
  const [hours, minutes] = /^\d{1,2}:\d{2}$/.test(timeStr)
    ? timeStr.split(':').map(Number)
    : [new Date(timeStr).getHours(), new Date(timeStr).getMinutes()];

  // UK and CA use 12h format
  if (countryCode === 'GB' || countryCode === 'CA') {
    const period = hours >= 12 ? 'pm' : 'am';
    const displayHours = hours % 12 || 12;
    // For exact hours (no minutes), show only the hour
    if (minutes === 0) {
      return `${displayHours} ${period}`;
    }
    return `${displayHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  // Default 24h format
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export const sameDay = (d1: Date, d2: Date): boolean => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export function getDaysBetweenDates(startDate: Date, endDate: Date, week: string[]) {
  const daysOfWeek: string[] = [];
  if (sameDay(startDate, endDate)) return [week[startDate.getDay()]];
  for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    const dayName = week[date.getDay()];
    if (!daysOfWeek.includes(dayName)) {
      daysOfWeek.push(dayName);
    }
  }

  return daysOfWeek;
}

export function numberOfDaysBetweenDates(date1: string, date2: string): number {
  const date = dayjs(date1);
  return date.diff(date2, 'day');
}
