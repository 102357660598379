import { ScrollArea } from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import useAssignDeviceToProject from '../../../data/hooks/Device/useAssignDeviceToProjectHook';
import { WorkZone } from '../../../models/Project';
import useProjectDevices from '../../../data/hooks/Device/useProjectDevicesHook';
import { ConnectedDevices } from './ConnectedDevices';
import { useDisclosure } from '@mantine/hooks';
import { AddDeviceToProjectModal } from '../../Device/AddDeviceToProject/AddDeviceToProjectModal';
import useUnassignDeviceFromProject from '../../../data/hooks/Device/useUnassignDeviceFromProject.ts';
import { TabTitle } from '@/components/TabTitle/TabTitle.tsx';

interface Props {
  project: WorkZone;
}

export const ManageDevices = ({ project }: Props) => {
  const { instance } = useMsal();
  const [opened, { open, close }] = useDisclosure(false);

  const { projectDevices } = useProjectDevices({
    instance,
    projectIds: [project.id],
  });

  const { mutateAsync: assignDeviceToProject } = useAssignDeviceToProject(instance);

  const { mutateAsync: unassignDeviceFromProject } = useUnassignDeviceFromProject(instance);

  return (
    <>
      <TabTitle>Manage Devices</TabTitle>
      <ScrollArea maw="100%" h="60vh">
        <ConnectedDevices
          devices={projectDevices[project.id]}
          onRemove={(id) =>
            unassignDeviceFromProject({
              deviceId: id,
            })
          }
          onAdd={open}
        />
      </ScrollArea>
      <AddDeviceToProjectModal
        initialDevice={null}
        initialProject={project}
        showModal={opened}
        close={close}
        onSubmit={(device) =>
          assignDeviceToProject({
            projectId: device.workZoneId,
            deviceId: device.id,
            equipmentType: device.currentEquipmentType,
            currentName: device.currentName,
            attachmentRef: device.attachmentRef,
            poweredEquipment: device.poweredEquipment,
          })
        }
      />
    </>
  );
};
