import { WorkZone } from '@/models/Project';

export const getDateOffset = (hours: string | null, startDate: Date): Date => {
  const endDate = new Date(startDate);
  if (hours == null) {
    return endDate;
  }
  const offset = Number(hours.replace('h', ''));
  endDate.setHours(endDate.getHours() + offset);
  return endDate;
};

export const isSnoozeActive = (project: WorkZone): boolean => {
  return project.isSnoozed;
};

export const isSnoozeScheduled = (project: WorkZone): boolean => {
  const now = new Date();
  return !!project.snoozedFrom && new Date(project.snoozedFrom) > now;
};
