import { ForwardedRef, useImperativeHandle } from 'react';
import { usePdfWorker } from '@/contexts/PdfWorker/usePdfWorker';
import { PdfBaseProps, PdfExportRef } from '@/models/PdfExport';
import { ReportType } from '@/models/enums/ReportType';
import { DeviceRow } from '@/models/SupervisionReport';
import { DashboardComponent } from '@/models/Dashboard';
import { EquipmentType } from '@/models/enums/DeviceEnums';
import primaryLogo from '@/assets/report/logo-primary.png';
import primaryLogoWhite from '@/assets/report/logo-primary-white.png';
import coverBackground from '@/assets/report/cover-page.png';
import checkIcon from '@/assets/report/StatusIcons/circle-check.png';
import alertIcon from '@/assets/report/StatusIcons/alert-circle.png';
import dottedIcon from '@/assets/report/StatusIcons/circle-dotted.png';
import squareIcon from '@/assets/report/StatusIcons/square-gray.png';
import { convertImageMapForWorker, convertIconToBase64Cached } from '@/utils/base64Utils';
import { prepareRoadSignsForWorker } from '@/utils/ImageHelpers';

/**
 * Hook for component-based PDF export with ref
 */
export const useComponentExporter = <D, P extends PdfBaseProps>(
  ref: ForwardedRef<PdfExportRef>,
  props: DashboardComponent<D>,
  additionalPdfProps: Omit<P, keyof PdfBaseProps> & {
    imageMap: Map<string, HTMLImageElement>;
    countryCode: string;
    rows: DeviceRow[];
    timeStamps: string[];
    intervalInHours: number;
    showSnoozed: boolean;
    statsImg?: string;
    includeStatistics?: boolean;
  },
  type: ReportType,
  additionalMethods?: Partial<PdfExportRef>
) => {
  const { generatePdf, status, isGenerating } = usePdfWorker();

  useImperativeHandle(
    ref,
    () => ({
      ...additionalMethods,
      exportPDF: async () => {
        // Convert images to base64 with caching
        const icons = await Promise.all([
          convertIconToBase64Cached(primaryLogo),
          convertIconToBase64Cached(primaryLogoWhite),
          convertIconToBase64Cached(coverBackground),
          convertIconToBase64Cached(checkIcon),
          convertIconToBase64Cached(alertIcon),
          convertIconToBase64Cached(dottedIcon),
          convertIconToBase64Cached(squareIcon),
        ]).then(
          ([
            primaryLogoBase64,
            primaryLogoWhiteBase64,
            coverBackgroundBase64,
            checkIconBase64,
            alertIconBase64,
            dottedIconBase64,
            squareIconBase64,
          ]) => ({
            primaryLogoBase64,
            primaryLogoWhiteBase64,
            coverBackgroundBase64,
            checkIconBase64,
            alertIconBase64,
            dottedIconBase64,
            squareIconBase64,
          })
        );

        // Convert statistics image if needed
        const statsImgBase64 =
          additionalPdfProps.statsImg && additionalPdfProps.includeStatistics
            ? additionalPdfProps.statsImg
            : undefined;

        // Convert imageMap for worker and prepare road signs
        const rows = additionalPdfProps.rows || [];
        const imageMap = additionalPdfProps.imageMap || new Map();

        // Get unique road signs from rows where equipment type is Sign
        const uniqueRoadSigns = Array.from(
          new Set(
            rows
              .filter((row) => row?.currentEquipmentType === EquipmentType.Sign)
              .map((row) => row.attachmentRef)
              .filter((ref): ref is string => typeof ref === 'string' && ref !== 'Sign')
          )
        );

        // Convert both regular images and road signs in parallel
        const [regularImages, roadSignImages] = await Promise.all([
          convertImageMapForWorker(imageMap),
          prepareRoadSignsForWorker(uniqueRoadSigns),
        ]);

        const pdfData = {
          projectName: props.projectName,
          customerName: props.customerName,
          reportStartDate: props.reportStartDate,
          reportEndDate: props.reportEndDate,
          contactPerson: props.contactPerson,
          depot: props.depot,
          reportType: type,
          rows: additionalPdfProps.rows || [],
          timeStamps: additionalPdfProps.timeStamps || [],
          intervalInHours: additionalPdfProps.intervalInHours || 8,
          showSnoozed: additionalPdfProps.showSnoozed || false,
          countryCode: additionalPdfProps.countryCode,
          includeStatistics: additionalPdfProps.includeStatistics || false,
          statsImg: statsImgBase64,
          imageMap: {
            ...regularImages,
            ...roadSignImages,
          },
          assets: {
            primaryLogo: icons.primaryLogoBase64,
            primaryLogoWhite: icons.primaryLogoWhiteBase64,
            coverBackground: icons.coverBackgroundBase64,
          },
          statusIcons: {
            check: icons.checkIconBase64,
            alert: icons.alertIconBase64,
            dotted: icons.dottedIconBase64,
            snoozed: icons.squareIconBase64,
          },
        };

        return await generatePdf(pdfData);
      },
    }),
    [props, additionalPdfProps, type, generatePdf, additionalMethods]
  );

  return {
    status,
    isGenerating,
  };
};
