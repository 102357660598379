import { MapWithPolygonEditor } from '@/components/Map/MapWithPolygonEditor/MapWithPolygonEditor';
import { CreateProjectFlowFormValues } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { Box, InputError, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useCallback, useRef } from 'react';

const FORM_KEY = 'psaSetupStep' as const satisfies keyof CreateProjectFlowFormValues;

export const GeozoneSetupSection = ({
  form,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
}) => {
  const hasScrolledIntoView = useRef(false);

  const currentPath = form.getValues()[FORM_KEY]!.geozonePath;
  const setCurrentPath = (path: google.maps.LatLngLiteral[] | null) => {
    form.setFieldValue(`${FORM_KEY}.geozonePath`, path);
  };

  const countryCode = form.getValues().informationStep.countryCode;

  const onGeozoneClosed = useCallback(
    (valid: boolean) => {
      const hasError = !!form.errors[`${FORM_KEY}.geozonePath`];

      if (!valid && !hasError) {
        form.setErrors({ 'psaSetupStep.geozonePath': 'Invalid Geozone' });
      } else if (valid && hasError) {
        form.setErrors({ 'psaSetupStep.geozonePath': undefined });
      }
    },
    [form]
  );

  return (
    <Stack
      gap="sm"
      ref={(node) => {
        if (hasScrolledIntoView.current) return;
        node?.scrollIntoView({ behavior: 'smooth' });
        hasScrolledIntoView.current = true;
      }}
    >
      <Box
        style={{
          minHeight: '50dvh',
          position: 'relative',
          border: `2px ${form.errors[`${FORM_KEY}.geozonePath`] ? 'red' : 'transparent'} solid`,
        }}
      >
        <Box
          style={{
            position: 'absolute',
            inset: 0,
          }}
        >
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
            <MapWithPolygonEditor
              currentPath={currentPath}
              onPathChange={setCurrentPath}
              countryCode={countryCode}
              onGeozoneClosed={onGeozoneClosed}
              defaultIsEditing
            />
          </APIProvider>
        </Box>
      </Box>
      {form.errors[`${FORM_KEY}.geozonePath`] && (
        <InputError size="md" fw={500}>
          {form.errors[`${FORM_KEY}.geozonePath`]}
        </InputError>
      )}
    </Stack>
  );
};
