import { Badge, Group, Text } from '@mantine/core';
import { IconExclamationCircle } from '@tabler/icons-react';

import styles from './styles.module.css';
import {
  AlarmStatus,
  AlarmStatusNames,
  AlarmType,
  AlarmTypeNames,
} from '../../models/enums/DeviceEnums';

type Props = {
  status?: AlarmStatus;
  type: AlarmType | null;
  number?: number;
  size?: 'md' | 'lg';
};

export default function StatusBadge({ status, number, type, size = 'lg' }: Props) {
  if (!status) return null;

  const getStatusIcon = () => {
    if (status === AlarmStatus.Alarming) {
      return <IconExclamationCircle color="black" stroke={1.5} size={size === 'md' ? 16 : 18} />;
    }
  };

  const getStatusClass = () => {
    if (status === AlarmStatus.OK) return styles.OK;
    if (type === AlarmType.PsaBlueAlert) return styles.BlueAlarming;
    return styles.Alarming;
  };

  const getStatusName = () => {
    let name = AlarmStatusNames[status];
    if (status === AlarmStatus.Alarming && type != null) {
      name = AlarmTypeNames[type];
    }
    return name || AlarmStatusNames[status];
  };

  return (
    <Badge
      size={size}
      variant={status === AlarmStatus.OK ? 'dot' : 'default'}
      className={getStatusClass()}
      leftSection={getStatusIcon()}
    >
      <Group gap={6} ml={2}>
        <Text size={size === 'md' ? 'xs' : 'sm'} c="black" fw={500}>
          {`${number || ''} ${getStatusName()}`}
        </Text>
      </Group>
    </Badge>
  );
}
