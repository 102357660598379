import { Center, SegmentedControl, Stack } from '@mantine/core';
import ManualConnection from './components/ManualConnection';
import { Scanner } from './components/Scanner/Scanner';
import { useDeviceConnection } from '../../hooks/useDeviceConnection';
import { useState } from 'react';
import { IconScan } from '@tabler/icons-react';

enum MODES {
  Scan = 'scan',
  Manual = 'manual',
}

interface Props {
  manualScanButtonLabel: string;
  manualTabLabel: string;
  scanLabel: string;
  isHealthCheck?: boolean;
  trackingId: string;
}

export const DeviceScanner = ({
  scanLabel,
  manualScanButtonLabel,
  manualTabLabel,
  isHealthCheck,
  trackingId,
}: Props) => {
  const [mode, setMode] = useState<string>(MODES.Scan);
  const { handleConnection, isLoading } = useDeviceConnection(isHealthCheck);

  return (
    <Stack gap={mode === MODES.Scan ? 'sm' : 'md'}>
      <SegmentedControl
        fullWidth
        data={[
          {
            label: (
              <Center style={{ gap: 8 }}>
                <IconScan size={16} />
                <span>Scan</span>
              </Center>
            ),
            value: MODES.Scan,
          },
          { label: manualTabLabel, value: MODES.Manual },
        ]}
        value={mode}
        onChange={(value) => setMode(value)}
      />
      {mode === MODES.Scan.toString() ? (
        <Scanner text={scanLabel} handleConnection={handleConnection} trackingId={trackingId} />
      ) : (
        <ManualConnection
          isLoading={isLoading}
          handleConnection={handleConnection}
          buttonLabel={manualScanButtonLabel}
          trackingId={trackingId}
        />
      )}
    </Stack>
  );
};
