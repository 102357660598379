import { Stack, Group } from '@mantine/core';
import { IconExclamationCircle, IconRouter, IconRouterOff } from '@tabler/icons-react';
import Badge, { BADGE_STATES } from '../../../../components/Badge/Badge';
import StatusRow from './StatusRow';
import { Device } from '../../../../models/Device';
import { AlarmStatus, BatteryStatus } from '../../../../models/enums/DeviceEnums';
import BatteryBadgeV2 from '../../../../components/Badge/BatteryBadgeV2';
import { ProjectOverviewMessage } from './ProjectOverviewMessage';

interface Props {
  projectId: string;
  deviceList: Device[];
}

export default function MainContent({ projectId, deviceList }: Props) {
  let alarming: number = 0,
    lowBattery: number = 0,
    criticalBattery: number = 0,
    outOfBattery: number = 0;

  deviceList.map((device) => {
    if (device.alarmStatus === AlarmStatus.Alarming) alarming++;
    if (device.batteryStatus === BatteryStatus.OUT_OF_BATTERY) outOfBattery++;
    if (device.batteryStatus === BatteryStatus.CRITICAL) criticalBattery++;
    if (device.batteryStatus === BatteryStatus.LOW) lowBattery++;
  });

  return (
    <>
      {deviceList.length !== 0 ? (
        <Stack h="100%">
          <StatusRow
            to={`/pwa/projects/${projectId}/all-devices`}
            title="All devices"
            emptyText="No devices"
          >
            {deviceList.length !== 0 && (
              <Badge
                state={'offline' as BADGE_STATES}
                size="sm"
                label={`${deviceList.length} ${deviceList.length > 1 ? 'Devices' : 'Device'}`}
                icon={<IconRouter size={16} style={{ transform: 'rotate(-90deg)' }} />}
              />
            )}
          </StatusRow>
          <StatusRow to={`/pwa/projects/${projectId}/alarms`} title="Alarms" emptyText="No alarms">
            {alarming !== 0 && (
              <Badge
                state={'critical' as BADGE_STATES}
                size="sm"
                label={`${alarming} ${alarming > 1 ? 'Alarms' : 'Alarm'}`}
                icon={<IconExclamationCircle size={16} />}
              />
            )}
          </StatusRow>
          <StatusRow
            to={`/pwa/projects/${projectId}/battery`}
            title="Battery"
            emptyText="No battery alerts"
          >
            {outOfBattery === 0 && criticalBattery === 0 && lowBattery === 0 ? null : (
              <Group gap="xs">
                {outOfBattery !== 0 && (
                  <BatteryBadgeV2
                    size="sm"
                    batteryStatus={BatteryStatus.OUT_OF_BATTERY}
                    number={outOfBattery}
                  />
                )}
                {criticalBattery !== 0 && (
                  <BatteryBadgeV2
                    size="sm"
                    batteryStatus={BatteryStatus.CRITICAL}
                    number={criticalBattery}
                  />
                )}
                {lowBattery !== 0 && (
                  <BatteryBadgeV2 size="sm" batteryStatus={BatteryStatus.LOW} number={lowBattery} />
                )}
              </Group>
            )}
          </StatusRow>
        </Stack>
      ) : (
        <ProjectOverviewMessage
          Icon={IconRouterOff}
          iconStyle={{ transform: 'rotate(-90deg)' }}
          title="No devices added"
          description="Scan the QR code on a device to start registering"
        />
      )}
    </>
  );
}
