import { ArrowDirection, PsaDeviceModel } from '@/models/Device';
import { PoweredEquipment } from '@/models/enums/DeviceEnums';
import { WorkerImageMap } from '@/models/WorkerTypes';
import { imageToBase64 } from './base64Utils';
import {
  ARROW_DIRECTION_IMAGE_MAP,
  POWERED_EQUIPMENT_IMAGE_MAP,
  PROJECT_CREATION_FLOWS_IMAGE_MAP,
  PSA_IMAGE_MAP,
  PSA_SETUP_IMAGE_MAP,
  ROAD_SIGN_BASE_URL,
  SCENARIO_IMAGE_MAP,
} from '@/constants/imageConstants';
import { ScenarioType } from '@/models/enums/ScenarioEnums';
import { PsaSetupType } from '@/models/enums/ProjectEnums';
import { AllProjectCreationFlows } from '@/components/Project/CreateProjectFlow/createProjectConstants';

class ImageLoadError extends Error {
  constructor(
    message: string,
    public readonly originalError?: Error
  ) {
    super(message);
    this.name = 'ImageLoadError';
  }
}

export const getArrowDirectionImage = (direction: ArrowDirection | null): string | null => {
  if (!direction) return null;
  return ARROW_DIRECTION_IMAGE_MAP[direction] || null;
};

export const getPoweredEquipmentImage = (equipment: PoweredEquipment) => {
  if (!equipment) return null;
  return POWERED_EQUIPMENT_IMAGE_MAP[equipment] || null;
};

export const getPSAImage = (deviceModel: PsaDeviceModel): string | undefined => {
  if (!deviceModel) return;
  return PSA_IMAGE_MAP[deviceModel];
};

export const getImageUrlForSign = (
  roadSign?: string,
  addCorsParameter?: boolean
): string | null => {
  if (!roadSign) return null;
  const baseUrl = `${ROAD_SIGN_BASE_URL}/${roadSign}.png`;
  return addCorsParameter ? `${baseUrl}?r=${Math.floor(Math.random() * 100000)}` : baseUrl;
};

export const getRoadSignAsBase64 = async (roadSign?: string): Promise<string | null> => {
  if (!roadSign) return null;
  const imageUrl = getImageUrlForSign(roadSign, true);
  if (!imageUrl) return null;

  try {
    return await imageToBase64(imageUrl);
  } catch (error) {
    throw new ImageLoadError(
      `Failed to convert road sign to base64: ${roadSign}`,
      error instanceof Error ? error : undefined
    );
  }
};

export const prepareRoadSignsForWorker = async (roadSigns: string[]): Promise<WorkerImageMap> => {
  const roadSignMap: WorkerImageMap = {};
  const errors: ImageLoadError[] = [];

  await Promise.all(
    roadSigns.map(async (roadSign) => {
      try {
        const base64Image = await getRoadSignAsBase64(roadSign);
        if (base64Image) {
          roadSignMap[roadSign] = base64Image;
        }
      } catch (error) {
        errors.push(
          new ImageLoadError(
            `Failed to prepare road sign ${roadSign}`,
            error instanceof Error ? error : undefined
          )
        );
      }
    })
  );

  if (errors.length > 0) {
    const combinedError = new Error(`Failed to prepare ${errors.length} road signs`) as Error & {
      errors?: ImageLoadError[];
    };
    combinedError.errors = errors;
    throw combinedError;
  }

  return roadSignMap;
};

export const getScenarioImage = (scenario: ScenarioType): string | undefined => {
  if (!scenario) return;
  return SCENARIO_IMAGE_MAP[scenario];
};

export const getPsaSetupImage = (setup: PsaSetupType): string | undefined => {
  if (!setup) return;
  return PSA_SETUP_IMAGE_MAP[setup];
};

export const getProjectCreationFlowImages = (
  flow: AllProjectCreationFlows
): string[] | undefined => {
  if (!flow) return;
  return PROJECT_CREATION_FLOWS_IMAGE_MAP[flow];
};
