import { PdfGenerationData } from '@/models/PdfExport';
import { BasePdfGenerator, CreationStep } from './basePdfGenerator';
import { formatTimeByCountry } from '@/utils/utils';
import { PDF_CONSTANTS } from '@/constants/pdfConstants';
import { addCoverPage, addHeader, addLastPage, applyPageFooter } from '../utils/pdfCommon';
import dayjs from 'dayjs';
import { ArrowDirection, ARROW_DIRECTION_NAMES } from '@/models/Device';
import {
  ARROWBOARD_EVENT_TYPE,
  ONLINE_STATUS,
  ONLINE_STATUS_NAMES,
} from '@/models/SupervisionReport';
import jsPDF from 'jspdf';

const EVENT_MARKER_COLOR = '#F16238';
const EVENTS_PER_PAGE = 11;
const EVENT_LINE_HEIGHT = 36;

interface ArrowboardDevice {
  name: string;
  referenceId: string;
  events: Array<{
    eventType: ARROWBOARD_EVENT_TYPE;
    eventValue: ArrowDirection | ONLINE_STATUS;
    timestamp: string;
  }>;
}

/**
 * Adds an event line to the document with pagination support
 */
function addEventLineWithPagination(
  doc: jsPDF,
  device: ArrowboardDevice,
  index: number,
  pageWidth: number,
  leftMargin: number,
  rightMargin: number,
  pageConfig: { currentPage: number; eventsOnPage: number },
  countryCode: string,
  overrideYPos?: number
) {
  doc.setTextColor(0, 0, 0);

  let Y_POS: number;
  if (overrideYPos !== undefined) {
    Y_POS = overrideYPos;
  } else if (pageConfig.currentPage > 0) {
    Y_POS = PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG;
  } else {
    Y_POS =
      PDF_CONSTANTS.TOP_MARGIN +
      PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG +
      (index % EVENTS_PER_PAGE) * EVENT_LINE_HEIGHT;
  }

  const TRIANGL_SIZE = 4;
  const LINE_X1 = leftMargin + 40;
  const LINE_X2 = rightMargin - 8;
  const LINE_LENGTH = pageWidth - (leftMargin + 40 + 8);
  const SPACING = LINE_LENGTH / pageConfig.eventsOnPage;

  // Draw base line
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0);
  doc.line(LINE_X1 + TRIANGL_SIZE / 2, Y_POS, LINE_X2, Y_POS);

  // Determine start and end indices for events
  const startIndex = pageConfig.currentPage * EVENTS_PER_PAGE;
  const endIndex = Math.min(startIndex + pageConfig.eventsOnPage, device.events.length);

  for (let i = startIndex; i < endIndex; i++) {
    const event = device.events[i];
    const eventPosition = i - startIndex;
    const EVENT_X_POS = LINE_X1 + SPACING * eventPosition;

    // Draw event marker
    doc.setFillColor(EVENT_MARKER_COLOR);
    doc.triangle(
      EVENT_X_POS,
      Y_POS + TRIANGL_SIZE / 2,
      EVENT_X_POS + TRIANGL_SIZE / 2,
      Y_POS - TRIANGL_SIZE / 2,
      EVENT_X_POS + TRIANGL_SIZE,
      Y_POS + TRIANGL_SIZE / 2,
      'F'
    );

    // Add event text
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    const eventText =
      event.eventType === ARROWBOARD_EVENT_TYPE.OnlineStatus
        ? ONLINE_STATUS_NAMES[event.eventValue as ONLINE_STATUS]
        : ARROW_DIRECTION_NAMES[event.eventValue as ArrowDirection];
    doc.text(eventText, EVENT_X_POS + TRIANGL_SIZE / 2, Y_POS - TRIANGL_SIZE - 4, {
      align: 'center',
    });

    // Add timestamp
    const dateTime = dayjs(event.timestamp);
    const time = formatTimeByCountry(event.timestamp, countryCode);
    const day = dateTime.date();
    const month = dateTime.format('MMM');
    doc.text(`${day} ${month}\n${time}`, EVENT_X_POS + TRIANGL_SIZE / 2, Y_POS + TRIANGL_SIZE + 4, {
      align: 'center',
    });
  }
}

/**
 * ArrowBoard PDF Generator class
 * Handles generation of PDF reports for ArrowBoard devices
 */
export class ArrowboardPdfGenerator extends BasePdfGenerator {
  /**
   * Generate a PDF for ArrowBoard devices
   */
  public async generatePdf(data: PdfGenerationData, creationStep: CreationStep): Promise<string> {
    // Create document
    const doc =
      this.existingDocument || (await creationStep('CREATE_DOC', () => this.createPdfDocument()));

    // Add cover page if not skipped
    if (!this.skipCoverPage) {
      await creationStep('ADD_COVER', () => {
        const startDateString = dayjs(data.reportStartDate).format('DD MMM YYYY');
        const endDateString = dayjs(data.reportEndDate).format('DD MMM YYYY');

        // Add cover page
        addCoverPage(doc, {
          projectName: data.projectName,
          depot: data.depot,
          startDate: startDateString,
          endDate: endDateString,
          contactPerson: data.contactPerson,
        });
      });
    }

    const needsNewPage = doc.getNumberOfPages() === 0;
    if (needsNewPage) {
      doc.addPage();
    }

    const startDateString = dayjs(data.reportStartDate).format('DD MMM YYYY');
    const endDateString = dayjs(data.reportEndDate).format('DD MMM YYYY');

    // Add header for the first content page
    addHeader(doc, {
      projectName: data.customerName
        ? `${data.customerName} - ${data.projectName}`
        : data.projectName,
      title: 'Arrowboards',
      startDate: startDateString,
      endDate: endDateString,
      sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
    });

    // Generate content
    await this.generateContent(data, doc, creationStep);

    // Add last page if not skipped
    if (!this.skipLastPage) {
      addLastPage(doc, data.contactPerson);
    }

    // Apply page footer with proper page numbers
    await creationStep('ADD_FOOTER', () => {
      applyPageFooter(doc, [], data.includeStatistics);
    });
    // Return the PDF as base64
    return await creationStep('COMPLETE', () => doc.output('datauristring'));
  }

  /**
   * Generate content for the arrowboard report
   * This method is called by CombinedPdfGenerator to generate content without creating a new document
   */
  public async generateContent(
    data: PdfGenerationData,
    doc: jsPDF,
    creationStep: CreationStep
  ): Promise<void> {
    if (this.skipCoverPage) {
      const startDateString = dayjs(data.reportStartDate).format('DD MMM YYYY');
      const endDateString = dayjs(data.reportEndDate).format('DD MMM YYYY');

      addHeader(doc, {
        projectName: data.customerName
          ? `${data.customerName} - ${data.projectName}`
          : data.projectName,
        title: 'Arrowboards',
        startDate: startDateString,
        endDate: endDateString,
        sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
      });
    }

    // Setup for ArrowBoard PDF generation
    await creationStep('GENERATE_TABLES', async () => {
      // Check for cancellation
      if (this.checkCancellation()) {
        throw new Error('PDF generation cancelled by user');
      }

      const PAGE_WIDTH = doc.internal.pageSize.getWidth();
      const PAGE_HEIGHT = doc.internal.pageSize.getHeight();
      const LEFT_POS = PDF_CONSTANTS.SIDE_MARGIN;
      const RIGHT_POS = PAGE_WIDTH - PDF_CONSTANTS.SIDE_MARGIN;
      const BOTTOM_MARGIN = 20;

      // Setup for device rendering
      // Using unknown as an intermediate cast to safely convert to the required type
      const devices = [...(data.arrowboardRows as unknown as ArrowboardDevice[])];
      const headerOffset = PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG + 20;
      let currentY = headerOffset;
      const DEVICE_TITLE_HEIGHT = 20;
      const DIVIDER_HEIGHT = 10;

      // Helper: Reorder devices if a larger one exists at page start
      function reorderDevices(): void {
        const availableSpace = PAGE_HEIGHT - BOTTOM_MARGIN - headerOffset;
        let candidateIndex = -1;
        let maxBlockHeight = 0;
        for (let i = 0; i < devices.length; i++) {
          const totalRows = Math.ceil(devices[i].events.length / EVENTS_PER_PAGE);
          const blockHeight = DEVICE_TITLE_HEIGHT + totalRows * EVENT_LINE_HEIGHT;
          if (blockHeight > maxBlockHeight) {
            maxBlockHeight = blockHeight;
            candidateIndex = i;
          }
        }
        const firstTotalRows = Math.ceil(devices[0].events.length / EVENTS_PER_PAGE);
        const firstBlockHeight = DEVICE_TITLE_HEIGHT + firstTotalRows * EVENT_LINE_HEIGHT;
        if (
          candidateIndex > 0 &&
          maxBlockHeight >= availableSpace * 0.8 &&
          firstBlockHeight < maxBlockHeight
        ) {
          const candidate = devices.splice(candidateIndex, 1)[0];
          devices.unshift(candidate);
        }
      }

      // Helper: Add new page and reset currentY
      function addNewPageAndResetY(): number {
        doc.addPage();
        const startDateString = dayjs(data.reportStartDate).format('DD MMM YYYY');
        const endDateString = dayjs(data.reportEndDate).format('DD MMM YYYY');
        addHeader(doc, {
          projectName: data.customerName
            ? `${data.customerName} - ${data.projectName}`
            : data.projectName,
          title: 'Arrowboards',
          startDate: startDateString,
          endDate: endDateString,
          sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
        });
        return headerOffset;
      }

      // Helper: Handle divider for the next device
      function handleDivider(): number {
        if (devices.length > 0) {
          const nextDevice = devices[0]!;
          const nextTotalRows = Math.ceil(nextDevice.events.length / EVENTS_PER_PAGE);
          const nextBlockHeight = DEVICE_TITLE_HEIGHT + nextTotalRows * EVENT_LINE_HEIGHT;
          if (currentY + DIVIDER_HEIGHT + nextBlockHeight <= PAGE_HEIGHT) {
            doc.setDrawColor(200, 200, 200);
            doc.setLineWidth(0.5);
            doc.line(LEFT_POS, currentY, RIGHT_POS, currentY);
            currentY += DIVIDER_HEIGHT;
          } else {
            currentY = addNewPageAndResetY();
          }
        }
        return currentY;
      }

      // Reorder devices to optimize page usage
      if (devices.length > 0) {
        reorderDevices();
      }

      // Process each device
      while (devices.length > 0) {
        // Check for cancellation
        if (this.checkCancellation()) {
          throw new Error('PDF generation cancelled by user');
        }

        // At page start, reorder devices if needed
        if (currentY === headerOffset) {
          reorderDevices();
        }

        // Process first device
        const device = devices.shift()!;
        const totalRows = Math.ceil(device.events.length / EVENTS_PER_PAGE);
        const deviceBlockHeight = DEVICE_TITLE_HEIGHT + EVENT_LINE_HEIGHT;

        // If device doesn't fit in remaining space, start a new page
        if (currentY + deviceBlockHeight > PAGE_HEIGHT) {
          currentY = addNewPageAndResetY();
        }

        doc.setFontSize(12);
        doc.setTextColor('gray');
        doc.setFont('helvetica', 'bold');
        doc.text(`${device.name}`, LEFT_POS + 4, currentY, { baseline: 'middle' });
        doc.setFont('helvetica', 'normal');
        currentY += DEVICE_TITLE_HEIGHT;

        // Draw each row of the device's arrowboard events, adding new pages as needed
        for (let rowIndex = 0; rowIndex < totalRows; rowIndex++) {
          // Check if current row will fit on this page
          if (currentY + EVENT_LINE_HEIGHT > PAGE_HEIGHT) {
            // This row won't fit, add a new page
            currentY = addNewPageAndResetY();

            // Re-add device title on new page for continuity
            doc.setFontSize(12);
            doc.setTextColor('gray');
            doc.setFont('helvetica', 'bold');
            doc.text(`${device.name} (continued)`, LEFT_POS + 4, currentY, { baseline: 'middle' });
            doc.setFont('helvetica', 'normal');
            currentY += DEVICE_TITLE_HEIGHT;
          }

          const eventsInRow = Math.min(
            EVENTS_PER_PAGE,
            device.events.length - rowIndex * EVENTS_PER_PAGE
          );
          addEventLineWithPagination(
            doc,
            device,
            rowIndex,
            PAGE_WIDTH,
            LEFT_POS,
            RIGHT_POS,
            { currentPage: rowIndex, eventsOnPage: eventsInRow },
            data.countryCode,
            currentY
          );
          currentY += EVENT_LINE_HEIGHT;
        }

        // Only handle divider if there are more devices to process
        if (devices.length > 0) {
          currentY = handleDivider();
        }
      }
    });
  }
}
