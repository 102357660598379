import { useMutation, useQueryClient } from '@tanstack/react-query';
import postData from '@/data/api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';
import QueryKeys from '@/data/queryKeys';

interface Params {
  Name: string;
  Comment: string;
  DeviceProvisioningRequests: DeviceProvisioningRequest[];
}

interface DeviceProvisioningRequest {
  imei: string;
  referenceId: string;
  modelRef: number;
  deviceType: number;
}

interface ValidationResultDto {
  totalDevices: number;
  validDevices: number;
  invalidDevices: number;
  errors: DeviceValidationError[];
}

interface DeviceValidationError {
  imei: string;
  errors: string[];
}

const bulkProvisioningPath = `/deviceprovisioning/bulk-provisioning`;
const validateCsvPath = `/device/validate-bulk-provisioning`;

const useBulkProvisionDevices = (instance: IPublicClientApplication) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (params: Params) => {
      return postData<Params>(bulkProvisioningPath, params, instance);
    },
    onSuccess: () => {
      // Invalidate the bulk provision list query to trigger a refetch
      queryClient.invalidateQueries({ queryKey: QueryKeys.Devices.infiniteList() });
    },
    onError: (error) => {
      console.error('Bulk provision failed:', error);
    },
  });

  // Helper function to validate CSV through the backend
  const validateCsv = async (csv: DeviceProvisioningRequest[]) => {
    try {
      const data = { DeviceProvisioningRequests: csv };
      const response = await postData(validateCsvPath, data, instance);

      if (!response.ok) {
        const errorData = await response.json();
        return {
          isValid: false,
          error: errorData.message || 'CSV validation failed',
        };
      }

      const validationResult: ValidationResultDto = await response.json();
      return {
        isValid: validationResult.invalidDevices === 0,
        validatedData: validationResult.validDevices > 0 ? csv : undefined,
        errorDetails: validationResult.errors,
        totalDevices: validationResult.totalDevices,
        validDevices: validationResult.validDevices,
        invalidDevices: validationResult.invalidDevices,
      };
    } catch (error) {
      console.error('CSV validation failed:', error);
      return {
        isValid: false,
        error: error instanceof Error ? error.message : 'Failed to validate CSV',
      };
    }
  };

  return {
    mutateAsync,
    isPending,
    validateCsv,
  };
};

export default useBulkProvisionDevices;
