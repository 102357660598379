import { CreateProjectFlowFormValues } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { GeozoneSetupSection } from '@/components/Project/CreateProjectFlow/CreateProjectPsaSetup/SetupType/GeozoneSetup/GeozoneSetup';
import { ScenarioSelection } from '@/components/Project/CreateProjectFlow/CreateProjectPsaSetup/Scenario/ScenarioSelection';
import { ManualSetupSection } from '@/components/Project/CreateProjectFlow/CreateProjectPsaSetup/SetupType/ManualSetup/ManualSetup';
import { SetupTypeSelection } from '@/components/Project/CreateProjectFlow/CreateProjectPsaSetup/SetupType/SetupTypeSelection';
import { PsaSetupType } from '@/models/enums/ProjectEnums';
import { Container, Stack, Text, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

const FORM_KEY = 'psaSetupStep' as const satisfies keyof CreateProjectFlowFormValues;

export const CreateProjectPsaSetupStep = ({
  form,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
}) => {
  const selectedScenario = form.getValues()[FORM_KEY]!.scenario;
  const selectedSetup = form.getValues()[FORM_KEY]!.setupType;

  return (
    <Container size={'md'}>
      <Stack gap={50}>
        <Stack gap="xs">
          <Title order={5} mb={'xs'}>
            PSA Setup
          </Title>
          <Text>
            A setup consists of a scenario and a connection between devices via Geozone or Manual
            connection.
          </Text>
        </Stack>
        <ScenarioSelection form={form} />
        {selectedScenario && (
          <>
            <SetupTypeSelection form={form} />
            {selectedSetup === PsaSetupType.Manual && <ManualSetupSection form={form} />}
            {selectedSetup === PsaSetupType.Geozone && <GeozoneSetupSection form={form} />}
          </>
        )}
      </Stack>
    </Container>
  );
};
