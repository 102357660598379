import { Intellitag } from '../../../../models/Device';
import { EquipmentTypeNames } from '../../../../models/enums/DeviceEnums';
import { LabelValueText } from '../../../../shared/LabelValueText';

export const IntellitagInfo = (
  props: Pick<Intellitag, 'currentEquipmentType' | 'attachmentRef'>
) => {
  return (
    <>
      <LabelValueText
        width="100%"
        label="Equipment Type"
        value={props.currentEquipmentType ? EquipmentTypeNames[props.currentEquipmentType] : '-'}
      />
      <LabelValueText width="100%" label="Attachment" value={props.attachmentRef} />
    </>
  );
};
