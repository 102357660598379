import { CreateProjectFlowFormValues } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import useCountries from '@/data/hooks/Country/useCountriesHook';
import useDepots from '@/data/hooks/Depots/useDepots';
import useMyUserAccesses from '@/data/hooks/UserAccess/useMyUserAccess';
import { useMsal } from '@azure/msal-react';
import { Checkbox, Container, Input, Paper, Select, Stack, Title, Tooltip } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { useMemo } from 'react';

const FORM_KEY = 'informationStep' as const satisfies keyof CreateProjectFlowFormValues;

export const CreateProjectFlowInformationStep = ({
  form,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
}) => {
  const { instance } = useMsal();
  const { countries: _countries } = useCountries({ instance });
  const { depots } = useDepots(instance);
  const { userAccesses } = useMyUserAccesses({ instance: instance });

  const countries = useMemo(
    () => _countries.filter((c) => userAccesses?.countries.includes(c.code)),
    [userAccesses, _countries]
  );

  return (
    <Container size={'sm'}>
      <Stack gap="xl" w={'100%'}>
        <Stack gap={'xs'}>
          <Title order={5} mb={'xs'}>
            Project information
          </Title>
          <Paper p={'lg'}>
            <Stack gap="md">
              <Input.Wrapper
                labelProps={{ fw: 600 }}
                label="Project name"
                required
                error={form.errors[`${FORM_KEY}.name`]}
                description="Maximum 100 characters"
              >
                <Input
                  placeholder="Name"
                  required
                  key={form.key(`${FORM_KEY}.name`)}
                  {...form.getInputProps(`${FORM_KEY}.name`)}
                />
              </Input.Wrapper>

              <Checkbox
                label="This is a demo project"
                {...form.getInputProps(`${FORM_KEY}.isDemoProject`, { type: 'checkbox' })}
              />

              {countries != null && (
                <Input.Wrapper labelProps={{ fw: 600 }} label="Country" required>
                  <Select
                    placeholder="Select country"
                    required
                    // key={form.key(`${FORM_KEY}.countryCode`)}
                    data={countries.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    searchable
                    {...form.getInputProps(`${FORM_KEY}.countryCode`)}
                    onChange={(c) => {
                      form.setFieldValue(`${FORM_KEY}.countryCode`, c ?? '');
                      form.setFieldValue(`${FORM_KEY}.depotId`, '');
                    }}
                  />
                </Input.Wrapper>
              )}

              {depots && (
                <Input.Wrapper labelProps={{ fw: 600 }} label="Depot" required>
                  <Tooltip
                    disabled={!!form.getValues()[FORM_KEY].countryCode}
                    label="Please select a country first"
                  >
                    <Select
                      placeholder="Select depot"
                      required
                      key={form.key(`${FORM_KEY}.depotId`)}
                      disabled={!form.getValues()[FORM_KEY].countryCode}
                      searchable
                      data={depots
                        .filter((d) => d.countryCode === form.getValues()[FORM_KEY].countryCode)
                        .map((d) => ({
                          label: d.name,
                          value: d.id,
                        }))}
                      {...form.getInputProps(`${FORM_KEY}.depotId`)}
                    />
                  </Tooltip>
                </Input.Wrapper>
              )}

              <Input.Wrapper labelProps={{ fw: 600 }} label="Customer" required>
                <Input
                  placeholder="Enter customer name"
                  required
                  key={form.key(`${FORM_KEY}.customerName`)}
                  {...form.getInputProps(`${FORM_KEY}.customerName`)}
                />
              </Input.Wrapper>
            </Stack>
          </Paper>
        </Stack>

        <Stack gap={'xs'}>
          <Title order={5} mb={'xs'}>
            Planned time period
          </Title>
          <Paper p={'lg'}>
            <Stack gap="md">
              <DatePickerInput
                placeholder="Start date"
                miw={'140px'}
                label="Start date"
                required
                key={form.key(`${FORM_KEY}.startDate`)}
                labelProps={{ fw: 600 }}
                {...form.getInputProps(`${FORM_KEY}.startDate`)}
                value={new Date(form.getValues()[FORM_KEY].startDate)}
                onChange={(c) => {
                  c && form.setFieldValue(`${FORM_KEY}.startDate`, c.toISOString());
                }}
                maxDate={
                  form.getValues()[FORM_KEY].endDate
                    ? new Date(form.getValues()[FORM_KEY].endDate!)
                    : undefined
                }
              />
              <DatePickerInput
                placeholder="End date"
                miw={'140px'}
                label="End date"
                clearable
                key={form.key(`${FORM_KEY}.endDate`)}
                labelProps={{ fw: 600 }}
                {...form.getInputProps(`${FORM_KEY}.endDate`)}
                minDate={new Date(form.getValues()[FORM_KEY].startDate)}
                value={
                  form.getValues()[FORM_KEY].endDate
                    ? new Date(form.getValues()[FORM_KEY].endDate!)
                    : undefined
                }
                onChange={(c) => {
                  form.setFieldValue(`${FORM_KEY}.endDate`, c?.toISOString());
                }}
              />
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Container>
  );
};
