import { useState, useCallback, useMemo, useRef, useEffect, forwardRef } from 'react';
import { Center, Group, Stack, Text } from '@mantine/core';
import { IconBarrierBlock, IconRoadSign } from '@tabler/icons-react';
import { AlarmStatus, EquipmentType } from '../../../../../models/enums/DeviceEnums';
import { DeviceEntry, SupervisionReportDto } from '../../../../../models/SupervisionReport';
import { getImageUrlForSign } from '../../../../../utils/ImageHelpers';
import { DashboardPdfProps, PdfExportRef } from '../../../../../models/PdfExport';
import { useDashboard } from '../../hooks/useDashboard';
import { IntellitagDashboardProps } from '../../../../../models/DashboardTypes';
import { ReportType } from '../../../../../models/enums/ReportType';
import { DashboardHeader } from '../common/DashboardHeader';
import { DashboardTable, DashboardTableColumn } from '../common/DashboardTable';
import { AlarmEventsDrawer, DeviceData } from '../common/AlarmEventsDrawer';
import { useAlarmDrawer } from '../../hooks/useAlarmDrawer';
import { AlarmLog, prepareLogsForDrawer } from '../../utils/alarmDrawerUtils';
import { STATUS_ICONS } from '../../constants/statusIcons';
import roadSignImg from '../../../../../assets/road-sign.png';
import barrierImg from '../../../../../assets/barrier-block.png';
import useTimeSegments from '../../../../../hooks/useTimeSegments';

const IntellitagDashboard = forwardRef<PdfExportRef, IntellitagDashboardProps>((props, ref) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showSnoozed, setShowSnoozed] = useState<boolean>(true);

  const {
    isDrawerOpen,
    selectedDeviceData,
    openDrawer: handleOpenDrawer,
    closeDrawer: handleCloseDrawer,
  } = useAlarmDrawer<SupervisionReportDto['rows'][0]>();

  // Use a single ref for the image map (used for PDF export)
  const imageMapRef = useRef(new Map<string, HTMLImageElement>());
  // Use a separate cache to avoid duplicate image loading
  const imageCache = useRef(new Map<string, HTMLImageElement>());
  // Track if background loading is complete
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // Use our new time segments hook for DST-aware intervals
  const timeSegments = useTimeSegments(
    props.data?.intervals,
    props.intervalInHours || 8,
    props.countryCode || 'SE'
  );

  // Create a memoized flattened list of all timestamps for performance
  const allTimestamps = useMemo(
    () => timeSegments.map((segment) => segment.timestamp),
    [timeSegments]
  );

  // Memoize filtered rows to avoid recalculation on every render
  const filteredRows = useMemo(
    () => props.data?.rows?.filter((_, idx) => !selectedRows.includes(idx)) ?? [],
    [props.data?.rows, selectedRows]
  );

  // Clean row names once
  useEffect(() => {
    if (filteredRows.length > 0) {
      filteredRows.forEach((row) => {
        row.name = row.name.replace(/(\r\n|\n|\r)/gm, ' ');
      });
    }
  }, [filteredRows]);

  // Load image helper with caching
  const loadImage = useCallback(async (url: string, key: string): Promise<HTMLImageElement> => {
    // Return from cache if available
    if (imageCache.current.has(key)) {
      return imageCache.current.get(key)!;
    }

    // Otherwise load the image
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = url;
      img.onload = () => {
        imageCache.current.set(key, img);
        resolve(img);
      };
      img.onerror = () => {
        // Fall back to default sign image
        const fallbackImg = imageCache.current.get('sign-default') || new Image();
        if (!fallbackImg.src) fallbackImg.src = roadSignImg;
        resolve(fallbackImg);
      };
    });
  }, []);

  // Load the default barrier and sign images once
  useEffect(() => {
    const loadDefaultImages = async () => {
      // Barrier image
      const barrierKey = EquipmentType.Barrier.toString();
      if (!imageCache.current.has('barrier-default')) {
        const barrierImgEl = new Image();
        barrierImgEl.src = barrierImg;
        await new Promise<void>((resolve) => {
          barrierImgEl.onload = () => {
            imageCache.current.set('barrier-default', barrierImgEl);
            imageMapRef.current.set(barrierKey, barrierImgEl);
            resolve();
          };
          barrierImgEl.onerror = () => resolve();
        });
      }

      // Sign image
      const signKey = EquipmentType.Sign.toString();
      if (!imageCache.current.has('sign-default')) {
        const signImgEl = new Image();
        signImgEl.src = roadSignImg;
        await new Promise<void>((resolve) => {
          signImgEl.onload = () => {
            imageCache.current.set('sign-default', signImgEl);
            imageMapRef.current.set(signKey, signImgEl);
            resolve();
          };
          signImgEl.onerror = () => resolve();
        });
      }
    };

    loadDefaultImages();
  }, []);

  // Load all road sign images in the background
  useEffect(() => {
    // Skip if no data or already loaded
    if (!filteredRows.length || imagesLoaded) return;

    const loadImages = async () => {
      // Collect unique attachment refs to avoid duplicates
      const distinctAttachmentRefs = new Set<string>();
      filteredRows.forEach((row) => {
        if (row.attachmentRef) {
          distinctAttachmentRefs.add(row.attachmentRef);
        }
      });

      // Process images in batches to not block the UI
      const batchSize = 10;
      const refs = Array.from(distinctAttachmentRefs);

      // Process each batch
      for (let i = 0; i < refs.length; i += batchSize) {
        const batch = refs.slice(i, i + batchSize);
        await Promise.all(
          batch.map((attachmentRef) => {
            const url = getImageUrlForSign(attachmentRef, true);
            if (!url) return Promise.resolve(); // Skip if no URL

            return loadImage(url, attachmentRef).then((img) => {
              imageMapRef.current.set(attachmentRef, img);
            });
          })
        );
      }

      setImagesLoaded(true);
    };

    loadImages();
  }, [filteredRows, imagesLoaded, loadImage]);

  // Export method to get image map for PDF generation
  const getImageMap = useCallback(async () => {
    // Make sure all images are loaded - this is a fallback if PDF export happens before background loading completes
    if (!imagesLoaded && filteredRows.length > 0) {
      // Find any missing attachments
      const missingAttachments = filteredRows
        .filter((row) => row.attachmentRef && !imageMapRef.current.has(row.attachmentRef))
        .map((row) => row.attachmentRef);

      // Load any missing images
      if (missingAttachments.length > 0) {
        await Promise.all(
          missingAttachments.map((attachmentRef) => {
            if (!attachmentRef) return Promise.resolve();
            const url = getImageUrlForSign(attachmentRef, true);
            if (!url) return Promise.resolve();

            return loadImage(url, attachmentRef).then((img) => {
              imageMapRef.current.set(attachmentRef, img);
            });
          })
        );
      }
    }

    return imageMapRef.current;
  }, [filteredRows, imagesLoaded, loadImage]);

  // Set up the PDF export ref
  const additionalMethods = {
    getImageMap,
  };

  const { shouldRender } = useDashboard<SupervisionReportDto, DashboardPdfProps>(
    ref,
    props,
    {
      timeStamps: allTimestamps,
      intervalInHours: props.intervalInHours ?? 8,
      rows: filteredRows,
      showSnoozed: showSnoozed,
      imageMap: imageMapRef.current,
      projectName: props.projectName,
      reportStartDate: props.reportStartDate,
      reportEndDate: props.reportEndDate,
      contactPerson: props.contactPerson,
      depot: props.depot,
      countryCode: props.countryCode,
      customerName: props.customerName,
      includeStatistics: props.includeStatistics,
      statsImg: props.statImgString,
    },
    ReportType.Intellitags,
    additionalMethods
  );

  if (!shouldRender || !props.data?.rows?.length) {
    return null;
  }

  const getStatus = (entry: DeviceEntry) => {
    const logsToShow = entry?.logs?.length
      ? showSnoozed
        ? entry.logs
        : entry.logs.filter((dl) => !dl.workZoneSnoozed)
      : [];

    const iconSrc = () => {
      if (logsToShow.length === 0) return STATUS_ICONS.DOTTED;
      if (logsToShow.some((log) => log.alarmStatus === AlarmStatus.Alarming))
        return STATUS_ICONS.ALERT;
      return STATUS_ICONS.CHECK;
    };

    return (
      <Group
        align="center"
        justify="center"
        gap="xs"
        wrap="nowrap"
        w={60}
        style={{ margin: '0 auto' }}
      >
        <Center>
          <img width="20px" height="20px" src={iconSrc()} alt="" />
        </Center>
        <Text size="sm" w={20} ta="left">
          {logsToShow.length}
        </Text>
      </Group>
    );
  };

  const legendItems = [
    { text: 'OK', img: STATUS_ICONS.CHECK },
    { text: 'Fallen', img: STATUS_ICONS.ALERT },
    { text: 'No Log', img: STATUS_ICONS.DOTTED },
    { text: 'Snoozed', img: STATUS_ICONS.SNOOZED },
  ];

  const getRoadSign = (attachmentRef: string) => {
    const roadSignUrl = getImageUrlForSign(attachmentRef);
    return roadSignUrl ? (
      <Center>
        <img
          style={{
            maxWidth: '30px',
            maxHeight: '20px',
            width: 'auto',
            height: 'auto',
            margin: '2px',
          }}
          src={roadSignUrl}
          alt=""
        />
      </Center>
    ) : (
      <Center>
        <IconRoadSign stroke={1.5} color="gray" size={25} />
      </Center>
    );
  };

  const equipmentImage = (currentEquipmentType: EquipmentType, attachmentRef: string) =>
    currentEquipmentType === EquipmentType.Barrier ? (
      <Center>
        <IconBarrierBlock stroke={1.5} color="gray" size={25} />
      </Center>
    ) : (
      getRoadSign(attachmentRef)
    );

  const handleRowClick = (row: SupervisionReportDto['rows'][0]) => {
    handleOpenDrawer(row);
  };

  const columns: DashboardTableColumn<SupervisionReportDto['rows'][0]>[] = [
    {
      header: 'Reference Id',
      minWidth: '120px',
      render: (row) => (
        <a
          href={`/devices/edit/${row.deviceId}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'rgb(var(--linkBlue)', textDecoration: 'none' }}
          onClick={(e) => e.stopPropagation()}
        >
          {row.referenceId}
        </a>
      ),
    },
    {
      header: 'Additional info',
      minWidth: '120px',
      render: (row) => row.name,
    },
    {
      header: 'Attachment',
      minWidth: '100px',
      render: (row) => equipmentImage(row.currentEquipmentType, row.attachmentRef),
    },
    ...timeSegments.map((segment) => ({
      header: (
        <Stack gap={2}>
          {[segment.date, segment.startTime, segment.endTime].map((part, i) => (
            <Text key={i} fw={700} size="sm" style={{ whiteSpace: 'nowrap' }}>
              {part}
            </Text>
          ))}
        </Stack>
      ),
      render: (row: SupervisionReportDto['rows'][0], columnIndex: number) =>
        getStatus(row.entries[columnIndex - 3]),
    })),
  ];

  // Get all logs from the selected device using helper function
  const getLogsForDrawer = (): AlarmLog[] => {
    return prepareLogsForDrawer(selectedDeviceData, (_, index) => {
      const segment = timeSegments[index];
      return segment ? segment.date : '';
    });
  };

  // Custom renderer for equipment attachment
  const renderAttachment = (deviceData: DeviceData) => {
    return equipmentImage(
      deviceData.currentEquipmentType as EquipmentType,
      deviceData.attachmentRef as string
    );
  };

  return (
    <Stack>
      <DashboardHeader
        title="Intellitags"
        interval={`${props.intervalInHours}h`}
        legendItems={legendItems}
        showSnoozed={showSnoozed}
        onShowSnoozedChange={setShowSnoozed}
      />

      <DashboardTable<SupervisionReportDto['rows'][0]>
        rows={props.data.rows}
        columns={columns}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        onRowClick={handleRowClick}
        snoozeDurations={props.snoozeDurations}
        intervals={props.data.intervals}
        showSnoozed={showSnoozed}
      />

      <AlarmEventsDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        deviceData={selectedDeviceData as DeviceData}
        allLogs={getLogsForDrawer()}
        reportStartDate={props.reportStartDate}
        reportEndDate={props.reportEndDate}
        countryCode={props.countryCode}
        renderAttachment={renderAttachment}
      />
    </Stack>
  );
});

export default IntellitagDashboard;
