import { Group } from '@mantine/core';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import { Button } from '@/components/Button/Button';

export type ConfirmButtonGroupProps = {
  confirmBtnText?: string;
  abortBtnText?: string;
  disabled?: boolean;
  onlyConfirm?: boolean;
  isLoading?: boolean;
  onAbort: () => void;
  onConfirm: () => void;
  confirmButtonType?: 'submit' | 'button';
};

export const ConfirmButtonGroup = ({
  confirmBtnText = 'Confirm',
  abortBtnText = 'Cancel',
  disabled,
  onlyConfirm,
  isLoading,
  onAbort,
  onConfirm,
  confirmButtonType,
}: ConfirmButtonGroupProps) => {
  const isMobile = useMobileDevice();
  return (
    <Group grow={isMobile} gap="sm" justify="end">
      {!onlyConfirm && (
        <Button variant="secondary" fullWidth={isMobile} onClick={onAbort}>
          {abortBtnText}
        </Button>
      )}
      <Button
        variant="primary"
        type={confirmButtonType}
        loading={isLoading}
        fullWidth={isMobile}
        onClick={onConfirm}
        disabled={disabled}
      >
        {confirmBtnText}
      </Button>
    </Group>
  );
};
