import { Stack, Title, Text, Button } from '@mantine/core';
import { useContext, useState } from 'react';
import { PWAContext } from '../../providers/PWAProvider';
import { DeviceScanner } from '../../components/Scanner/DeviceScanner';
import { DeviceStatuses } from './DeviceStatuses/DeviceStatuses';
import { IconExclamationCircle } from '@tabler/icons-react';

export const PwaHealthCheckPage = () => {
  const { device } = useContext(PWAContext);
  const [showIntroMessage, setIntroMessage] = useState(true);

  return (
    <>
      {showIntroMessage ? (
        <Stack flex={1} mb="lg">
          <Stack
            flex={1}
            p="xl"
            bg="primaryGreenLight"
            justify="center"
            style={{ borderRadius: '24px' }}
          >
            <Stack gap="lg" align="center">
              <IconExclamationCircle size={80} />

              <Stack align="center">
                <Title order={2} size="h4" ta="center">
                  For optimal conditions to check health
                </Title>

                <Stack w="fit-content">
                  <Text>1. Make sure the device is added to a project</Text>
                  <Text>2. Make sure the device is outdoors</Text>
                  <Text>3. Wait for 8 hours (UK: 2 hours)</Text>
                  <Text>4. Continue to health check</Text>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Button
            size="lg"
            onClick={() => {
              if (window._paq) {
                window._paq.push(['trackEvent', 'Health Check', 'Continued to health check']);
              }
              setIntroMessage(false);
            }}
          >
            Continue
          </Button>
        </Stack>
      ) : (
        <Stack>
          <DeviceScanner
            isHealthCheck
            scanLabel="Scan QR code to check device health"
            manualScanButtonLabel="Check"
            manualTabLabel="Check manually"
            trackingId="Health Check"
          />

          {device && (
            <DeviceStatuses
              referenceId={device.referenceId}
              latestUpdate={device.latestUpdate}
              batteryLevel={device.batteryVoltage}
              batteryStatus={device.batteryStatus}
            />
          )}
        </Stack>
      )}
    </>
  );
};
