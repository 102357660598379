import useTogglePowerSavingMode from '@/data/hooks/Project/useTogglePowerSavingMode';
import { WorkZone } from '@/models/Project';
import { InfoIcon } from '@/shared/IconButton/InfoIcon/InfoIcon';
import { useMsal } from '@azure/msal-react';
import { Box, Checkbox, Group, Stack, Text, Title } from '@mantine/core';

interface Props {
  project: WorkZone;
}

export const ProjectAdvancedSettings = ({ project }: Props) => {
  const { instance } = useMsal();
  const { mutate } = useTogglePowerSavingMode({ instance: instance, id: project.id });

  return (
    <>
      <Stack p={'md'} gap={'xs'}>
        <Box>
          <Group gap={'sm'}>
            <Title order={5}>Auto power saving mode</Title>
            <InfoIcon
              iconColor="black"
              size={16}
              textBoxWidth={300}
              text="If a device sends updates too often, it will enter power-saving mode (sending fewer updates), even if an alarm is triggered. After 4 hours, it returns to normal."
              textColor="white"
              textBoxColor="black"
            />
          </Group>
          <Text mt={'xs'} size="xs" c={'gray.6'}>
            Toggle auto power saving mode on all devices in the project
          </Text>
        </Box>
        <Checkbox
          label="Enable auto power saving mode"
          labelPosition="right"
          py="md"
          checked={project.isPowerSavingModeEnabled}
          onChange={(event) => {
            mutate(event.currentTarget.checked);
          }}
        />
      </Stack>
    </>
  );
};
