import { PdfGenerationData, HeaderCell, StatusIcons } from '@/models/PdfExport';
import { BasePdfGenerator, CreationStep } from './basePdfGenerator';
import { PDF_CONSTANTS, PDF_TABLE_STYLES, PDF_TABLE_CONSTANTS } from '@/constants/pdfConstants';
import { addCoverPage, addLastPage, applyPageFooter } from '../utils/pdfCommon';
import { BatteryMonitorRow, DeviceLog, LegendItem } from '@/models/SupervisionReport';
import { AlarmStatus } from '@/models/enums/DeviceEnums';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import { getStatusIcon } from '../../../components/Project/SupervisionReport/utils/pdfStatusIconUtils';
import { PoweredEquipmentIcons, PoweredEquipmentNames } from '@/models/enums/DeviceEnums';
import { generateTableConfig } from '@/utils/pdfTableUtils';
import { CellDef } from 'jspdf-autotable';
import { addAlarmEventsToDocument } from '../utils/alarmEvents';
import { formatDate } from '@/utils/utils';

export enum BatteryDeviceStatus {
  OK = 'OK',
  POWERLESS = 'POWERLESS',
  NO_LOGS = 'NO_LOGS',
}

/**
 * BatteryMonitorPdfGenerator abstract class
 * Base class for all battery monitor type PDF generators (SmartCable, SmartSolar, etc.)
 */
export abstract class BatteryMonitorPdfGenerator extends BasePdfGenerator {
  protected statusIcons?: StatusIcons;
  protected abstract getTitle(): string;

  /**
   * Generate a PDF for this battery monitor device type
   */
  public async generatePdf(data: PdfGenerationData, creationStep: CreationStep): Promise<string> {
    // Store status icons from the data
    this.statusIcons = data.statusIcons;

    // Create a new PDF document
    const doc =
      this.existingDocument || (await creationStep('CREATE_DOC', () => this.createPdfDocument()));

    // Process assets

    // Add cover page only if we're not skipping it
    if (!this.skipCoverPage) {
      await creationStep('ADD_COVER', () => {
        addCoverPage(doc, {
          projectName: data.projectName,
          depot: data.depot,
          startDate: formatDate(data.reportStartDate),
          endDate: formatDate(data.reportEndDate),
          contactPerson: data.contactPerson,
        });
      });
    }

    // Generate content
    await this.generateContent(data, doc, creationStep);

    // Add alarm events if available
    if (!this.skipLastPage) {
      const deviceRows = this.getDeviceRows(data);
      if (deviceRows && deviceRows.length > 0) {
        await creationStep('ADD_ALARM_EVENTS', () => {
          addAlarmEventsToDocument(doc, data);
        });
      }

      // Add last page
      await creationStep('ADD_FOOTER', () => {
        // If we didn't add alarm events before, add a new page now
        if (!deviceRows || deviceRows.length === 0) {
          doc.addPage();
        }

        addLastPage(doc, data.contactPerson);
      });
    }

    // Apply page footer with proper page numbers
    await creationStep('ADD_FOOTER', () => {
      // Create legend with status icons
      const legendItems: LegendItem[] = [
        { text: 'OK', img: this.statusIcons?.check || '' },
        { text: 'Powerless/Disconnected', img: this.statusIcons?.alert || '' },
        { text: 'No data', img: this.statusIcons?.dotted || '' },
        { text: 'Snoozed', img: this.statusIcons?.snoozed || '' },
      ];

      applyPageFooter(doc, legendItems, data.includeStatistics);
    });

    // Return the PDF as base64
    return await creationStep('COMPLETE', () => doc.output('datauristring'));
  }

  /**
   * Generate content for the battery monitor report
   * This method is called by CombinedPdfGenerator to generate content without creating a new document
   */
  public async generateContent(
    data: PdfGenerationData,
    doc: jsPDF,
    creationStep: CreationStep
  ): Promise<void> {
    // Generate tables with actual data
    await creationStep('GENERATE_TABLES', () => {
      // Get the appropriate rows for this device type
      const batteryMonitorRows = this.getDeviceRows(data);

      if (!batteryMonitorRows || batteryMonitorRows.length === 0) {
        return;
      }

      // Calculate date range (all days between start and end)
      const dateRange = this.generateDateRange(
        dayjs(data.reportStartDate).format('YYYY-MM-DD'),
        dayjs(data.reportEndDate).format('YYYY-MM-DD')
      );

      // Calculate available date columns per page using constants
      const pageWidth = doc.internal.pageSize.getWidth();
      const availableWidth = pageWidth - PDF_CONSTANTS.SIDE_MARGIN * 2;
      const fixedColumnsWidth = PDF_TABLE_CONSTANTS.TEXT_FIELDS_CELL_WIDTH * 3 + 18;
      const remainingWidth = availableWidth - fixedColumnsWidth;
      const columnsPerPage = Math.floor(remainingWidth / PDF_TABLE_CONSTANTS.TIMESTAMP_CELL_WIDTH);

      // Generate headers and data
      const timeStampHeaders = this.generateTimeHeaders(dateRange);
      const tableData = this.generateTableData(batteryMonitorRows, dateRange);

      // Split data into pages based on how many columns fit
      const pages: Array<Array<Array<string | number | null>>> = [];
      const fixedColumns = tableData.map((row) => row.slice(0, PDF_TABLE_CONSTANTS.FIXED_COLUMNS));

      for (
        let i = PDF_TABLE_CONSTANTS.FIXED_COLUMNS;
        i < tableData[0].length;
        i += columnsPerPage
      ) {
        const pageData = tableData.map((row) => {
          const pagePortion = row.slice(i, i + columnsPerPage);
          return [...fixedColumns[tableData.indexOf(row)], ...pagePortion];
        });
        pages.push(pageData);
      }

      // Split headers into pages
      const pageHeaders: Array<HeaderCell[][]> = [];
      for (let i = 0; i < timeStampHeaders.length; i += columnsPerPage) {
        const pageTimestampHeaders = timeStampHeaders.slice(i, i + columnsPerPage);
        pageHeaders.push([
          [
            {
              content: 'Reference ID',
              styles: {
                ...PDF_TABLE_STYLES.HEAD,
                cellPadding: [2.5, 1, 1, 1],
                lineWidth: { right: 0.2, left: 0.5, top: 0.2, bottom: 0.2 },
              },
            },
            {
              content: 'Name',
              styles: {
                ...PDF_TABLE_STYLES.HEAD,
                cellPadding: [2.5, 1, 1, 1],
                lineWidth: { right: 0.2, left: 0.2, top: 0.2, bottom: 0.2 },
              },
            },
            {
              content: 'Powered Equipment',
              styles: {
                ...PDF_TABLE_STYLES.HEAD,
                cellPadding: [2.5, 1, 1, 1],
                lineWidth: { right: 0.5, left: 0.2, top: 0.2, bottom: 0.2 },
              },
            },
            ...pageTimestampHeaders,
          ],
        ]);
      }

      // Process each page
      pages.forEach((pageData, pageIndex) => {
        if (pageIndex > 0) {
          doc.addPage();
        }

        const daysInThisPage = pageData[0].length - PDF_TABLE_CONSTANTS.FIXED_COLUMNS;

        // Calculate page specific date range
        const startDayIndex = pageIndex * columnsPerPage;
        const endDayIndex = Math.min(startDayIndex + columnsPerPage - 1, dateRange.length - 1);
        const pageStartDate = dateRange[startDayIndex];
        const pageEndDate = dateRange[endDayIndex];

        const tableConfig = generateTableConfig(
          pageHeaders[pageIndex],
          pageData as CellDef[][],
          {
            left: PDF_CONSTANTS.SIDE_MARGIN,
            right: PDF_CONSTANTS.SIDE_MARGIN,
            top: PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.HEADER,
          },
          daysInThisPage,
          1,
          {
            projectName: `${data.customerName} - ${data.projectName}`,
            title: this.getTitle(),
            startDate: formatDate(new Date(pageStartDate)),
            endDate: formatDate(new Date(pageEndDate)),
            assets: data.assets,
          }
        );

        // Add custom cell handlers
        tableConfig.didParseCell = (data) => {
          if (data.column.index > 2 && data.cell.section === 'body') {
            data.cell.text = [''];
          }
        };

        tableConfig.didDrawCell = (data) => {
          if (data.cell.section === 'body') {
            if (data.column.index === 2) {
              const equipment = data.cell.raw as number;

              const icon = PoweredEquipmentIcons[equipment as keyof typeof PoweredEquipmentIcons];
              if (icon) {
                try {
                  const iconSize = PDF_TABLE_CONSTANTS.FIXED_SIGN_SIZE;
                  const x = data.cell.x + data.cell.width / 2 - iconSize / 2;
                  const y = data.cell.y + data.cell.height / 2 - iconSize / 2;
                  doc.addImage(icon, 'PNG', x, y, iconSize, iconSize);
                } catch (error) {
                  console.error('Failed to add equipment icon:', error);
                  // Fallback to text if image fails
                  const text =
                    PoweredEquipmentNames[equipment as keyof typeof PoweredEquipmentNames] || '-';
                  doc.text(text, data.cell.x + 2, data.cell.y + data.cell.height / 2);
                }
              } else {
                const text =
                  PoweredEquipmentNames[equipment as keyof typeof PoweredEquipmentNames] || '-';
                doc.text(text, data.cell.x + 2, data.cell.y + data.cell.height / 2);
              }
            } else if (data.column.index > 2) {
              const status = data.cell.raw as BatteryDeviceStatus;
              const iconSrc = getStatusIcon(status);
              if (iconSrc) {
                try {
                  const iconSize = 4;
                  const x = data.cell.x + data.cell.width / 2 - iconSize / 2;
                  const y = data.cell.y + data.cell.height / 2 - iconSize / 2;
                  doc.addImage(iconSrc, 'PNG', x, y, iconSize, iconSize);
                } catch (error) {
                  console.error('Failed to add status icon:', error);
                }
              }
            }
          }
        };

        // Apply the table
        this.applyAutoTable(doc, tableConfig);
      });
    });
  }

  /**
   * Helper method to get the appropriate device rows based on the report type
   */
  private getDeviceRows(data: PdfGenerationData): BatteryMonitorRow[] {
    // Check if we have the new data structure (smartCableRows/smartSolarRows) or the old one (rows)
    if (this.getTitle() === 'Smart Cable' && data.smartCableRows) {
      return data.smartCableRows;
    } else if (this.getTitle() === 'Smart Solar' && data.smartSolarRows) {
      return data.smartSolarRows;
    } else {
      return (data.rows || []) as unknown as BatteryMonitorRow[];
    }
  }

  /**
   * Generate header cells for time columns
   */
  private generateTimeHeaders(timeStamps: string[]): HeaderCell[] {
    return timeStamps.map((timestamp) => {
      const date = dayjs(timestamp);
      const day = date.format('ddd');
      const dayNum = date.date();
      const month = date.format('MMM');

      return {
        content: `${day}\n(${dayNum} ${month})`,
        styles: {
          lineWidth: { right: 0.2, left: 0.2, top: 0.2, bottom: 0.2 },
          cellPadding: [2, 2, 1, 1],
          minCellHeight: 5,
          halign: 'center',
        },
      } as HeaderCell;
    });
  }

  /**
   * Generate table data from battery monitor rows
   */
  private generateTableData(rows: BatteryMonitorRow[], uniqueDays: string[]) {
    return rows.map((row) => {
      const statusData = uniqueDays.map((day) => {
        const dayEvents = this.getLogsForDate(row, day);
        return this.determineStatusForDay(dayEvents);
      });

      return [row.referenceId || '-', row.name || '-', row.poweredEquipment ?? null, ...statusData];
    });
  }

  /**
   * Generate an array of dates between start and end dates
   */
  private generateDateRange(startDate: string, endDate: string): string[] {
    const dates: string[] = [];
    let currentDate = dayjs(startDate).startOf('day');
    const end = dayjs(endDate).endOf('day');

    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    return dates;
  }

  /**
   * Get events for a specific date
   */
  private getLogsForDate(row: BatteryMonitorRow, date: string): DeviceLog[] {
    if (!row.entries || !Array.isArray(row.entries)) {
      return [];
    }

    // Normalize the date format
    const targetDate = dayjs(date).format('YYYY-MM-DD');
    const allLogs: DeviceLog[] = [];

    // Go through all entries and collect logs for the target date
    row.entries.forEach((entry) => {
      if (entry.logs && Array.isArray(entry.logs)) {
        entry.logs.forEach((log) => {
          if (log.timeStamp) {
            const logDate = dayjs(log.timeStamp).format('YYYY-MM-DD');
            if (logDate === targetDate) {
              allLogs.push(log);
            }
          }
        });
      }
    });

    return allLogs;
  }

  /**
   * Determine status for a day based on events
   */
  determineStatusForDay(logs: DeviceLog[]): BatteryDeviceStatus {
    if (!logs || logs.length === 0) {
      return BatteryDeviceStatus.NO_LOGS;
    }

    // Sort logs by timestamp (newest to oldest)
    const sortedLogs = [...logs].sort((a, b) => {
      // Sort in descending order to get the most recent log first
      return new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime();
    });

    // Check the most recent log
    const latestLog = sortedLogs[0];

    // Primarily use alarmStatus (same as for Intellitag)
    if (latestLog.alarmStatus === AlarmStatus.OK) {
      return BatteryDeviceStatus.OK;
    } else {
      return BatteryDeviceStatus.POWERLESS;
    }
  }
}
