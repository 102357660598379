import { Point } from 'geojson';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { useMapZoomLevel } from '@/hooks/useMapZoomLevel';

export interface Props {
  id: string;
  color: string;
  centerPoint: Point | null;
  map: google.maps.Map | null;
  size?: 'sm' | 'md';
  hasCollisionFilter?: boolean;
  onClick?: (id: string) => void;
}

export const DotMarker = ({
  id,
  color,
  centerPoint,
  map,
  size = 'md',
  hasCollisionFilter,
  onClick,
}: Props) => {
  const dotSize = size === 'md' ? '12px' : '8px';
  const { isAtZoomLevel } = useMapZoomLevel({
    map,
    zoomLevel: ['mid', 'global'],
  });

  return (
    <>
      {isAtZoomLevel && (
        <AdvancedMarker
          key={id}
          map={map}
          onClick={() => {
            onClick?.(id ? id : '');
          }}
          position={
            centerPoint
              ? {
                  lat: centerPoint.coordinates[1],
                  lng: centerPoint.coordinates[0],
                }
              : null
          }
          collisionBehavior={
            hasCollisionFilter
              ? google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY
              : google.maps.CollisionBehavior.REQUIRED
          }
        >
          <div
            style={{
              height: dotSize,
              width: dotSize,
              borderRadius: '50%',
              background: color,
              border: '1px solid white',
            }}
          />
        </AdvancedMarker>
      )}
    </>
  );
};
