import { jsPDF } from 'jspdf';
import { CellHookData } from 'jspdf-autotable';
import { PdfDeviceAlarmEvents, PdfTableRow } from './alarmEventUtils';
import { PDF_CONSTANTS } from '@/constants/pdfConstants';

const ALARM_TABLE_FONT_SIZE = 8;
const ALARM_ICON_SIZE = 4;

export function handleAlarmCell(
  doc: jsPDF,
  data: CellHookData,
  deviceAlarms: PdfDeviceAlarmEvents[],
  statusIcons: { alert: string; check: string }
): void {
  const fillColor = data.row.index % 2 === 0 ? [248, 248, 248] : [255, 255, 255];
  doc.setFillColor(fillColor[0], fillColor[1], fillColor[2]);

  const gapSize = 0.5;
  doc.rect(
    data.cell.x,
    data.cell.y + gapSize,
    data.cell.width,
    data.cell.height - gapSize * 2,
    'F'
  );

  const row = data.row.raw as string[];
  if (row[3] || row[4]) {
    let currentRow = 0;
    let targetDevice: PdfDeviceAlarmEvents | undefined;
    let targetEventIndex = 0;

    for (const device of deviceAlarms) {
      const eventCount = device.events.length;
      if (data.row.index >= currentRow && data.row.index < currentRow + eventCount) {
        targetDevice = device;
        targetEventIndex = data.row.index - currentRow;
        break;
      }
      currentRow += eventCount;
    }

    if (targetDevice && targetDevice.events[targetEventIndex]) {
      const event = targetDevice.events[targetEventIndex];
      // Add alarm icons with proper vertical centering
      if (data.column.index === 3) {
        try {
          const paddingSizeX = 8;
          const startX = data.cell.x + paddingSizeX;
          const iconY = data.cell.y + (data.cell.height - ALARM_ICON_SIZE) / 2;
          doc.addImage(statusIcons.alert, 'PNG', startX, iconY, ALARM_ICON_SIZE, ALARM_ICON_SIZE);

          // Add text with padding, vertically centered
          doc.setFontSize(ALARM_TABLE_FONT_SIZE);
          doc.setTextColor(0, 0, 0);
          doc.text(
            row[data.column.index],
            startX + ALARM_ICON_SIZE + 4,
            data.cell.y + data.cell.height / 2,
            { align: 'left', baseline: 'middle' }
          );
        } catch (error) {
          // Silently handle image errors
        }
      } else if (data.column.index === 4 && event.alarmAddressed) {
        try {
          const paddingSizeX = 8;
          const startX = data.cell.x + paddingSizeX;
          const iconY = data.cell.y + (data.cell.height - ALARM_ICON_SIZE) / 2;
          doc.addImage(statusIcons.check, 'PNG', startX, iconY, ALARM_ICON_SIZE, ALARM_ICON_SIZE);

          // Add text with padding, vertically centered
          doc.setFontSize(ALARM_TABLE_FONT_SIZE);
          doc.setTextColor(0, 0, 0);
          doc.text(
            row[data.column.index],
            startX + ALARM_ICON_SIZE + 4,
            data.cell.y + data.cell.height / 2,
            { align: 'left', baseline: 'middle' }
          );
        } catch (error) {
          // Silently handle image errors
        }
      } else {
        // Don't add any icon if there's no addressed time
        doc.setFontSize(ALARM_TABLE_FONT_SIZE);
        doc.setTextColor(0, 0, 0);
        doc.text(
          row[data.column.index],
          data.cell.x + data.cell.width / 2,
          data.cell.y + data.cell.height / 2,
          {
            align: 'center',
            baseline: 'middle',
          }
        );
      }
    }
  }

  data.cell.text = [];
}

export function handleDeviceGroupSeparator(
  doc: jsPDF,
  data: CellHookData,
  deviceAlarms: PdfDeviceAlarmEvents[],
  tableBody: PdfTableRow[]
): void {
  if (data.cell.section === 'body' && data.column.index === 4) {
    let currentRow = 0;
    for (const device of deviceAlarms) {
      currentRow += device.events.length;
      if (data.row.index === currentRow - 1 && currentRow < tableBody.length) {
        doc.setDrawColor(220, 220, 220);
        doc.setLineWidth(0.5);
        doc.line(
          PDF_CONSTANTS.SIDE_MARGIN,
          data.cell.y + data.cell.height,
          doc.internal.pageSize.getWidth() - PDF_CONSTANTS.SIDE_MARGIN,
          data.cell.y + data.cell.height
        );
        break;
      }
    }
  }
}
