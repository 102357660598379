import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { GENERATION_STEPS } from '../pdfWorker';
import { PdfGenerationData, ProgressUpdate } from '@/models/PdfExport';

/**
 * Type for creation step function
 */
export type CreationStep = <T>(
  step: keyof typeof GENERATION_STEPS,
  fn: () => Promise<T> | T
) => Promise<T>;

/**
 * Base class for PDF generators
 * Provides common functionality for all device-specific PDF generators
 */
export abstract class BasePdfGenerator {
  protected isCancellationRequested = false;
  protected progressCallback?: (progress: ProgressUpdate) => void;
  protected skipCoverPage = false;
  protected skipLastPage = false;
  protected existingDocument?: jsPDF;

  /**
   * Set the progress callback function
   */
  public setProgressCallback(callback?: (progress: ProgressUpdate) => void): void {
    this.progressCallback = callback;
  }

  /**
   * Configure generator to skip cover page and last page
   * Used for combined report generation
   */
  public setSkipCoverAndLastPage(skipCover: boolean, skipLast: boolean): void {
    this.skipCoverPage = skipCover;
    this.skipLastPage = skipLast;
  }

  /**
   * Set an existing document to use
   * Used for combined reports
   */
  public setExistingDocument(doc: jsPDF): void {
    this.existingDocument = doc;
  }

  /**
   * Create a new PDF document
   */
  protected createPdfDocument(): jsPDF {
    // Use existing document if provided, otherwise create a new one
    if (this.existingDocument) {
      return this.existingDocument;
    }

    return new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });
  }

  /**
   * Apply autotable to a document
   */
  protected applyAutoTable(doc: jsPDF, tableConfig: UserOptions): void {
    autoTable(doc, tableConfig);
  }

  /**
   * Request cancellation of PDF generation
   */
  public cancelGeneration(): void {
    this.isCancellationRequested = true;
  }

  /**
   * Check if cancellation was requested
   */
  protected checkCancellation(): boolean {
    return this.isCancellationRequested;
  }

  /**
   * Update progress callback with current status
   */
  protected updateProgressCallback(progress: ProgressUpdate): void {
    if (this.progressCallback) {
      try {
        this.progressCallback(progress);
      } catch (error) {
        // Silently handle callback errors
      }
    }
  }

  /**
   * Generate a PDF for the specific device type
   * This method must be implemented by each device-specific generator
   */
  public abstract generatePdf(data: PdfGenerationData, creationStep: CreationStep): Promise<string>;

  /**
   * Generate content for the specific device type
   * This method is used by the CombinedPdfGenerator to generate content without creating a new document
   * Must be implemented by each device-specific generator
   */
  public abstract generateContent(
    data: PdfGenerationData,
    doc: jsPDF,
    creationStep: CreationStep
  ): Promise<void>;
}
