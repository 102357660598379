import {
  Stack,
  Title,
  Text,
  Center,
  Loader,
  Group,
  Button,
  Modal,
  Tooltip,
  InputWrapper,
  Input,
  ActionIcon,
  Textarea,
} from '@mantine/core';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Papa from 'papaparse';
import useBulkProvisionDevices from '@/data/hooks/Device/useBulkProvisionDevices';
import useInfBulkProvisionListHook from '@/data/hooks/BulkProvision/useInfBulkProvisionListHook';
import { useDisclosure } from '@mantine/hooks';
import { IconGift, IconDownload } from '@tabler/icons-react';
import { ConfirmButtonGroup } from './ConfirmButtonGroup/ConfirmButtonGroup';
import { FlexSpacer } from './FlexSpacer';
import { uniqueNamesGenerator, Config, adjectives, animals } from 'unique-names-generator';
import { isNotEmpty, useForm } from '@mantine/form';
import { ProvisionTable } from '@/components/BulkProvision/ProvisionTable';
import { FileUpload } from '@/components/FileUpload/FileUpload';
import { parseDeviceModel, parseDeviceType } from '@/utils/deviceEnumParser';
import { DeviceModel, DeviceType } from '@/models/Device';
import useMyUserAccesses from '@/data/hooks/UserAccess/useMyUserAccess';
import { AccessDenied } from '@/pages/AccessDenied';

interface CSVRow {
  referenceId: string;
  imei: string;
  modelRef: string;
  deviceType: string;
  [key: string]: string | undefined;
}

interface DeviceData {
  referenceId: string;
  imei: string;
  modelRef: DeviceModel;
  deviceType: DeviceType;
}

interface FormValues {
  name: string;
  comment: string;
}

export const BulkProvisionDevices = () => {
  const { instance } = useMsal();
  const { userAccesses } = useMyUserAccesses({ instance });
  const [fileError, setFileError] = useState<string>('');
  const [validatedData, setValidatedData] = useState<DeviceData[] | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const form = useForm<FormValues>({
    initialValues: {
      name: '',
      comment: '',
    },
    validate: {
      name: isNotEmpty('Name is required'),
    },
  });

  const { bulkProvisions, isLoading } = useInfBulkProvisionListHook(instance);

  const {
    mutateAsync: bulkProvisionDevices,
    isPending: isProvisioning,
    validateCsv,
  } = useBulkProvisionDevices(instance);

  if (!userAccesses?.hasDeviceProvisioningAccess) {
    return <AccessDenied />;
  }

  const isSubmitDisabled = !form.values.name.trim() || validatedData === null;

  const getUniqueName = (): void => {
    const customConfig: Config = {
      dictionaries: [adjectives, animals],
      separator: ' ',
      length: 2,
    };

    const name = `The ${uniqueNamesGenerator(customConfig)}`;
    form.setFieldValue('name', name);
  };

  const transformCsvToDeviceData = (csvData: CSVRow[]): DeviceData[] => {
    const errors: string[] = [];

    const parsedData = csvData.map((row) => {
      try {
        return {
          referenceId: row.referenceId,
          imei: row.imei,
          modelRef: parseDeviceModel(row.modelRef),
          deviceType: parseDeviceType(row.deviceType),
        };
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        errors.push(`${row.referenceId}: ${errorMessage}`);
        return null;
      }
    });

    if (errors.length > 0) {
      throw new Error(`Found ${errors.length} errors in CSV:\n${errors.join('\n')}`);
    }

    return parsedData.filter((data): data is DeviceData => data !== null);
  };

  const handleFileSelect = (file: File): void => {
    setFileError('');
    setValidatedData(null);
    setSelectedFile(file);

    if (!file || file.type !== 'text/csv') {
      setFileError('Please upload a valid CSV file.');
      setSelectedFile(null);
      return;
    }

    const parseConfig: Papa.ParseConfig<CSVRow> = {
      header: true,
      skipEmptyLines: true,
    };

    Papa.parse<CSVRow>(file, {
      ...parseConfig,
      complete: (results) => {
        const { data, errors } = results;

        if (errors.length) {
          setFileError(
            "The CSV file format doesn't match the expected structure. Please download our template to see the correct format."
          );
          setSelectedFile(null);
          return;
        }

        if (data.length === 0) {
          setFileError('CSV file is empty');
          setSelectedFile(null);
          return;
        }

        try {
          const parsedData = transformCsvToDeviceData(data);

          // Send to backend for validation
          validateCsv(parsedData)
            .then((validationResult) => {
              if (!validationResult.isValid) {
                if (validationResult.errorDetails && validationResult.errorDetails.length > 0) {
                  const errorMessages = validationResult.errorDetails.map(
                    (err) => `${err.errors.join(', ')}`
                  );
                  setFileError(errorMessages.join('\n'));
                } else {
                  setFileError(validationResult.error || 'CSV validation failed');
                }
                setSelectedFile(null);
                return;
              }

              setValidatedData(parsedData);
            })
            .catch((error) => {
              console.error('CSV validation failed:', error);
              setFileError('Failed to validate CSV. Please try again.');
              setSelectedFile(null);
            });
        } catch (error) {
          setFileError(error instanceof Error ? error.message : 'Failed to parse CSV data');
          setSelectedFile(null);
        }
      },
      error: (error) => {
        console.error('CSV parsing failed:', error);
        setFileError('Failed to parse CSV file. Please check the format.');
        setSelectedFile(null);
      },
    });
  };

  const handleFileRemove = (): void => {
    setSelectedFile(null);
    setValidatedData(null);
    setFileError('');
  };

  const handleBulkAdd = (): void => {
    if (!validatedData) return;

    bulkProvisionDevices({
      Name: form.values.name,
      Comment: form.values.comment,
      DeviceProvisioningRequests: validatedData,
    })
      .then(() => {
        close();
        form.reset();
        setValidatedData(null);
      })
      .catch((error) => {
        console.error('Bulk provision failed:', error);
        setFileError('Failed to provision devices. Please try again.');
      });
  };

  const handleSubmit = (): void => {
    if (!form.validate().hasErrors && validatedData) {
      handleBulkAdd();
    }
  };

  const handleModalClose = (): void => {
    form.reset();
    setValidatedData(null);
    setFileError('');
    setSelectedFile(null);
    close();
  };

  const handleDownloadTemplate = () => {
    const templateContent = `referenceId,imei,modelRef,deviceType
TAG-123,000000000000000,Tat140,Intellitag
TAG-124,000000000000001,Fmc130,Arrowboard
TAG-125,000000000000002,Fmc880,Smart Cable
TAG-126,000000000000003,PsaRed,Portable Site Alarm
TAG-127,000000000000004,Fmc880,Smart Solar
TAG-128,000000000000005,Tat141,Intellitag Lite
TAG-129,000000000000006,Dekimo,Intellitag v2`;
    const blob = new Blob([templateContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'bulk-provisioning-example.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <>
      <Stack bg="white" p="md">
        <Group justify="space-between">
          <Title order={3} mb="xs">
            Device Provisioning
          </Title>
          <Button variant="default" onClick={open}>
            New provisioning
          </Button>
        </Group>
      </Stack>
      {isLoading ? (
        <Center mt="xl">
          <Loader />
        </Center>
      ) : (
        <Stack gap="sm" p="md">
          {bulkProvisions.map((provision) => (
            <ProvisionTable key={provision.name} provision={provision} />
          ))}
        </Stack>
      )}
      <Modal.Root opened={opened} onClose={handleModalClose}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title fw={600}>New provisioning</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Stack mih={500}>
              <Button
                variant="light"
                ml="auto"
                leftSection={<IconDownload size={16} />}
                onClick={handleDownloadTemplate}
              >
                Download Template
              </Button>
              <FileUpload
                onFileSelect={handleFileSelect}
                onFileRemove={handleFileRemove}
                selectedFile={selectedFile}
                accept={['text/csv']}
              />
              {fileError ? (
                <Text c="dangerRed" size="sm" style={{ whiteSpace: 'pre-wrap' }}>
                  {fileError}
                </Text>
              ) : validatedData ? (
                <Text c="successGreen" size="sm">
                  CSV validated successfully - {validatedData.length} devices ready to provision
                </Text>
              ) : null}
              <Group h={50} gap="xs" wrap="nowrap" align="end">
                <InputWrapper w="100%" label="Name" required error={form.errors.name}>
                  <Input
                    value={form.values.name}
                    onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
                    error={form.errors.name}
                  />
                </InputWrapper>
                <Tooltip label="Surprise me">
                  <ActionIcon size={36} variant="subtle" onClick={getUniqueName}>
                    <IconGift />
                  </ActionIcon>
                </Tooltip>
              </Group>
              <InputWrapper w="100%" label="Comment">
                <Textarea
                  value={form.values.comment}
                  onChange={(event) => form.setFieldValue('comment', event.currentTarget.value)}
                />
              </InputWrapper>
              <FlexSpacer />
              <ConfirmButtonGroup
                confirmBtnText="Submit"
                onAbort={handleModalClose}
                onConfirm={handleSubmit}
                isLoading={isProvisioning}
                disabled={isSubmitDisabled || isProvisioning}
              />
            </Stack>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
