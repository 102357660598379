import { Stack, Text, Title } from '@mantine/core';
import { IconKey } from '@tabler/icons-react';

export const AccessDenied = () => {
  return (
    <>
      <Stack px={'md'} mx={'auto'} maw={400} h={'70vh'} align="center" justify="center">
        <IconKey size={40} />
        <Title opacity={0.8} order={3}>
          Access Denied
        </Title>
        <Text ta={'center'} c={'gray'}>
          You dont have access to this page, please contact your supervisor
        </Text>
      </Stack>
    </>
  );
};
