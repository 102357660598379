import { Button, Checkbox, Popover, Stack, Text } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import { useState, useMemo, useEffect } from 'react';
import { DeviceEventType } from '../../../models/DeviceEvent';
import { eventConfig, eventTypeGroups } from './deviceEventConfig';

interface DeviceEventsFilterProps {
  selectedFilters: DeviceEventType[];
  onFilterChange: (filters: DeviceEventType[]) => void;
  availableEventTypes: DeviceEventType[];
  isLoading?: boolean;
}

export const DeviceEventsFilter = ({
  selectedFilters,
  onFilterChange,
  availableEventTypes,
  isLoading = false,
}: DeviceEventsFilterProps) => {
  const [opened, setOpened] = useState(false);

  // Store the full list of all event types
  const [allPossibleEventTypes, setAllPossibleEventTypes] = useState<DeviceEventType[]>([]);

  useEffect(() => {
    if (availableEventTypes.length === 0) {
      setAllPossibleEventTypes([]);
      return;
    }

    setAllPossibleEventTypes((prevTypes) => {
      const combinedTypes = [...prevTypes];

      availableEventTypes.forEach((type) => {
        if (!combinedTypes.includes(type)) {
          combinedTypes.push(type);
        }
      });

      return combinedTypes;
    });
  }, [availableEventTypes]);

  const filteredGroups = useMemo(() => {
    return eventTypeGroups
      .map((group) => ({
        title: group.title,
        types: group.types.filter((type) => allPossibleEventTypes.includes(type)),
      }))
      .filter((group) => group.types.length > 0);
  }, [allPossibleEventTypes]);

  const hasActiveFilters = selectedFilters.length > 0;
  const hasAvailableFilters = filteredGroups.length > 0;

  const handleToggleFilter = (type: DeviceEventType) => {
    const newFilters = selectedFilters.includes(type)
      ? selectedFilters.filter((t) => t !== type)
      : [...selectedFilters, type];

    onFilterChange(newFilters);
  };

  const handleClearFilters = () => {
    onFilterChange([]);
  };

  return (
    <Popover opened={opened} onChange={setOpened} position="bottom-end" width={220} shadow="md">
      <Popover.Target>
        <Button
          className={hasActiveFilters ? 'mantineBtn-override' : ''}
          miw="fit-content"
          variant="default"
          bg={'var(--mantine-color-lightGrey-0)'}
          fw={300}
          leftSection={<IconFilter size={18} />}
          disabled={!hasAvailableFilters && !isLoading}
          onClick={() => setOpened((o) => !o)}
        >
          {hasActiveFilters ? `Filters (${selectedFilters.length})` : 'Filter'}
        </Button>
      </Popover.Target>
      <Popover.Dropdown onClick={(e) => e.stopPropagation()}>
        <Stack gap="sm">
          {filteredGroups.length > 0 ? (
            <>
              {filteredGroups.map((group) => (
                <Stack key={group.title} gap="xs">
                  <Text size="sm">{group.title}</Text>
                  {group.types.map((type) => (
                    <Checkbox
                      key={type}
                      label={
                        eventConfig[type]?.displayName ||
                        type
                          .replace(/([A-Z])/g, ' $1')
                          .replace(/Event$/, '')
                          .trim()
                      }
                      checked={selectedFilters.includes(type)}
                      onChange={() => handleToggleFilter(type)}
                    />
                  ))}
                </Stack>
              ))}

              {hasActiveFilters && (
                <Button
                  ml="auto"
                  c="black"
                  fw={300}
                  size="md"
                  variant="transparent"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handleClearFilters}
                >
                  Clear all filters
                </Button>
              )}
            </>
          ) : (
            <Text size="sm" c="dimmed" ta="center">
              No event types available
            </Text>
          )}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
