import { ReactNode, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { IconPlus, IconInfoCircle } from '@tabler/icons-react';
import { Checkbox, Divider, Group, Loader, Paper, Stack, Text } from '@mantine/core';

import { AddRecipiant } from '@/components/Project/AddRecipiant/AddRecipiant.tsx';
import { ContactsList } from '@/components/Project/ContactsList/ContactsList.tsx';
import { SearchEmployee } from '@/components/SearchEmployee/SearchEmployee.tsx';
import { WorkZone } from '@/models/Project.ts';
import useCreateNotificationContact from '@/data/hooks/NotificationContact/useCreateNotificationContactHook.ts';
import useGetNotificationContactsInWorkZone from '@/data/hooks/NotificationContact/useGetNotificationContactsInWorkZoneHook.ts';
import useToggleExternalConnection from '@/data/hooks/Project/useToggleExternalConnectionHook.ts';
import useSnoozeProject from '@/data/hooks/Project/useSnoozeProjectHook.ts';
import useUnSnoozeProject from '@/data/hooks/Project/useUnSnoozeProjectHook.ts';
import { Employee } from '@/models/Employee.ts';
import useGetMyUserInformation from '@/data/hooks/User/useGetUserInformationHook.ts';
import { getEmployees } from '@/services/auth.ts';
import { IdentityProvider } from '@/models/enums/UserEnums.ts';
import UnSnoozeButton from '@/components/Project/UnSnoozeButton/UnSnoozeButton.tsx';
import { useMobileDevice } from '@/hooks/useMobileDevice.tsx';
import SnoozeStatus from '@/components/Project/SnoozeStatus/SnoozeStatus';
import SnoozeButton from '@/components/Project/SnoozeButton/SnoozeButton.tsx';
import { isSnoozeActive, isSnoozeScheduled } from '@/utils/snoozeUtils.ts';
import { TabTitle } from '@/components/TabTitle/TabTitle.tsx';
import { Button } from '@/components/Button/Button';
import { Setup } from '@/models/Setups';

export const NotificationSettings = (props: { project: WorkZone; setup?: Setup }) => {
  const isMobile = useMobileDevice();
  const [showInput, setShowInput] = useState<boolean>(false);
  const { instance } = useMsal();

  const { mutate: createNotificationContact, isPending: createNotificationContactPending } =
    useCreateNotificationContact(instance);
  const { notificationContacts, isLoading: notificationContactsLoading } =
    useGetNotificationContactsInWorkZone({
      instance,
      projectId: props.project.id,
    });

  const contactsLoading = notificationContactsLoading || createNotificationContactPending;

  const { mutate: toggleExternalConnection } = useToggleExternalConnection(instance);
  const { mutate: snoozeProject, isPending: snoozePending } = useSnoozeProject(instance);
  const { mutate: unsnoozeProject } = useUnSnoozeProject(instance);

  const { userInformation } = useGetMyUserInformation({ instance });

  const fetchEmployees = async (query: string): Promise<Employee[]> => {
    return await getEmployees(instance, query);
  };

  return (
    <>
      <Stack p="md">
        <div>
          <TabTitle>Snooze notifications</TabTitle>
          <Text size="xs" c="gray.6">
            Silence all alarm notifications for a set period of time
          </Text>
        </div>
        {isSnoozeActive(props.project) || isSnoozeScheduled(props.project) ? (
          // Any type of snooze - show UnSnoozeButton and SnoozeStatus
          <Stack>
            <UnSnoozeButton project={props.project} unsnoozeProject={unsnoozeProject} />
            <SnoozeStatus
              typeOfSnooze={isSnoozeActive(props.project) ? 'Active' : 'Scheduled'}
              isSnoozed={props.project.isSnoozed}
              snoozedFrom={props.project.snoozedFrom}
              snoozedUntil={props.project.snoozedUntil}
            />
          </Stack>
        ) : (
          // No snooze - show regular SnoozeButton
          <>
            <SnoozeButton
              projectId={props.project.id}
              snoozeProject={(params) => snoozeProject(params)}
              disabled={!!props.setup}
            />
            {props.setup && (
              <InformationBanner>
                <IconInfoCircle size="20px" />
                <Text size="sm">A Project with PSAs cannot be snoozed</Text>
              </InformationBanner>
            )}
          </>
        )}
        {snoozePending ? <Loader ml={'md'} size={'sm'} type="dots" /> : null}
        <Divider></Divider>
        <div>
          <TabTitle>Alarm Notifications</TabTitle>
          <Text size="xs" c={'gray.6'}>
            Set who should get notifications and when
          </Text>
        </div>
        <div>
          <Text size="sm">
            <strong>Internal users</strong>
          </Text>
        </div>
        <ContactsList
          contactsLoading={contactsLoading}
          instance={instance}
          contacts={notificationContacts}
          external={false}
        />
        {userInformation?.item.idp === IdentityProvider.RamuddenGlobalEntraId && (
          <SearchEmployee
            width={isMobile ? '100%' : '500px'}
            onChange={(values) => {
              createNotificationContact({
                workZoneId: props.project.id,
                name: values.name,
                email: values.email,
                phone: values.phone,
                external: false,
                timeZoneId: 'Central European Time',
                externalUserId: values.externalUserId,
                timeFrame: {
                  start: '00:00:00',
                  end: '23:59:00',
                  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                },
              });
              setShowInput(false);
            }}
            onClose={() => {
              setShowInput(false);
            }}
            fetchEmployees={fetchEmployees}
          />
        )}
        <Divider size="md" />
        <div>
          <Text size="sm">
            <strong>Customer</strong>
          </Text>
        </div>
        <ContactsList
          contactsLoading={contactsLoading}
          instance={instance}
          contacts={notificationContacts}
          external={true}
        />
        {showInput ? (
          <AddRecipiant
            width={isMobile ? '100%' : '500px'}
            onSubmit={(values) => {
              createNotificationContact({
                workZoneId: props.project.id,
                name: values.name,
                email: values.email,
                phone: values.phone,
                external: true,
                timeZoneId: 'Central European Time',
                timeFrame: {
                  start: '00:00:00',
                  end: '23:59:00',
                  daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                },
              });
              setShowInput(false);
            }}
            onClose={() => {
              setShowInput(false);
            }}
          />
        ) : (
          <Button
            variant="secondary"
            icon={IconPlus}
            fullWidth={isMobile}
            onClick={() => {
              setShowInput(true);
            }}
          >
            Add contact
          </Button>
        )}
        <Divider size="md" />
        <div>
          <TabTitle>Automatic work order</TabTitle>
          <Text size="xs" c={'gray.6'}>
            Create work orders when alarms are registered
          </Text>
        </div>
        {props.project.externalId === null || props.project.workOrderSystem === null ? (
          <>
            <Checkbox
              label="Activate automatic work orders (External connection is missing)"
              labelPosition="right"
              disabled={true}
            />
            <InformationBanner>
              <IconInfoCircle size={'20px'} />
              <Text size={'sm'}>
                Only available if the project is connected to a workorder system
              </Text>
            </InformationBanner>
          </>
        ) : (
          <Checkbox
            label="Activate automatic work orders"
            labelPosition="right"
            py="md"
            defaultChecked={props.project.externalConnectionEnabled}
            onChange={() => {
              toggleExternalConnection({ id: props.project.id });
            }}
          />
        )}
      </Stack>
    </>
  );
};

const InformationBanner = ({ children }: { children: ReactNode }) => {
  return (
    <Paper>
      <Group
        gap="sm"
        style={{
          border: '1px solid #228BE7',
          backgroundColor: '#228BE71A',
          borderRadius: '5px',
          padding: '8px',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {children}
      </Group>
    </Paper>
  );
};
