import { IPublicClientApplication } from '@azure/msal-browser';
import { useInfiniteQuery, InfiniteData, QueryKey } from '@tanstack/react-query';
import { useState } from 'react';
import { Device } from '../../../models/Device';
import { defaultDeviceFilters } from '../../filters';
import { useDebouncedCallback } from '@mantine/hooks';
import useInfiniteScroll from '../useInfiniteScroll';
import { APIResponseList, buildQueryString, prepareHeaders } from '../../api/apiHelpers';
import { SortDirection } from '../../../models/enums/SortEnums';

const path = `${import.meta.env.VITE_API_BASE_PATH}/device/paginated`;

interface DeviceFilters {
  searchTerm?: string;
  sortColumn?: string;
  sortDirection?: SortDirection;
}

const useInfDeviceList = (instance: IPublicClientApplication) => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<DeviceFilters>(defaultDeviceFilters);
  const [sortColumn, setSortColumn] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.None);
  const pageSize = 20;

  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<
      APIResponseList<Device>,
      Error,
      InfiniteData<APIResponseList<Device>>,
      QueryKey,
      number
    >({
      queryKey: ['devices', { filters, sortColumn, sortDirection }],
      queryFn: async ({ pageParam }) => {
        const queryString = buildQueryString({
          ...filters,
          pageSize,
          pageNumber: pageParam,
          sortColumn,
          sortDirection,
        });
        const response = await fetch(`${path}${queryString}`, {
          mode: 'cors',
          method: 'GET',
          headers: await prepareHeaders(instance),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return {
          items: data.data,
          pageSize: data.pageSize,
          pageNumber: data.pageNumber,
          totalCount: data.totalCount,
        };
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        const nextPage =
          lastPage.pageNumber < lastPage.totalCount ? lastPage.pageNumber + 1 : undefined;
        return nextPage;
      },
      refetchInterval: 60000,
    });

  useInfiniteScroll(fetchNextPage, isLoading, isFetchingNextPage, hasNextPage);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    handleSearch(event.target.value);
  };

  const handleSearch = useDebouncedCallback(async (search: string) => {
    setFilters({ ...filters, searchTerm: search });
  }, 500);

  const handleSort = (column: string) => {
    let newDirection = SortDirection.Ascending;

    if (sortColumn === column) {
      if (sortDirection === SortDirection.Ascending) {
        newDirection = SortDirection.Descending;
      } else if (sortDirection === SortDirection.Descending) {
        newDirection = SortDirection.None;
      }
    }

    setSortColumn(newDirection === SortDirection.None ? undefined : column);
    setSortDirection(newDirection);
    setFilters({
      ...filters,
      sortColumn: newDirection === SortDirection.None ? undefined : column,
      sortDirection: newDirection,
    });
  };

  const onSerachSpecific = (searchString: string) => {
    setSearch(searchString);
    setFilters({ ...filters, searchTerm: searchString });
  };

  const devices = data?.pages.flatMap((page) => page.items) ?? [];
  const totalCount = data?.pages[0]?.totalCount ?? 0;

  return {
    devices,
    isLoading,
    isError,
    search,
    handleSearchChange,
    onSerachSpecific,
    fetchNextPage,
    setFilters,
    setSearch,
    hasNextPage,
    isFetchingNextPage,
    sortColumn,
    sortDirection,
    handleSort,
    totalCount,
  };
};

export default useInfDeviceList;
