import { useContext, useEffect, useState } from 'react';
import { Button, Flex, Stack, Textarea } from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import { PWAContext } from '../providers/PWAProvider';
import { assignToProject } from '../../services/devices';
import DeviceActivationInfo from '../components/DeviceActivationInfo';
import { getDeviceInfoDescription, getDeviceInfoLabel } from '../../copy';
import DeviceSummary from '../components/DeviceSummary';
import { DeviceType, DeviceTypeNames } from '../../models/Device';

export const PwaSummaryPage = () => {
  const { instance } = useMsal();
  const {
    device,
    equipmentType,
    setEquipmentType,
    setPoweredEquipment,
    poweredEquipment,
    sign,
    project,
    setMessage,
    setDevice,
    setSign,
  } = useContext(PWAContext);
  const [currentName, setCurrentName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const connect = () => {
    if (project?.id && device?.id) {
      assignToProject({
        instance,
        deviceId: device?.id,
        projectId: project?.id,
        equipmentType,
        poweredEquipment,
        currentName,
        attachmentRef: sign?.value || null,
      })
        .then(() => {
          setDevice(null);
          setSign(null);
          setMessage({
            title: `Successfully added ${DeviceTypeNames[device.deviceType]}`,
            text: 'Successfully added a device to the project',
            type: 'success',
          });
          setEquipmentType(null);
          setPoweredEquipment(null);
          navigate('/pwa/projects/scanner');
        })
        .catch((error) => {
          setMessage({
            text: error,
            title: 'Something went wrong',
            type: 'error',
          });
        });
    }
  };

  const showActivationInfo = [DeviceType.IntellitagV1, DeviceType.IntelliTagLite];

  return (
    <Flex h="100%" direction="column" justify="space-between" gap="md" mb="lg">
      <Stack gap="lg">
        {device && showActivationInfo.includes(device?.deviceType) && <DeviceActivationInfo />}

        <DeviceSummary
          projectName={project?.name || '-'}
          referenceID={device?.referenceId || '-'}
          deviceType={device?.deviceType}
          equipment={{
            type: equipmentType,
            name: sign?.name,
            value: sign?.value,
          }}
          poweredEquipment={poweredEquipment}
        />
      </Stack>

      <Stack gap="md">
        <Textarea
          label={getDeviceInfoLabel(project?.countryCode || '')}
          placeholder={getDeviceInfoDescription(project?.countryCode || '')}
          onChange={(event) => setCurrentName(event.currentTarget.value)}
          size="md"
          value={currentName}
        />
        <Button size="xl" onClick={connect}>
          Add to project
        </Button>
      </Stack>
    </Flex>
  );
};
