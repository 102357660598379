import { CreateProjectFlowFormValues } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { ScenarioType } from '@/models/enums/ScenarioEnums';
import { getScenarioImage } from '@/utils/ImageHelpers';
import { Flex, Group, Image, InputError, Radio, Stack, Text, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

const FORM_KEY = 'psaSetupStep' as const satisfies keyof CreateProjectFlowFormValues;

export const ScenarioSelection = ({
  form,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
}) => {
  const selectedScenario = form.getValues()[FORM_KEY]!.scenario;

  return (
    <Stack gap="md">
      <Stack gap="xs">
        <Title order={5} mb={'xs'}>
          Select scenario
        </Title>
        {form.errors[`${FORM_KEY}.scenario`] && (
          <InputError size="md" fw={500}>
            {form.errors[`${FORM_KEY}.scenario`]}
          </InputError>
        )}
      </Stack>

      <Flex gap="md" align="stretch" wrap={'wrap'}>
        <ScenarioCard
          scenarioType={ScenarioType.Scenario1}
          title="Scenario 1"
          description="Manned and potentially unmanned full closure points either side of the working area"
          checked={selectedScenario === ScenarioType.Scenario1}
          onClick={() => {
            form.setFieldValue(`${FORM_KEY}.scenario`, ScenarioType.Scenario1);
          }}
        />

        <ScenarioCard
          scenarioType={ScenarioType.Scenario2}
          title="Scenario 2"
          description="All potentially unmanned full closure points, before the Entry Point Airlock, prior to the working area."
          checked={selectedScenario === ScenarioType.Scenario2}
          onClick={() => {
            form.setFieldValue(`${FORM_KEY}.scenario`, ScenarioType.Scenario2);
          }}
        />
      </Flex>
    </Stack>
  );
};

const ScenarioCard = ({
  scenarioType,
  title,
  description,
  disabled,
  checked,
  onClick,
}: {
  scenarioType: ScenarioType;
  title: string;
  description: string;
  disabled?: boolean;

  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <Radio.Card
      radius={'md'}
      w={225}
      px="lg"
      py="lg"
      withBorder
      styles={{
        card: {
          backgroundColor: 'var(--mantine-color-body)',
          opacity: disabled ? 0.7 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',

          borderColor: checked ? 'var(--mantine-primary-color-filled)' : '',
          borderWidth: 2,
        },
      }}
      disabled={disabled}
      checked={checked}
      onClick={() => !disabled && onClick()}
    >
      <Stack align="stretch" gap="md" w={'100%'} h={'100%'}>
        <Stack
          justify="center"
          style={{
            height: 200,
            minHeight: 200,
            maxHeight: 200,
          }}
        >
          <Image
            src={getScenarioImage(scenarioType)}
            alt=""
            fit="contain"
            style={{
              filter: disabled ? 'grayscale(1)' : '',
            }}
          />
        </Stack>
        <Stack gap="xs">
          <Group justify="space-between">
            <Title order={5}>{title}</Title>
          </Group>
          {disabled && <Title order={5}>Coming soon!</Title>}
          <Text size="sm" c={'greyText'}>
            {description}
          </Text>
        </Stack>
      </Stack>
    </Radio.Card>
  );
};
