import { Flex, Text } from '@mantine/core';
import { ReactNode } from 'react';

interface Props {
  label: string;
  value: string | null | undefined | ReactNode;
  direction?: 'column' | 'row';
  width?: string;
  justify?: string;
  textSize?: string;
  fwLabel?: number;
  fwValue?: number;
  testId?: string;
}

export const LabelValueText = ({
  label,
  value,
  direction = 'row',
  width = '',
  justify = 'space-between',
  textSize = 'md',
  fwLabel: fwLabel = 300,
  fwValue: fwValue = 300,
  testId,
}: Props) => {
  return (
    <>
      <Flex direction={direction} justify={justify} w={width}>
        <Text size={textSize} fw={fwLabel}>
          {label}
          {direction == 'row' ? ':' : null}
        </Text>
        <Text size={textSize} fw={fwValue} data-testid={testId}>
          {value ? value : '-'}
        </Text>
      </Flex>
    </>
  );
};
