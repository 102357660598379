import { forwardRef } from 'react';
import { Divider, Stack, Stepper, TitleOrder } from '@mantine/core';
import { PdfExportRef, DashboardPdfProps } from '../../../../../models/PdfExport';
import { ArrowboardDashboardProps } from '../../../../../models/DashboardTypes';
import { DashboardHeader } from '../common/DashboardHeader';
import { ArrowboardStepperEvent } from './components/ArrowboardStepperEvent';
import { ReportType } from '@/models/enums/ReportType';
import { useDashboard } from '../../hooks/useDashboard';
import { ArrowboardRow } from '@/models/SupervisionReport';

interface ArrowboardPdfProps extends DashboardPdfProps {
  rows: ArrowboardRow[];
}

const ArrowboardDashboard = forwardRef<PdfExportRef, ArrowboardDashboardProps>((props, ref) => {
  const { shouldRender } = useDashboard<ArrowboardRow[], ArrowboardPdfProps>(
    ref,
    props,
    {
      rows: props.data ?? [],
      projectName: props.projectName,
      reportStartDate: props.reportStartDate,
      reportEndDate: props.reportEndDate,
      contactPerson: props.contactPerson,
      depot: props.depot,
      countryCode: props.countryCode,
      customerName: props.customerName,
    },
    ReportType.Arrowboards
  );

  if (!shouldRender || !props.data?.length) {
    return null;
  }

  return (
    <Stack>
      <DashboardHeader title="Arrowboards (BETA)" legendItems={[]} />
      {props.data.map((device: ArrowboardRow) => (
        <Stack key={device.referenceId}>
          {device.name && device.name.trim() !== '' && (
            <DashboardHeader
              title={device.name}
              order={5 as TitleOrder}
              size="h5"
              legendItems={[]}
            />
          )}
          <Stepper active={device.events.length} mx="md">
            {device.events.map((event, index) => (
              <ArrowboardStepperEvent
                key={index}
                event={event}
                index={index}
                countryCode={props.countryCode}
              />
            ))}
          </Stepper>
          <Divider size="md" />
        </Stack>
      ))}
    </Stack>
  );
});
export default ArrowboardDashboard;
