import { Button } from '@mantine/core';
import { WorkZone } from '../../../models/Project';
import { IconZzzOff } from '@tabler/icons-react';

interface UnSnoozeButtonProps {
  project: WorkZone;
  unsnoozeProject: (params: { id: string }) => void;
  isPwa?: boolean;
}

/**
 * Button to cancel a snooze with support for PWA mode
 */
const UnSnoozeButton = ({ project, unsnoozeProject, isPwa = false }: UnSnoozeButtonProps) => {
  // Button props change based on if it's used in a PWA
  const buttonProps = isPwa
    ? {
        size: 'md',
        variant: 'default',
        w: 'fit-content', // Explicitly set width to fit content
      }
    : {
        w: 220,
      };

  return (
    <Button
      leftSection={<IconZzzOff size={18} stroke={1.5} />}
      onClick={() => {
        unsnoozeProject({ id: project.id });
      }}
      {...buttonProps}
    >
      {isPwa ? 'Unsnooze' : 'Unsnooze alarms'}
    </Button>
  );
};

export default UnSnoozeButton;
