import primaryLogo from '@/assets/report/logo-primary.png';
import primaryLogoWhite from '@/assets/report/logo-primary-white.png';
import coverBackground from '@/assets/report/cover-page.png';

export interface PdfAssets {
  primaryLogo: string;
  primaryLogoWhite: string;
  coverBackground: string;
}

export const DEFAULT_PDF_ASSETS: PdfAssets = {
  primaryLogo,
  primaryLogoWhite,
  coverBackground,
};

export const PDF_CONSTANTS = {
  SIDE_MARGIN: 4,
  TOP_MARGIN: 12,
  LOGO: {
    WIDTH: 4006 / 90,
    HEIGHT: 1031 / 90,
  },
  FONT: {
    SIZES: {
      TITLE: 20,
      HEADING: 16,
      SUBHEADING: 11,
      BODY: 10,
    },
    FAMILY: {
      NORMAL: 'helvetica',
      BOLD: 'helvetica',
    },
  },
  COLORS: {
    GREEN_PRIMARY: '#2E6B69',
    SAND: '#FFFAEB',
    YELLOW: '#FFE137',
  },
  HEIGHTS: {
    HEADER: 24, // New height for the header section
    SUB_HEADER_BG: 10,
  },
  SPACING: {
    HEADER_TOP: 8, // Space from top of page to header
    HEADER_BOTTOM: 4, // Space below header
  },
};

export const PDF_TABLE_CONSTANTS = {
  TIMESTAMP_CELL_WIDTH: 10.5,
  TEXT_FIELDS_CELL_WIDTH: 17,
  FONT_SIZE: 6.5,
  FIXED_COLUMNS: 3,
  FIXED_ICON_SIZE: 5.5,
  FIXED_SIGN_SIZE: 6.5,
  MAX_IMAGE_HEIGHT: 7,
  MAX_IMAGE_WIDTH: 7,
  CELL_IMAGE_SCALE: 0.5,
  CELL_HEIGHT: 4,
  CELL_PADDING: 3,
  DYNAMIC_TIMESTAMP_WIDTH: 0,
} as const;

export const PDF_STATUS_ICONS = {
  OK: 'circle-check',
  ALARMING: 'alert-circle',
  NO_LOG: 'circle-dotted',
  SNOOZED: 'square-gray',
} as const;

export const PDF_TABLE_STYLES = {
  HEAD: {
    valign: 'middle',
    halign: 'center',
    fillColor: PDF_CONSTANTS.COLORS.GREEN_PRIMARY,
    fontStyle: 'bold',
    fontSize: 6.5,
    lineWidth: 0.5,
    lineColor: [255, 255, 255],
    minCellHeight: 4,
  },
  BODY: {
    valign: 'middle',
    fontSize: 6.5,
    lineWidth: 0.5,
    minCellHeight: 4,
    cellPadding: 3,
  },
  ALTERNATE_ROW: {
    fillColor: [245, 245, 245],
  },
} as const;
