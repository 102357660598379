import { Point } from 'geojson';
import {
  AlarmStatus,
  AlarmType,
  BatteryStatus,
  EquipmentType,
  LifeCycleStatus,
  PoweredEquipment,
} from './enums/DeviceEnums';

export interface BaseDevice {
  id: string;
  referenceId: string;
  deviceType: DeviceType;
  currentName: string;
  position: Point | null;
  batteryVoltage: number;
  batteryStatus: BatteryStatus;
  latestUpdate: string;
  workZoneName: string;
  workZoneId: string;
  currentConfigId: string;
  requestedConfigId: string;
  currentConfigTimestamp: Date;
  requestedConfigTimestamp: Date;
  imei: string;
  alarmStatus: AlarmStatus;
  assignedAtTimestamp: Date;
  lifeCycleStatus: LifeCycleStatus;
  availableForAssignment: boolean;
  alarmType: AlarmType | null;
  deviceModel: DeviceModel;
}

export interface Intellitag {
  currentEquipmentType: EquipmentType | null;
  attachmentRef: string;
  inPowerSavingMode: boolean;
  estimatedBatteryEndDate?: string;
  latestBatteryChangeDate?: Date;
}

export interface ArrowBoard {
  arrowDirection: ArrowDirection;
  externalBatteryVoltage: number;
}

export interface SmartCable {
  poweredEquipment: PoweredEquipment;
  externalBatteryVoltage: number;
}

export interface SmartSolar {
  poweredEquipment: PoweredEquipment;
  externalBatteryVoltage: number;
}

export interface PortableSiteAlarm {}

export enum DeviceType {
  Unknown = 0,
  IntellitagV1 = 1,
  ArrowBoardV1 = 2,
  SmartCableV1 = 3,
  PSAV1 = 4,
  SmartSolarV1 = 5,
  IntelliTagLite = 6,
  IntelliTagV2 = 7,
  BeaconTracker = 9999,
}

export enum DeviceModel {
  Unknown = 999,
  Tat100 = 0,
  Fmc130 = 1,
  Fmc13A = 2,
  Tat141 = 3,
  Fmc880 = 4,
  PsaRed = 5,
  PsaYellow = 6,
  PsaBlue = 7,
  PsaOrange = 8,
  Tat140 = 9,
  DekimoV2 = 10,
}

export type PsaDeviceModel =
  | DeviceModel.PsaBlue
  | DeviceModel.PsaRed
  | DeviceModel.PsaYellow
  | DeviceModel.PsaOrange;

export const DeviceModelNames: Record<DeviceModel, string> = {
  [DeviceModel.Unknown]: 'Unknown',
  [DeviceModel.Tat100]: 'Tat100',
  [DeviceModel.Fmc130]: 'Fmc130',
  [DeviceModel.Fmc13A]: 'Fmc13A',
  [DeviceModel.Tat141]: 'Tat141',
  [DeviceModel.Fmc880]: 'Fmc880',
  [DeviceModel.PsaRed]: 'PsaRed',
  [DeviceModel.PsaYellow]: 'PsaYellow',
  [DeviceModel.PsaBlue]: 'PsaBlue',
  [DeviceModel.PsaOrange]: 'PsaOrange',
  [DeviceModel.Tat140]: 'Tat140',
  [DeviceModel.DekimoV2]: 'Dekimo',
};

export enum ArrowDirection {
  Unknown = 0,
  Off = 1,
  Left = 2,
  Right = 3,
  Bar = 4,
  LeftRight = 5,
}

export const DeviceTypeNames: Record<DeviceType, string> = {
  [DeviceType.IntellitagV1]: 'Intellitag',
  [DeviceType.ArrowBoardV1]: 'Arrowboard',
  [DeviceType.SmartCableV1]: 'Smart Cable',
  [DeviceType.PSAV1]: 'Portable Site Alarm',
  [DeviceType.SmartSolarV1]: 'Smart Solar',
  [DeviceType.IntelliTagLite]: 'Intellitag Lite',
  [DeviceType.IntelliTagV2]: 'Intellitag v2',
  [DeviceType.BeaconTracker]: 'Beacon Tracker',
  [DeviceType.Unknown]: 'Unknown',
};

export const ARROW_DIRECTION_NAMES: Record<ArrowDirection, string> = {
  [ArrowDirection.Unknown]: 'Unknown',
  [ArrowDirection.Off]: 'Off',
  [ArrowDirection.Left]: 'Left',
  [ArrowDirection.Right]: 'Right',
  [ArrowDirection.Bar]: 'Bar',
  [ArrowDirection.LeftRight]: 'Left & Right',
};

export interface Device
  extends BaseDevice,
    Intellitag,
    ArrowBoard,
    SmartCable,
    SmartSolar,
    PortableSiteAlarm {}
