import { ModalConfirm } from '@/components/Modal/ModalConfirm';
import { ConfirmButtonGroupProps } from '@/shared/ConfirmButtonGroup/ConfirmButtonGroup';
import { createContext, ReactNode, useContext, useState } from 'react';

type OpenProps = {
  text: string;
  confirmGroupProps: ConfirmButtonGroupProps;
};

type ModalContextType = {
  openModal: (props: OpenProps) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextType | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const useConfirmationModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useConfirmationModal must be used within a ModalProvider');
  }
  return context;
};

export const ConfirmModalProvider = ({ children }: { children: ReactNode }) => {
  const [props, setProps] = useState<OpenProps | null>(null);
  const [isOpened, setIsOpened] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        openModal: (props) => {
          setProps(props);
          // Open modal next tick, otherwise animation doesn't fire
          setTimeout(() => setIsOpened(true), 1);
        },
        closeModal: () => setIsOpened(false),
      }}
    >
      {children}
      {props && (
        <ModalConfirm
          opened={isOpened}
          setOpened={setIsOpened}
          text={props.text}
          {...props.confirmGroupProps}
        />
      )}
    </ModalContext.Provider>
  );
};
