import { Stack, Text, Timeline } from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import { WorkZone } from '../../../models/Project.ts';
import useProjectCommandLogs from '../../../data/hooks/Project/useProjectCommandLogsHook.ts';
import {
  IconBarrierBlock,
  IconBarrierBlockOff,
  IconLink,
  IconPencilCheck,
  IconZzz,
  IconZzzOff,
} from '@tabler/icons-react';
import { TabTitle } from '@/components/TabTitle/TabTitle.tsx';

const projectHistoryLogMap = {
  CreateWorkZoneCommand: {
    title: 'Created',
    description: 'created the project',
    icon: <IconBarrierBlock size={12} />,
  },
  CreateWorkZoneFlowCommand: {
    title: 'Created',
    description: 'created the project',
    icon: <IconBarrierBlock size={12} />,
  },
  SetWorkZoneInformationCommand: {
    title: 'Edited',
    description: 'edited the project',
    icon: <IconPencilCheck size={12} />,
  },
  SnoozeWorkZoneCommand: {
    title: 'Snoozed',
    description: 'snoozed the project',
    icon: <IconZzzOff size={12} />,
  },
  UnSnoozeWorkZoneCommand: {
    title: 'Unsnoozed',
    description: 'unsnoozed the project',
    icon: <IconZzz size={12} />,
  },
  ToggleExternalConnectionEnabledCommand: {
    title: 'Toggled External Connection',
    description: 'toggled exernal connection',
    icon: <IconLink size={12} />,
  },
  CloseWorkZoneCommand: {
    title: 'Closed',
    description: 'closed the project',
    icon: <IconBarrierBlockOff size={12} />,
  },
};

export const ProjectHistory = (props: { project: WorkZone }) => {
  const { instance } = useMsal();
  const { projectCommandLogs } = useProjectCommandLogs({ instance, projectId: props.project.id });
  return (
    <>
      <TabTitle>Project History</TabTitle>
      <Stack p={'md'}>
        <Timeline active={100} bulletSize={24} lineWidth={2}>
          {projectCommandLogs.map((log) => {
            const eventType = log.eventType as keyof typeof projectHistoryLogMap;
            const eventDetails = projectHistoryLogMap[eventType];

            return (
              <Timeline.Item
                key={log.id}
                bullet={eventDetails?.icon}
                title={eventDetails?.title ?? log?.eventType}
              >
                <Text c="dimmed" size="sm">
                  {log.createdBy} {eventDetails?.description}
                </Text>
                <Text size="xs" mt={4}>
                  {new Date(log.created).toLocaleString()}
                </Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Stack>
    </>
  );
};
