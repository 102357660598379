export const STEPPER_STYLES = {
  step: { position: 'relative' },
  stepBody: {
    position: 'absolute',
    margin: 0,
    top: '-16px',
    left: '-50%',
    width: 'max-content',
  },
  stepLabel: {
    marginBottom: '40px',
    textAlign: 'center',
    fontSize: '14px',
  },
  stepDescription: {
    fontSize: '12px',
    textAlign: 'center',
  },
  stepIcon: {
    backgroundColor: 'transparent',
    border: 'none',
  },
} as const;
