import { useQuery } from '@tanstack/react-query';
import { Device } from '../../../models/Device';
import { IPublicClientApplication } from '@azure/msal-browser';
import { buildQueryString, prepareHeaders } from '../../api/apiHelpers';
import { useState, useMemo } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';
import { isBoundsWithinPreviousBounds } from '../../../services/helpers';
import { Mapbounds } from '../../../shared/types';
import { FilterOptions } from '@/components/Map/FilterDrawer/MapFilterDrawer';
import { AlarmStatus, AlarmType, BatteryStatus } from '../../../models/enums/DeviceEnums';

const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const ENLARGE_BOUNDS_BY = 0.15;

const path = `/device/in-area`;

const useDevicesInArea = (instance: IPublicClientApplication, filters?: FilterOptions) => {
  const [bounds, setBounds] = useState<Mapbounds>({
    northEastLongitude: 0,
    northEastLatitude: 0,
    southWestLongitude: 0,
    southWestLatitude: 0,
  });

  const {
    data: allDevices,
    isPending,
    isError,
    isSuccess,
  } = useQuery<Device[]>({
    queryKey: ['devices', bounds],
    queryFn: async () => {
      const queryString = buildQueryString(bounds);
      const response = await fetch(`${baseUrl}${path}${queryString}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });

  const data = useMemo(() => {
    if (!allDevices) return [];

    // Return all devices if no filters are applied
    if (
      !filters?.devices ||
      (filters.devices.deviceTypes.length === 0 &&
        !filters.devices.assignmentStatus &&
        filters.devices.alertingStatus.length === 0)
    ) {
      return allDevices;
    }

    return allDevices.filter((device) => {
      // Filter by device type
      if (
        filters.devices.deviceTypes.length > 0 &&
        !filters.devices.deviceTypes.includes(device.deviceType)
      ) {
        return false;
      }

      // Filter by assignment status
      if (filters.devices.assignmentStatus && filters.devices.assignmentStatus.length > 0) {
        const isAssigned = !!device.workZoneId;
        const matchesAssigned = filters.devices.assignmentStatus.includes('assigned') && isAssigned;
        const matchesUnassigned =
          filters.devices.assignmentStatus.includes('unassigned') && !isAssigned;

        if (!matchesAssigned && !matchesUnassigned) {
          return false;
        }
      }

      // Filter by alerting status
      if (filters.devices.alertingStatus.length > 0) {
        const deviceHasMatchingAlert = filters.devices.alertingStatus.some((alertType) => {
          switch (alertType) {
            case 'alarm':
              return device.alarmStatus === AlarmStatus.Alarming;
            case 'blueAlarm':
              return device.alarmType === AlarmType.PsaBlueAlert;
            case 'outOfBattery':
              return device.batteryStatus === BatteryStatus.OUT_OF_BATTERY;
            case 'criticalBattery':
              return device.batteryStatus === BatteryStatus.CRITICAL;
            case 'lowBattery':
              return device.batteryStatus === BatteryStatus.LOW;
            default:
              return false;
          }
        });

        if (!deviceHasMatchingAlert) {
          return false;
        }
      }
      return true;
    });
  }, [allDevices, filters]);

  const handleBoundsChange = useDebouncedCallback((updatedBounds: Mapbounds) => {
    const isWithin = isBoundsWithinPreviousBounds(updatedBounds, bounds);
    if (!isWithin) {
      setBounds({
        northEastLatitude: updatedBounds.northEastLatitude + ENLARGE_BOUNDS_BY,
        northEastLongitude: updatedBounds.northEastLongitude + ENLARGE_BOUNDS_BY,
        southWestLatitude: updatedBounds.southWestLatitude - ENLARGE_BOUNDS_BY,
        southWestLongitude: updatedBounds.southWestLongitude - ENLARGE_BOUNDS_BY,
      });
    }
  }, 500);

  return {
    data,
    isPending,
    isError,
    isSuccess,
    handleBoundsChange,
  };
};

export default useDevicesInArea;
