type FilterCriteria<T> = {
  [K in keyof T]?: (value: T[K]) => boolean;
};

type FilterGroup<T> = {
  filterActive: boolean;
  criteria: FilterCriteria<T>;
};

export type FilterProps<T> = {
  items: T[];
  filterGroups: FilterGroup<T>[];
};

export function useGenericFilter<T>({ items, filterGroups }: FilterProps<T>): T[] {
  return items.filter((item) => {
    const groups = filterGroups.map((filter) =>
      filter.filterActive ? filterFunction(item, filter.criteria) : true
    );
    return groups.every((v) => v);
  });
}

function filterFunction<T>(item: T, criteria: FilterCriteria<T>): boolean {
  return Object.entries(criteria).some(([key, validate]) => {
    const typedKey = key as keyof T;
    const typedValidation = validate as (value: T[keyof T]) => boolean;
    return typedValidation(item[typedKey]);
  });
}
