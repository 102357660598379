import { MapWithPolygonEditor } from '@/components/Map/MapWithPolygonEditor/MapWithPolygonEditor';
import { MODAL_ZINDEX } from '@/constants/styling';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import { ConfirmButtonGroup } from '@/shared/ConfirmButtonGroup/ConfirmButtonGroup';
import { Modal, Stack, Box } from '@mantine/core';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useCallback, useState } from 'react';
import { Polygon } from 'geojson';

interface EditGeozoneProps {
  isOpen: boolean;
  close: () => void;
  onSubmit: (updatedPath: google.maps.LatLngLiteral[]) => void;
  geozone: Polygon;
  setupId: string;
  countryCode: string;
}

export const EditGeozoneModal = ({
  isOpen,
  close,
  onSubmit,
  geozone,
  setupId,
  countryCode,
}: EditGeozoneProps) => {
  const isMobile = useMobileDevice();

  const defaultGeozone = geozone.coordinates[0].map(([lng, lat]) => ({ lat, lng })).slice(0, -1);
  const [currentPath, setCurrentPath] = useState<google.maps.LatLngLiteral[] | null>(
    defaultGeozone
  );
  const [validUpdatedGeozone, setValidUpdatedGeozone] = useState(false);

  const onGeozoneClosed = useCallback((valid: boolean) => {
    setValidUpdatedGeozone(valid);
  }, []);

  if (!geozone) return null;

  const onPathChange = (path: google.maps.LatLngLiteral[] | null) => {
    setCurrentPath(path);
  };

  return (
    <Modal.Root
      opened={isOpen}
      onClose={() => {
        setCurrentPath(defaultGeozone);
        close();
      }}
      fullScreen={isMobile}
      centered
      zIndex={MODAL_ZINDEX}
      size="90%"
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fw={600}>Edit Workzone</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body pl={0} pr={0}>
          <Stack h="100%">
            <Box
              style={{
                minHeight: '70dvh',
                position: 'relative',
              }}
            >
              <Box
                style={{
                  position: 'absolute',
                  inset: 0,
                }}
              >
                <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
                  <MapWithPolygonEditor
                    currentPath={currentPath}
                    onPathChange={onPathChange}
                    defaultIsEditing={true}
                    setupId={setupId}
                    countryCode={countryCode}
                    onGeozoneClosed={onGeozoneClosed}
                  />
                </APIProvider>
              </Box>
            </Box>
            <Box p={isMobile ? '0 10% 0 10%' : '0 50 0 0'}>
              <ConfirmButtonGroup
                confirmBtnText="Save"
                abortBtnText="Cancel"
                disabled={!currentPath || !validUpdatedGeozone}
                onAbort={() => {
                  close();
                }}
                onConfirm={() => {
                  if (currentPath) {
                    onSubmit(currentPath);
                    close();
                  }
                }}
              />
            </Box>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
