import { Group, Text, Stack, ActionIcon, Center } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconFile, IconCirclePlus, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  onFileRemove: () => void;
  selectedFile?: File | null;
  accept?: string[];
}

export function FileUpload({
  onFileSelect,
  onFileRemove,
  selectedFile,
  accept = ['text/csv'],
}: FileUploadProps) {
  const [error, setError] = useState<string | null>(null);

  return (
    <Stack gap="md">
      <Group justify="space-between" align="center">
        <Text size="xl" fw={500}>
          Upload Files
        </Text>
      </Group>

      <Dropzone
        onDrop={(files) => {
          setError(null);
          onFileSelect(files[0]);
        }}
        onReject={() => {
          setError('Invalid file type or size');
        }}
        accept={accept}
        style={{
          border: '1px dashed var(--mantine-color-gray-4)',
        }}
      >
        <Center p="xl">
          <Stack gap="xs" align="center">
            <IconCirclePlus size={32} stroke={1.5} />
            <Text size="sm">Drag & drop or click to choose file</Text>
            {error && (
              <Text c="dangerRed" size="sm">
                {error}
              </Text>
            )}
          </Stack>
        </Center>
      </Dropzone>

      {selectedFile && (
        <>
          <Group
            p="xs"
            style={{
              border: '1px solid var(--mantine-color-gray-4)',
              borderRadius: 'var(--mantine-radius-sm)',
            }}
          >
            <IconFile size={20} />
            <Stack gap={0} style={{ flex: 1 }}>
              <Text size="sm">{selectedFile.name}</Text>
              <Text size="xs" c="dimmed">
                {(selectedFile.size / 1024).toFixed(2)} KB
              </Text>
            </Stack>
            <Group gap="xs">
              <ActionIcon variant="subtle" color="gray" radius="xl" onClick={onFileRemove}>
                <IconTrash size={16} />
              </ActionIcon>
            </Group>
          </Group>
        </>
      )}
    </Stack>
  );
}
