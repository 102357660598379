import { openRoutes, protectedRoutes } from './router.tsx';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import MantineTheme from './config/MantineTheme.ts';
import PWAProvider from './pwa/providers/PWAProvider.tsx';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import useMatomo from './matomo/useMatomo.ts';
import { PdfWorkerProvider } from './contexts/PdfWorker/PdfWorkerProvider';
import { PdfExportProgressBar } from './components/Project/SupervisionReport/components/PdfExportProgressBar/PdfExportProgressBar';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import './config/dayjsConfig';
import './index.css';
import { ConfirmModalProvider } from '@/components/Modal/ModalConfirmProvider.tsx';

interface AppProps {
  pca: PublicClientApplication;
}

export default function App({ pca }: AppProps) {
  useMatomo(pca);

  return (
    <MsalProvider instance={pca}>
      <MantineProvider theme={MantineTheme}>
        <ConfirmModalProvider>
          <Notifications />
          <AuthenticatedTemplate>
            <PdfWorkerProvider>
              <PWAProvider>
                <Routes>
                  {protectedRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element}>
                      {route.children &&
                        route.children.map((childRoute, childIndex) => (
                          <Route
                            key={childIndex}
                            path={childRoute.path}
                            element={childRoute.element}
                          />
                        ))}
                    </Route>
                  ))}
                </Routes>
                <PdfExportProgressBar />
              </PWAProvider>
            </PdfWorkerProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              {openRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </UnauthenticatedTemplate>
        </ConfirmModalProvider>
      </MantineProvider>
    </MsalProvider>
  );
}
