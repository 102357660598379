import { Stack, Text, Title } from '@mantine/core';

interface Props {
  icon: JSX.Element;
  title: string;
  subtitle: string;
}

export const EmptyState = ({ icon, title, subtitle }: Props) => {
  return (
    <Stack align="center" gap={'sm'} mt={'md'}>
      {icon}
      <Title order={5}>{title}</Title>
      <Text size="xs" c={'gray.6'}>
        {subtitle}
      </Text>
    </Stack>
  );
};
