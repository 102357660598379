import { Badge, Group, Text } from '@mantine/core';
import { Device } from '../../models/Device';
import { BatteryStatus, LifeCycleStatus } from '../../models/enums/DeviceEnums';
import StatusBadge from './StatusBadge';
import styles from '../Badge/styles.module.css';
import BatteryBadge from './BatteryBadge/BatteryBadge';

type Props = {
  device: Device;
};

export const BadgeWrapper = ({ device }: Props) => {
  return (
    <>
      {device.lifeCycleStatus != LifeCycleStatus.Unassigned ? (
        <StatusBadge size="md" status={device.alarmStatus} type={device.alarmType} />
      ) : (
        <Badge size={'md'} variant={'filled'} className={styles.NOT_ASSIGNED}>
          <Group gap={6} ml={2}>
            <Text size={'xs'} c="black" fw={500}>
              Not assigned
            </Text>
          </Group>
        </Badge>
      )}
      <BatteryBadge
        size="md"
        batteryStatus={device.batteryStatus == BatteryStatus.OK ? null : device.batteryStatus}
      />
    </>
  );
};
