import { Stack } from '@mantine/core';
import { Device, DeviceTypeNames } from '../../../../models/Device';
import { LabelValueText } from '../../../../shared/LabelValueText';

type Props = {
  device: Device;
};

export const DeviceInfo = ({ device }: Props) => {
  return (
    <Stack gap={'xs'}>
      <LabelValueText
        width="100%"
        label={'Device type'}
        value={DeviceTypeNames[device.deviceType]}
      />
      <LabelValueText width="100%" label={'Reference Id'} value={device.referenceId} />
      <LabelValueText
        width="100%"
        label={'Battery'}
        value={`${device.batteryVoltage ?? '-'} (V)`}
      />
    </Stack>
  );
};
