import { Badge, Group, Text } from '@mantine/core';
import styles from '../styles.module.css';
import { BatteryStatus, BatteryStatusNames } from '../../../models/enums/DeviceEnums';
import { BatteryIcon } from '../../../pwa/components/BatteryIcon';

type Props = {
  batteryStatus: BatteryStatus | null;
  number?: number;
  size?: 'md' | 'lg';
};

export default function BatteryBadge({ batteryStatus, number, size = 'lg' }: Props) {
  if (!batteryStatus) return null;

  return (
    <Badge
      leftSection={<BatteryIcon batteryStatus={batteryStatus} size={16} stroke={1.3} />}
      size={size ? size : 'lg'}
      className={styles[BatteryStatus[batteryStatus]]}
    >
      <Group gap={6}>
        <Text size={size == 'md' ? 'xs' : 'sm'} c="black" fw={500}>
          {number} {BatteryStatusNames[batteryStatus]}
        </Text>
      </Group>
    </Badge>
  );
}
