import {
  Anchor,
  Badge,
  Breadcrumbs,
  Center,
  Group,
  Loader,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useMemo, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconEdit, IconError404 } from '@tabler/icons-react';

import { NotificationSettings } from '@/components/Project/NotificationSettings/NotificationSettings.tsx';
import Reports from '@/components/Project/SupervisionReport/Reports';
import { formatDate, formatDateTime } from '@/utils/utils.tsx';
import { ProjectOverview } from '@/components/Project/ProjectOverview/ProjectOverview';
import { EditProjectInformationModal } from '@/components/Project/CreateProjectModal/EditProjectInformationModal.tsx';
import useProjectDevices from '@/data/hooks/Device/useProjectDevicesHook.ts';
import useProject from '@/data/hooks/Project/useProjectHook.ts';
import useEditProject from '@/data/hooks/Project/useEditProjectHook.ts';
import { ManageDevices } from '@/components/Project/ManageDevices/ManageDevices.tsx';
import useCountries from '@/data/hooks/Country/useCountriesHook.ts';
import { ProjectHistory } from '@/components/Project/ProjectHistory/ProjectHistory.tsx';
import { sortDevices } from '@/services/helpers.tsx';
import useDepots from '@/data/hooks/Depots/useDepots.tsx';
import { UpdateWorkZone } from '@/models/Project.ts';
import { ProjectCo2CalculatorTab } from '@/components/Project/ProjectCo2CalculatorTab/ProjectCo2CalculatorTab';
import { ProjectTabPanel } from '@/components/Project/ProjectTabPanel/ProjectTabPanel.tsx';
import { LabelValueText } from '@/shared/LabelValueText.tsx';
import { ProjectAdvancedSettings } from '@/components/Project/ProjectAdvancedSettings/ProjectAdvancedSettings.tsx';
import { FlexSpacer } from '@/shared/FlexSpacer.tsx';
import { useMobileDevice } from '@/hooks/useMobileDevice.tsx';
import SnoozeBadge from '@/components/Badge/SnoozeBadge';
import useMyUserAccesses from '@/data/hooks/UserAccess/useMyUserAccess.ts';
import useDeviceLogsByProjectId from '@/data/hooks/DeviceLog/useDeviceLogsByProjectId.ts';
import { Device } from '@/models/Device.ts';
import useSetup from '@/data/hooks/Setups/useSetupHook.ts';
import { Button } from '@/components/Button/Button';

export default function ProjectPage() {
  const isMobile = useMobileDevice();
  const { projectId } = useParams();
  const { instance } = useMsal();
  const { userAccesses } = useMyUserAccesses({ instance: instance });
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = useMemo(() => {
    return searchParams.get('tab') || 'overview';
  }, [searchParams]);
  const setActiveTab = (tab: string) => {
    setSearchParams(
      (prev) => {
        prev.set('tab', tab);
        return prev;
      },
      {
        replace: true,
      }
    );
  };
  const initialSelectedDevice = searchParams.get('ref');
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [timeTravelDate, setTimeTravelDate] = useState<Date | null>(null);

  const { project, isFetching: projectLoading } = useProject({
    instance,
    workZoneId: projectId ?? '',
  });
  const { setup } = useSetup({
    instance,
    setupId: project?.setupId ?? '',
    enabled: !!project?.setupId,
  });
  const { depotsNameMap } = useDepots(instance);
  const { projectDevices, isLoading: projectDevicesLoading } = useProjectDevices({
    instance,
    projectIds: [projectId ?? ''],
  });
  const { mutate: editProject } = useEditProject({ instance, id: projectId ?? '' });
  const { countries } = useCountries({ instance });
  const { deviceLogs, isLoading: deviceLogsLoading } = useDeviceLogsByProjectId({
    instance: instance,
    projectId: projectId ?? '',
    dateTime: timeTravelDate ? timeTravelDate.toISOString() : '',
  });
  const handleSetProjectInformation = (project: UpdateWorkZone) => {
    if (project.id) {
      editProject(project);
    } else {
      console.error('Error: project id required');
    }
  };

  const timeTravelDevices: Device[] | undefined = deviceLogs?.map((logs) => {
    return {
      id: logs.deviceId,
      referenceId: logs.referenceId,
      attachmentRef: logs.attachmentRef,
      deviceType: logs.deviceType,
      currentName: logs.name,
      position: logs.position,
      batteryVoltage: logs.batteryVoltage,
      batteryStatus: logs.batteryStatus,
      latestUpdate: logs.timestamp,
      workZoneName: logs.workZoneName,
      workZoneId: logs.workZoneId,
      alarmStatus: logs.alarmStatus,
      lifeCycleStatus: logs.lifeCycleStatus,
      currentEquipmentType: logs.currentEquipmentType,
      arrowDirection: logs.arrowDirection,
      poweredEquipment: logs.poweredEquipment,
    } as Device;
  });

  const crumbs = [
    { title: 'All projects', href: '/projects' },
    { title: project?.name, href: `/projects/${project?.id}` },
  ].map((item, index) => (
    <Anchor truncate="end" size="sm" href={item.href} key={index} h={'md'}>
      {item.title}
    </Anchor>
  ));

  if (!projectLoading && (!projectId || !project)) {
    return (
      <Center mt="xl">
        <Stack align="center">
          <IconError404 />
          <Text c="gray.7">Project not found</Text>
        </Stack>
      </Center>
    );
  }

  if (projectLoading && !project) {
    return <Loader mx="50%" my="15%" size="md" />;
  }

  if (!project) return null;

  return (
    <>
      <Stack gap="sm" bg="white" px="xl" pt="md" pb={isMobile ? 'xs' : 'lg'}>
        <Breadcrumbs bg="white" separator=">" separatorMargin="sm" style={{ flexWrap: 'nowrap' }}>
          {crumbs}
        </Breadcrumbs>
        <Group wrap="nowrap">
          <Title order={isMobile ? 5 : 3}>{project?.name}</Title>
          {project.isSnoozed && <SnoozeBadge />}
          {project.isClosed ? (
            <Badge
              c="black"
              bg="lightGrey"
              style={{ border: '1px solid black', textTransform: 'none' }}
              w="fit-content"
            >
              <Text size="sm" c="black" fw={500}>
                Closed
              </Text>
            </Badge>
          ) : null}
          <FlexSpacer />
          <Tooltip
            label="To edit this project go to your work order system"
            position="bottom-end"
            offset={5}
            disabled={!project.externalId}
          >
            <Button
              variant="secondary"
              icon={IconEdit}
              disabled={project.externalId !== null}
              onClick={() => setOpenEditModal(true)}
            >
              Edit
            </Button>
          </Tooltip>
        </Group>
        <Group gap="xl" wrap="nowrap" style={{ overflowX: 'auto' }}>
          <LabelValueText
            label="External ID"
            value={project.externalId}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="Country"
            value={countries?.find((p) => p.code == project.countryCode)?.name}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="Depot"
            value={depotsNameMap.get(project.depotId)}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="Customer"
            value={project.customerName}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="Start"
            value={formatDate(project.startDate)}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="End"
            value={formatDate(project.endDate)}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          {project && project.isClosed && (
            <LabelValueText
              label="Closed"
              value={formatDateTime(project.closedAt)}
              fwLabel={500}
              textSize="sm"
              direction="column"
            />
          )}
        </Group>
      </Stack>

      <Tabs
        value={activeTab}
        onChange={(value) => {
          if (value) {
            setActiveTab(value);
          }
        }}
      >
        <Tabs.List px="md" bg="white" style={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
          <Tabs.Tab value="overview">
            <Text>Overview</Text>
          </Tabs.Tab>
          <Tabs.Tab value="report">
            <Text>Report</Text>
          </Tabs.Tab>
          <Tabs.Tab value="settings" disabled={project.isClosed}>
            <Text>Notification settings</Text>
          </Tabs.Tab>
          <Tabs.Tab value="connect-device" disabled={project.isClosed}>
            <Text>Manage devices</Text>
          </Tabs.Tab>
          <Tabs.Tab value="history">
            <Text>History</Text>
          </Tabs.Tab>
          <Tabs.Tab
            display={!userAccesses?.hasAdvancedProjectSettings ? 'none' : ''}
            value="advanced-settings"
          >
            <Text>Advanced settings</Text>
          </Tabs.Tab>
          <Tabs.Tab value="co2-calculator">
            <Text component="div">
              CO2 calculator
              <Badge variant="transparent" c="blue" style={{ cursor: 'inherit' }}>
                Beta
              </Badge>
            </Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="overview">
          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
            <ProjectOverview
              devices={
                timeTravelDevices ? timeTravelDevices : sortDevices(projectDevices[project.id])
              }
              workZone={project}
              initialSelectedDevice={initialSelectedDevice}
              timeTravelDate={timeTravelDate}
              loading={projectDevicesLoading || deviceLogsLoading}
              onTimeTravel={(date) => setTimeTravelDate(date)}
              setup={setup}
            />
          </APIProvider>
        </Tabs.Panel>
        <ProjectTabPanel value="report">
          <Reports project={project}></Reports>
        </ProjectTabPanel>
        <ProjectTabPanel value="settings">
          <NotificationSettings project={project} setup={setup} />
        </ProjectTabPanel>
        <ProjectTabPanel value="connect-device">
          <ManageDevices project={project} />
        </ProjectTabPanel>
        <ProjectTabPanel value="history">
          <ProjectHistory project={project} />
        </ProjectTabPanel>

        <ProjectTabPanel
          value="advanced-settings"
          accessDenied={!userAccesses?.hasAdvancedProjectSettings}
        >
          <ProjectAdvancedSettings project={project} />
        </ProjectTabPanel>
        <Tabs.Panel m="md" value="co2-calculator">
          <ProjectCo2CalculatorTab project={project} />
        </Tabs.Panel>
      </Tabs>

      <EditProjectInformationModal
        isOpen={openEditModal}
        project={{
          ...project,
          id: projectId ?? '',
        }}
        close={() => setOpenEditModal(false)}
        onSubmit={(project) => {
          handleSetProjectInformation(project);
          setOpenEditModal(false);
        }}
      />
    </>
  );
}
