import { BatteryStatus, BatteryStatusNames } from '../../models/enums/DeviceEnums';
import { BatteryIcon } from '../../pwa/components/BatteryIcon';
import getBadgeStateFromBatteryStatus from '../../utils/battery';
import Badge from './Badge';

type Props = {
  batteryStatus: BatteryStatus | null;
  number?: number;
  size?: 'sm' | 'md';
};

export default function BatteryBadgeV2({ batteryStatus, size = 'md', number }: Props) {
  if (!batteryStatus) return null;

  return (
    <Badge
      state={getBadgeStateFromBatteryStatus(batteryStatus)}
      size={size}
      label={`${number} ${BatteryStatusNames[batteryStatus]}`}
      icon={<BatteryIcon batteryStatus={batteryStatus} size={16} />}
    />
  );
}
