import { Stepper } from '@mantine/core';
import { IconCircleFilled } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { ArrowDirection, ARROW_DIRECTION_NAMES } from '../../../../../../models/Device';
import {
  ARROWBOARD_EVENT_TYPE,
  ONLINE_STATUS,
  ONLINE_STATUS_NAMES,
} from '../../../../../../models/SupervisionReport';
import { formatTimeByCountry } from '../../../../../../utils/utils';
import { ArrowIcon } from './ArrowIcon';
import { STATUS_COLORS } from '../../../constants/colors';
import { STEPPER_STYLES } from '../../../constants/stepperStyles';

interface ArrowboardEvent {
  eventType: ARROWBOARD_EVENT_TYPE;
  eventValue: ONLINE_STATUS | ArrowDirection;
  timestamp: string;
}

interface ArrowboardStepperEventProps {
  event: ArrowboardEvent;
  index: number;
  countryCode: string;
}

export const ArrowboardStepperEvent = ({
  event,
  index,
  countryCode,
}: ArrowboardStepperEventProps) => {
  const getLabel = () => {
    return event.eventType === ARROWBOARD_EVENT_TYPE.OnlineStatus
      ? ONLINE_STATUS_NAMES[event.eventValue as ONLINE_STATUS]
      : ARROW_DIRECTION_NAMES[event.eventValue as ArrowDirection];
  };

  const getIcon = () => {
    if (event.eventType === ARROWBOARD_EVENT_TYPE.ArrowChange) {
      return <ArrowIcon size={32} arrowDirection={event.eventValue as ArrowDirection} />;
    }

    return (
      <IconCircleFilled
        style={{
          color:
            event.eventValue === ONLINE_STATUS.Online
              ? STATUS_COLORS.ONLINE
              : STATUS_COLORS.OFFLINE,
        }}
      />
    );
  };

  return (
    <Stepper.Step
      key={index}
      mb="xl"
      color="none"
      label={getLabel()}
      description={`${dayjs(event.timestamp).format('MMM DD')}, ${formatTimeByCountry(
        dayjs(event.timestamp).toISOString(),
        countryCode
      )}`}
      icon={getIcon()}
      styles={STEPPER_STYLES}
    />
  );
};
