import { useState } from 'react';
import { WorkZone } from '../../../../models/Project';
import { getEndOfDay } from '../../../../utils/utils';

export const DEFAULT_NUMBER_OF_DAYS = 7;

export interface ReportDates {
  dateValue: [Date | null, Date | null];
  setDateValue: (value: [Date | null, Date | null]) => void;
  startTime: string;
  endTime: string;
  startDate: Date;
  endDate: Date;
}

export const getInitialReportDates = (project: WorkZone): { startDate: Date; endDate: Date } => {
  const defaultDays = DEFAULT_NUMBER_OF_DAYS;
  const endDate = project.isClosed && project.closedAt ? new Date(project.closedAt) : new Date();
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - defaultDays);
  startDate.setHours(0, 0, 0, 0);
  return { startDate, endDate };
};

export const useDatepickerDates = (startDate: Date, endDate: Date): ReportDates => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([startDate, endDate]);

  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - DEFAULT_NUMBER_OF_DAYS);
  sevenDaysAgo.setHours(0, 0, 0, 0);
  today.setHours(23, 59, 59, 999);

  return {
    dateValue,
    setDateValue,
    startTime:
      dateValue[0] && dateValue[1] ? dateValue[0].toISOString() : sevenDaysAgo.toISOString(),
    endTime:
      dateValue[0] && dateValue[1] ? getEndOfDay(dateValue[1]).toISOString() : today.toISOString(),
    startDate,
    endDate,
  };
};
