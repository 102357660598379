import { useContext, useEffect } from 'react';
import {
  Flex,
  Stack,
  Button,
  Radio,
  SimpleGrid,
  Text,
  CheckIcon,
  Center,
  Image,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { PWAContext } from '../providers/PWAProvider';
import PwaPageHeading from '../components/PwaPageHeading';
import { PoweredEquipment, PoweredEquipmentNames } from '../../models/enums/DeviceEnums';
import { POWERED_EQUIPMENT_IMAGE_MAP } from '@/constants/imageConstants.ts';
import { IconCircle } from '@tabler/icons-react';

const equipmentIcons = {
  [PoweredEquipment.Other]: <IconCircle size={40} />,
  [PoweredEquipment.Buffer]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.Buffer]} w={40} />
  ),
  [PoweredEquipment.TrafficLight]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.TrafficLight]} w={40} />
  ),
  [PoweredEquipment.WarningLight]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.WarningLight]} w={40} />
  ),
  [PoweredEquipment.SequentialLight]: (
    <Image src={POWERED_EQUIPMENT_IMAGE_MAP[PoweredEquipment.SequentialLight]} w={40} />
  ),
};

export const PwaPairPoweredEquipmentPage = () => {
  const { device, poweredEquipment, setPoweredEquipment } = useContext(PWAContext);
  const navigate = useNavigate();

  useEffect(() => setPoweredEquipment(null), [setPoweredEquipment]);

  const nextStep = () => navigate('/pwa/projects/summary');

  return (
    <Flex h="100%" direction="column" pos="relative" justify="space-between" mb="lg">
      <Stack mb="md">
        <PwaPageHeading label={`Reference ID: ${device?.referenceId}`}>
          Select equipment
        </PwaPageHeading>
      </Stack>
      <Radio.Group
        value={`${poweredEquipment}`}
        onChange={(value) => setPoweredEquipment(Number(value))}
        aria-label="Select equipment"
      >
        <SimpleGrid cols={2}>
          {Object.values(PoweredEquipment)
            .filter((value) => typeof value === 'number' && value !== PoweredEquipment.Unknown)
            .map((equipment) => {
              return (
                <Radio.Card
                  pos="relative"
                  value={`${equipment}`}
                  key={equipment}
                  p="md"
                  bd={
                    poweredEquipment === equipment
                      ? 'solid 4px primaryGreen'
                      : 'solid 4px transparent'
                  }
                >
                  {poweredEquipment === equipment && (
                    <Center
                      pos="absolute"
                      h="32px"
                      w="32px"
                      bg="primaryGreen"
                      bottom="-16px"
                      right="-16px"
                      style={{
                        borderRadius: '100%',
                      }}
                    >
                      <CheckIcon size={16} color="white" />
                    </Center>
                  )}
                  <Stack style={{ alignItems: 'center' }}>
                    {equipmentIcons[equipment]}
                    <Text size="lg" ta="center">
                      {PoweredEquipmentNames[equipment as PoweredEquipment]}
                    </Text>
                  </Stack>
                </Radio.Card>
              );
            })}
        </SimpleGrid>
      </Radio.Group>

      <Button size="xl" onClick={nextStep} disabled={!poweredEquipment}>
        Continue
      </Button>
    </Flex>
  );
};
