import { Checkbox, Drawer, Stack, Text, Box, Button } from '@mantine/core';
import { DeviceType, DeviceTypeNames } from '@/models/Device';
import { useCallback } from 'react';
import { ViewMode } from '@/pages/MapPage';

export type AlertingStatusType =
  | 'alarm'
  | 'blueAlarm'
  | 'outOfBattery'
  | 'criticalBattery'
  | 'lowBattery';

export interface FilterOptions {
  devices: {
    deviceTypes: DeviceType[];
    assignmentStatus: ('assigned' | 'unassigned')[];
    alertingStatus: AlertingStatusType[];
  };
  projects: {
    showActiveProjects: boolean;
    showDemoProjects: boolean;
    showClosedProjects: boolean;
  };
}

interface MapFilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  viewMode: ViewMode;
  filters: FilterOptions;
  onFiltersChange: (filters: FilterOptions) => void;
}

// Utility function for toggling items in arrays
const getNewArrayWithToggledItem = <T,>(array: T[], item: T): T[] => {
  return array.includes(item) ? array.filter((i) => i !== item) : [...array, item];
};

export const MapFilterDrawer = ({
  isOpen,
  onClose,
  viewMode,
  filters,
  onFiltersChange,
}: MapFilterDrawerProps) => {
  // Generic function to handle device type changes
  const handleDeviceTypeToggle = useCallback(
    (type: DeviceType) => {
      const newDeviceTypes = getNewArrayWithToggledItem(filters.devices.deviceTypes, type);
      onFiltersChange({
        ...filters,
        devices: {
          ...filters.devices,
          deviceTypes: newDeviceTypes,
        },
      });
    },
    [filters, onFiltersChange]
  );

  // Updated function to handle multiple assignment statuses
  const handleAssignmentStatusChange = (status: 'assigned' | 'unassigned') => {
    const newStatus = filters.devices.assignmentStatus.includes(status)
      ? filters.devices.assignmentStatus.filter((s) => s !== status)
      : [...filters.devices.assignmentStatus, status];

    onFiltersChange({
      ...filters,
      devices: {
        ...filters.devices,
        assignmentStatus: newStatus,
      },
    });
  };

  // Generic function to handle all alert status changes
  const handleAlertingStatusChange = (status: AlertingStatusType) => {
    const newStatus = filters.devices.alertingStatus.includes(status)
      ? filters.devices.alertingStatus.filter((s) => s !== status)
      : [...filters.devices.alertingStatus, status];

    onFiltersChange({
      ...filters,
      devices: {
        ...filters.devices,
        alertingStatus: newStatus,
      },
    });
  };

  // Generic function to handle project filter changes
  const handleProjectFilterChange = useCallback(
    (filterName: keyof FilterOptions['projects']) => {
      if (typeof filters.projects[filterName] !== 'boolean') return;

      onFiltersChange({
        ...filters,
        projects: {
          ...filters.projects,
          [filterName]: !filters.projects[filterName as keyof typeof filters.projects],
        },
      });
    },
    [filters, onFiltersChange]
  );

  const handleClearAllFilters = useCallback(() => {
    if (viewMode === 'projects') {
      onFiltersChange({
        ...filters,
        projects: {
          ...filters.projects,
          showActiveProjects: false,
          showDemoProjects: false,
          showClosedProjects: false,
        },
      });
    } else {
      onFiltersChange({
        ...filters,
        devices: {
          ...filters.devices,
          deviceTypes: [],
          alertingStatus: [],
          assignmentStatus: [],
        },
      });
    }
  }, [filters, onFiltersChange, viewMode]);

  // Only show device types that are relevant (exclude unknown, etc.)
  const deviceTypes = Object.values(DeviceType)
    .filter(
      (type) =>
        typeof type === 'number' && type !== DeviceType.Unknown && type !== DeviceType.BeaconTracker
    )
    .sort((type, next) => DeviceTypeNames[type].localeCompare(DeviceTypeNames[next]));

  const totalActiveFilters =
    viewMode === 'projects'
      ? (filters.projects.showActiveProjects ? 1 : 0) +
        (filters.projects.showDemoProjects ? 1 : 0) +
        (filters.projects.showClosedProjects ? 1 : 0)
      : filters.devices.deviceTypes.length +
        (filters.devices.alertingStatus?.length || 0) +
        (filters.devices.assignmentStatus?.length || 0);

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      title={<Text fw={700}>Filter</Text>}
      position="right"
      overlayProps={{ backgroundOpacity: 0.2 }}
    >
      <Stack gap="md">
        {viewMode === 'projects' && (
          <Stack gap="xs">
            <Text fw={500}>Project status</Text>
            <Box p={4}>
              <Checkbox
                label="Active projects"
                checked={filters.projects.showActiveProjects}
                onChange={() => handleProjectFilterChange('showActiveProjects')}
              />
            </Box>
            <Box p={4}>
              <Checkbox
                label="Demo projects"
                checked={filters.projects.showDemoProjects}
                onChange={() => handleProjectFilterChange('showDemoProjects')}
              />
            </Box>
            <Box p={4}>
              <Checkbox
                label="Closed projects"
                checked={filters.projects.showClosedProjects}
                onChange={() => handleProjectFilterChange('showClosedProjects')}
              />
            </Box>
          </Stack>
        )}

        {viewMode === 'devices' && (
          <Stack gap="xs">
            <Text fw={500}>Assigned to project</Text>
            <Box p={4}>
              <Checkbox
                label="Yes"
                checked={filters.devices.assignmentStatus.includes('assigned')}
                onChange={() => handleAssignmentStatusChange('assigned')}
              />
            </Box>
            <Box p={4}>
              <Checkbox
                label="No"
                checked={filters.devices.assignmentStatus.includes('unassigned')}
                onChange={() => handleAssignmentStatusChange('unassigned')}
              />
            </Box>
          </Stack>
        )}

        {viewMode === 'devices' && (
          <Stack gap="xs">
            <Text fw={500}>Device types</Text>
            {deviceTypes.map((type) => (
              <Box key={type} p={4}>
                <Checkbox
                  label={DeviceTypeNames[type]}
                  checked={filters.devices.deviceTypes.includes(type)}
                  onChange={() => handleDeviceTypeToggle(type)}
                />
              </Box>
            ))}
          </Stack>
        )}

        {viewMode === 'devices' && (
          <Stack gap="xs">
            <Text fw={500}>Status</Text>
            <Box p={4}>
              <Checkbox
                label="Alarming"
                checked={filters.devices.alertingStatus.includes('alarm')}
                onChange={() => handleAlertingStatusChange('alarm')}
              />
            </Box>
            <Box p={4}>
              <Checkbox
                label="Alerting"
                checked={filters.devices.alertingStatus.includes('blueAlarm')}
                onChange={() => handleAlertingStatusChange('blueAlarm')}
              />
            </Box>
            <Box p={4}>
              <Checkbox
                label="Low battery"
                checked={filters.devices.alertingStatus.includes('lowBattery')}
                onChange={() => handleAlertingStatusChange('lowBattery')}
              />
            </Box>
            <Box p={4}>
              <Checkbox
                label="Critical battery"
                checked={filters.devices.alertingStatus.includes('criticalBattery')}
                onChange={() => handleAlertingStatusChange('criticalBattery')}
              />
            </Box>
            <Box p={4}>
              <Checkbox
                label="Out of battery"
                checked={filters.devices.alertingStatus.includes('outOfBattery')}
                onChange={() => handleAlertingStatusChange('outOfBattery')}
              />
            </Box>
          </Stack>
        )}

        {totalActiveFilters > 0 && (
          <Button
            ml="auto"
            c="black"
            fw={300}
            size="md"
            variant="transparent"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleClearAllFilters}
          >
            Clear all filters
          </Button>
        )}
      </Stack>
    </Drawer>
  );
};
