import { forwardRef } from 'react';
import { PdfExportRef } from '../../../../../models/PdfExport';
import { BatteryMonitorDashboardProps } from '../../../../../models/DashboardTypes';
import { ReportType } from '@/models/enums/ReportType';
import BatteryMonitorDashboardWrapper from '../BatteryMonitorDashboard/BatteryMonitorDashboardWrapper';

/**
 * Dashboard component for Smart Cable devices
 */
const SmartCableDashboard = forwardRef<PdfExportRef, BatteryMonitorDashboardProps>((props, ref) => {
  return (
    <BatteryMonitorDashboardWrapper
      {...props}
      ref={ref}
      headerTitle="Smart Cable"
      reportType={ReportType.SmartCables}
    />
  );
});

export default SmartCableDashboard;
