import { MapConstants } from '@/components/Map/MapConstants';

export function getZoomLevel(currentZoomLevel: number) {
  if (currentZoomLevel > MapConstants.DEVICE_ZOOM) {
    return 'close';
  } else if (
    currentZoomLevel > MapConstants.PROJECT_ZOOM &&
    currentZoomLevel <= MapConstants.DEVICE_ZOOM
  ) {
    return 'mid';
  } else if (currentZoomLevel <= MapConstants.PROJECT_ZOOM) {
    return 'global';
  } else {
    return 'global';
  }
}
