import dayjs from '@/config/dayjsConfig';
import { formatTimeByCountry } from '@/utils/utils';
import { AlarmStatus } from '@/models/enums/DeviceEnums';
import { DeviceLog } from '@/models/SupervisionReport';
import { createTimeSegments } from '@/hooks/useTimeSegments';

export interface AlarmLog {
  timeStamp: string;
  alarmStatus: AlarmStatus;
  workZoneSnoozed?: boolean;
  batteryVoltage?: number;
  timeIndex?: number;
  timeHeader?: string;
}

export interface LogEntryWithLogs {
  logs?: DeviceLog[];
}

export interface DeviceWithEntries {
  entries?: LogEntryWithLogs[];
}

export interface AlarmPeriod {
  firstReported: AlarmLog;
  lastAddressed: AlarmLog | null;
}

/**
 * Helper function to prepare logs for the drawer
 * @param deviceData The device data object containing entries with logs
 * @param getHeaderFunc Function that returns header string for a given entry
 * @returns Array of logs with time headers and indices
 */
export const prepareLogsForDrawer = <T extends DeviceWithEntries>(
  deviceData: T | null,
  getHeaderFunc: (entry: LogEntryWithLogs, index: number) => string
): AlarmLog[] => {
  if (!deviceData || !deviceData.entries || !Array.isArray(deviceData.entries)) {
    return [];
  }

  // Flatten logs from all entries and add time headers
  return deviceData.entries.flatMap((entry, index) => {
    if (!entry || !entry.logs || !Array.isArray(entry.logs) || entry.logs.length === 0) {
      return [];
    }

    return entry.logs.map(
      (log) =>
        ({
          ...log,
          timeIndex: index,
          timeHeader: getHeaderFunc(entry, index),
        }) as AlarmLog
    );
  });
};

/**
 * Format a timestamp with country-specific formatting
 * @param dateString ISO date string
 * @param countryCode Country code (e.g. 'GB', 'CA')
 * @returns Formatted time string
 */

export const formatDrawerTime = (dateString: string, countryCode: string): string => {
  // Use our DST-aware method to create a segment for the timestamp
  const segment = createTimeSegments([dateString], 24, countryCode)[0];

  if (!segment) return ''; // Fallback if something went wrong

  // Get today's date from the segment
  const date = segment.date; // e.g. "Mon, 15/3"

  // Get the timestamp with the help of formatTimeByCountry
  // We need to use original timestamps here because we want exact time, not interval
  const time = formatTimeByCountry(dateString, countryCode);

  // Return the formatted string
  return `${date}, ${time}`;
};

/**
 * Group alarm logs by periods (alarm start to resolution)
 * @param logs Array of alarm logs sorted by timestamp
 * @returns Array of alarm periods with first report and last addressed logs
 */
export const groupAlarmsByPeriod = (logs: AlarmLog[]): AlarmPeriod[] => {
  if (!logs || logs.length === 0) return [];

  // Sort logs by timestamp
  const sortedLogs = [...logs].sort(
    (a, b) => dayjs(a.timeStamp).valueOf() - dayjs(b.timeStamp).valueOf()
  );

  // Group logs by periods
  const alarmPeriods: AlarmPeriod[] = [];
  let currentPeriod: Partial<AlarmPeriod> = {};
  let inAlarmState = false;

  for (let i = 0; i < sortedLogs.length; i++) {
    const currentLog = sortedLogs[i];

    // Transition from OK to Alarming - start of an alarm period
    if (!inAlarmState && currentLog.alarmStatus === AlarmStatus.Alarming) {
      inAlarmState = true;
      currentPeriod.firstReported = currentLog;
    }
    // Transition from Alarming to OK - end of an alarm period
    else if (inAlarmState && currentLog.alarmStatus !== AlarmStatus.Alarming) {
      inAlarmState = false;
      currentPeriod.lastAddressed = currentLog;

      // Add completed period to the list and reset
      if (currentPeriod.firstReported) {
        alarmPeriods.push(currentPeriod as AlarmPeriod);
        currentPeriod = {};
      }
    }
  }

  // If we were still in an alarm state at the end, add the period with no addressed timestamp
  if (inAlarmState && currentPeriod.firstReported) {
    alarmPeriods.push({
      firstReported: currentPeriod.firstReported,
      lastAddressed: null,
    });
  }

  // Sort periods by the first reported time (newest first)
  return [...alarmPeriods].sort(
    (a, b) =>
      dayjs(b.firstReported.timeStamp).valueOf() - dayjs(a.firstReported.timeStamp).valueOf()
  );
};
