import { TablerIconsProps } from '@tabler/icons-react';
import { Box, FloatingPosition, Group, Popover, Text } from '@mantine/core';
import { Button } from '@/components/Button/Button';

type Props = {
  opened: boolean;
  setOpened: (next: boolean) => void;
  text: string;
  btnText: string;
  width: number;
  btnWidth?: string;
  variant?: 'primary' | 'secondary';
  position?: FloatingPosition;
  onClick: () => void;
  icon?: React.ComponentType<TablerIconsProps>;
  withGroup?: boolean; // Allow wrapping text and icon in a Group component
};

export const PopoverConfirm = ({
  opened,
  setOpened,
  text,
  btnText,
  width,
  variant = 'secondary',
  onClick,
  position = 'right-start',
  icon,
}: Props) => {
  return (
    <>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        width={width}
        position={position}
        withArrow
      >
        <Popover.Target>
          {/* Pop-over doesn't work properly if not our Button component is wrapped with another element */}
          <Box>
            <Button variant={variant} onClick={() => setOpened(true)} icon={icon}>
              {btnText}
            </Button>
          </Box>
        </Popover.Target>
        <Popover.Dropdown>
          <div style={{ padding: 10 }}>
            <Text size="sm">{text}</Text>
            <Group justify="end">
              <Button variant="secondary" onClick={() => setOpened(false)}>
                Cancel
              </Button>
              <Button variant="warning" onClick={onClick}>
                Confirm
              </Button>
            </Group>
          </div>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
