import { Mapbounds } from '@/shared/types';

/**
 * Utility function to calculate a bounding box from points.
 * Can handle raw LatLng literals or a LatLngBounds object.
 *
 * @param points Array of LatLng points or an object with NE and SW LatLng bounds.
 */
function calculateBoundingBox(bounds: {
  northEast: google.maps.LatLngLiteral;
  southWest: google.maps.LatLngLiteral;
}): { minLat: number; maxLat: number; minLng: number; maxLng: number } {
  let minLat = Number.POSITIVE_INFINITY;
  let maxLat = Number.NEGATIVE_INFINITY;
  let minLng = Number.POSITIVE_INFINITY;
  let maxLng = Number.NEGATIVE_INFINITY;

  if (bounds) {
    minLat = Math.min(minLat, bounds.southWest.lat);
    maxLat = Math.max(maxLat, bounds.northEast.lat);
    minLng = Math.min(minLng, bounds.southWest.lng);
    maxLng = Math.max(maxLng, bounds.northEast.lng);
  }

  return { minLat, maxLat, minLng, maxLng };
}

/**
 * Get the bounding box from a LatLngBounds object.
 *
 * @param bounds The LatLngBounds object provided by Google Maps API.
 */
export function getBoundingBoxFromLatLngBounds(bounds: google.maps.LatLngBounds): Mapbounds {
  const northEast = bounds.getNorthEast(); // google.maps.LatLng
  const southWest = bounds.getSouthWest(); // google.maps.LatLng

  const { minLat, maxLat, minLng, maxLng } = calculateBoundingBox({
    northEast: { lat: northEast.lat(), lng: northEast.lng() },
    southWest: { lat: southWest.lat(), lng: southWest.lng() },
  });

  return {
    northEastLatitude: maxLat,
    northEastLongitude: maxLng,
    southWestLatitude: minLat,
    southWestLongitude: minLng,
  };
}

/**
 * Checks if two polygons intersect by testing if any of their edges intersect.
 *
 * @param {google.maps.Polygon} poly1 - The first polygon.
 * @param {google.maps.Polygon} poly2 - The second polygon.
 * @returns {boolean} True if the polygons intersect, otherwise false.
 */
export const doPolygonsIntersect = (
  poly1: google.maps.Polygon,
  poly2: google.maps.Polygon
): boolean => {
  const paths1 = poly1.getPath().getArray();
  const paths2 = poly2.getPath().getArray();

  for (let i = 0; i < paths1.length; i++) {
    const p1 = paths1[i];
    const p2 = paths1[(i + 1) % paths1.length]; // Wrap around for last edge

    for (let j = 0; j < paths2.length; j++) {
      const q1 = paths2[j];
      const q2 = paths2[(j + 1) % paths2.length]; // Wrap around for last edge

      if (doLineSegmentsIntersect(p1, p2, q1, q2)) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Determines whether two line segments intersect.
 *
 * @param {google.maps.LatLng} p1 - The first point of the first line segment.
 * @param {google.maps.LatLng} p2 - The second point of the first line segment.
 * @param {google.maps.LatLng} q1 - The first point of the second line segment.
 * @param {google.maps.LatLng} q2 - The second point of the second line segment.
 */
const doLineSegmentsIntersect = (
  p1: google.maps.LatLng,
  p2: google.maps.LatLng,
  q1: google.maps.LatLng,
  q2: google.maps.LatLng
): boolean => {
  /**
   * Computes whether three points are in counterclockwise order.
   */
  const ccw = (a: google.maps.LatLng, b: google.maps.LatLng, c: google.maps.LatLng) => {
    return (c.lat() - a.lat()) * (b.lng() - a.lng()) > (b.lat() - a.lat()) * (c.lng() - a.lng());
  };

  return ccw(p1, q1, q2) !== ccw(p2, q1, q2) && ccw(p1, p2, q1) !== ccw(p1, p2, q2);
};
