import { expose } from 'comlink';
import { PdfGenerationData, PdfGenerationResult, ProgressCallback } from '@/models/PdfExport';
import { ReportType } from '@/models/enums/ReportType';
import { IntellitagPdfGenerator } from './devices/intellitagPdfGenerator';
import { ArrowboardPdfGenerator } from './devices/arrowboardPdfGenerator';
import { SmartSolarPdfGenerator } from './devices/smartSolarPdfGenerator';
import { SmartCablePdfGenerator } from './devices/smartCablePdfGenerator';
import { BasePdfGenerator } from './devices/basePdfGenerator';
import { CombinedPdfGenerator } from './devices/combinedPdfGenerator';

export const GENERATION_STEPS = {
  INIT: { progress: 0, message: 'Initializing PDF generation' },
  CREATE_DOC: { progress: 10, message: 'Creating document' },
  ADD_COVER: { progress: 20, message: 'Adding cover page' },
  ADD_STATISTICS: { progress: 30, message: 'Adding statistics page' },
  GENERATE_HEADERS: { progress: 40, message: 'Generating headers and data' },
  PROCESS_WEEKLY: { progress: 50, message: 'Processing weekly data' },
  GENERATE_TABLES: { progress: 60, message: 'Generating tables' },
  ADD_ALARM_EVENTS: { progress: 70, message: 'Adding alarm events' },
  ADD_FOOTER: { progress: 90, message: 'Adding footer and finalizing' },
  COMPLETE: { progress: 100, message: 'PDF generation complete' },
} as const;

/**
 * Factory method to get the appropriate PDF generator based on report type
 */
function getPdfGenerator(type: ReportType, isCombined = false): BasePdfGenerator {
  // Handle combined reports
  if (isCombined) {
    return new CombinedPdfGenerator();
  }

  switch (type) {
    case ReportType.Intellitags:
      return new IntellitagPdfGenerator();
    case ReportType.Arrowboards:
      return new ArrowboardPdfGenerator();
    case ReportType.SmartSolar:
      return new SmartSolarPdfGenerator();
    case ReportType.SmartCables:
      return new SmartCablePdfGenerator();
    default:
      // Default to intellitag for now, can be extended later
      return new IntellitagPdfGenerator();
  }
}

const workerApi = {
  // Cancellation flag
  isCancellationRequested: false,

  cancelGeneration: async (): Promise<void> => {
    workerApi.isCancellationRequested = true;
  },

  generatePdf: async (
    data: PdfGenerationData,
    progressCallback?: ProgressCallback
  ): Promise<PdfGenerationResult> => {
    // Reset cancellation flag at the start of generation
    workerApi.isCancellationRequested = false;
    const totalStart = performance.now();
    const stepTimes: Record<string, number> = {};

    // Get appropriate generator
    const pdfGenerator = getPdfGenerator(
      data.reportType ?? ReportType.Intellitags,
      data.isCombinedReport
    );

    // Set progress callback
    pdfGenerator.setProgressCallback(progressCallback);

    try {
      const creationStep = async <T>(
        step: keyof typeof GENERATION_STEPS,
        fn: () => Promise<T> | T
      ): Promise<T> => {
        // Check for cancellation before starting step
        if (workerApi.isCancellationRequested) {
          throw new Error('PDF generation cancelled by user');
        }

        if (progressCallback) {
          try {
            progressCallback({
              progress: GENERATION_STEPS[step].progress,
              message: GENERATION_STEPS[step].message,
            });
          } catch (error) {
            // Silently handle callback errors
          }
        }

        return await fn();
      };

      // Initial progress update
      if (progressCallback) {
        progressCallback({
          progress: GENERATION_STEPS.INIT.progress,
          message: GENERATION_STEPS.INIT.message,
        });
      }

      // Generate PDF using the appropriate generator
      const output = await pdfGenerator.generatePdf(data, creationStep);

      const totalDuration = performance.now() - totalStart;
      return {
        success: true,
        pdfBase64: output,
        performance: {
          total: totalDuration,
          steps: stepTimes,
        },
      };
    } catch (error) {
      const totalDuration = performance.now() - totalStart;

      // Check if the error is due to cancellation
      if (
        workerApi.isCancellationRequested ||
        (error instanceof Error && error.message.includes('cancelled by user'))
      ) {
        return {
          success: false,
          error: 'PDF generation cancelled by user',
          performance: {
            total: totalDuration,
            steps: stepTimes,
          },
        };
      }

      return {
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error occurred',
        performance: {
          total: totalDuration,
          steps: stepTimes,
        },
      };
    }
  },
};

expose(workerApi);
