import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { Paper, Text } from '@mantine/core';
import { StatusSummaryRow } from '@/components/Status';
import { WorkZone } from '@/models/Project';
import { Device } from '@/models/Device';
import { useMapZoomLevel } from '@/hooks/useMapZoomLevel';

export interface Props {
  project: WorkZone;
  devices: Device[];
  map: google.maps.Map | null;
  onClick: (id: string) => void;
}

export const ProjectMarker = ({ project, devices, map, onClick }: Props) => {
  const projectMiddlePoint = project.center;

  const { isAtZoomLevel } = useMapZoomLevel({
    map,
    zoomLevel: 'mid',
  });

  return (
    <>
      {isAtZoomLevel && (
        <AdvancedMarker
          key={project.id}
          map={map}
          onClick={() => onClick(project.id ? project.id : '')}
          position={
            projectMiddlePoint
              ? {
                  lat: projectMiddlePoint.coordinates[1],
                  lng: projectMiddlePoint.coordinates[0],
                }
              : null
          }
        >
          <Paper p="sm" shadow="lg" px="sm">
            <Text fw={500}>{project.name}</Text>
            <StatusSummaryRow size="md" devices={devices} />
          </Paper>
        </AdvancedMarker>
      )}
    </>
  );
};
