import { Stack, Title, Text } from '@mantine/core';
import { TablerIconsProps } from '@tabler/icons-react';

interface Props {
  Icon: React.ComponentType<TablerIconsProps>;
  iconStyle?: React.CSSProperties;
  title: string;
  description: string;
}

export const ProjectOverviewMessage = ({ Icon, iconStyle, title, description }: Props) => {
  return (
    <Stack gap="xs" align="center">
      <Icon size={140} stroke={1} style={iconStyle} />
      <Title order={2} size="h3" fw={400}>
        {title}
      </Title>
      <Text ta="center">{description}</Text>
    </Stack>
  );
};
