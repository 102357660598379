import { Intellitag } from '../../../../models/Device';
import { EquipmentTypeNames } from '../../../../models/enums/DeviceEnums';
import { LabelValueText } from '../../../../shared/LabelValueText';

export const IntellitagLiteInfo = (props: Pick<Intellitag, 'currentEquipmentType'>) => {
  return (
    <>
      <LabelValueText
        width="100%"
        label="Equipment Type"
        value={props.currentEquipmentType ? EquipmentTypeNames[props.currentEquipmentType] : '-'}
      />
    </>
  );
};
