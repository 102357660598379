import { Group, Text, Stack, Box } from '@mantine/core';
import { DeviceEvent, DeviceEventType } from '../../../models/DeviceEvent';
import { formatDateTimeString } from '../../../utils/utils.tsx';
import { eventConfig } from './deviceEventConfig';
import { memo } from 'react';

interface DeviceEventItemProps {
  event: DeviceEvent;
}

export const DeviceEventItem = memo(({ event }: DeviceEventItemProps) => {
  const config = eventConfig[event.type] || eventConfig[DeviceEventType.Unknown];

  return (
    <Group align="flex-start" gap="md" bg="white" p="md">
      <Box pt={4}>{config.icon}</Box>
      <Stack gap={2} style={{ flex: 1 }}>
        <Text size="sm" fw={500}>
          {config.displayName}
        </Text>
        <Text size="xs" c="dimmed">
          {event.referenceId}
        </Text>
        <Text size="xs" c="dimmed">
          {formatDateTimeString(event.timestamp)}
        </Text>
      </Stack>
    </Group>
  );
});
