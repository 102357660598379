import { useState } from 'react';

/**
 * Hook for managing alarm drawer state
 * @returns Drawer state and handlers
 */
export const useAlarmDrawer = <T>() => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDeviceData, setSelectedDeviceData] = useState<T | null>(null);

  const openDrawer = (deviceData: T) => {
    setSelectedDeviceData(deviceData);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return {
    isDrawerOpen,
    selectedDeviceData,
    openDrawer,
    closeDrawer,
  };
};
