import { PdfGenerationData } from '@/models/PdfExport';
import { ReportType } from '@/models/enums/ReportType';
import { BasePdfGenerator, CreationStep } from './basePdfGenerator';
import { IntellitagPdfGenerator } from './intellitagPdfGenerator';
import { SmartCablePdfGenerator } from './smartCablePdfGenerator';
import { SmartSolarPdfGenerator } from './smartSolarPdfGenerator';
import { ArrowboardPdfGenerator } from './arrowboardPdfGenerator';
import {
  addCoverPage,
  addLastPage,
  addStatisticPage,
  applyPageFooter,
  getStandardLegendItems,
  LegendItemType,
} from '../utils/pdfCommon';
import { formatDate } from '@/utils/utils';
import { addAlarmEventsToDocument } from '../utils/alarmEvents';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { LegendItem } from '@/models/SupervisionReport';
import { StatusIcons } from '@/models/PdfExport';
import { GENERATION_STEPS } from '../pdfWorker';

/**
 * Combined PDF Generator class
 * Handles generation of PDF reports for multiple device types in a single document
 */
export class CombinedPdfGenerator extends BasePdfGenerator {
  /**
   * Generate a PDF for multiple device types
   */
  public async generatePdf(data: PdfGenerationData, creationStep: CreationStep): Promise<string> {
    // Create document
    const doc = await creationStep('CREATE_DOC', () => this.createPdfDocument());

    // Process assets

    // Add cover page
    await creationStep('ADD_COVER', () =>
      addCoverPage(doc, {
        projectName: data.projectName,
        depot: data.depot,
        startDate: formatDate(data.reportStartDate),
        endDate: formatDate(data.reportEndDate),
        contactPerson: data.contactPerson,
      })
    );

    // Add statistics page if needed
    if (data.statsImg && data.includeStatistics) {
      const statsImgString = data.statsImg;
      await creationStep('ADD_STATISTICS', () =>
        addStatisticPage(doc, statsImgString, {
          projectName: data.projectName,
          depot: data.depot,
          startDate: formatDate(data.reportStartDate),
          endDate: formatDate(data.reportEndDate),
        })
      );
    }

    // Generate content for each report type
    await this.generateContent(data, doc, creationStep);

    // Add last page
    await creationStep('ADD_FOOTER', () => {
      addLastPage(doc, data.contactPerson);
    });

    // Apply page footer with proper page numbers
    await creationStep('ADD_FOOTER', () => {
      // Create page-specific legend items based on device type
      const getLegendItems = (pageNum: number): LegendItem[] => {
        // If no status icons exist, return empty array
        if (!data.statusIcons) {
          return [];
        }

        // Get the page ranges for each report type
        interface DocWithUserData extends jsPDF {
          userData?: {
            pageRanges?: Record<ReportType, { start: number; end: number }>;
          };
        }

        const pageRanges =
          (doc as DocWithUserData).userData?.pageRanges ||
          ({} as Record<ReportType, { start: number; end: number }>);

        // Determine which report type this page belongs to
        let currentReportType: ReportType | null = null;
        for (const [reportType, range] of Object.entries(pageRanges) as [
          ReportType,
          { start: number; end: number },
        ][]) {
          if (pageNum >= range.start && pageNum <= range.end) {
            currentReportType = reportType;
            break;
          }
        }

        // Return appropriate legend items for the current report type
        if (currentReportType) {
          return this.getReportTypeLegendItems(currentReportType, data.statusIcons);
        }

        // Fallback to default legend items if no matching report type
        return getStandardLegendItems(
          [LegendItemType.OK, LegendItemType.NoLog, LegendItemType.Snoozed],
          data.statusIcons
        );
      };

      applyPageFooter(doc, undefined, data.includeStatistics, getLegendItems);
    });

    // Generate the PDF output
    return await creationStep('COMPLETE', () => doc.output('datauristring'));
  }

  /**
   * Generate content for multiple device types
   * This method is called by generatePdf to generate content for each report type
   */
  public async generateContent(
    data: PdfGenerationData,
    doc: jsPDF,
    creationStep: CreationStep
  ): Promise<void> {
    // Keep track of page ranges for different report types
    const pageRanges: Record<ReportType, { start: number; end: number }> = {} as Record<
      ReportType,
      { start: number; end: number }
    >;

    // Determine which report types to include
    const reportTypes = data.reportTypes || [];
    if (reportTypes.length === 0 && data.reportType) {
      reportTypes.push(data.reportType);
    }

    // Create a filtered creationStep function that skips progress updates for certain steps
    const filteredCreationStep: CreationStep = async <T>(
      step: keyof typeof GENERATION_STEPS,
      fn: () => Promise<T> | T
    ): Promise<T> => {
      // Skip progress updates for these steps in child generators
      const stepsToSkip: (keyof typeof GENERATION_STEPS)[] = ['GENERATE_TABLES'];

      if (stepsToSkip.includes(step)) {
        // Just execute the function without triggering progress update
        return await fn();
      }
      // For other steps, use the original creationStep
      return await creationStep(step, fn);
    };

    // Process each report type
    for (const reportType of reportTypes) {
      // Create a modified data object with only this report type
      const reportData = {
        ...data,
        reportType: reportType,
      };

      // Check if we need to add a page
      // For the first device section, we don't add a new page (to avoid the empty page)
      const isFirstDevice = reportTypes.indexOf(reportType) === 0;
      if (!isFirstDevice) {
        doc.addPage();
      }

      // Store the starting page for this report type
      const startPage = doc.getNumberOfPages();

      // Create the appropriate generator for this report type
      const generator = this.createGeneratorForType(reportType);

      // Configure the generator to skip cover and last page
      generator.setSkipCoverAndLastPage(true, true);

      // Set the existing document
      generator.setExistingDocument(doc);

      // Generate content for this device type using the filtered creationStep
      await generator.generateContent(reportData, doc, filteredCreationStep);

      // Add alarm events for this device type immediately after its content
      await this.addDeviceAlarmEvents(doc, reportData, reportType, filteredCreationStep);

      // Store the ending page for this report type
      const endPage = doc.getNumberOfPages();

      // Store the page range for this report type
      pageRanges[reportType] = { start: startPage, end: endPage };
    }

    // Store the page ranges in the document user data for use in the footer
    (doc as jsPDF & { userData?: Record<string, unknown> }).userData = {
      ...(doc as jsPDF & { userData?: Record<string, unknown> }).userData,
      pageRanges,
    };
  }

  /**
   * Add alarm events for a specific device type
   */
  private async addDeviceAlarmEvents(
    doc: jsPDF,
    data: PdfGenerationData,
    reportType: ReportType,
    creationStep: CreationStep
  ): Promise<void> {
    // Create device-specific data for alarm events
    const deviceData = {
      ...data,
      reportType: reportType, // Set the correct report type for alarm events
    };

    // Let addAlarmEventsToDocument handle the logic for determining if there is data
    await creationStep('ADD_ALARM_EVENTS', () => {
      // Add alarm events, function returns true if any events were added
      return addAlarmEventsToDocument(doc, deviceData);
    });
  }

  /**
   * Create a generator for the specified report type
   */
  private createGeneratorForType(reportType: ReportType): BasePdfGenerator {
    switch (reportType) {
      case ReportType.Intellitags:
        return new IntellitagPdfGenerator();
      case ReportType.SmartCables:
        return new SmartCablePdfGenerator();
      case ReportType.SmartSolar:
        return new SmartSolarPdfGenerator();
      case ReportType.Arrowboards:
        return new ArrowboardPdfGenerator();
      default:
        throw new Error(`Unsupported report type: ${reportType}`);
    }
  }

  /**
   * Get legend items for a specific report type
   */
  private getReportTypeLegendItems(
    reportType: ReportType,
    statusIcons?: StatusIcons
  ): LegendItem[] {
    switch (reportType) {
      case ReportType.Intellitags:
        return getStandardLegendItems(
          [LegendItemType.OK, LegendItemType.Fallen, LegendItemType.NoLog, LegendItemType.Snoozed],
          statusIcons
        );
      case ReportType.Arrowboards:
        // Return empty array for Arrowboard - no legend needed
        return [];
      case ReportType.SmartCables:
      case ReportType.SmartSolar:
        return getStandardLegendItems(
          [
            LegendItemType.BatteryOK,
            LegendItemType.PowerlessDisconnected,
            LegendItemType.NoLog,
            LegendItemType.Snoozed,
          ],
          statusIcons
        );
      default:
        return getStandardLegendItems(
          [LegendItemType.OK, LegendItemType.NoLog, LegendItemType.Snoozed],
          statusIcons
        );
    }
  }
}
