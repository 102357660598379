import { Group } from '@mantine/core';
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react';
import { SortDirection } from '../../models/enums/SortEnums';

interface SortableHeaderProps {
  title: string;
  columnName: string;
  sortColumn?: string;
  sortDirection: SortDirection;
  onSort: (columnName: string) => void;
}

const SortableHeader = ({
  title,
  columnName,
  sortColumn,
  sortDirection,
  onSort,
}: SortableHeaderProps) => {
  const getSortIcon = () => {
    if (!sortColumn || sortColumn !== columnName) return null;
    return sortDirection === SortDirection.Ascending ? (
      <IconArrowUp size={14} />
    ) : (
      <IconArrowDown size={14} />
    );
  };

  return (
    <Group gap={4} onClick={() => onSort(columnName)} style={{ cursor: 'pointer' }}>
      {title}
      {getSortIcon()}
    </Group>
  );
};

export default SortableHeader;
