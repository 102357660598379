import { OptionsFilter } from '@mantine/core';

/**
 * @example
 * ```ts
 * const form = useForm({
 *   initialValues: {
 *     contact: {
 *       name: '',
 *       phone: '',
 *       email: ''
 *     },
 *     isDemoProject: true
 *   },
 * });
 * // matches 'contact.name', 'contact.phone', 'contact.email'
 * const isContactValid = hasNestedErrors(form.errors, 'contact')
 * ```
 *
 * Note: if the key is a substring of another key, there may be false matches
 * @param errors `form.errors` object from mantine `useForm`
 * @param key the key to check for nested errors
 * @returns true if there are nested errors for the key
 */
export const hasNestedErrors = <T extends Record<string, unknown>, K extends keyof T & string>(
  errors: T,
  key: K
) => {
  return Object.keys(errors).some((err) => err.startsWith(key));
};

export const getNestedKeys = <T extends object>(obj: T): string[] => {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        return getNestedKeys(value).map((k) => `${key}.${k}`);
      }
      return key;
    })
    .flat();
};

/**
 * No-op filter for `Select` component, when doing server-side filtering, prevents optimistic filtering on client which can lead to UI bugs
 * @example
 * ```tsx
 * <Select
 *   // ... other props
 *   filter={noopFilter}
 * />
 * ```
 */
export const noopFilter: OptionsFilter = ({ options }) => options;
