import { Box } from '@mantine/core';
import { DeviceEventItem } from './DeviceEventItem';
import { DeviceEvent } from '../../../models/DeviceEvent';
import { memo } from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

interface DeviceEventListProps {
  events: DeviceEvent[];
}

const ITEM_HEIGHT = 100;

interface RowProps extends Pick<ListChildComponentProps, 'index' | 'style'> {
  data: DeviceEvent[];
}

const EventRow = memo(({ index, style, data }: RowProps) => (
  <div style={style}>
    <DeviceEventItem event={data[index]} />
  </div>
));

export const DeviceEventList = memo(({ events }: DeviceEventListProps) => {
  return (
    <Box style={{ height: '100%', width: '100%' }}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List
            height={height}
            width={width}
            itemCount={events.length}
            itemSize={ITEM_HEIGHT}
            itemData={events}
          >
            {EventRow}
          </List>
        )}
      </AutoSizer>
    </Box>
  );
});
