import { IPublicClientApplication } from '@azure/msal-browser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import postData from '../../api/postData';
import { UpdateWorkZone } from '../../../models/Project';
import { notifications } from '@mantine/notifications';

const path = `/workzone/set-information`;

interface Params {
  instance: IPublicClientApplication;
  id: string;
}

const useEditProject = ({ instance, id }: Params) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending, isError, isSuccess } = useMutation({
    mutationFn: (params: UpdateWorkZone) => {
      return postData<UpdateWorkZone>(path, params, instance);
    },
    onError: (error) => {
      console.error(`Error assigning device to project`, error);
      notifications.show({
        title: 'Edit project failed',
        message: `Message: ${error.message}`,
        color: 'var(--mantine-color-dangerRed-9)',
        autoClose: 10000,
        position: 'bottom-center',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects', id] });
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
    isError,
    isSuccess,
  };
};

export default useEditProject;
