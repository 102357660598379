import { forwardRef } from 'react';
import { PdfExportRef, DashboardPdfProps } from '../../../../../models/PdfExport';
import { BatteryMonitorDashboardProps } from '../../../../../models/DashboardTypes';
import BatteryMonitorDashboard from './BatteryMonitorDashboard';
import { ReportType } from '@/models/enums/ReportType';
import { useDashboard } from '../../hooks/useDashboard';
import { SupervisionReportDto, BatteryMonitorRow } from '@/models/SupervisionReport';

interface BatteryMonitorPdfProps extends DashboardPdfProps {
  rows: BatteryMonitorRow[];
}

interface BatteryMonitorDashboardWrapperProps extends BatteryMonitorDashboardProps {
  headerTitle: string;
  reportType: ReportType;
}

/**
 * Generic wrapper component for battery monitoring dashboards
 */
const BatteryMonitorDashboardWrapper = forwardRef<
  PdfExportRef,
  BatteryMonitorDashboardWrapperProps
>((props, ref) => {
  // Use useDashboard to handle PDF generation
  const { shouldRender } = useDashboard<SupervisionReportDto, BatteryMonitorPdfProps>(
    ref,
    props,
    {
      rows: props.data?.rows || [],
      projectName: props.projectName,
      reportStartDate: props.reportStartDate,
      reportEndDate: props.reportEndDate,
      contactPerson: props.contactPerson,
      depot: props.depot,
      countryCode: props.countryCode,
      customerName: props.customerName,
    },
    props.reportType
  );

  if (!shouldRender || !props.data?.rows.length) {
    return null;
  }

  return (
    <BatteryMonitorDashboard
      {...props}
      headerTitle={props.headerTitle}
      createPDF={() => Promise.resolve()} // Empty function since worker handles the export
    />
  );
});

export default BatteryMonitorDashboardWrapper;
