import { useMemo } from 'react';
import { Notification } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { Device } from '@/models/Device';
import { WorkZone } from '@/models/Project';
import { DeviceMarker } from '../DeviceMarker/DeviceMarker';
import { DotMarker } from './DotMarker/DotMarker';
import { ProjectMarker } from './ProjectMarker/ProjectMarker';
import { ProjectArea } from '@/components/Map/Markers/ProjectArea/ProjectArea';
import { MapConstants } from '@/components/Map/MapConstants';
import { ViewMode } from '@/pages/MapPage';
import { useMapZoomLevel } from '@/hooks/useMapZoomLevel';

interface Props {
  viewMode: ViewMode;
  projects: WorkZone[];
  projectDevices: { [projectsId: string]: Device[] };
  map: google.maps.Map | null;
  devices: Device[];
  onClick: (device: Device | null, project: WorkZone | null) => void;
  selectedDevice?: string | null;
  selectedProject: WorkZone | null;
}

export const Markers = ({
  viewMode,
  projects,
  projectDevices,
  onClick,
  map,
  devices,
  selectedDevice,
  selectedProject,
}: Props) => {
  const { isAtZoomLevel: isCloseZoom } = useMapZoomLevel({ map, zoomLevel: 'close' });

  const visibleDevicesCloseZoom = useMemo(() => {
    if (isCloseZoom) {
      const bounds = map?.getBounds();
      if (!bounds) return [];

      return devices.filter((device) => {
        if (!device.position) return false;
        const { coordinates } = device.position;
        return bounds.contains(new google.maps.LatLng(coordinates[1], coordinates[0]));
      });
    } else {
      return [];
    }
  }, [isCloseZoom, map, devices]);

  if (!map) return;

  const projectMarkers = projects.map((project) => (
    <ProjectMarker
      key={project.id}
      onClick={() => onClick(null, project)}
      project={project}
      devices={projectDevices[project.id]}
      map={map}
    />
  ));

  const projectDotMarkers = projects.map((project) => (
    <DotMarker
      key={project.id}
      id={project.id}
      map={map}
      color="#003D8A"
      centerPoint={project.center}
      onClick={() => onClick(null, project)}
    />
  ));

  const deviceDotMarkers = (viewMode === 'devices' ? devices : []).map(
    (device) =>
      device.position && (
        <DotMarker
          key={device.id}
          id={device.id}
          map={map}
          size="sm"
          color="#000"
          hasCollisionFilter
          centerPoint={{
            type: 'Point',
            coordinates: [device.position.coordinates[0], device.position.coordinates[1]],
          }}
        />
      )
  );

  const deviceMarkers = (
    visibleDevicesCloseZoom.length > MapConstants.MAX_DEVICE_RENDER ? [] : visibleDevicesCloseZoom
  ).map((device) => (
    <DeviceMarker
      key={device.id}
      onClick={() => onClick(device, null)}
      visibleAtZoomLevel="close"
      baseDevice={device}
      map={map}
      isSelected={selectedDevice === device.id}
    />
  ));

  const projectAreas = projects.map((project) => (
    <ProjectArea
      area={project.area}
      map={map}
      key={project.id}
      project={project}
      selectedProject={selectedProject}
      onClick={() => onClick(null, project)}
    />
  ));

  const tooManyDevicesError = (
    <Notification
      style={{
        position: 'absolute',
        bottom: '16px',
        right: '16px',
        zIndex: 2,
      }}
      w={'fit-content'}
      icon={<IconX />}
      p="sm"
      color="red"
      title="Too many devices to render"
    >
      Unable to render, try to zoom in
    </Notification>
  );

  return (
    <>
      {/* PROJECTS*/}
      {viewMode === 'projects' && projectDotMarkers}
      {viewMode === 'projects' && projectMarkers}

      {/* DEVICES */}
      {viewMode === 'devices' && deviceDotMarkers}

      {/* CLOSE ZOOM LEVEL (RENDER BOTH)*/}
      {deviceMarkers}
      {projectAreas}

      {/* This should be in a notification service , later todo */}
      {visibleDevicesCloseZoom.length > MapConstants.MAX_DEVICE_RENDER && tooManyDevicesError}
    </>
  );
};
