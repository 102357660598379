import { SegmentedControl } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { PWAContext } from '../../providers/PWAProvider';
import { EquipmentType } from '../../../models/enums/DeviceEnums';

const EQUIPMENT = [
  { name: 'Sign', type: EquipmentType.Sign },
  { name: 'Barrier', type: EquipmentType.Barrier },
];

export default function EquipmentTypeSelector() {
  const { equipmentType, setEquipmentType, setSign } = useContext(PWAContext);

  useEffect(() => setEquipmentType(EquipmentType.Sign), [setEquipmentType]);

  const handleEquipmentSelect = (type: EquipmentType) => {
    setEquipmentType(type);
    setSign(null);
  };

  return (
    <SegmentedControl
      fullWidth
      data={EQUIPMENT.map((item) => ({ label: item.name, value: item.type.toString() }))}
      value={equipmentType?.toString()}
      onChange={(value) => handleEquipmentSelect(Number(value))}
    />
  );
}
