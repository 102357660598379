// hooks/useReportAccordion.ts
import { useState, useEffect } from 'react';

interface UseReportAccordionProps {
  hasIntellitagsData?: boolean;
  hasArrowboardsData?: boolean;
  hasSmartcablesData?: boolean;
  hasSmartsolarData?: boolean;
  includeStatistics: boolean;
}

/**
 * Custom hook to manage the state of report accordions
 */
export function useReportAccordion({
  hasIntellitagsData = false,
  hasArrowboardsData = false,
  hasSmartcablesData = false,
  hasSmartsolarData = false,
  includeStatistics = false,
}: UseReportAccordionProps) {
  // Select which accordion should be open initially
  const getInitialAccordion = () => {
    const items: string[] = [];

    // Open the first available dashboard panel
    if (hasIntellitagsData) {
      items.push('intellitag-dashboard');
    } else if (hasArrowboardsData) {
      items.push('arrowboard-dashboard');
    } else if (hasSmartcablesData) {
      items.push('smartcable-dashboard');
    } else if (hasSmartsolarData) {
      items.push('smartsolar-dashboard');
    }

    // Open the statistics panel if there is Intellitag data and statistics are enabled
    if (hasIntellitagsData && includeStatistics) {
      items.push('intellitag-statistics');
    }

    return items;
  };

  const [activeAccordions, setActiveAccordions] = useState<string[]>(getInitialAccordion);

  // If statistics are enabled, ensure the statistics panel is visible
  useEffect(() => {
    if (includeStatistics && hasIntellitagsData) {
      setActiveAccordions((current) =>
        current.includes('intellitag-statistics') ? current : [...current, 'intellitag-statistics']
      );
    }
  }, [includeStatistics, hasIntellitagsData]);

  // Function to reset accordions to their basic state when the modal window opens
  const resetAccordionsForModal = () => {
    const initialItems: string[] = [];

    // Keep the statistics panel open if it's already open
    if (activeAccordions.includes('intellitag-statistics') && hasIntellitagsData) {
      initialItems.push('intellitag-statistics');
    } else if (includeStatistics && hasIntellitagsData) {
      initialItems.push('intellitag-statistics');
    }

    setActiveAccordions(initialItems);
  };

  return {
    activeAccordions,
    setActiveAccordions,
    resetAccordionsForModal,
  };
}
