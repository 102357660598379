import {
  CreateProjectFlowFormValues,
  IS_GEOZONE_SETUP_AVAILABLE,
} from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import { PsaSetupType } from '@/models/enums/ProjectEnums';
import { getPsaSetupImage } from '@/utils/ImageHelpers';
import { Group, Image, InputError, Radio, Stack, Text, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useRef } from 'react';

const FORM_KEY = 'psaSetupStep' as const satisfies keyof CreateProjectFlowFormValues;

export const SetupTypeSelection = ({
  form,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
}) => {
  const hasScrolledIntoView = useRef(false);
  const selectedSetup = form.getValues()[FORM_KEY]!.setupType;
  const isMobile = useMobileDevice();

  return (
    <Stack
      gap="md"
      ref={(node) => {
        if (hasScrolledIntoView.current) return;
        node?.scrollIntoView({ behavior: 'smooth' });
        hasScrolledIntoView.current = true;
      }}
    >
      <Stack gap="xs">
        <Title order={5} mb={'xs'}>
          Select setup type
        </Title>
        {form.errors[`${FORM_KEY}.setupType`] && (
          <InputError size="md" fw={500}>
            {form.errors[`${FORM_KEY}.setupType`]}
          </InputError>
        )}
      </Stack>
      <Group align="stretch" grow={!isMobile}>
        <SetupTypeCard
          setupType={PsaSetupType.Manual}
          title="Manual connection"
          description="Add PSAs by reference number manually to create a manual connection between devices."
          checked={selectedSetup === PsaSetupType.Manual}
          onClick={() => {
            form.setFieldValue(`${FORM_KEY}.setupType`, PsaSetupType.Manual);
          }}
        />
        <SetupTypeCard
          setupType={PsaSetupType.Geozone}
          title="Geozone"
          description="Draw a geozone on the map, devices placed within the zone will be activated."
          checked={selectedSetup === PsaSetupType.Geozone}
          onClick={() => {
            form.setFieldValue(`${FORM_KEY}.setupType`, PsaSetupType.Geozone);
          }}
          disabled={!IS_GEOZONE_SETUP_AVAILABLE}
        />
      </Group>
    </Stack>
  );
};

const SetupTypeCard = ({
  setupType,
  title,
  description,
  disabled,
  checked,
  onClick,
}: {
  setupType: PsaSetupType;
  title: string;
  description: string;
  disabled?: boolean;

  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <Radio.Card
      bg={'var(--mantine-color-body)'}
      opacity={disabled ? 0.7 : 1}
      radius={'md'}
      px="lg"
      py="lg"
      withBorder
      styles={{
        card: {
          cursor: disabled ? 'not-allowed' : 'pointer',
          borderColor: checked ? 'var(--mantine-primary-color-filled)' : '',
          borderWidth: 2,
        },
      }}
      disabled={disabled}
      checked={checked}
      onClick={() => !disabled && onClick()}
    >
      <Stack align="stretch" gap="md" w={'100%'} h={'100%'}>
        <Stack
          justify="center"
          style={{
            height: 125,
            minHeight: 125,
            maxHeight: 125,
            overflow: 'hidden',
          }}
        >
          <Image
            src={getPsaSetupImage(setupType)}
            alt=""
            style={{
              filter: disabled ? 'grayscale(1)' : '',
            }}
          />
        </Stack>
        <Stack gap={'sm'}>
          <Group justify="space-between">
            <Title order={5}>
              {title}
              {disabled && ' - Coming soon!'}
            </Title>
          </Group>
          <Text size="sm" c={'greyText'}>
            {description}
          </Text>
        </Stack>
      </Stack>
    </Radio.Card>
  );
};
