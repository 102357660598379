import { SnoozeDuration } from '@/models/SnoozeDurationsDto';

interface Params {
  snoozeDurations: SnoozeDuration[];
  intervals: string[];
  columnIndex: number;
  showSnoozed: boolean;
}

const getSnoozeBackgroundColor = ({
  snoozeDurations,
  intervals,
  columnIndex,
  showSnoozed,
}: Params): string | undefined => {
  if (columnIndex < 3 || !showSnoozed) return undefined;
  const startIndex = columnIndex - 3;
  const endIndex = columnIndex - 2;

  const intervalStart = intervals[startIndex];
  const intervalEnd = intervals[endIndex];

  const isSnoozedByDuration = snoozeDurations.some(
    (duration) => intervalStart < duration.until && intervalEnd > duration.from
  );
  if (isSnoozedByDuration) return '#CED4DA';

  return undefined;
};

export default getSnoozeBackgroundColor;
