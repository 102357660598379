import { Button, Divider, Group, Menu, Stack, Text, Box } from '@mantine/core';
import { formatDateTimeString } from '../../../utils/utils.tsx';
import { IconDots, IconEdit, IconExternalLink } from '@tabler/icons-react';
import { Device, DeviceTypeNames } from '../../../models/Device';
import StatusGroup from '../../Status/StatusGroup';
import { getAttachmentText } from '../../../utils/signs.ts';
import { useState } from 'react';
import { EditDeviceInformationModal } from './../EditDeviceInformation/EditDeviceInformationModal.tsx';
import { DeviceImageWrapper } from '../../Map/InfoCard/DeviceInfo/DeviceImageWrapper.tsx';

type Props = {
  device: Device;
  isSnoozed?: boolean;
  countryCode: string;
  selected?: boolean;
  setSelectedDevice: (deviceId: string) => void;
  onNavigateToDevice: (deviceId: string) => void;
  onHoverChange?: (deviceId: string | null) => void;
};

export function DeviceListItem({
  device,
  isSnoozed,
  countryCode,
  selected,
  setSelectedDevice,
  onNavigateToDevice,
  onHoverChange,
}: Props) {
  const attachmentText = getAttachmentText(device, countryCode);
  const [assignToProjectModalOpened, setEditDeviceInformationModalOpened] = useState(false);
  const deviceTypeName = DeviceTypeNames[device.deviceType];
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHoverChange?.(device.id);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHoverChange?.(null);
  };

  return (
    <>
      <Box
        onClick={() => setSelectedDevice(device.id)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        w="100%"
        p="md"
        bd={selected ? '1px solid black' : '1px solid transparent'}
        style={{
          cursor: 'pointer',
          borderRadius: '4px',
          transition: 'background-color 150ms ease',
          backgroundColor: isHovered ? 'var(--mantine-color-lightGrey-0)' : 'white',
        }}
      >
        <Group align="flex-start" wrap="nowrap" p="xs" w="100%">
          <DeviceImageWrapper baseDevice={device} />
          <Stack gap="4px" w="100%" mr="xs">
            <Group justify="space-between" w="100%" wrap="nowrap" align="start">
              <Stack gap="2px">
                <Text size="sm" fw={700} lineClamp={1}>
                  {attachmentText && (
                    <span>
                      {attachmentText}
                      {device.currentName && ' - '}
                    </span>
                  )}
                  {device.currentName || ''}
                </Text>
                <Text size="sm">{deviceTypeName}</Text>
                <Text size="xs" c="dimmed">
                  {device.referenceId}
                </Text>
              </Stack>
              <Menu shadow="md" width={200} position="bottom-end">
                <Menu.Target>
                  <Button
                    variant="subtle"
                    color="black"
                    size="xs"
                    px="4px"
                    py="4px"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconDots size={16} />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditDeviceInformationModalOpened(true);
                    }}
                    leftSection={<IconEdit size={12} />}
                  >
                    Edit device
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      e.stopPropagation();
                      onNavigateToDevice(device.id);
                    }}
                    leftSection={<IconExternalLink size={12} />}
                  >
                    Go to Device page
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
            <Group justify="space-between" w="100%" mt="4px">
              {device.batteryVoltage && (
                <Text size="sm">
                  <b>{device.batteryVoltage}</b> (V)
                </Text>
              )}
              <Group gap="xs">
                <StatusGroup
                  alarmStatus={device.alarmStatus}
                  batteryStatus={device.batteryStatus}
                  isSnoozed={isSnoozed}
                  alarmType={device.alarmType}
                  size="md"
                />
              </Group>
            </Group>
            <Text c="gray.6" size="xs" mt="4px">
              Latest update: {formatDateTimeString(device.latestUpdate) ?? '-'}
            </Text>
          </Stack>
        </Group>
        <EditDeviceInformationModal
          device={device}
          countryCode={countryCode}
          showModal={assignToProjectModalOpened}
          close={() => {
            setEditDeviceInformationModalOpened(false);
          }}
          onSubmit={() => {
            setEditDeviceInformationModalOpened(false);
          }}
        />
      </Box>
      <Divider />
    </>
  );
}
