import { Group, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

import styles from './List.module.css';
import { WorkZone } from '../../../../models/Project';
import { NoProjectsFound } from '../../../../components/Project/NoProjectsFound/NoProjectsFound';
import { AccessDenied } from '@/pages/AccessDenied';

type Props = {
  onSelect: (project: WorkZone) => void;
  searchString: string;
  projects: WorkZone[];
  projectName?: string;
  hasAccess: boolean;
};

export default function ProjectList({
  onSelect,
  searchString,
  projects,
  projectName,
  hasAccess,
}: Props) {
  if (!hasAccess) {
    return <AccessDenied />;
  }

  const filteredProjects = projects.filter((project) =>
    project.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
  );

  if (filteredProjects.length === 0) {
    return <NoProjectsFound />;
  }

  return filteredProjects.map((item) => {
    return (
      <Group key={item.id} onClick={() => onSelect(item)} className={styles.listItem}>
        {projectName === item.name && <IconCheck color={'#748484'} />}
        <Text
          c={projectName === item.name ? '#2e4747' : '#455b5b'}
          fw={projectName === item.name ? 700 : 400}
        >
          {item.name}
        </Text>
      </Group>
    );
  });
}
