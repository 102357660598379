import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatDate } from '../../../utils/utils';
import { addHeader, drawTableBorders } from './pdfCommon';
import { PDF_CONSTANTS, PDF_TABLE_CONSTANTS } from '../../../constants/pdfConstants';
import { PdfGenerationData } from '@/models/PdfExport';
import { processAlarmEvents, generateTableBody } from './alarmEventUtils';
import { handleDeviceGroupSeparator, handleAlarmCell } from './cellRenderers';
import { ReportType } from '@/models/enums/ReportType';
import { PoweredEquipmentIcons, PoweredEquipmentNames } from '@/models/enums/DeviceEnums';
import { DeviceRow } from '@/models/SupervisionReport';

const ALARM_TABLE_FONT_SIZE = 8;
const ALARM_TABLE_CELL_HEIGHT = 12;

/**
 * Add alarm events to the document
 * @param doc The PDF document
 * @param data The data for PDF generation
 * @returns boolean indicating if alarm events were added
 */
export function addAlarmEventsToDocument(doc: jsPDF, data: PdfGenerationData): boolean {
  // Determine which rows to use based on the report type
  let rows: DeviceRow[] = [];

  if (data.reportType === ReportType.Intellitags) {
    rows = data.rows || [];
  } else if (data.reportType === ReportType.SmartCables) {
    if (data.smartCableRows) {
      rows = data.smartCableRows.map((row) => ({
        ...row,
        currentEquipmentType: row.poweredEquipment || 0,
      })) as unknown as DeviceRow[];
    }
  } else if (data.reportType === ReportType.SmartSolar) {
    if (data.smartSolarRows) {
      rows = data.smartSolarRows.map((row) => ({
        ...row,
        currentEquipmentType: row.poweredEquipment || 0,
      })) as unknown as DeviceRow[];
    }
  } else if (data.reportType === ReportType.Arrowboards) {
    return false;
  }

  const deviceAlarms = processAlarmEvents(rows);
  if (deviceAlarms.length === 0) {
    // If there are no alarms, return false to indicate nothing was added
    return false;
  }

  // Add a new page for alarm events
  doc.addPage();

  // Determine device type and column title based on report type
  let deviceTypeTitle = 'Intellitags';
  let columnTitle = 'Attachment';

  if (data.reportType) {
    switch (data.reportType) {
      case ReportType.SmartCables:
        deviceTypeTitle = 'Smart Cable';
        columnTitle = 'Powered Equipment';
        break;
      case ReportType.SmartSolar:
        deviceTypeTitle = 'Smart Solar';
        columnTitle = 'Powered Equipment';
        break;
      case ReportType.Intellitags:
        deviceTypeTitle = 'Intellitags';
        columnTitle = 'Attachment';
        break;
      default:
        // Use defaults
        break;
    }
  }

  const tableBody = generateTableBody(deviceAlarms, data.countryCode, data.reportType);
  const tableWidth = doc.internal.pageSize.getWidth() - PDF_CONSTANTS.SIDE_MARGIN * 2;

  const scaleFactor = 1 / 0.75;
  const colWidth = {
    refId: tableWidth * 0.15 * scaleFactor,
    info: tableWidth * 0.2 * scaleFactor,
    attach: tableWidth * 0.1 * scaleFactor,
    reported: tableWidth * 0.15 * scaleFactor,
    addressed: tableWidth * 0.15 * scaleFactor,
  };

  const headerPositions: { x: number; y: number; width: number; height: number; text: string }[] =
    [];

  let currentTableStartY = 0;
  let isFirstPage = true;
  let currentTableEndY = 0;
  let isLastPage = false;

  // Create and render table
  autoTable(doc, {
    head: [['Reference ID', 'Additional info', columnTitle, 'Alarm reported', 'Alarm addressed']],
    body: tableBody.map((row) => [
      row.referenceId,
      row.name,
      row.attachment,
      row.alarmReported,
      row.alarmAddressed,
    ]),
    margin: {
      left: PDF_CONSTANTS.SIDE_MARGIN,
      right: PDF_CONSTANTS.SIDE_MARGIN,
      top: PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.HEADER,
    },
    styles: {
      cellPadding: 4,
      minCellHeight: ALARM_TABLE_CELL_HEIGHT,
      lineWidth: 0,
      fontSize: ALARM_TABLE_FONT_SIZE * 0.9,
    },
    theme: 'plain',
    alternateRowStyles: {
      fillColor: [255, 255, 255],
    },
    columnStyles: {
      0: { cellWidth: colWidth.refId, halign: 'center', valign: 'middle' },
      1: {
        cellWidth: colWidth.info,
        overflow: 'ellipsize' as const,
        halign: 'center',
        valign: 'middle',
      },
      2: { cellWidth: colWidth.attach, halign: 'center', valign: 'middle' },
      3: { cellWidth: colWidth.reported, halign: 'center', valign: 'middle' },
      4: { cellWidth: colWidth.addressed, halign: 'center', valign: 'middle' },
    },
    headStyles: {
      fillColor: PDF_CONSTANTS.COLORS.GREEN_PRIMARY,
      textColor: '#FFFFFF',
      fontStyle: 'bold',
      fontSize: ALARM_TABLE_FONT_SIZE * 0.9,
      minCellHeight: ALARM_TABLE_CELL_HEIGHT * 0.8,
      cellPadding: 2,
      halign: 'center',
      valign: 'middle',
    },
    bodyStyles: {
      textColor: '#000000',
      fontSize: ALARM_TABLE_FONT_SIZE * 0.9,
      minCellHeight: ALARM_TABLE_CELL_HEIGHT * 0.8,
      cellPadding: 2,
      halign: 'center',
      valign: 'middle',
    },
    willDrawCell: (hookData) => {
      if (hookData.cell.section === 'body') {
        currentTableEndY = hookData.cell.y + hookData.cell.height;
        isLastPage = hookData.row.index === tableBody.length - 1;
      }
    },
    didDrawPage: () => {
      addHeader(doc, {
        projectName: `${data.customerName} - ${data.projectName}`,
        title: `Alarm Events for ${deviceTypeTitle}`,
        startDate: formatDate(data.reportStartDate),
        endDate: formatDate(data.reportEndDate),
        sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
      });

      if (isFirstPage) {
        currentTableStartY = PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.HEADER;
        isFirstPage = false;
      } else {
        currentTableStartY = PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.HEADER;
      }

      drawTableBorders(doc, currentTableStartY, tableWidth, currentTableEndY, isLastPage);
    },
    didParseCell: (hookData) => {
      if (hookData.cell.section === 'head') {
        headerPositions.push({
          x: hookData.cell.x,
          y: hookData.cell.y,
          width: hookData.cell.width,
          height: hookData.cell.height,
          text: hookData.cell.raw as string,
        });
      }
      if (
        hookData.cell.section === 'body' &&
        (hookData.column.index === 2 || hookData.column.index >= 3)
      ) {
        hookData.cell.text = [];
      }
    },
    didDrawCell: (hookData) => {
      if (hookData.cell.section === 'body') {
        // Handle attachment/powered equipment images (column 2)
        if (hookData.column.index === 2) {
          const attachmentValue = hookData.cell.raw as string;

          // Check if this is a battery monitor device (powered equipment)
          const isBatteryMonitor =
            data.reportType === ReportType.SmartCables || data.reportType === ReportType.SmartSolar;

          if (isBatteryMonitor) {
            // For battery monitor devices, use PoweredEquipmentIcons directly with the numeric value
            const equipmentValue = Number(attachmentValue);

            // Check if it's a valid number and a valid equipment type
            if (!isNaN(equipmentValue) && equipmentValue in PoweredEquipmentIcons) {
              // Get the icon directly using the numeric value
              const icon =
                PoweredEquipmentIcons[equipmentValue as keyof typeof PoweredEquipmentIcons];

              if (icon) {
                const fixedSize = PDF_TABLE_CONSTANTS.FIXED_SIGN_SIZE;
                const x = hookData.cell.x + (hookData.cell.width - fixedSize) / 2;
                const y = hookData.cell.y + (hookData.cell.height - fixedSize) / 2;

                try {
                  doc.addImage(icon, 'PNG', x, y, fixedSize, fixedSize, undefined, 'FAST');
                } catch (error) {
                  // Fallback to text if image fails
                  const equipmentName =
                    PoweredEquipmentNames[equipmentValue as keyof typeof PoweredEquipmentNames] ||
                    'Unknown';
                  doc.text(
                    equipmentName,
                    hookData.cell.x + hookData.cell.width / 2,
                    hookData.cell.y + hookData.cell.height / 2,
                    {
                      align: 'center',
                      baseline: 'middle',
                    }
                  );
                }
              } else {
                // No icon found, display text with the name
                const equipmentName =
                  PoweredEquipmentNames[equipmentValue as keyof typeof PoweredEquipmentNames] ||
                  'Unknown';
                doc.text(
                  equipmentName,
                  hookData.cell.x + hookData.cell.width / 2,
                  hookData.cell.y + hookData.cell.height / 2,
                  {
                    align: 'center',
                    baseline: 'middle',
                  }
                );
              }
            } else if (attachmentValue && attachmentValue !== '-') {
              // Not a valid equipment value, display as is
              doc.text(
                attachmentValue,
                hookData.cell.x + hookData.cell.width / 2,
                hookData.cell.y + hookData.cell.height / 2,
                {
                  align: 'center',
                  baseline: 'middle',
                }
              );
            }
          } else {
            // For intellitags, use the existing image map
            const attachmentImage = data.imageMap[attachmentValue];
            if (attachmentImage) {
              const fixedSize = PDF_TABLE_CONSTANTS.FIXED_SIGN_SIZE;
              const x = hookData.cell.x + (hookData.cell.width - fixedSize) / 2;
              const y = hookData.cell.y + (hookData.cell.height - fixedSize) / 2;

              try {
                doc.addImage(attachmentImage, 'PNG', x, y, fixedSize, fixedSize, undefined, 'FAST');
              } catch (error) {
                // Silently handle image errors
              }
            }
          }
        }
        // Handle alarm status icons (columns 3 and 4)
        else if (hookData.column.index >= 3) {
          handleAlarmCell(doc, hookData, deviceAlarms, data.statusIcons);
        }
      }

      handleDeviceGroupSeparator(doc, hookData, deviceAlarms, tableBody);
    },
  });

  return true;
}
