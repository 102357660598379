import { Device, DeviceType } from '../../../../models/Device';
import { ArrowboardInfo } from './ArrowBoardInfo';
import { IntellitagInfo } from './IntellitagInfo';
import { IntellitagLiteInfo } from './IntellitagLiteInfo';

interface Props {
  device: Device;
}

export const DeviceSpecifcInfoWrapper = ({ device }: Props) => {
  if (device.deviceType == DeviceType.ArrowBoardV1)
    return <ArrowboardInfo arrowDirection={device.arrowDirection} />;
  if (device.deviceType == DeviceType.IntellitagV1)
    return (
      <IntellitagInfo
        attachmentRef={device.attachmentRef}
        currentEquipmentType={device.currentEquipmentType}
      />
    );
  if (device.deviceType === DeviceType.IntelliTagLite) {
    return <IntellitagLiteInfo currentEquipmentType={device.currentEquipmentType} />;
  }
};
