import dayjs from 'dayjs';
import { useState } from 'react';
import { LineChart } from '@mantine/charts';
import { useMsal } from '@azure/msal-react';
import { DatePickerInput } from '@mantine/dates';
import { Center, Group, Loader, Stack, Text, Title } from '@mantine/core';
import { IconBatteryCharging, IconCalendarSad, IconChartBarOff } from '@tabler/icons-react';

import { PopoverConfirm } from '@/components/Popover/PopoverConfirm';
import { useDatepickerDates } from '@/components/Project/SupervisionReport/hooks/useDatepickerDates';
import useDeviceLogByTimeStamp from '@/data/hooks/DeviceLog/useDeviceLogByTimeStamp';
import { Device, DeviceType } from '@/models/Device';
import { BatteryStatus, BatteryStatusNames } from '@/models/enums/DeviceEnums';
import { getDeviceModelBatteryThresholds, getMinValue } from '@/utils/ChartHelper';
import { FlexSpacer } from '@/shared/FlexSpacer';
import { LabelValueText } from '@/shared/LabelValueText';
import {
  formatDateTime,
  getEstimatedBatteryLifetime,
  numberOfDaysBetweenDates,
} from '@/utils/utils';

interface Props {
  device: Device | undefined;
  onLogBatteryChange: (id: string) => void;
}

const DEFAULT_NUMBER_OF_DAYS = 5;
const TIMESPAN_MAX = 30;
const showBatteryChartForDeviceTypes = [
  DeviceType.IntellitagV1,
  DeviceType.SmartCableV1,
  DeviceType.SmartSolarV1,
];

export const BatteryManagement = ({ device, onLogBatteryChange }: Props) => {
  const startDate = dayjs().subtract(DEFAULT_NUMBER_OF_DAYS, 'days').toDate();
  const { dateValue, setDateValue, startTime, endTime } = useDatepickerDates(startDate, new Date());
  const timespanMaxExceeded = numberOfDaysBetweenDates(startTime, endTime) > TIMESPAN_MAX;
  const { instance } = useMsal();
  const showBatteryChart = device
    ? showBatteryChartForDeviceTypes.includes(device.deviceType)
    : false;
  const [openedConfirmChangeBattery, setOpenedConfirmChangeBattery] = useState<boolean>(false);
  const { deviceLogs, isLoading } = useDeviceLogByTimeStamp({
    instance,
    deviceId: device?.id,
    startTime,
    endTime,
  });
  const data = deviceLogs
    ? deviceLogs
        .map((log) => ({
          date: formatDateTime(new Date(log.timestamp)),
          voltage:
            device?.deviceType === DeviceType.IntellitagV1
              ? log.batteryVoltage
              : log.externalBatteryVoltage ?? -9,
        }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    : [];

  const thresholds = getDeviceModelBatteryThresholds(device);
  const chartMax = data ? Math.max(...data.map((d) => d.voltage)) : 12;
  const chartMin = getMinValue(
    data ? Math.min(...data.map((d) => d.voltage)) : 0,
    thresholds.critical
  );
  if (!device) {
    return (
      <Center>
        <Text>No device found</Text>
      </Center>
    );
  }

  const loading = (
    <Center mt="md" data-testid="loader">
      <Loader />
    </Center>
  );

  const noData = (
    <Center>
      <Stack align="center">
        <IconChartBarOff />
        <Text c="gray.7">No battery data to show for selected days</Text>
      </Stack>
    </Center>
  );

  const timespanTooBig = (
    <Center>
      <Stack align="center">
        <IconCalendarSad />
        <Text c="gray.7">Timespan to big, change timespan no more than 30 days</Text>
      </Stack>
    </Center>
  );

  const chart = (
    <LineChart
      p="xl"
      mt="md"
      h={300}
      miw={0}
      mih={undefined}
      data={data}
      dataKey="date"
      withDots={false}
      yAxisProps={{
        domain: [chartMin - 1, chartMax + 1],
      }}
      referenceLines={[
        {
          y: thresholds.low,
          label: BatteryStatusNames[BatteryStatus.LOW],
          color: 'var(--mantine-color-warningYellow-1)',
        },
        {
          y: thresholds.critical,
          label: BatteryStatusNames[BatteryStatus.CRITICAL],
          color: 'var(--mantine-color-dangerRed-1)',
        },
      ]}
      series={[{ name: 'voltage', color: 'indigo.6' }]}
    />
  );

  const chartAndPlaceholder = timespanMaxExceeded ? timespanTooBig : !data.length ? noData : chart;
  const shouldShowAdvancedBatteryFeatures =
    device.deviceType === DeviceType.IntellitagV1 ||
    device.deviceType === DeviceType.IntelliTagLite;
  const shouldShowReplaceBatteryButton =
    shouldShowAdvancedBatteryFeatures && device?.estimatedBatteryEndDate;

  return (
    <>
      <Title order={4}>Battery Management</Title>
      <Group mt="md">
        <LabelValueText
          label="Battery (V)"
          value={device?.batteryVoltage ? device.batteryVoltage.toString() : '-'}
          fwLabel={500}
          textSize="sm"
          direction="column"
        />
        {shouldShowAdvancedBatteryFeatures && (
          <>
            <LabelValueText
              label="Estimated battery lifetime"
              value={getEstimatedBatteryLifetime(device.estimatedBatteryEndDate)}
              fwLabel={500}
              textSize="sm"
              direction="column"
              testId="estimated-battery-lifetime"
            />
            <LabelValueText
              label="Last battery change"
              value={
                device.latestBatteryChangeDate
                  ? dayjs(device.latestBatteryChangeDate).format('YYYY-MM-DD, HH:mm')
                  : '-'
              }
              fwLabel={500}
              textSize="sm"
              direction="column"
              testId="last-battery-change"
            />
          </>
        )}
        <LabelValueText
          label="Power saving mode"
          value={device.inPowerSavingMode ? 'Yes' : 'No'}
          fwLabel={500}
          textSize="sm"
          direction="column"
        />
        <FlexSpacer />
        {shouldShowReplaceBatteryButton && (
          <PopoverConfirm
            opened={openedConfirmChangeBattery}
            setOpened={setOpenedConfirmChangeBattery}
            text="Are you sure you want to log the battery change?"
            btnText="Log Battery Change"
            width={300}
            onClick={() => onLogBatteryChange(device.id)}
            position="bottom"
            icon={IconBatteryCharging}
            withGroup={true} // Enabling group for icon and text alignment
          />
        )}
      </Group>
      {showBatteryChart && (
        <Stack>
          <DatePickerInput
            mt={'md'}
            maw="300px"
            type="range"
            label="Select date range"
            placeholder="Pick date range"
            value={dateValue}
            onChange={(val) => setDateValue(val)}
            maxDate={new Date()}
            allowSingleDateInRange
          />
          {isLoading ? loading : chartAndPlaceholder}
        </Stack>
      )}
    </>
  );
};
