import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { proxy } from 'comlink';
import type { PdfGenerationData, PdfGenerationResult, ProgressUpdate } from '@/models/PdfExport';
import { formatDate } from '@/utils/utils';
import { workerManager } from './WorkerManager';
import { PdfWorkerContext, type PdfState, initialState } from './types';

// Delay before actually completing cancellation (ms)
const CANCELLATION_DISPLAY_TIME = 1000;

export function PdfWorkerProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, setState] = useState<PdfState>(initialState);

  // Separate timeout ref for cancellation
  const cancelTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Use a ref to track cancellation state to avoid closure issues
  const cancelRequestedRef = useRef<boolean>(false);

  const updateState = useCallback((updates: Partial<PdfState>) => {
    setState((prev) => {
      const newState = { ...prev, ...updates };
      // Keep our ref in sync with state updates
      if ('cancelRequested' in updates) {
        cancelRequestedRef.current = !!updates.cancelRequested;
      }
      return newState;
    });
  }, []);

  const completeCancellation = useCallback(() => {
    // Final cleanup after cancellation display time is over
    updateState({
      status: 'idle',
      progress: null,
      isGenerating: false,
      cancelRequested: false, // Reset this flag for subsequent exports
    });
    workerManager.resetCancellationState();
  }, [updateState]);

  const handleError = useCallback(
    (err: unknown) => {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error occurred';
      updateState({ error: errorMessage, status: 'error' });
    },
    [updateState]
  );

  const cancelGeneration = useCallback(() => {
    // First set cancellation state without changing status to idle
    // This allows the cancellation UI to be shown
    updateState({
      cancelRequested: true,
      // Don't immediately set status to idle
      progress: { progress: 0, message: 'Cancelling operation...' },
    });

    // Signal worker to cancel
    workerManager.requestCancellation();

    // Schedule completion of cancellation after display time
    if (cancelTimeoutRef.current) {
      clearTimeout(cancelTimeoutRef.current);
    }

    cancelTimeoutRef.current = setTimeout(() => {
      completeCancellation();
      cancelTimeoutRef.current = null;
    }, CANCELLATION_DISPLAY_TIME);
  }, [updateState, completeCancellation]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (cancelTimeoutRef.current) {
        clearTimeout(cancelTimeoutRef.current);
      }
      workerManager.cleanup();
    };
  }, []);

  const downloadPdf = useCallback(
    async (result: PdfGenerationResult, data: PdfGenerationData): Promise<void> => {
      const fileName = `${data.projectName}_${formatDate(data.reportStartDate)}_${formatDate(
        data.reportEndDate
      )}_${data.reportType}.pdf`;

      try {
        const blob = await fetch(result.pdfBase64!).then((res) => res.blob());
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        throw new Error(
          'Failed to download PDF: ' + (error instanceof Error ? error.message : 'Unknown error')
        );
      }
    },
    []
  );

  const generatePdf = useCallback(
    async (data: PdfGenerationData): Promise<PdfGenerationResult> => {
      try {
        // Cancel any existing cancellation timeout
        if (cancelTimeoutRef.current) {
          clearTimeout(cancelTimeoutRef.current);
          cancelTimeoutRef.current = null;
        }

        // Early cancellation check - make sure to reset fully
        if (cancelRequestedRef.current || workerManager.isCancellationRequested()) {
          // Reset state before starting new generation
          updateState({
            status: 'idle',
            isGenerating: false,
            error: null,
            progress: null,
            cancelRequested: false,
          });
          workerManager.resetCancellationState();

          // Return error to indicate cancellation
          return { success: false, error: 'Cancelled by user' };
        }

        // Set generating state
        updateState({
          status: 'generating',
          isGenerating: true,
          error: null,
          cancelRequested: false, // Ensure this is reset
        });
        workerManager.setGenerating(true);

        // Initialize worker
        let worker;
        try {
          worker = workerManager.initWorker();
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : 'Failed to initialize PDF generation';
          updateState({
            status: 'idle',
            isGenerating: false,
            error: errorMessage,
            progress: null,
            cancelRequested: false,
          });
          workerManager.setGenerating(false);
          return { success: false, error: errorMessage };
        }

        // Create progress callback
        const progressCallback = proxy((update: ProgressUpdate) => {
          // Use ref to avoid closure issues with stale state
          if (!cancelRequestedRef.current && !workerManager.isCancellationRequested()) {
            updateState({ progress: update });
          }
        });

        // Generate PDF
        const result = await worker.generatePdf(data, progressCallback);

        // Check cancellation after generation
        if (cancelRequestedRef.current || workerManager.isCancellationRequested()) {
          // If we're here and cancellation is requested, let the timeout handle the UI
          return { success: false, error: 'Cancelled by user' };
        }

        // Check result validity
        if (!result.success || !result.pdfBase64) {
          throw new Error(result.error || 'Failed to generate PDF');
        }

        // Download PDF and update state
        await downloadPdf(result, data);
        updateState({
          status: 'completed',
          cancelRequested: false, // Ensure this is reset
        });
        return result;
      } catch (err) {
        // Handle cancellation in error case
        if (cancelRequestedRef.current || workerManager.isCancellationRequested()) {
          // Don't immediately change status - let the timeout handle it
          return { success: false, error: 'Cancelled by user' };
        }
        handleError(err);
        throw err;
      } finally {
        // Always reset worker state
        workerManager.setGenerating(false);

        // If cancellation is requested, the timer will handle state reset
        // Otherwise, make sure cancelRequested is false for next time
        if (!cancelRequestedRef.current && !workerManager.isCancellationRequested()) {
          updateState({ cancelRequested: false });
        }
      }
    },
    [updateState, handleError, downloadPdf]
  );

  const value = useMemo(
    () => ({
      generatePdf,
      isGenerating: state.isGenerating,
      status: state.status,
      error: state.error,
      progress: state.progress,
      updateState,
      cancelRequested: state.cancelRequested,
      cancelGeneration,
    }),
    [
      generatePdf,
      state.isGenerating,
      state.status,
      state.error,
      state.progress,
      state.cancelRequested,
      updateState,
      cancelGeneration,
    ]
  );

  return <PdfWorkerContext.Provider value={value}>{children}</PdfWorkerContext.Provider>;
}
