import '../styles/Root.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { AppShell, Burger, Group } from '@mantine/core';
import { useDisclosure, useSessionStorage } from '@mantine/hooks';
import { Outlet } from 'react-router-dom';
import { NavbarSegmented } from '../components/Navbar/NavbarSegmented';
import logo from '../assets/R_L1_gul_neg_RGB.svg';
import '@mantine/dropzone/styles.css';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import { useEffect } from 'react';

export default function Root({ sidebarAvailable = true }: { sidebarAvailable?: boolean }) {
  const [opened, { toggle }] = useDisclosure();
  const [minimized, setMinimized] = useSessionStorage({ key: 'menuMini', defaultValue: false });
  const isMobile = useMobileDevice();

  useEffect(() => {
    if (isMobile) setMinimized(false);
  }, [isMobile, setMinimized]);

  return (
    <>
      <AppShell
        header={{ height: isMobile ? 60 : 0 }}
        navbar={
          sidebarAvailable
            ? {
                width: minimized ? 80 : 300,
                breakpoint: 'md',
                collapsed: { mobile: !opened },
              }
            : undefined
        }
        padding={'0'}
        styles={(theme) => ({
          main: { backgroundColor: theme.colors.sandBg[0] },
          navbar: { backgroundColor: 'white' },
          header: {
            backgroundColor: theme.colors.greenHover[9],
            borderColor: theme.colors.greenHover[9],
            zIndex: 200,
          },
        })}
      >
        <AppShell.Header hiddenFrom="md">
          <Group justify="space-between" mx={'16px'} h={60}>
            <a href="/projects">
              <img className="logo" src={logo} width={'150px'}></img>
            </a>
            {sidebarAvailable && (
              <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="sm" color="white" />
            )}
          </Group>
        </AppShell.Header>
        {sidebarAvailable && (
          <AppShell.Navbar zIndex={102} p="md" pt={0} bg={'var(--mantine-color-greenHover-9)'}>
            <NavbarSegmented
              toggleNavbar={toggle}
              toggleMinimize={(value) => setMinimized(value)}
              minimize={minimized}
            />
          </AppShell.Navbar>
        )}
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </>
  );
}
