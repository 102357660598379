import { ActionIcon, ButtonVariant, Button as MantineButton } from '@mantine/core';
import { TablerIconsProps } from '@tabler/icons-react';
import React from 'react';

interface Props {
  variant: 'primary' | 'warning' | 'secondary';
  type?: HTMLButtonElement['type'];
  onClick?: () => void;
  children?: string | null;
  icon?: React.ComponentType<TablerIconsProps>;
  _rightIcon?: React.ComponentType<TablerIconsProps>;
  disabled?: boolean;
  fullWidth?: boolean;
  isFloating?: boolean;
  highlight?: boolean;
  loading?: boolean;
}

export const Button = ({
  variant,
  type,
  onClick,
  icon: Icon,
  _rightIcon: RightIcon,
  children,
  disabled,
  fullWidth,
  isFloating,
  highlight,
  loading,
}: Props) => {
  const correctlySizedIcon = Icon ? <Icon size={16} /> : null;
  const correctlySizedRightIcon = RightIcon ? <RightIcon size={16} /> : null;
  const isIconButton = Icon && !children;

  let mantineVariantStyles: { variant: ButtonVariant; color?: string };
  switch (variant) {
    case 'primary':
      mantineVariantStyles = { variant: 'filled' };
      break;
    case 'warning':
      mantineVariantStyles = { variant: 'filled', color: 'dangerRed.8' };
      break;
    case 'secondary':
      mantineVariantStyles = { variant: 'default' };
      break;
  }

  return isIconButton ? (
    <ActionIcon
      size="xl"
      type={type}
      {...mantineVariantStyles}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      w={fullWidth ? '100%' : 'fit-content'}
      {...(isFloating && { pos: 'absolute', style: { zIndex: 1 } })}
    >
      {correctlySizedIcon}
    </ActionIcon>
  ) : (
    <MantineButton
      type={type}
      {...mantineVariantStyles}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      w={fullWidth ? '100%' : 'fit-content'}
      leftSection={correctlySizedIcon}
      rightSection={correctlySizedRightIcon}
      {...(isFloating && { pos: 'absolute', style: { zIndex: 1 } })}
      {...(highlight && { bg: 'ramuddenBlue.0', c: 'ramuddenBlue', bd: '1px solid ramuddenBlue' })}
    >
      {children}
    </MantineButton>
  );
};
