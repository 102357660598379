import { daysLeftInWeek } from '@/utils/dates/dates';

// L / 10 KM
const FUEL_CONSUMPTION = {
  TMA: 2.0,
  lightTruck: 0.9,
  car: 0.6,
};

// KG CO2e / L
const FUEL_EMISSIONS = {
  gasoline: 3.0,
  diesel: 2.7,
};

interface Week {
  startDate: Date;
  endDate: Date;
  digitalSupervisionVisits: number;
}

interface OutputWeek {
  startDate: Date;
  endDate: Date;
  co2WithDigital: number;
  co2WithPhysical: number;
  co2Avoided: number;
}

export interface CalculateCarbonAvoidedProps {
  distanceDepotToWorksite: number;
  physicalSupervisionVisitsPerWeek: number;
  amountTmaCars: number;
  amountLightTrucks: number;
  amountCars: number;
  selectedWeeks: Week[];
}

export interface CalculateCarbonAvoidedOutput {
  totalCo2Avoided: number;
  selectedWeeks: OutputWeek[];
}

export const calculateCo2Avoided = ({
  distanceDepotToWorksite,
  physicalSupervisionVisitsPerWeek,
  amountTmaCars,
  amountLightTrucks,
  amountCars,
  selectedWeeks,
}: CalculateCarbonAvoidedProps): CalculateCarbonAvoidedOutput => {
  let totalCo2Avoided = 0;

  // To match the fuel consumption unit
  const distanceIn10km = (distanceDepotToWorksite * 2) / 10;

  const selectedWeeksCo2Avoided = selectedWeeks.map((week) => {
    const DAYS_LEFT_IN_WEEK = daysLeftInWeek(week.startDate, week.endDate);
    const PERCENTAGE_OF_FULL_WEEK = DAYS_LEFT_IN_WEEK / 7;

    // Emissions per vehicle type for physical supervision
    const physicalSupervisionCo2Tma =
      amountTmaCars *
      FUEL_CONSUMPTION.TMA *
      distanceIn10km *
      FUEL_EMISSIONS.diesel *
      physicalSupervisionVisitsPerWeek *
      PERCENTAGE_OF_FULL_WEEK;
    const physicalSupervisionCo2LightTrucks =
      amountLightTrucks *
      FUEL_CONSUMPTION.lightTruck *
      distanceIn10km *
      FUEL_EMISSIONS.diesel *
      physicalSupervisionVisitsPerWeek *
      PERCENTAGE_OF_FULL_WEEK;
    const physicalSupervisionCo2Cars =
      amountCars *
      FUEL_CONSUMPTION.car *
      distanceIn10km *
      FUEL_EMISSIONS.diesel *
      physicalSupervisionVisitsPerWeek *
      PERCENTAGE_OF_FULL_WEEK;

    // Total emissions (physical)
    const co2WithPhysical = Number(
      (
        physicalSupervisionCo2Tma +
        physicalSupervisionCo2LightTrucks +
        physicalSupervisionCo2Cars
      ).toFixed(1)
    );

    // Emissions per vehicle type for digital supervision
    const digitalSupervisionCo2Tma =
      amountTmaCars *
      FUEL_CONSUMPTION.TMA *
      distanceIn10km *
      FUEL_EMISSIONS.diesel *
      week.digitalSupervisionVisits;
    const digitalSupervisionCo2LightTrucks =
      amountLightTrucks *
      FUEL_CONSUMPTION.lightTruck *
      distanceIn10km *
      FUEL_EMISSIONS.diesel *
      week.digitalSupervisionVisits;
    const digitalSupervisionCo2Cars =
      amountCars *
      FUEL_CONSUMPTION.car *
      distanceIn10km *
      FUEL_EMISSIONS.diesel *
      week.digitalSupervisionVisits;

    // Total emissions (digital)
    const co2WithDigital = Number(
      (
        digitalSupervisionCo2Tma +
        digitalSupervisionCo2LightTrucks +
        digitalSupervisionCo2Cars
      ).toFixed(1)
    );

    // Total emissions for this week and accumulated with previous selected weeks
    const co2Avoided = Number((co2WithPhysical - co2WithDigital).toFixed(1));
    totalCo2Avoided += co2Avoided;

    return {
      startDate: week.startDate,
      endDate: week.endDate,
      co2WithDigital,
      co2WithPhysical,
      co2Avoided,
    };
  });

  return {
    totalCo2Avoided: Number(totalCo2Avoided.toFixed(1)),
    selectedWeeks: selectedWeeksCo2Avoided,
  };
};
