import { forwardRef } from 'react';
import { PdfExportRef } from '../../../../../models/PdfExport';
import { BatteryMonitorDashboardProps } from '../../../../../models/DashboardTypes';
import { ReportType } from '@/models/enums/ReportType';
import BatteryMonitorDashboardWrapper from '../BatteryMonitorDashboard/BatteryMonitorDashboardWrapper';

/**
 * Dashboard component for Smart Solar devices
 */
const SmartSolarDashboard = forwardRef<PdfExportRef, BatteryMonitorDashboardProps>((props, ref) => {
  return (
    <BatteryMonitorDashboardWrapper
      {...props}
      ref={ref}
      headerTitle="Smart Solar"
      reportType={ReportType.SmartSolar}
    />
  );
});

export default SmartSolarDashboard;
