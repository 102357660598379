import { IPublicClientApplication } from '@azure/msal-browser';
import { useQuery } from '@tanstack/react-query';
import { ArrowboardsReportDto, SupervisionReportDto } from '../../../models/SupervisionReport';
import fetchDataSingleItem, { APIResponseSingleItem } from '../../api/fetchDataSingleItem';
import { SUPERVISION_REPORT_PATHS, useSupervisionReportConfig } from './useSupervisionReportConfig';

interface Params {
  instance: IPublicClientApplication;
  workZoneId: string;
  startTime: string;
  endTime: string;
  interval: string;
  smartCableSolarInterval: string;
}

const useGetSupervisionReport = ({
  instance,
  workZoneId,
  startTime,
  endTime,
  interval,
  smartCableSolarInterval,
}: Params) => {
  const intellitagConfig = useSupervisionReportConfig({
    instance,
    workZoneId,
    startTime,
    endTime,
    interval,
    type: 'intellitags',
  });

  const arrowboardConfig = useSupervisionReportConfig({
    instance,
    workZoneId,
    startTime,
    endTime,
    type: 'arrowboards',
  });

  const smartCableConfig = useSupervisionReportConfig({
    instance,
    workZoneId,
    startTime,
    endTime,
    interval: smartCableSolarInterval,
    type: 'smartcables',
  });

  const smartSolarConfig = useSupervisionReportConfig({
    instance,
    workZoneId,
    startTime,
    endTime,
    interval: smartCableSolarInterval,
    type: 'smartsolar',
  });

  const {
    data: dataIntellitags,
    isLoading: isLoadingIntellitags,
    isError: isErrorIntellitags,
  } = useQuery<APIResponseSingleItem<SupervisionReportDto>>({
    queryKey: intellitagConfig.queryKey,
    queryFn: () =>
      fetchDataSingleItem(SUPERVISION_REPORT_PATHS.base, intellitagConfig.queryParams, instance),
    enabled: intellitagConfig.enabled,
  });

  const {
    data: dataArrowboards,
    isLoading: isLoadingArrowboards,
    isError: isErrorArrowboards,
  } = useQuery<APIResponseSingleItem<ArrowboardsReportDto>>({
    queryKey: arrowboardConfig.queryKey,
    queryFn: () =>
      fetchDataSingleItem(
        SUPERVISION_REPORT_PATHS.arrowboard,
        arrowboardConfig.queryParams,
        instance
      ),
    enabled: arrowboardConfig.enabled,
  });

  const {
    data: dataSmartCables,
    isLoading: isLoadingSmartCables,
    isError: isErrorSmartCables,
  } = useQuery<APIResponseSingleItem<SupervisionReportDto>>({
    queryKey: smartCableConfig.queryKey,
    queryFn: () =>
      fetchDataSingleItem(
        SUPERVISION_REPORT_PATHS.smartCable,
        smartCableConfig.queryParams,
        instance
      ),
    enabled: smartCableConfig.enabled,
  });

  const {
    data: dataSmartSolar,
    isLoading: isLoadingSmartSolar,
    isError: isErrorSmartSolar,
  } = useQuery<APIResponseSingleItem<SupervisionReportDto>>({
    queryKey: smartSolarConfig.queryKey,
    queryFn: () =>
      fetchDataSingleItem(
        SUPERVISION_REPORT_PATHS.smartSolar,
        smartSolarConfig.queryParams,
        instance
      ),
    enabled: smartSolarConfig.enabled,
  });

  return {
    supervisionReport: {
      intellitags: dataIntellitags?.item,
      arrowboards: dataArrowboards?.item,
      smartcables: dataSmartCables?.item,
      smartsolar: dataSmartSolar?.item,
    },
    isLoading:
      isLoadingIntellitags || isLoadingArrowboards || isLoadingSmartCables || isLoadingSmartSolar,
    isError: isErrorIntellitags || isErrorArrowboards || isErrorSmartCables || isErrorSmartSolar,
  };
};

export default useGetSupervisionReport;
