import { BatteryMonitorPdfGenerator } from './batteryMonitorPdfGenerator';

/**
 * SmartSolar PDF Generator class
 * Handles generation of PDF reports for SmartSolar devices
 */
export class SmartSolarPdfGenerator extends BatteryMonitorPdfGenerator {
  /**
   * Provide PDF title
   */
  protected getTitle(): string {
    return 'Smart Solar';
  }
}
