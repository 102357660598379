import { useMsal } from '@azure/msal-react';
import * as htmlToImage from 'html-to-image';
import { useState, useRef, useEffect, useCallback } from 'react';
import { Accordion, Badge, Group, Stack } from '@mantine/core';
import {
  IconAlertTriangle,
  IconChartBar,
  IconArrowAutofitContent,
  IconPlugConnected,
  IconRouter,
  IconSolarPanel,
  IconDownload,
} from '@tabler/icons-react';

import { usePdfWorker } from '@/contexts/PdfWorker/usePdfWorker';
import { GENERATION_STEPS } from '@/workers/pdf/pdfWorker';
import SmartCableDashboard from './SmartCableDashboard/SmartCableDashboard';
import IntellitagDashboard from './IntellitagDashboard/IntellitagDashboard';
import { WorkZone } from '@/models/Project';
import {
  ArrowboardsReportDto,
  SupervisionReportDto,
  BatteryMonitorRow,
} from '@/models/SupervisionReport';
import { notifications } from '@mantine/notifications';
import { ReportType } from '@/models/enums/ReportType';
import { CombinedPdfExportProps, PdfExportRef } from '@/models/PdfExport';
import ExportReportModal, { StatisticsFilter } from './ExportReportModal';
import { Employee } from '@/models/Employee';
import { getEmployees } from '@/services/auth';
import { StatisticsDashboard } from '@/components/Dashboard/StatisticsDashboard';
import ArrowboardDashboard from './ArrowBoardDashboard/ArrowBoardDashboard';
import { FlexSpacer } from '@/shared/FlexSpacer';
import SmartSolarDashboard from './SmartSolarDashboard/SmartSolarDashboard';
import { useSupervisionReportExport } from '../hooks/useSupervisionReportExport.ts';
import { preparePdfGenerationData, prepareCombinedPdfData } from '@/utils/pdfDataTransformers';
import { prepareImagesForPdf } from '@/utils/base64Utils';
import primaryLogo from '@/assets/report/logo-primary.png';
import primaryLogoWhite from '@/assets/report/logo-primary-white.png';
import coverBackground from '@/assets/report/cover-page.png';
import { SnoozeDuration } from '@/models/SnoozeDurationsDto.ts';
import { useReportAccordion } from '../hooks/useReportAccordion';
import { Button } from '@/components/Button/Button.tsx';
import { StatisticSection } from '../constants/enums.ts';

interface Props {
  projectName: WorkZone['name'];
  projectId: WorkZone['id'];
  reportData: {
    intellitags?: SupervisionReportDto;
    arrowboards?: ArrowboardsReportDto;
    smartcables?: SupervisionReportDto;
    smartsolar?: SupervisionReportDto;
  };
  depotName: string;
  intervalInHours: string;
  dateValue: [Date | null, Date | null];
  startDate: Date;
  endDate: Date;
  projectContactPerson?: {
    name: string;
    email: string;
    phone: string;
  };
  countryCode: string;
  customerName: string;
  snoozeDurations: SnoozeDuration[];
}

interface ExtendedPdfExportRef extends PdfExportRef {
  getImageMap?: () => Promise<Map<string, HTMLImageElement>>;
  getBatteryMonitorRows?: () => BatteryMonitorRow[];
}

export default function ReportOverview({
  projectName,
  projectId,
  reportData,
  depotName,
  intervalInHours,
  dateValue,
  startDate,
  endDate,
  projectContactPerson,
  countryCode,
  customerName,
  snoozeDurations,
}: Props) {
  const { instance } = useMsal();
  const { status, updateState } = usePdfWorker();
  const { exportCombinedPdf, exportSinglePdf } = useSupervisionReportExport();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [includeStatistics, setIncludeStatistics] = useState(false);
  const [exportContactPerson, setExportContactPerson] = useState<
    | {
        name: string;
        email: string;
        phone: string;
      }
    | undefined
  >(undefined);
  const [pendingExport, setPendingExport] = useState<ReportType[] | null>(null);

  // Use the new accordion handling hook
  const { activeAccordions, setActiveAccordions, resetAccordionsForModal } = useReportAccordion({
    hasIntellitagsData: !!reportData.intellitags?.rows.length,
    hasArrowboardsData: !!reportData.arrowboards?.length,
    hasSmartcablesData: !!reportData.smartcables?.rows.length,
    hasSmartsolarData: !!reportData.smartsolar?.rows.length,
    includeStatistics,
  });

  const initialFilter = {
    [StatisticSection.AllSigns]: true,
    [StatisticSection.Uptime]: true,
    [StatisticSection.SignsUsed]: true,
    [StatisticSection.AccAlarmsPerDay]: true,
    [StatisticSection.FallenOverEvents]: true,
    [StatisticSection.AdressedEvents]: true,
    [StatisticSection.MedianAdressedTime]: true,
    [StatisticSection.AccAlarmsPerSign]: true,
  };

  const [filterStat, setFilterStat] = useState<StatisticsFilter>(initialFilter);

  const intellitagsRef = useRef<ExtendedPdfExportRef>(null);
  const arrowboardsRef = useRef<ExtendedPdfExportRef>(null);
  const smartcablesRef = useRef<ExtendedPdfExportRef>(null);
  const smartSolarRef = useRef<ExtendedPdfExportRef>(null);

  const element = document.getElementById('stats');
  const [statisticImg, setStatisticsImg] = useState<string>();

  /**
   * Unified PDF export handler for all report types
   * Uses the worker-based approach for all reports
   */
  const handlePdfExport = useCallback(
    async (types: ReportType[]) => {
      try {
        // Common base properties for all report types
        const baseProps = {
          projectName,
          depot: depotName,
          reportStartDate: dateValue[0] || startDate,
          reportEndDate: dateValue[1] || endDate,
          contactPerson: exportContactPerson,
          countryCode,
          customerName,
          includeStatistics: types.length > 1 ? includeStatistics : false,
        };

        if (types.length === 1) {
          // Single report export - use the modern approach for all types
          const reportType = types[0];
          let reportDataSource;

          // Get the appropriate data source based on report type
          switch (reportType) {
            case ReportType.Intellitags:
              reportDataSource = reportData.intellitags;
              break;
            case ReportType.Arrowboards:
              reportDataSource = reportData.arrowboards;
              break;
            case ReportType.SmartCables:
              reportDataSource = reportData.smartcables;
              break;
            case ReportType.SmartSolar:
              reportDataSource = reportData.smartsolar;
              break;
            default:
              reportDataSource = null;
          }

          try {
            // Get image map for Intellitags if needed
            let imageMap = new Map<string, HTMLImageElement>();
            if (reportType === ReportType.Intellitags && intellitagsRef.current?.getImageMap) {
              imageMap = await intellitagsRef.current.getImageMap();
            }

            // Use the unified image preparation function from base64Utils
            const {
              statusIcons,
              assets,
              imageMap: convertedImageMap,
            } = await prepareImagesForPdf(primaryLogo, primaryLogoWhite, coverBackground, imageMap);

            // Add statistics image if needed
            const statImgString =
              includeStatistics && reportType === ReportType.Intellitags ? statisticImg : undefined;
            const useIncludeStats = reportType === ReportType.Intellitags && includeStatistics;

            // Additional props specific to each report type
            const additionalProps = {
              // Common props for all report types
              statusIcons,
              assets,
              imageMap: convertedImageMap,

              // Intellitag specific props
              timeStamps:
                reportType === ReportType.Intellitags
                  ? reportData.intellitags?.intervals || []
                  : [],
              intervalInHours: Number.parseInt(intervalInHours) || 1,
              showSnoozed: true,
              statsImg: statImgString,
              includeStatistics: useIncludeStats,
            };

            if (reportDataSource) {
              const pdfData = preparePdfGenerationData(
                reportDataSource,
                reportType,
                baseProps,
                additionalProps
              );

              if (pdfData) {
                // Use the worker-based export for all types
                const result = await exportSinglePdf(pdfData, reportType);

                if (!result.success) {
                  notifications.show({
                    title: 'Error',
                    message: `Failed to export PDF report: ${result.error || 'Unknown error'}`,
                    color: 'dangerRed',
                  });
                }
              }
            } else {
              // Handle the case when reportDataSource is null or undefined
              console.error('No report data available for', reportType);
              setIsExporting(false);
              return;
            }
          } catch (error) {
            console.error('Error preparing PDF data:', error);
            notifications.show({
              title: 'Error',
              message: 'Failed to prepare PDF report data',
              color: 'dangerRed',
            });
          }
        } else {
          const props: CombinedPdfExportProps = {
            ...baseProps,
            reportTypes: types,
          };

          // For combined report, handle Intellitag image map if needed
          let imageMap = new Map<string, HTMLImageElement>();
          if (
            types.includes(ReportType.Intellitags) &&
            reportData.intellitags?.intervals &&
            reportData.intellitags?.rows &&
            intellitagsRef.current?.getImageMap
          ) {
            imageMap = await intellitagsRef.current.getImageMap();

            // Update props with Intellitag data
            props.intellitagProps = {
              timeStamps: reportData.intellitags.intervals,
              intervalInHours: Number.parseInt(intervalInHours) || 1,
              rows: reportData.intellitags.rows,
              showSnoozed: true,
              imageMap,
            };
          }

          // Prepare combined data for other report types
          const combinedData = prepareCombinedPdfData(types, {
            intellitags: reportData.intellitags,
            arrowboards: reportData.arrowboards as unknown as ArrowboardsReportDto,
            smartcables: reportData.smartcables,
            smartsolar: reportData.smartsolar,
          });

          // Assign data to props
          if (types.includes(ReportType.Arrowboards) && combinedData.arrowboardRows) {
            props.arrowboardProps = {
              rows: combinedData.arrowboardRows,
            };
          }

          if (types.includes(ReportType.SmartCables) && combinedData.smartCableRows) {
            props.smartCableProps = {
              rows: {
                rows: combinedData.smartCableRows,
              },
            };
          }

          if (types.includes(ReportType.SmartSolar) && combinedData.smartSolarRows) {
            props.smartSolarProps = {
              rows: {
                rows: combinedData.smartSolarRows,
              },
            };
          }

          // Get statistics image if needed
          const statsImg =
            includeStatistics && element ? await getStatImgFromHtml(element) : undefined;

          // Generate the PDF
          const result = await exportCombinedPdf(props, statsImg);

          if (!result.success) {
            console.warn('Worker-based PDF generation failed:', result.error);
            notifications.show({
              title: 'Error Generating PDF',
              message: `Failed to export PDF report: ${result.error || 'Unknown error'}. If this persists, please try with fewer report types.`,
              color: 'dangerRed',
              autoClose: false,
            });
          }
        }
      } catch (error) {
        console.error('Error exporting PDF:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to export PDF report',
          color: 'dangerRed',
        });
      } finally {
        setIsExporting(false);
        setPendingExport(null);
      }
    },
    [
      reportData,
      projectName,
      depotName,
      dateValue,
      startDate,
      endDate,
      exportContactPerson,
      countryCode,
      intervalInHours,
      customerName,
      includeStatistics,
      element,
      exportCombinedPdf,
      exportSinglePdf,
      statisticImg,
    ]
  );

  // Reset isExporting when status changes to idle or error
  useEffect(() => {
    if (status === 'idle' || status === 'error') {
      setIsExporting(false);
    }
  }, [status]);

  const exportPdf = useCallback(async () => {
    if (!pendingExport || !reportData) return;

    try {
      // Clear pendingExport before starting to prevent re-triggers
      const typesToExport = [...pendingExport];
      setPendingExport(null);
      await handlePdfExport(typesToExport);
    } catch (error) {
      console.error('Failed to export PDF:', error);
    }
  }, [pendingExport, reportData, handlePdfExport]);

  useEffect(() => {
    if (pendingExport) {
      exportPdf();
    }
  }, [pendingExport, exportPdf]);

  const fetchEmployees = async (query: string): Promise<Employee[]> => {
    return await getEmployees(instance, query);
  };

  function getStatImgFromHtml(element: HTMLElement | null): Promise<string> {
    if (!element) return Promise.resolve('');
    return htmlToImage.toPng(element);
  }

  const handleExport = (
    types: ReportType[],
    contactPerson: { name: string; email: string; phone: string },
    includeStats: boolean
  ) => {
    setIsExporting(true);
    setIsExportModalOpen(false);

    updateState({
      isGenerating: true,
      status: 'generating',
      error: null,
      progress: GENERATION_STEPS.INIT,
    });

    // Update includeStatistics state
    setIncludeStatistics(includeStats);

    const promise =
      includeStats && element
        ? getStatImgFromHtml(element).then((response) => {
            setStatisticsImg(response);
          })
        : Promise.resolve();

    promise.finally(() => {
      setExportContactPerson(contactPerson);
      setPendingExport(types);
      setFilterStat(initialFilter);
    });
  };

  const noData = (
    <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
      <IconAlertTriangle size={48} color="#FFC107" />
      <h3>No data available for the selected period</h3>
      <p>
        Please try selecting a different date range or check if the data for this project is
        available during the selected period.
      </p>
    </div>
  );

  const intellitagReport = (
    <IntellitagDashboard
      ref={intellitagsRef}
      intervalInHours={intervalInHours ? Number.parseInt(intervalInHours) : null}
      data={reportData.intellitags}
      reportStartDate={dateValue[0] || startDate}
      reportEndDate={dateValue[1] || endDate}
      projectId={projectId}
      projectName={projectName}
      depot={depotName}
      contactPerson={exportContactPerson}
      countryCode={countryCode}
      customerName={customerName}
      includeStatistics={includeStatistics}
      statImgString={statisticImg}
      snoozeDurations={snoozeDurations}
    />
  );

  const arrowboardReport = (
    <ArrowboardDashboard
      ref={arrowboardsRef}
      projectId={projectId}
      projectName={projectName}
      depot={depotName}
      reportStartDate={dateValue[0] || startDate}
      reportEndDate={dateValue[1] || endDate}
      data={reportData.arrowboards}
      contactPerson={exportContactPerson}
      countryCode={countryCode}
      customerName={customerName}
    />
  );

  const smartCableReport = (
    <SmartCableDashboard
      ref={smartcablesRef}
      intervalInHours={intervalInHours ? Number.parseInt(intervalInHours) : null}
      data={reportData.smartcables}
      reportStartDate={dateValue[0] || startDate}
      reportEndDate={dateValue[1] || endDate}
      projectId={projectId}
      projectName={projectName}
      contactPerson={exportContactPerson}
      depot={depotName}
      countryCode={countryCode}
      customerName={customerName}
      snoozeDurations={snoozeDurations}
    />
  );

  const smartSolarReport = (
    <SmartSolarDashboard
      ref={smartSolarRef}
      intervalInHours={intervalInHours ? Number.parseInt(intervalInHours) : null}
      data={reportData.smartsolar}
      reportStartDate={dateValue[0] || startDate}
      reportEndDate={dateValue[1] || endDate}
      projectId={projectId}
      projectName={projectName}
      depot={depotName}
      contactPerson={exportContactPerson}
      countryCode={countryCode}
      customerName={customerName}
      snoozeDurations={snoozeDurations}
    />
  );

  // Check if there is any data to display
  const hasData =
    !!reportData.intellitags?.rows.length ||
    !!reportData.arrowboards?.length ||
    !!reportData.smartcables?.rows.length ||
    !!reportData.smartsolar?.rows.length;

  return (
    <>
      <Group>
        <FlexSpacer />
        <Button
          variant="primary"
          icon={IconDownload}
          loading={isExporting}
          onClick={() => {
            resetAccordionsForModal();
            setIsExportModalOpen(true);
          }}
          disabled={!hasData}
        >
          Export report
        </Button>
      </Group>

      <Stack>
        {!hasData ? (
          noData
        ) : (
          <Accordion
            multiple
            value={activeAccordions}
            onChange={setActiveAccordions}
            transitionDuration={500}
          >
            {!!reportData.intellitags?.rows.length && (
              <Accordion.Item value="intellitag-statistics">
                <Accordion.Control icon={<IconChartBar />}>
                  <Group gap={'xs'}>
                    Intellitag Statistics
                    <Badge variant="transparent" c={'blue'}>
                      Beta
                    </Badge>
                  </Group>
                </Accordion.Control>
                <Accordion.Panel py="sm">
                  <StatisticsDashboard
                    startDate={dateValue[0] || startDate}
                    endDate={dateValue[1] || endDate}
                    deviceRows={reportData.intellitags?.rows ?? []}
                    filterStats={filterStat}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            )}

            {!!reportData.intellitags?.rows.length && (
              <Accordion.Item value="intellitag-dashboard">
                <Accordion.Control icon={<IconRouter style={{ transform: 'rotate(-90deg)' }} />}>
                  Intellitag Dashboard
                </Accordion.Control>
                <Accordion.Panel py="sm">{intellitagReport}</Accordion.Panel>
              </Accordion.Item>
            )}

            {!!reportData.arrowboards?.length && (
              <Accordion.Item value="arrowboard-dashboard">
                <Accordion.Control icon={<IconArrowAutofitContent />}>
                  Arrowboard Dashboard
                </Accordion.Control>
                <Accordion.Panel py="sm">{arrowboardReport}</Accordion.Panel>
              </Accordion.Item>
            )}

            {!!reportData.smartcables?.rows.length && (
              <Accordion.Item value="smartcable-dashboard">
                <Accordion.Control
                  icon={<IconPlugConnected style={{ transform: 'rotate(-90deg)' }} />}
                >
                  Smart Cable Dashboard
                </Accordion.Control>
                <Accordion.Panel py="sm">{smartCableReport}</Accordion.Panel>
              </Accordion.Item>
            )}

            {!!reportData.smartsolar?.rows.length && (
              <Accordion.Item value="smartsolar-dashboard">
                <Accordion.Control icon={<IconSolarPanel />}>
                  Smart Solar Dashboard
                </Accordion.Control>
                <Accordion.Panel py="sm">{smartSolarReport}</Accordion.Panel>
              </Accordion.Item>
            )}
          </Accordion>
        )}
      </Stack>

      <ExportReportModal
        isOpen={isExportModalOpen}
        close={(reset) => {
          if (reset) setFilterStat(initialFilter);
          setIsExportModalOpen(false);
        }}
        onExport={handleExport}
        onStatisticsToggle={(statFilter) => {
          const includeStats = Object.values(statFilter).some((section) => section);
          setIncludeStatistics(includeStats);
          if (includeStats) setFilterStat(statFilter);
        }}
        hasIntellitagsData={!!reportData.intellitags?.rows.length}
        hasArrowboardsData={!!reportData.arrowboards?.length}
        hasSmartCablesData={!!reportData.smartcables?.rows.length}
        hasSmartSolarData={!!reportData.smartsolar?.rows.length}
        projectContactPerson={projectContactPerson}
        fetchEmployees={fetchEmployees}
      />
    </>
  );
}
