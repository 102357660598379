import type { PdfWorkerApi } from '@/models/WorkerTypes';
import { wrap } from 'comlink';

interface GenerationState {
  isGenerating: boolean;
  cancellationRequested: boolean;
}

const createWorkerManager = () => {
  let worker: Worker | null = null;
  let workerApi: PdfWorkerApi | null = null;
  const generationState: GenerationState = {
    isGenerating: false,
    cancellationRequested: false,
  };

  const initWorker = (): PdfWorkerApi => {
    // Don't initialize if cancellation is in progress
    if (generationState.cancellationRequested) {
      throw new Error('Cannot start PDF generation: cancellation in progress');
    }

    // Always create a new worker
    worker = new Worker(new URL('../../workers/pdf/pdfWorker.ts', import.meta.url), {
      type: 'module',
    });
    workerApi = wrap<PdfWorkerApi>(worker);
    return workerApi;
  };

  const requestCancellation = async (): Promise<void> => {
    generationState.cancellationRequested = true;
    generationState.isGenerating = false;

    if (worker) {
      worker.terminate();
      worker = null;
      workerApi = null;
    }
  };

  const cleanup = (): void => {
    generationState.isGenerating = false;
    generationState.cancellationRequested = false;

    if (worker) {
      worker.terminate();
      worker = null;
      workerApi = null;
    }
  };

  return {
    initWorker,
    cleanup,
    isGenerating: () => generationState.isGenerating,
    requestCancellation,
    isCancellationRequested: () => generationState.cancellationRequested,
    resetCancellationState: () => {
      generationState.cancellationRequested = false;
    },
    setGenerating: (status: boolean) => {
      generationState.isGenerating = status;
    },
  };
};

// Singleton instance
const workerManager = createWorkerManager();
export { workerManager };
