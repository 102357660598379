import { Group, Skeleton, Stack, Title, Input, Box } from '@mantine/core';
import { DeviceListItemLight } from './DeviceListItem/DeviceListItemLight';
import { DeviceListItem } from './DeviceListItem/DeviceListItem';
import { Device, DeviceType, DeviceTypeNames } from '../../models/Device';
import { useNavigate } from 'react-router-dom';
import { useRef, useEffect, useState, useCallback } from 'react';
import { IconBuildingBroadcastTower, IconSearch } from '@tabler/icons-react';
import {
  AlarmStatus,
  AlarmStatusNames,
  BatteryStatus,
  BatteryStatusNames,
} from '@/models/enums/DeviceEnums';
import { EmptyState } from '@/shared/EmptyState';
import { useGenericFilter } from '../../hooks/useGenericFilters';
import { FilterBase, FilterButton, FilterState } from '@/shared/FilterButton';

type StatusFilterValue = `alarm_${AlarmStatus}` | `battery_${BatteryStatus}`;
type DeviceFilterValue = StatusFilterValue | DeviceType;

interface DeviceFilters extends FilterState<DeviceFilterValue> {
  statusGroup: StatusFilterValue[];
  deviceType: DeviceType[];
}

interface DeviceFilterBase extends FilterBase<Device, DeviceFilterValue> {
  statusGroup: {
    name: string;
    options: {
      label: string;
      value: StatusFilterValue;
      checked: boolean;
    }[];
  };
  deviceType: {
    name: string;
    options: {
      label: string;
      value: DeviceType;
      checked: boolean;
    }[];
  };
}

type Props = {
  devices: Device[];
  light: boolean;
  projectId?: string;
  closedProject?: boolean;
  isSnoozed: boolean;
  countryCode: string;
  minWidth?: string;
  bg?: string;
  padding?: string;
  margin?: string;
  selectedDevice?: string | null;
  loading?: boolean;
  showFilters?: boolean;
  timeTravelActive?: boolean;
  setSelectedDevice: (deviceId: string) => void;
  onDeviceHover?: (deviceId: string | null) => void;
};

export default function DeviceList({
  devices,
  light,
  isSnoozed,
  countryCode,
  minWidth,
  bg,
  padding,
  margin,
  selectedDevice,
  closedProject,
  loading = false,
  showFilters = false,
  timeTravelActive = false,
  setSelectedDevice,
  onDeviceHover,
}: Props) {
  const navigate = useNavigate();
  const deviceRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const [searchString, setSearchString] = useState<string>('');

  // Filter state setup
  const [filters, setFilters] = useState<DeviceFilters>({
    statusGroup: [],
    deviceType: [],
  });

  // Define filter groups for FilterButton component
  const getFilterGroups = useCallback(
    (currentFilters: FilterState<DeviceFilterValue>): DeviceFilterBase => {
      const typedFilters = currentFilters as DeviceFilters;

      return {
        statusGroup: {
          name: 'Status',
          options: [
            {
              label: AlarmStatusNames[AlarmStatus.Alarming],
              value: `alarm_${AlarmStatus.Alarming}`,
              checked: typedFilters.statusGroup?.includes(`alarm_${AlarmStatus.Alarming}`) || false,
            },
            {
              label: BatteryStatusNames[BatteryStatus.LOW],
              value: `battery_${BatteryStatus.LOW}`,
              checked: typedFilters.statusGroup?.includes(`battery_${BatteryStatus.LOW}`) || false,
            },
            {
              label: BatteryStatusNames[BatteryStatus.CRITICAL],
              value: `battery_${BatteryStatus.CRITICAL}`,
              checked:
                typedFilters.statusGroup?.includes(`battery_${BatteryStatus.CRITICAL}`) || false,
            },
            {
              label: BatteryStatusNames[BatteryStatus.OUT_OF_BATTERY],
              value: `battery_${BatteryStatus.OUT_OF_BATTERY}`,
              checked:
                typedFilters.statusGroup?.includes(`battery_${BatteryStatus.OUT_OF_BATTERY}`) ||
                false,
            },
          ],
        },
        deviceType: {
          name: 'Device Type',
          options: [
            {
              label: DeviceTypeNames[DeviceType.IntellitagV1],
              value: DeviceType.IntellitagV1,
              checked: typedFilters.deviceType?.includes(DeviceType.IntellitagV1) || false,
            },
            {
              label: DeviceTypeNames[DeviceType.SmartCableV1],
              value: DeviceType.SmartCableV1,
              checked: typedFilters.deviceType?.includes(DeviceType.SmartCableV1) || false,
            },
            {
              label: DeviceTypeNames[DeviceType.SmartSolarV1],
              value: DeviceType.SmartSolarV1,
              checked: typedFilters.deviceType?.includes(DeviceType.SmartSolarV1) || false,
            },
            {
              label: DeviceTypeNames[DeviceType.ArrowBoardV1],
              value: DeviceType.ArrowBoardV1,
              checked: typedFilters.deviceType?.includes(DeviceType.ArrowBoardV1) || false,
            },
          ],
        },
      };
    },
    []
  );

  const filteredData = useGenericFilter({
    items: devices,
    filterGroups: [
      {
        criteria: {
          alarmStatus: (status) =>
            !filters.statusGroup.length || filters.statusGroup.includes(`alarm_${status}`),
          batteryStatus: (batteryStatus) =>
            !filters.statusGroup.length || filters.statusGroup.includes(`battery_${batteryStatus}`),
        },
        filterActive: !!filters.statusGroup.length,
      },
      {
        criteria: {
          deviceType: (type) => !filters.deviceType.length || filters.deviceType.includes(type),
        },
        filterActive: !!filters.deviceType.length,
      },
    ],
  });

  useEffect(() => {
    if (selectedDevice && deviceRefs.current[selectedDevice]) {
      deviceRefs.current[selectedDevice]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedDevice]);

  const filteredDevices = filteredData.filter((device) =>
    [device.currentName, device.referenceId].some((field) =>
      field?.toLowerCase().includes(searchString.toLowerCase())
    )
  );

  const projectDevices = filteredDevices.map((device, index) =>
    light ? (
      <div ref={(el) => (deviceRefs.current[device.id] = el)} key={`device-${index}`}>
        <DeviceListItemLight
          device={device}
          isSnoozed={isSnoozed}
          countryCode={countryCode}
          onClick={() => {
            navigate(`/devices/edit/${device.id}`);
          }}
        />
      </div>
    ) : (
      <div ref={(el) => (deviceRefs.current[device.id] = el)} key={`device-${index}`}>
        <DeviceListItem
          device={device}
          isSnoozed={isSnoozed}
          countryCode={countryCode}
          selected={device.id === selectedDevice}
          setSelectedDevice={setSelectedDevice}
          onNavigateToDevice={(deviceId) => navigate('/devices/edit/' + deviceId)}
          onHoverChange={onDeviceHover}
        />
      </div>
    )
  );

  const noDevicesEmptyState = (
    <EmptyState
      icon={<IconBuildingBroadcastTower />}
      title="No devices added"
      subtitle={
        closedProject
          ? 'Cannot assign devices since project is closed'
          : 'Use the PWA to start assigning devices'
      }
    />
  );

  const timeTravelEmptyState = (
    <EmptyState
      icon={<IconSearch />}
      title="No devices to show"
      subtitle=" Please choose another date or filter"
    />
  );

  const hasActiveFilters = filters.statusGroup.length > 0 || filters.deviceType.length > 0;

  return (
    <Stack bg={bg} m={margin} miw={minWidth} h="100%" p={0}>
      <Box
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          backgroundColor: bg || 'white',
        }}
      >
        <Stack gap="sm" p={padding}>
          <Title fw={400} order={5}>
            Devices
          </Title>

          {showFilters && (
            <Group gap="sm" wrap="nowrap">
              <Input
                w="100%"
                placeholder="Search device"
                value={searchString}
                onChange={(event) => setSearchString(event.currentTarget.value)}
              />
              <FilterButton<Device, DeviceFilterValue>
                filters={filters}
                setFilters={(newFilters: FilterState<DeviceFilterValue>) => {
                  setFilters(newFilters as DeviceFilters);
                }}
                getFilterGroups={getFilterGroups}
              />
            </Group>
          )}
        </Stack>
      </Box>

      <Stack style={{ flex: 1, overflow: 'auto' }} p={padding}>
        {loading ? (
          <>
            {Array(6)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={`skeleton-${i}`} height={100} width="100%" radius="xs" />
              ))}
          </>
        ) : projectDevices?.length ? (
          projectDevices
        ) : timeTravelActive || hasActiveFilters ? (
          timeTravelEmptyState
        ) : (
          noDevicesEmptyState
        )}
      </Stack>
    </Stack>
  );
}
