import { Stack } from '@mantine/core';
import { getPSAImage } from '@/utils/ImageHelpers';
import { PsaDeviceModel } from '@/models/Device';

type Props = {
  deviceModel: PsaDeviceModel;
};

const PSA_ASPECT_RATIO = 71 / 43;
const PSA_MAX_WIDTH = 30;
const PSA_MAX_HEIGHT = PSA_MAX_WIDTH * PSA_ASPECT_RATIO;

export const PortableSiteAlarmMarker = ({ deviceModel }: Props) => {
  const psa = (
    <img
      src={getPSAImage(deviceModel)}
      alt=""
      style={{
        zIndex: '5',
        maxWidth: `${PSA_MAX_WIDTH}px`,
        maxHeight: `${PSA_MAX_HEIGHT}px`,
        width: 'auto',
        height: 'auto',
      }}
    />
  );
  return (
    <>
      <Stack gap={0} align="center">
        {psa}
      </Stack>
    </>
  );
};
