import {
  Paper,
  Title,
  Table,
  Badge,
  Group,
  Collapse,
  Text,
  ActionIcon,
  Tooltip,
  Progress,
  Stack,
} from '@mantine/core';
import { ProvisionData } from '@/models/ProvisionData';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import {
  IconAlertCircle,
  IconChevronUp,
  IconChevronDown,
  IconCopy,
  IconCheck,
} from '@tabler/icons-react';
import { DeviceModelNames, DeviceTypeNames } from '@/models/Device';
import { FlexSpacer } from '@/shared/FlexSpacer';
import { LabelValueText } from '@/shared/LabelValueText';
import dayjs from 'dayjs';

interface ProvisionTableProps {
  provision: ProvisionData;
}

export const ProvisionTable = ({ provision }: ProvisionTableProps) => {
  const hasConnectedCount = provision.provisions.filter((device) => device.hasConnected).length;
  const hasNotConnectedCount = provision.provisions.length - hasConnectedCount;
  const [opened, { toggle }] = useDisclosure(false);
  const tableHeader = ['IMEI', 'Device type', 'Model', 'Reference Id', 'Connected'];
  const MORE_THAN_3_DAYS = 3;

  // Sort devices with hasConnected false at the top
  const sortedDevices = [...provision.provisions].sort((a, b) => {
    if (a.hasConnected === b.hasConnected) return 0;
    return a.hasConnected ? 1 : -1;
  });

  const date = new Date(provision.createdAt);
  const today = dayjs(new Date());

  // Option 1: Using Intl.DateTimeFormat
  const formatter = new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
  const formattedDate = formatter.format(date);

  const clipboard = useClipboard({ timeout: 1000 });

  const alertNotConnected = hasNotConnectedCount && today.diff(dayjs(date), 'd') > MORE_THAN_3_DAYS;
  return (
    <Paper p="md">
      <Group onClick={toggle} style={{ cursor: 'pointer' }}>
        <Stack gap={4}>
          <Title order={5}>{provision.name}</Title>
          {provision.comment && (
            <Text size="sm" c="dimmed" style={{ wordBreak: 'break-word' }}>
              {provision.comment}
            </Text>
          )}
        </Stack>
        <FlexSpacer />
        <Text mr="lg" size="sm">
          {formattedDate}
        </Text>
        <div style={{ width: '20%' }}>
          <Tooltip
            disabled={!provision.provisions.length}
            label={
              hasNotConnectedCount
                ? `${hasNotConnectedCount}/${provision.provisions.length} not connected`
                : `All ${hasConnectedCount} connected`
            }
          >
            <Group wrap="nowrap" gap="xs">
              {hasConnectedCount === provision.provisions.length &&
              provision.provisions.length > 0 ? (
                <IconCheck color="green" size={18} />
              ) : (
                <IconAlertCircle color={alertNotConnected ? 'red' : 'transparent'} size={18} />
              )}
              {!provision.provisions.length ? (
                <Text fw={500} size="xs">
                  NO DEVICES
                </Text>
              ) : (
                <Progress
                  w="100%"
                  color={
                    hasConnectedCount / provision.provisions.length < 1
                      ? 'var(--mantine-color-linkBlue-1)'
                      : 'green'
                  }
                  value={(hasConnectedCount / provision.provisions.length) * 100}
                />
              )}
            </Group>
          </Tooltip>
        </div>

        <ActionIcon c="black" variant="transparent">
          {opened ? <IconChevronUp /> : <IconChevronDown />}
        </ActionIcon>
      </Group>
      <Collapse in={opened} transitionDuration={300}>
        <Group mt="md" gap="xl">
          <LabelValueText
            direction="column"
            label="Created by"
            value={provision.createdBy}
            fwLabel={400}
          />
          <LabelValueText
            direction="column"
            label="Status"
            value={`${hasConnectedCount}/${provision.provisions.length} has connected`}
            fwLabel={400}
          />
        </Group>
        {sortedDevices.length > 0 ? (
          <Table highlightOnHover bg={'white'}>
            <Table.Thead>
              <Table.Tr>
                {tableHeader.map((th) => (
                  <Table.Th key={th}>{th}</Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {sortedDevices.map((device) => {
                return (
                  <Table.Tr key={device.id}>
                    <Table.Td>
                      <Group gap="xs" align="center">
                        <Tooltip disabled={!clipboard.copied} label="Copied!">
                          <ActionIcon
                            size="sm"
                            variant="transparent"
                            onClick={() => clipboard.copy(device.referenceId)}
                          >
                            <IconCopy />
                          </ActionIcon>
                        </Tooltip>
                        {device.referenceId}
                      </Group>
                    </Table.Td>
                    <Table.Td>{DeviceTypeNames[device.deviceType]}</Table.Td>
                    <Table.Td>{DeviceModelNames[device.modelRef]}</Table.Td>
                    <Table.Td>{device.imei}</Table.Td>
                    <Table.Td>
                      {device.hasConnected ? (
                        <Badge variant="dot" color="green">
                          Connected
                        </Badge>
                      ) : (
                        <Badge variant="outline" color="red">
                          Not Connected
                        </Badge>
                      )}
                    </Table.Td>
                  </Table.Tr>
                );
              })}
            </Table.Tbody>
          </Table>
        ) : (
          <Text>No devices available.</Text>
        )}
      </Collapse>
    </Paper>
  );
};
