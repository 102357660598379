import { createContext } from 'react';
import type { PdfGenerationData, PdfGenerationResult, ProgressUpdate } from '@/models/PdfExport';

export type PdfGenerationStatus = 'idle' | 'generating' | 'completed' | 'error';

export interface PdfWorkerContextType {
  generatePdf: (data: PdfGenerationData) => Promise<PdfGenerationResult>;
  isGenerating: boolean;
  status: PdfGenerationStatus;
  error: string | null;
  progress: ProgressUpdate | null;
  updateState: (updates: Partial<PdfState>) => void;
  cancelRequested: boolean;
  cancelGeneration: () => void;
}

export interface PdfState {
  status: PdfGenerationStatus;
  error: string | null;
  progress: ProgressUpdate | null;
  isGenerating: boolean;
  cancelRequested: boolean;
}

export const initialState: PdfState = {
  status: 'idle',
  error: null,
  progress: null,
  isGenerating: false,
  cancelRequested: false,
};

export const PdfWorkerContext = createContext<PdfWorkerContextType | null>(null);
