import '@mantine/core/styles.css';
import {
  Stack,
  Title,
  Loader,
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  Table,
  TextInput,
  rem,
  Text,
  Box,
} from '@mantine/core';
import { useMsal } from '@azure/msal-react';
import { IconArrowRight, IconSearch } from '@tabler/icons-react';
import { StatusBadge, BatteryBadge } from '../components/Badge';
import { BatteryStatus } from '../models/enums/DeviceEnums';
import { formatDateTimeString } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import useInfDeviceList from '../data/hooks/Device/useInfDeviceListHook';
import { NoDevicesFound } from '../data/hooks/Device/NoDevicesFound/NoDevicesFound';
import SortableHeader from '../components/Table/SortableHeader';
import { DeviceTypeNames } from '../models/Device';

const columnWidths: Record<string, string> = {
  deviceType: '10%',
  deviceName: '30%',
  referenceId: '10%',
  workZone: '10%',
  status: '10%',
  battery: '15%',
  lastUpdate: '10%',
  actions: '5%',
};

export default function DevicesPage() {
  const navigate = useNavigate();
  const crumbs = [
    { title: 'All projects', href: '/projects' },
    { title: 'Devices', href: '/devices' },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  const { instance } = useMsal();
  const {
    devices,
    isLoading: loading,
    search,
    handleSearchChange,
    totalCount,
    sortColumn,
    sortDirection,
    handleSort,
  } = useInfDeviceList(instance);

  const rows = devices.map((device) => (
    <Table.Tr key={device.id}>
      <Table.Td w={columnWidths.deviceType}>{DeviceTypeNames[device.deviceType]}</Table.Td>
      <Table.Td w={columnWidths.deviceName}>{device.currentName}</Table.Td>
      <Table.Td w={columnWidths.referenceId}>{device.referenceId}</Table.Td>
      <Table.Td w={columnWidths.workZone}>{device.workZoneName}</Table.Td>
      <Table.Td w={columnWidths.status}>
        <StatusBadge status={device.alarmStatus} type={device.alarmType} size="md" />
      </Table.Td>
      <Table.Td w={columnWidths.battery}>
        <Group>
          {device.batteryVoltage}
          <BatteryBadge
            batteryStatus={device.batteryStatus === BatteryStatus.OK ? null : device.batteryStatus}
            size="md"
          />
        </Group>
      </Table.Td>
      <Table.Td w={columnWidths.lastUpdate}>{formatDateTimeString(device.latestUpdate)}</Table.Td>
      <Table.Td w={columnWidths.actions} ta="right">
        <Button
          variant="transparent"
          rightSection={<IconArrowRight />}
          onClick={() => navigate(`/devices/edit/${device.id}`)}
        >
          View
        </Button>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Box>
      <Stack p="xl">
        <Breadcrumbs separator=">" mt="sm">
          {crumbs}
        </Breadcrumbs>

        <Title order={3}>Devices</Title>

        <Group align="top" mb="sm">
          <TextInput
            placeholder="Search by any field"
            mb="md"
            w={450}
            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
          />
          <Text c="dimmed" size="sm" mt={8}>
            Showing {devices.length} of {totalCount} devices
          </Text>
        </Group>
        <Table layout="fixed" w="100%">
          <Table.Thead>
            <Table.Tr>
              <Table.Th w={columnWidths.deviceType}>Device Type</Table.Th>
              <Table.Th w={columnWidths.deviceName}>Additional info</Table.Th>
              <Table.Th w={columnWidths.referenceId}>
                <SortableHeader
                  title="Reference Id"
                  columnName="referenceId"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                />
              </Table.Th>
              <Table.Th w={columnWidths.workZone}>
                <SortableHeader
                  title="Project"
                  columnName="project"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                />
              </Table.Th>
              <Table.Th w={columnWidths.status}>
                <SortableHeader
                  title="Status"
                  columnName="status"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                />
              </Table.Th>
              <Table.Th w={columnWidths.battery}>
                <SortableHeader
                  title="Battery Health"
                  columnName="batteryHealth"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                />
              </Table.Th>
              <Table.Th w={columnWidths.lastUpdate}>
                <SortableHeader
                  title="Latest Update"
                  columnName="latestUpdate"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                />
              </Table.Th>
              <Table.Th w={columnWidths.actions} />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows}
            {loading && (
              <Table.Tr>
                <Table.Td colSpan={8}>
                  <Group justify="center" mt="xl">
                    <Loader />
                  </Group>
                </Table.Td>
              </Table.Tr>
            )}
            {rows.length === 0 && !loading && (
              <Table.Tr>
                <Table.Td colSpan={8}>
                  <NoDevicesFound />
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </Stack>
    </Box>
  );
}
