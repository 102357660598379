import { useContext } from 'react';
import { PdfWorkerContext, type PdfWorkerContextType } from './types';

export const usePdfWorker = (): PdfWorkerContextType => {
  const context = useContext(PdfWorkerContext);
  if (!context) {
    throw new Error('usePdfWorker must be used within a PdfWorkerProvider');
  }
  return context;
};
