import { CreateProjectFlowFormValues } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { showErrorNotification } from '@/data/errorHandler';
import useSearchDeviceNew from '@/data/hooks/Device/useSearchDevicesNew';
import { Device, DeviceType, PsaDeviceModel } from '@/models/Device';
import { FlexSpacer } from '@/shared/FlexSpacer';
import { noopFilter } from '@/utils/form';
import { getPSAImage } from '@/utils/ImageHelpers';
import { useMsal } from '@azure/msal-react';
import {
  ActionIcon,
  Group,
  Image,
  InputWrapper,
  Select,
  SelectProps,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconExclamationCircle, IconTrash } from '@tabler/icons-react';
import { useCallback, useRef, useState } from 'react';

const FORM_KEY = 'psaSetupStep' as const satisfies keyof CreateProjectFlowFormValues;

export const ManualSetupSection = ({
  form,
}: {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
}) => {
  const hasScrolledIntoView = useRef(false);

  const assignedPsas = form.getValues()[FORM_KEY]!.assignedPsas;

  return (
    <Stack
      gap="md"
      ref={(node) => {
        if (hasScrolledIntoView.current) return;
        node?.scrollIntoView({ behavior: 'smooth' });
        hasScrolledIntoView.current = true;
      }}
      styles={{ root: { minHeight: '50dvh' } }}
      align="start"
    >
      <PsaSelect form={form} />

      {/* SELECTED DEVICES */}
      <Stack align="start">
        {assignedPsas
          ?.map((psa, index) => (
            <Group key={psa.referenceId} w={'100%'}>
              <Group
                py={'sm'}
                px="md"
                gap="md"
                bg={'var(--mantine-color-body)'}
                style={{ borderRadius: 4, flexGrow: 1 }}
              >
                <Image src={getPSAImage(psa.deviceModel as PsaDeviceModel)} alt="" w={18} />
                <Text fw={600}>{psa.referenceId}</Text>
              </Group>
              <ActionIcon
                type="button"
                size="input-md"
                variant="subtle"
                onClick={() => {
                  form.removeListItem(`${FORM_KEY}.assignedPsas`, index);
                }}
              >
                <IconTrash color="var(--mantine-color-greyText-5)" />
              </ActionIcon>
            </Group>
          ))
          .reverse()}
      </Stack>
    </Stack>
  );
};

const PsaSelect = ({ form }: { form: UseFormReturnType<CreateProjectFlowFormValues> }) => {
  const { instance } = useMsal();
  const [searchString, setSearchString] = useState('');
  const { devices, isFetching, isDebounced } = useSearchDeviceNew({
    instance,
    deviceType: DeviceType.PSAV1,
    unassignedOnly: false,
    searchTerm: searchString,
    excludedDeviceIds: form.getValues()[FORM_KEY]!.assignedPsas?.map((psa) => psa.id),
  });

  const handleTryAssignNewPsa = useCallback(
    (value: Device) => {
      setSearchString('');

      const assignedPsas = form.getValues()[FORM_KEY]!.assignedPsas;
      if (assignedPsas?.some((psa) => psa.id === value.id)) {
        showErrorNotification('Error adding PSA', 'PSA is already assigned to this project');
        return;
      }
      if (value.workZoneId) {
        showErrorNotification(
          'Error adding PSA',
          `PSA already assigned to a project. Please unassign it from its current project before adding it to this one.`
        );
        return;
      }
      form.insertListItem(`${FORM_KEY}.assignedPsas`, {
        id: value.id,
        referenceId: value.referenceId,
        deviceModel: value.deviceModel,
      });
    },
    [form]
  );

  return (
    <>
      {/* Mini device form */}
      <Group align="flex-end">
        <InputWrapper
          label="PSA Reference ID"
          description="Example: PSA 23-1234"
          styles={{ label: { fontWeight: 600, marginBottom: 4 }, description: { marginBottom: 8 } }}
        >
          <Select
            ta="left"
            flex={1}
            size="md"
            w={250}
            value={null}
            // TODO: verify if we should filter out PSAs already picked
            // TODO: verify that showing but disabling devices assigned to other projects is the correct behavior
            data={
              searchString
                ? devices.map((d) => ({
                    value: d.id,
                    label: d.referenceId,
                    disabled: !!d.workZoneId,
                    device: d,
                  }))
                : []
            }
            searchable
            searchValue={searchString}
            onSearchChange={(value) => {
              setSearchString(value);
            }}
            onChange={(_value, options) => {
              if (options && 'device' in options) {
                handleTryAssignNewPsa(options.device as Device);
              }
            }}
            onClear={() => {
              setSearchString('');
            }}
            clearable
            // rightSection={isFetching && <Loader size={18} />}
            placeholder="Enter reference ID"
            nothingFoundMessage={
              searchString.trim().length > 1 && !isFetching && !isDebounced
                ? 'No device with that reference ID found...'
                : undefined
            }
            renderOption={renderSelectOption}
            filter={noopFilter}
            maxDropdownHeight={300}
          />
        </InputWrapper>
      </Group>
    </>
  );
};

// @ts-expect-error - cannot override renderOption type, so we need to cast it
const renderSelectOption: SelectProps['renderOption'] = ({
  option,
}: {
  option: { label: string; device: Device; disabled: boolean };
  checked: boolean;
}) => {
  return (
    <Tooltip
      disabled={!option.device.workZoneId}
      label={
        <>
          This device is already assigned to another project and cannot be added to this one.
          <br />
          Please unassign the device from it's current project before selecting.
        </>
      }
    >
      <Group flex="1" gap="md" align="center" mih={'30px'}>
        <Image src={getPSAImage(option.device.deviceModel as PsaDeviceModel)} alt="" w={18} />
        <Text>{option.label}</Text>
        <FlexSpacer />
        {!!option.device.workZoneId && <IconExclamationCircle color="orange" />}
      </Group>
    </Tooltip>
  );
};
