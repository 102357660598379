import { IPublicClientApplication } from '@azure/msal-browser';
import { useDebouncedValue } from '@mantine/hooks';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Device, DeviceType } from '../../../models/Device';
import { APIResponseList, prepareHeaders } from '../../api/apiHelpers';

const path = `${import.meta.env.VITE_API_BASE_PATH}/device`;

interface Params {
  deviceType?: DeviceType;
  unassignedOnly?: boolean;
  searchTerm?: string;
  excludedDeviceIds?: string[];
}

/**
 * Uses /device endpoint, not /device/paginated. Results are however still paginated
 * @param instance
 * @returns
 */
const useSearchDeviceNew = ({
  instance,
  searchTerm,
  deviceType,
  unassignedOnly,
  excludedDeviceIds,
}: {
  instance: IPublicClientApplication;
} & Params) => {
  const pageSize = 5;
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500, { leading: true });

  const queryString = useMemo(() => {
    const params = new URLSearchParams();
    debouncedSearchTerm && params.set('searchTerm', debouncedSearchTerm);
    pageSize && params.set('pageSize', pageSize.toString());
    deviceType && params.set('deviceType', deviceType.toString());
    unassignedOnly && params.set('unassignedOnly', unassignedOnly.toString());
    excludedDeviceIds?.forEach((id) => params.append('excludedDeviceIds', id));

    return params.toString();
  }, [debouncedSearchTerm, deviceType, unassignedOnly, excludedDeviceIds]);

  const { data, isLoading, isFetching, isError } = useQuery<APIResponseList<Device>>({
    queryKey: ['devices', 'paginated', 'search', 'v2', queryString],
    queryFn: async () => {
      const response = await fetch(`${path}?${queryString}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return {
        items: data.data,
        pageSize: data.pageSize,
        pageNumber: data.pageNumber,
        totalCount: data.totalCount,
      };
    },
    placeholderData: keepPreviousData,
    enabled: !!debouncedSearchTerm,
  });

  return {
    devices: data?.items ?? [],
    isFetching,
    isLoading,
    isError,
    isDebounced: debouncedSearchTerm !== searchTerm,
  };
};

export default useSearchDeviceNew;
