import { ReportType } from '@/models/enums/ReportType';
import { PdfBaseProps, PdfGenerationData, StatusIcons } from '@/models/PdfExport';
import {
  ArrowboardRow,
  ArrowboardsReportDto,
  BatteryMonitorRow,
  DeviceRow,
  SupervisionReportDto,
  BatteryMonitorReportDto,
} from '@/models/SupervisionReport';
import { EquipmentType } from '@/models/enums/DeviceEnums';
import { WorkerImageMap } from '../models/WorkerTypes';
import { PdfAssets } from '../constants/pdfConstants';

/**
 * Interface for additional PDF generation properties
 */
export interface PdfAdditionalProps {
  intervalInHours?: number;
  showSnoozed?: boolean;
  imageMap?: WorkerImageMap;
  assets?: PdfAssets;
  statusIcons?: StatusIcons;
  statsImg?: string;
  includeStatistics?: boolean;
}

/**
 * Converts BatteryMonitorRows to DeviceRows for alarm events processing
 */
export const convertToDeviceRows = (rows: BatteryMonitorRow[]): DeviceRow[] => {
  return rows.map((row) => {
    const equipmentType: EquipmentType = EquipmentType.Sign; // Default

    return {
      deviceId: row.deviceId,
      name: row.name,
      referenceId: row.referenceId,
      attachmentRef: row.attachmentRef,
      currentEquipmentType: equipmentType,
      entries: Array.isArray(row.entries) ? row.entries : [],
      projectId: row.projectId ?? '',
    };
  });
};

/**
 * Prepares data for PDF generation for any report type
 */
export const preparePdfGenerationData = (
  reportData: SupervisionReportDto | ArrowboardsReportDto | BatteryMonitorReportDto,
  reportType: ReportType,
  props: PdfBaseProps,
  additionalProps: PdfAdditionalProps = {}
): PdfGenerationData | null => {
  // Check if required properties exist
  if (!additionalProps.assets || !additionalProps.statusIcons) {
    console.warn('Missing required assets or statusIcons in PDF generation');
    return null;
  }

  // Helper function to create partial PdfGenerationData with common properties
  const createPartialData = (): Omit<PdfGenerationData, 'statusIcons'> => ({
    ...props,
    reportType,
    reportTypes: [reportType],
    intervalInHours: additionalProps.intervalInHours || 1,
    showSnoozed: additionalProps.showSnoozed ?? true,
    imageMap: additionalProps.imageMap || {},
  });

  // Process each report type with its specific data structure
  switch (reportType) {
    case ReportType.Intellitags: {
      const intellitagData = reportData as SupervisionReportDto;

      if (
        intellitagData &&
        intellitagData.rows?.length &&
        intellitagData.intervals?.length &&
        intellitagData.rows?.length > 0 &&
        intellitagData.intervals?.length > 0
      ) {
        return {
          ...createPartialData(),
          rows: intellitagData.rows,
          timeStamps: intellitagData.intervals,
          statsImg: additionalProps.statsImg,
          includeStatistics: additionalProps.includeStatistics,
          statusIcons: additionalProps.statusIcons,
        };
      }
      return null;
    }

    case ReportType.Arrowboards: {
      const arrowboardData = Array.isArray(reportData) ? reportData : [];
      return {
        ...createPartialData(),
        arrowboardRows: arrowboardData,
        statusIcons: additionalProps.statusIcons,
      };
    }

    case ReportType.SmartCables: {
      const smartCableData = reportData as SupervisionReportDto;

      if (
        smartCableData &&
        smartCableData.rows?.length &&
        smartCableData.intervals?.length &&
        smartCableData.rows?.length > 0 &&
        smartCableData.intervals?.length > 0
      ) {
        return {
          ...createPartialData(),
          timeStamps: smartCableData.intervals,
          smartCableRows: smartCableData.rows as unknown as BatteryMonitorRow[],
          statusIcons: additionalProps.statusIcons,
        };
      }
      return null;
    }

    case ReportType.SmartSolar: {
      const smartSolarData = reportData as SupervisionReportDto;

      if (
        smartSolarData &&
        smartSolarData.rows?.length &&
        smartSolarData.intervals?.length &&
        smartSolarData.rows?.length > 0 &&
        smartSolarData.intervals?.length > 0
      ) {
        return {
          ...createPartialData(),
          timeStamps: smartSolarData.intervals,
          smartSolarRows: smartSolarData.rows as unknown as BatteryMonitorRow[],
          statusIcons: additionalProps.statusIcons,
        };
      }
      return null;
    }

    default:
      return null;
  }
};

/**
 * Prepares data for combined PDF generation
 * Handles multiple report types in a single PDF
 */
export const prepareCombinedPdfData = (
  types: ReportType[],
  reportData: {
    intellitags?: SupervisionReportDto;
    arrowboards?: ArrowboardsReportDto;
    smartcables?: SupervisionReportDto;
    smartsolar?: SupervisionReportDto;
  }
) => {
  const result: {
    intellitagRows?: DeviceRow[];
    arrowboardRows?: ArrowboardRow[];
    smartCableRows?: BatteryMonitorRow[];
    smartSolarRows?: BatteryMonitorRow[];
  } = {};

  if (types.includes(ReportType.Intellitags) && reportData.intellitags?.rows?.length) {
    result.intellitagRows = reportData.intellitags.rows;
  }

  if (types.includes(ReportType.Arrowboards)) {
    if (Array.isArray(reportData.arrowboards)) {
      result.arrowboardRows = reportData.arrowboards;
    } else if (reportData.arrowboards) {
      result.arrowboardRows = [];
    }
  }

  if (types.includes(ReportType.SmartCables) && reportData.smartcables?.rows?.length) {
    result.smartCableRows = reportData.smartcables.rows as unknown as BatteryMonitorRow[];
  }

  if (types.includes(ReportType.SmartSolar) && reportData.smartsolar?.rows?.length) {
    result.smartSolarRows = reportData.smartsolar.rows as unknown as BatteryMonitorRow[];
  }

  return result;
};
