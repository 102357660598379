import { useRef, useEffect } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { LeftHandDrivingCountries } from '../../MapConstants';

export const PointToPointComponent = ({
  from,
  to,
  onCompleted,
  countryCode,
}: {
  from: google.maps.LatLngLiteral;
  to: google.maps.LatLngLiteral;
  onCompleted: (path: google.maps.LatLngLiteral[]) => void;
  countryCode: string;
}) => {
  const map = useMap();

  const onCompletedRef = useRef(onCompleted);
  onCompletedRef.current = onCompleted;

  useEffect(() => {
    if (map) {
      generatePointToPointPolygon(map, from, to, countryCode).then((path) => {
        if (path) {
          onCompletedRef.current(path);
        }
      });
    }
  }, [map, from, to, countryCode]);

  return null;
};

async function generatePointToPointPolygon(
  map: google.maps.Map,
  from: google.maps.LatLngLiteral,
  to: google.maps.LatLngLiteral,
  countryCode: string
) {
  if (!from || !to) return;

  const path = await getDrivingPathFromPoints(from, to);
  if (!path || path.length < 2) return;

  const sideOfRoadAngle = LeftHandDrivingCountries.includes(countryCode) ? 90 : 180;

  const firstSideCoordinates = buildArrayOfCoordinates(map, path, sideOfRoadAngle, 0.00006);
  const otherSideCoordinates = buildArrayOfCoordinates(
    map,
    path.reverse(),
    sideOfRoadAngle,
    0.001,
    true
  );

  return [...firstSideCoordinates, ...otherSideCoordinates].map(({ lat, lng }) => ({
    lat: lat(),
    lng: lng(),
  }));
}

const getDrivingPathFromPoints = async (
  from: google.maps.LatLngLiteral,
  to: google.maps.LatLngLiteral
) => {
  const directionService = new google.maps.DirectionsService();
  const result = await new Promise<google.maps.DirectionsResult>((res, rej) => {
    directionService.route(
      {
        origin: from,
        destination: to,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          return res(result);
        }
        return rej(result);
      }
    );
  });

  return result.routes?.[0]?.overview_path;
};

const convertRadiansToDegrees = (radians: number) => {
  return (radians * 180) / Math.PI;
};

const convertDegreesToRadians = (degrees: number) => {
  return (degrees * Math.PI) / 180;
};

function offsetPoint(lat: number, lng: number, angle: number, offset: number): google.maps.LatLng {
  const latOffset = offset * Math.cos(convertDegreesToRadians(angle));
  const lngOffset = offset * Math.sin(convertDegreesToRadians(angle));
  return new google.maps.LatLng(lat + latOffset, lng + lngOffset);
}

function buildArrayOfCoordinates(
  map: google.maps.Map,
  path: google.maps.LatLng[],
  sideOfRoadAngle: number,
  offset: number,
  secondIteration: boolean = false
): google.maps.LatLng[] {
  if (path.length < 2) return [];

  const polygon: google.maps.LatLng[] = [];
  let position1 = map.getProjection()?.fromLatLngToPoint(path[0]);
  let lastAngle = 0;

  if (!secondIteration) polygon.push(path[0]);

  for (let i = 1; i < path.length; i++) {
    const position2 = map.getProjection()?.fromLatLngToPoint(path[i]);
    if (!position1 || !position2) continue;

    const angle =
      sideOfRoadAngle +
      convertRadiansToDegrees(Math.atan2(position2.y - position1.y, position2.x - position1.x));
    const adjustedAngle = angle - lastAngle;

    polygon.push(offsetPoint(path[i].lat(), path[i].lng(), angle + sideOfRoadAngle, offset));

    position1 = position2;
    lastAngle = adjustedAngle;
  }

  return polygon;
}
