import { Group, Switch, Text, Title, TitleOrder } from '@mantine/core';
import { ReactNode } from 'react';
import { LegendItem } from '../../../../../models/SupervisionReport';

interface DashboardHeaderProps {
  title: string;
  interval?: string;
  legendItems: LegendItem[];
  showSnoozed?: boolean;
  onShowSnoozedChange?: (value: boolean) => void;
  order?: TitleOrder;
  size?: string;
}

export const DashboardHeader = ({
  title,
  interval,
  legendItems,
  showSnoozed,
  onShowSnoozedChange,
  order = 3 as TitleOrder,
  size,
}: DashboardHeaderProps) => {
  const renderLegendItem = (item: LegendItem, index: number): ReactNode => {
    if (item.icon) {
      return (
        <Group key={index} gap="xs" align="center">
          {item.icon}
          <Text c="gray.7" size="xs">
            {item.text}
          </Text>
        </Group>
      );
    }

    return (
      <Group key={index} gap="xs" align="center">
        <img width="20px" src={item.img} alt="" style={item.style || {}} />
        <Text c="gray.7" size="xs">
          {item.text}
        </Text>
      </Group>
    );
  };

  return (
    <Group justify="space-between" align="end">
      <Title order={order} size={size}>
        {title}
      </Title>
      <Group>
        {interval && (
          <Text c="gray.7" size="xs">
            Interval: {interval}
          </Text>
        )}
        {legendItems.map((item, index) => renderLegendItem(item, index))}
        {onShowSnoozedChange && (
          <Switch
            label="Show snoozed"
            checked={showSnoozed}
            onChange={(event) => onShowSnoozedChange(event.currentTarget.checked)}
          />
        )}
      </Group>
    </Group>
  );
};
