import { useMsal } from '@azure/msal-react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, Stack, Switch, Text } from '@mantine/core';

import { PWAContext } from '../providers/PWAProvider';
import useProjectDevices from '../../data/hooks/Device/useProjectDevicesHook';
import { DeviceScanner } from '../components/Scanner/DeviceScanner';
import PwaPageHeading from '../components/PwaPageHeading';

export const PwaScanPage = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { automaticDisconnect, setAutomaticDisconnect, project } = useContext(PWAContext);

  const { projectDevices } = useProjectDevices({
    instance,
    projectIds: [project ? project.id : ''],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!project) navigate('/pwa/projects');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack flex={1} mb="lg">
      <Stack>
        <Group justify="space-between" align="end">
          <PwaPageHeading label="Project">{project?.name || 'No project selected'}</PwaPageHeading>
          {projectDevices && project && (
            <Group gap="xs">
              <Text fw={600}>{projectDevices[project.id].length}</Text>
              <Text size="sm" c="#575757">
                devices
              </Text>
            </Group>
          )}
        </Group>
      </Stack>

      <Stack>
        <DeviceScanner
          scanLabel="Scan QR code to add or remove device from project"
          manualScanButtonLabel="Configure"
          manualTabLabel="Add manually"
          trackingId="Pair device"
        />

        <Switch
          checked={automaticDisconnect}
          label="Instant remove mode"
          onChange={(event) => setAutomaticDisconnect(event.currentTarget.checked)}
          size="md"
        />
      </Stack>
    </Stack>
  );
};
