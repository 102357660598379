import { useQuery } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import { buildQueryString, prepareHeaders } from '../../api/apiHelpers';
import { useState, useMemo } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';
import { isBoundsWithinPreviousBounds } from '../../../services/helpers';
import { Mapbounds } from '../../../shared/types';
import { WorkZone } from '../../../models/Project';
import { FilterOptions } from '@/components/Map/FilterDrawer/MapFilterDrawer';

const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const ENLARGE_BOUNDS_BY = 2;

const path = `/workZone/in-area`;

const useProjectsInArea = (instance: IPublicClientApplication, filters?: FilterOptions) => {
  const [bounds, setBounds] = useState<Mapbounds>({
    northEastLongitude: 0,
    northEastLatitude: 0,
    southWestLongitude: 0,
    southWestLatitude: 0,
  });

  const {
    data: allProjects,
    isPending,
    isError,
    isSuccess,
  } = useQuery<WorkZone[]>({
    queryKey: [
      'projects',
      bounds,
      filters?.projects?.showDemoProjects,
      filters?.projects?.showClosedProjects,
    ],
    queryFn: async () => {
      const queryParams = {
        ...bounds,
        shouldHideDemoProjects: !filters?.projects?.showDemoProjects,
        shouldHideClosed: !filters?.projects?.showClosedProjects,
      };

      const queryString = buildQueryString(queryParams);
      const response = await fetch(`${baseUrl}${path}${queryString}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });

  const data = useMemo(() => {
    if (!allProjects) return [];

    // If no filters are active, show all projects
    if (
      !filters?.projects?.showActiveProjects &&
      !filters?.projects?.showDemoProjects &&
      !filters?.projects?.showClosedProjects
    ) {
      return allProjects;
    }

    // Apply inclusive filtering based on checked filter options
    return allProjects.filter((project) => {
      // Check if the project matches any of the active filters
      if (filters?.projects?.showActiveProjects && !project.isClosed && !project.isDemoProject) {
        return true; // Show active projects (neither closed nor demo)
      }

      if (filters?.projects?.showDemoProjects && project.isDemoProject) {
        return true; // Show demo projects
      }

      if (filters?.projects?.showClosedProjects && project.isClosed) {
        return true; // Show closed projects
      }

      return false;
    });
  }, [
    allProjects,
    filters?.projects?.showActiveProjects,
    filters?.projects?.showDemoProjects,
    filters?.projects?.showClosedProjects,
  ]);

  const handleBoundsChange = useDebouncedCallback((updatedBounds: Mapbounds) => {
    const isWithin = isBoundsWithinPreviousBounds(updatedBounds, bounds);
    if (!isWithin) {
      setBounds({
        northEastLatitude: updatedBounds.northEastLatitude + ENLARGE_BOUNDS_BY,
        northEastLongitude: updatedBounds.northEastLongitude + ENLARGE_BOUNDS_BY,
        southWestLatitude: updatedBounds.southWestLatitude - ENLARGE_BOUNDS_BY,
        southWestLongitude: updatedBounds.southWestLongitude - ENLARGE_BOUNDS_BY,
      });
    }
  }, 500);

  return {
    data,
    isPending,
    isError,
    isSuccess,
    handleBoundsChange,
  };
};

export default useProjectsInArea;
