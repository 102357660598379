import { prepareHeaders } from '@/data/api/apiHelpers';
import { DeviceLog } from '@/models/DeviceLog';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useQuery } from '@tanstack/react-query';

interface Params {
  deviceId?: string;
  startTime?: string;
  endTime?: string;
  instance: IPublicClientApplication;
}

const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const path = `/deviceLog/query`;

const useDeviceLogByTimeStamp = ({ instance, deviceId, startTime, endTime }: Params) => {
  const {
    data: deviceLogs,
    isLoading,
    isError,
  } = useQuery<DeviceLog[]>({
    queryKey: ['device-logs', { deviceId }, { startTime }, { endTime }],
    queryFn: async () => {
      if (!deviceId || !startTime || !endTime) return;
      const response = await fetch(
        `${baseUrl}${path}?deviceId=${deviceId}&startTime=${startTime}&endTime=${endTime}`,
        {
          mode: 'cors',
          method: 'GET',
          headers: await prepareHeaders(instance),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });
  return {
    deviceLogs,
    isLoading,
    isError,
  };
};

export default useDeviceLogByTimeStamp;
