import { CellDef } from 'jspdf-autotable';
import { PdfGenerationData } from '@/models/PdfExport';
import { DeviceEntry } from '../../../models/SupervisionReport';
import { AlarmStatus, EquipmentType } from '../../../models/enums/DeviceEnums';
import { applyPageFooter, addLastPage, addCoverPage, addStatisticPage } from '../utils/pdfCommon';
import { splitIntoWeeks, columnsPerPage } from '../utils/pdfTableUtils';
import { generateTableConfig } from '@/utils/pdfTableUtils';
import { formatDate } from '@/utils/utils';
import { HeaderCell } from '@/models/PdfExport';
import { PDF_CONSTANTS, PDF_TABLE_CONSTANTS } from '@/constants/pdfConstants';
import { addAlarmEventsToDocument } from '../utils/alarmEvents';
import { BasePdfGenerator, CreationStep } from './basePdfGenerator';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { LegendItem } from '@/models/SupervisionReport';
import { createTimeSegments } from '../../../hooks/useTimeSegments';

// This enum is specific to Intellitag status representation
enum LogStatus {
  NoLog = 0,
  Snoozed = 1,
}

/**
 * Intellitag PDF Generator class
 * Handles generation of PDF reports for Intellitag devices
 */
export class IntellitagPdfGenerator extends BasePdfGenerator {
  /**
   * Generate a PDF for Intellitag devices
   */
  public async generatePdf(data: PdfGenerationData, creationStep: CreationStep) {
    // Create document
    const doc =
      this.existingDocument || (await creationStep('CREATE_DOC', () => this.createPdfDocument()));

    // Add cover page if not skipped
    if (!this.skipCoverPage) {
      await creationStep('ADD_COVER', () =>
        addCoverPage(doc, {
          projectName: data.projectName,
          depot: data.depot,
          startDate: formatDate(data.reportStartDate),
          endDate: formatDate(data.reportEndDate),
          contactPerson: data.contactPerson,
        })
      );

      // Add statistics page if needed (only if cover page is included)
      if (data.statsImg && data.includeStatistics) {
        const statsImgString = data.statsImg;
        await creationStep('ADD_STATISTICS', () =>
          addStatisticPage(doc, statsImgString, {
            projectName: data.projectName,
            depot: data.depot,
            startDate: formatDate(data.reportStartDate),
            endDate: formatDate(data.reportEndDate),
          })
        );
      }
    }

    // Generate the content
    await this.generateContent(data, doc, creationStep);

    // Add alarm events if available
    if (!this.skipLastPage && data.rows && data.rows.length > 0) {
      await creationStep('ADD_ALARM_EVENTS', () => {
        addAlarmEventsToDocument(doc, data);
      });
    }

    // Add last page if not skipped
    if (!this.skipLastPage) {
      await creationStep('ADD_FOOTER', () => {
        // Using ADD_FOOTER step for last page
        // If we didn't add alarm events before, add a new page now
        if (!data.rows || data.rows.length === 0) {
          doc.addPage();
        }

        addLastPage(doc, data.contactPerson);
      });
    }

    // Apply page footer with proper page numbers
    await creationStep('ADD_FOOTER', () => {
      // Create legend with status icons
      const legendItems: LegendItem[] = [
        { text: 'OK', img: data.statusIcons?.check || '' },
        { text: 'Fallen', img: data.statusIcons?.alert || '' },
        { text: 'No data', img: data.statusIcons?.dotted || '' },
        { text: 'Snoozed', img: data.statusIcons?.snoozed || '' },
      ];

      applyPageFooter(doc, legendItems, data.includeStatistics);
    });

    // Generate the PDF output
    return await creationStep('COMPLETE', () => doc.output('datauristring'));
  }

  /**
   * Generate content for Intellitag report
   * This method is called by CombinedPdfGenerator to generate content without creating a new document
   */
  public async generateContent(
    data: PdfGenerationData,
    doc: jsPDF,
    creationStep: CreationStep
  ): Promise<void> {
    const columnsPerDay = 24 / data.intervalInHours;

    // Generate headers and data
    const { timeStampHeaders, timeHeaders } = await creationStep('GENERATE_HEADERS', () => {
      return generateTimeHeaders(data.timeStamps || [], columnsPerDay, data.countryCode);
    });

    // Process weekly data
    const tableData = generateTableData(data);
    const weeklyData = splitIntoWeeks(tableData, columnsPerDay);
    const weeklyHeaders = columnsPerPage(timeStampHeaders, timeHeaders, {
      daysPerPage: 7,
      timestampsPerDay: columnsPerDay,
    });

    // Generate tables
    await creationStep('GENERATE_TABLES', async () => {
      for (let weekIndex = 0; weekIndex < weeklyData.length; weekIndex++) {
        // Check for cancellation
        if (this.checkCancellation()) {
          throw new Error('PDF generation cancelled by user');
        }

        const weekData = weeklyData[weekIndex];
        if (weekIndex > 0) {
          doc.addPage();
        }

        const daysInThisWeek = Math.ceil(
          (weekData[0].length - PDF_TABLE_CONSTANTS.FIXED_COLUMNS) / columnsPerDay
        );

        // Calculate page specific date range based on timestamps for this week
        const weekStartIndex = weekIndex * 7 * columnsPerDay; // 7 days per week
        const weekEndIndex =
          Math.min(weekStartIndex + 7 * columnsPerDay - 1, data.timeStamps?.length || 0) - 1;

        // Get actual dates for first and last timestamp in this week
        const weekStartDate =
          data.timeStamps && data.timeStamps.length > weekStartIndex
            ? new Date(data.timeStamps[weekStartIndex])
            : data.reportStartDate;

        const weekEndDate =
          data.timeStamps && data.timeStamps.length > weekEndIndex && weekEndIndex >= 0
            ? new Date(data.timeStamps[weekEndIndex])
            : data.reportEndDate;

        const tableConfig = generateTableConfig(
          weeklyHeaders[weekIndex],
          weekData as CellDef[][],
          {
            left: PDF_CONSTANTS.SIDE_MARGIN,
            right: PDF_CONSTANTS.SIDE_MARGIN,
            top: PDF_CONSTANTS.TOP_MARGIN + PDF_CONSTANTS.HEIGHTS.HEADER,
          },
          daysInThisWeek,
          columnsPerDay,
          {
            projectName: `${data.customerName} - ${data.projectName}`,
            title: 'Intellitags',
            startDate: formatDate(weekStartDate),
            endDate: formatDate(weekEndDate),
            assets: data.assets,
          }
        );

        // Add cell handlers
        tableConfig.didDrawCell = (cellData) => {
          // Handle status icons (columns after attachment)
          if (cellData.cell.section === 'body' && cellData.column.index > 2) {
            const status = cellData.cell.raw as AlarmStatus | LogStatus;
            let statusIcon: string | undefined;

            switch (status) {
              case AlarmStatus.OK:
                statusIcon = data.statusIcons.check;
                break;
              case AlarmStatus.Alarming:
                statusIcon = data.statusIcons.alert;
                break;
              case LogStatus.NoLog:
                statusIcon = data.statusIcons.dotted;
                break;
              case LogStatus.Snoozed:
                statusIcon = data.statusIcons.snoozed;
                break;
            }

            if (status !== undefined && statusIcon) {
              const iconSize = 4;
              const cellWidth = cellData.cell.width;
              const cellHeight = cellData.cell.height;
              const x = cellData.cell.x + (cellWidth - iconSize) / 2;
              const y = cellData.cell.y + (cellHeight - iconSize) / 2;

              try {
                doc.addImage(statusIcon, 'PNG', x, y, iconSize, iconSize, undefined, 'FAST');
              } catch (error) {
                // Silently handle image errors to not interrupt PDF generation
              }
            }
          }

          // Handle attachment images (column 2)
          if (cellData.cell.section === 'body' && cellData.column.index === 2) {
            const attachmentRef = cellData.cell.raw as string;
            const attachmentImage = data.imageMap[attachmentRef];

            if (attachmentImage) {
              const fixedSize = PDF_TABLE_CONSTANTS.FIXED_SIGN_SIZE;
              const x = cellData.cell.x + (cellData.cell.width - fixedSize) / 2;
              const y = cellData.cell.y + (cellData.cell.height - fixedSize) / 2;

              try {
                doc.addImage({
                  imageData: attachmentImage,
                  format: 'PNG',
                  x,
                  y,
                  width: fixedSize,
                  height: fixedSize,
                  compression: 'FAST',
                  alias: `attachment-${attachmentRef}`,
                });
              } catch (error) {
                // Silently handle image errors to not interrupt PDF generation
              }
            }
          }
        };

        tableConfig.didParseCell = (data) => {
          // Only clear text in attachment column (2) and status columns (>2)
          if ((data.column.index === 2 || data.column.index > 2) && data.cell.section === 'body') {
            data.cell.text = [''];
          }
        };

        // Apply the table
        this.applyAutoTable(doc, tableConfig);
      }
    });
  }
}

/**
 * Gets the status of a device entry based on its logs
 */
const getTemporaryTextStatus = (
  entry: DeviceEntry,
  showSnoozed: boolean
): AlarmStatus | LogStatus => {
  const logsToShow = entry?.logs?.length
    ? showSnoozed
      ? entry.logs
      : entry.logs.filter((dl) => !dl.workZoneSnoozed)
    : [];

  if (logsToShow.length === 0) return LogStatus.NoLog;
  if (logsToShow.some((log) => log.alarmStatus === AlarmStatus.Alarming))
    return AlarmStatus.Alarming;
  return AlarmStatus.OK;
};

/**
 * Generates time headers for the Intellitag table
 * Using our new DST-aware solution for consistent headers
 */
const generateTimeHeaders = (timeStamps: string[], columnsPerDay: number, countryCode: string) => {
  // Create time segment directly with createTimeSegments
  const segments = createTimeSegments(timeStamps, 24 / columnsPerDay, countryCode || 'SE');

  // Create date headers for each day
  const timeStampHeaders = segments
    .map((segment, index: number) => {
      if (index % columnsPerDay === 0) {
        return {
          content: segment.date, // Use segment.date directly
          colSpan: columnsPerDay,
          styles: {
            lineWidth: { right: 0.5, top: 0.5, bottom: 0, left: 0 },
            cellPadding: [2.5, 1, 1, 1],
          },
        } as HeaderCell;
      }
      return null;
    })
    .filter((header: HeaderCell | null): header is HeaderCell => header !== null);

  // Create time headers for each cell
  const timeHeaders = segments.map((segment, index: number) => {
    // Only show start time from the segment
    const displayTime = segment.startTime;
    const isLastInDay = index % columnsPerDay === columnsPerDay - 1;

    return {
      content: displayTime,
      styles: {
        lineWidth: {
          right: isLastInDay ? 0.5 : 0,
          top: 0,
          bottom: 0.5,
          left: 0,
        },
        cellPadding: [1, 0.5, 1, 0.5],
        minCellWidth: PDF_TABLE_CONSTANTS.TIMESTAMP_CELL_WIDTH,
        maxCellWidth: PDF_TABLE_CONSTANTS.TIMESTAMP_CELL_WIDTH,
        halign: 'center',
        valign: 'middle',
        overflow: 'hidden',
      },
    } as HeaderCell;
  });

  return { timeStampHeaders, timeHeaders };
};

/**
 * Generates table data for the Intellitag PDF
 */
const generateTableData = (data: PdfGenerationData) => {
  const rows = data.rows || [];
  const timeStamps = data.timeStamps || [];

  return rows.map((row) => {
    const statusData = timeStamps.map((_, index) => {
      const entry = row.entries?.[index];
      if (!entry) {
        return LogStatus.NoLog;
      }
      return getTemporaryTextStatus(entry, data.showSnoozed);
    });
    const equipmentType =
      row.currentEquipmentType === EquipmentType.Barrier
        ? EquipmentType.Barrier.toString()
        : EquipmentType.Sign.toString();
    const attachmentRef = row.attachmentRef ? row.attachmentRef : equipmentType;
    return [row.referenceId, row.name, attachmentRef, ...statusData];
  });
};
