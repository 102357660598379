import { Navigate, useLocation } from 'react-router-dom';
import Root from '@/pages/Root';
import Home from '@/pages/Home';
import DevicesPage from '@/pages/DeviceListPage';
import { MapPage } from '@/pages/MapPage.tsx';
import Error from '@/pages/Error';
import Login from '@/pages/Login';
import { DeviceDetailsPage } from '@/pages/DeviceDetailsPage.tsx';
import { BulkProvisionDevices } from './shared/BulkProvisionDevices';
import Redirect from '@/pages/Redirect';
import AccessManagement from '@/pages/AccessManagement';
import { ErrorBoundary } from './components/Error/ErrorBoundary';
import ProjectPage from '@/pages/ProjectPage';
import { BatteryStatus } from './models/enums/DeviceEnums';
import { AfterLoginRedirect } from '@/pages/AfterLogin';
import { AccessDenied } from '@/pages/AccessDenied';
import PrivacyPolicy from '@/pages/PrivacyPolicy.tsx';
import ProjectSetup from '@/pages/ProjectSetup';

import { PwaWrapper } from './pwa/components/PwaWrapper';
import { PwaScanPage } from './pwa/pages/PwaScanPage.tsx';
import { PwaHelpPage } from './pwa/pages/PwaHelpPage.tsx';
import { PwaSummaryPage } from './pwa/pages/PwaSummaryPage.tsx';
import { PwaDeviceListPage } from './pwa/pages/PwaDeviceListPage.tsx';
import { PwaDisconnectPage } from './pwa/pages/PwaDisconnectPage.tsx';
import { PwaProjectListPage } from './pwa/pages/PwaProjectListPage.tsx';
import { PwaBatteryBlockerPage } from './pwa/pages/PwaBatteryBlockerPage.tsx';
import { PwaIntellitagEquipmentPage } from './pwa/pages/PwaIntellitagEquipmentPage.tsx';
import { PwaProjectOverviewPage } from './pwa/pages/PwaProjectOverviewPage/PwaProjectOverviewPage.tsx';
import { PwaNotAvailableForAssignmentBlockerPage } from './pwa/pages/PwaNotAvailableForAssignmentBlockerPage.tsx';
import { PwaHealthCheckPage } from './pwa/pages/PwaHealthCheckPage/PwaHealthCheckPage.tsx';
import { PwaPairPoweredEquipmentPage } from './pwa/pages/PwaPairPoweredEquipmentPage.tsx';
import { isTruthy } from '@/utils/truthy.ts';
import { CreateProjectFlow } from './components/Project/CreateProjectFlow/CreateProjectFlow.tsx';
import { AllProjectCreationFlows } from './components/Project/CreateProjectFlow/createProjectConstants.ts';
import { CreateProjectPage } from '@/pages/CreateProjectPage.tsx';

// eslint-disable-next-line react-refresh/only-export-components
const ErrorBoundaryWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  return <ErrorBoundary key={location.pathname}>{children}</ErrorBoundary>;
};

export const protectedRoutes = [
  {
    path: '/after-login',
    element: <AfterLoginRedirect />,
  },
  // * Routes with a sidebar:
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Redirect />,
      },
      {
        path: '/access-denied',
        element: <AccessDenied />,
      },
      {
        path: '/projects',
        element: (
          <ErrorBoundaryWrapper>
            <Home />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/create',
        element: (
          <ErrorBoundaryWrapper>
            <CreateProjectPage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/create/psa',
        element: (
          <ErrorBoundaryWrapper>
            <CreateProjectFlow selectedFlow={AllProjectCreationFlows.psa} />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/create/default',
        element: (
          <ErrorBoundaryWrapper>
            <CreateProjectFlow selectedFlow={AllProjectCreationFlows.default} />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/map',
        element: (
          <ErrorBoundaryWrapper>
            <MapPage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/devices',
        element: (
          <ErrorBoundaryWrapper>
            <DevicesPage />
          </ErrorBoundaryWrapper>
        ),
        children: [],
      },
      {
        path: '/devices/edit/:deviceId',
        element: (
          <ErrorBoundaryWrapper>
            <DeviceDetailsPage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/bulk-provision-devices',
        element: (
          <ErrorBoundaryWrapper>
            <BulkProvisionDevices />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/:projectId',
        element: (
          <ErrorBoundaryWrapper>
            <ProjectPage />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/projects/:projectId/setup',
        element: (
          <ErrorBoundaryWrapper>
            <ProjectSetup />
          </ErrorBoundaryWrapper>
        ),
      },
      {
        path: '/access-management',
        element: <AccessManagement />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/redirect',
        element: <Redirect />,
      },
      {
        path: '*',
        element: <Navigate to="/redirect" state={{ from: window.location.pathname }} />,
      },
    ].filter(isTruthy),
  },
  {
    path: '/pwa/projects/:projectId',
    element: (
      <PwaWrapper>
        <PwaProjectOverviewPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/:projectId/all-devices',
    element: (
      <PwaWrapper>
        <PwaDeviceListPage filter="All Devices" />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/:projectId/alarms',
    element: (
      <PwaWrapper>
        <PwaDeviceListPage filter="Alarms" />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/:projectId/battery',
    element: (
      <PwaWrapper>
        <PwaDeviceListPage filter="Battery" />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects',
    element: (
      <PwaWrapper>
        <PwaProjectListPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/pair-intellitag',
    element: (
      <PwaWrapper>
        <PwaIntellitagEquipmentPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/pair-powered-equipment',
    element: (
      <PwaWrapper>
        <PwaPairPoweredEquipmentPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/disconnect',
    element: (
      <PwaWrapper>
        <PwaDisconnectPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/summary',
    element: (
      <PwaWrapper>
        <PwaSummaryPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/critical-battery',
    element: (
      <PwaWrapper>
        <PwaBatteryBlockerPage type={BatteryStatus.CRITICAL} />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/out-of-battery',
    element: (
      <PwaWrapper>
        <PwaBatteryBlockerPage type={BatteryStatus.OUT_OF_BATTERY} />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/not-available-for-assignment',
    element: (
      <PwaWrapper>
        <PwaNotAvailableForAssignmentBlockerPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/help',
    element: (
      <PwaWrapper>
        <PwaHelpPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/projects/scanner',
    element: (
      <PwaWrapper>
        <PwaScanPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/health-check',
    element: (
      <PwaWrapper>
        <PwaHealthCheckPage />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa/privacy-policy',
    element: (
      <PwaWrapper>
        <PrivacyPolicy />
      </PwaWrapper>
    ),
  },
  {
    path: '/pwa',
    element: <Navigate to="/pwa/projects" />,
  },
  {
    path: '*',
    element: <Navigate to="/redirect" />,
  },
].filter(isTruthy);

export const openRoutes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/redirect',
    element: <Redirect />,
  },
  {
    path: '*',
    element: <Navigate to="/redirect" state={{ from: window.location.pathname }} />,
  },
];
