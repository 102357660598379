import { useConfirmationModal } from '@/components/Modal/ModalConfirmProvider';
import {
  AllProjectCreationFlows,
  createProjectFlowFormSchema,
  CreateProjectFlowFormValues,
  isProjectCreationFlowAvailable,
} from '@/components/Project/CreateProjectFlow/createProjectConstants';
import { CreateProjectFlowInformationStep } from '@/components/Project/CreateProjectFlow/CreateProjectInformation/CreateProjectFlowInformationStep';
import { CreateProjectFlowNotificationStep } from '@/components/Project/CreateProjectFlow/CreateProjectNotifications/CreateProjectFlowNotificationStep';
import { CreateProjectPsaSetupStep } from '@/components/Project/CreateProjectFlow/CreateProjectPsaSetup/CreateProjectPsaSetupStep';
import { showErrorNotification } from '@/data/errorHandler';
import useCreateProjectFromFlow, {
  CreateProjectFromFlowParams,
} from '@/data/hooks/Project/useCreateProjectFromFlowHook';
import { PsaSetupType } from '@/models/enums/ProjectEnums';
import { ConfirmButtonGroup } from '@/shared/ConfirmButtonGroup/ConfirmButtonGroup';
import { getNestedKeys, hasNestedErrors } from '@/utils/form';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Group, Paper, rem, Space, Stack, Stepper, Title } from '@mantine/core';
import { useForm, UseFormReturnType, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconCircleX } from '@tabler/icons-react';
import { FunctionComponent, ReactElement, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type DefaultStepsProps = {
  form: UseFormReturnType<CreateProjectFlowFormValues>;
};

export const CreateProjectFlow = ({ selectedFlow }: { selectedFlow: AllProjectCreationFlows }) => {
  const form = useForm<CreateProjectFlowFormValues>({
    mode: 'uncontrolled',
    initialValues: {
      informationStep: {
        name: '',
        isDemoProject: false,
        countryCode: '',
        depotId: '',
        customerName: '',
        startDate: new Date().toISOString(),
        endDate: null,
      },
      notificationStep: {
        alarmContacts: [],
      },
      psaSetupStep:
        selectedFlow === AllProjectCreationFlows.psa
          ? // @ts-expect-error - default is null, validate will catch if not selected
            ({
              scenario: null,
              setupType: null,
              assignedPsas: [],
              geozonePath: null,
            } as CreateProjectFlowFormValues['psaSetupStep'])
          : null,
    },
    validate: zodResolver(createProjectFlowFormSchema),
  });

  const navigate = useNavigate();

  const [activeStepIdx, setActiveStepIdx] = useState(0);

  const stepperConfiguration = useMemo(() => {
    const steps: {
      id: keyof CreateProjectFlowFormValues;
      label: string;
      component: FunctionComponent<DefaultStepsProps>;
    }[] = [];

    steps.push({
      id: 'informationStep',
      label: 'Information',
      component: CreateProjectFlowInformationStep,
    });

    if (
      selectedFlow === AllProjectCreationFlows.psa &&
      isProjectCreationFlowAvailable(selectedFlow)
    ) {
      steps.push({
        id: 'psaSetupStep',
        label: 'PSA Setup',
        component: CreateProjectPsaSetupStep,
      });
    }

    steps.push({
      id: 'notificationStep',
      label: 'Notifications',
      component: CreateProjectFlowNotificationStep,
    });

    return steps;
  }, [selectedFlow]);

  const activeStep = useMemo(
    () => stepperConfiguration[activeStepIdx],
    [activeStepIdx, stepperConfiguration]
  );

  const ActiveStepBody = useMemo(() => activeStep?.component, [activeStep]);

  const previousStep = () => {
    if (activeStepIdx === 0) {
      if (form.isDirty()) {
        openModal({
          text: "Are you sure you want to go back to device selection? You'll lose all progress.",
          confirmGroupProps: {
            confirmBtnText: 'Yes, go back',
            abortBtnText: 'No',
            onConfirm: () => {
              closeModal();
              navigate('/projects/create');
            },
            onAbort: () => {
              closeModal();
            },
          },
        });
      } else {
        navigate('/projects/create');
      }
    } else {
      setActiveStepIdx((prev) => (prev > 0 ? prev - 1 : prev));
    }
  };
  const nextStep = () => {
    // validateField top level of a nested object doesn't trickle down to individual fields, so we need to do it manually
    const validationResults = getNestedKeys(form.getValues()[activeStep.id] ?? {}).map(
      (key) =>
        [
          `${activeStep.id}.${key}`,
          form.validateField(`${activeStep.id}.${key}` as keyof CreateProjectFlowFormValues),
        ] as const
    );

    if (
      validationResults.some(([_, result]) => result.hasError) ||
      Object.keys(form.errors).length > 0
    ) {
      console.error(validationResults.filter(([_, result]) => result.hasError));
      return;
    }

    setActiveStepIdx((prev) => (prev < stepperConfiguration.length - 1 ? prev + 1 : prev));
  };

  const isFinalStep = activeStepIdx === stepperConfiguration.length - 1;

  const formRef = useRef<HTMLFormElement | null>(null);

  const { instance } = useMsal();

  const createProjectFromFlow = useCreateProjectFromFlow(instance);

  const { openModal, closeModal } = useConfirmationModal();
  const [loading, setLoading] = useState(false);

  return (
    <Stack
      h="100%"
      gap={0}
      style={{
        overflow: 'hidden',
        minHeight: `calc(100vh - var(--app-shell-header-offset, 0rem) + var(--app-shell-padding))`,
        maxHeight: `calc(100vh - var(--app-shell-header-offset, 0rem) + var(--app-shell-padding))`,
      }}
      renderRoot={(props) => (
        <form
          {...props}
          ref={formRef}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // Don't submit if someone hits enter in a field, must hit the button!
              if (event.target instanceof HTMLInputElement) {
                event.preventDefault();
              }
            }
          }}
          onSubmit={async (event) => {
            event.preventDefault();
            if (!formRef.current) return;
            if (formRef.current !== event.target) return; // don't submit if a child form is submitting
            setLoading(true);

            const handleSubmit = form.onSubmit(
              (values) => {
                const params: CreateProjectFromFlowParams = {
                  ...values.informationStep,
                  ...values.notificationStep,
                };

                const psaSetup = values.psaSetupStep;
                if (selectedFlow === AllProjectCreationFlows.psa && psaSetup) {
                  params.psaSetup = {
                    scenario: psaSetup.scenario,
                    setupType: psaSetup.setupType,
                  };
                  if (psaSetup.setupType === PsaSetupType.Manual) {
                    params.psaSetup.assignedPsas = psaSetup.assignedPsas?.map((d) => ({
                      id: d.id,
                    }));
                  } else if (psaSetup.setupType === PsaSetupType.Geozone && psaSetup.geozonePath) {
                    params.psaSetup.geozoneGeometry = {
                      type: 'Polygon',
                      coordinates: [psaSetup.geozonePath.map((p) => [p.lng, p.lat])],
                    };
                    params.psaSetup.geozoneGeometry.coordinates[0].push(
                      params.psaSetup.geozoneGeometry.coordinates[0][0]
                    ); // close the polygon
                  }
                }

                createProjectFromFlow
                  .mutateAsync(params)
                  .then(async (result) => {
                    const id = await result.json();
                    notifications.show({
                      title: 'Project created successfully',
                      message: '',
                      color: 'var(--mantine-color-successGreen-9)',
                      autoClose: 5000,
                      position: 'bottom-center',
                    });

                    const isManualSetupProjectWithDevices =
                      values.psaSetupStep?.setupType === PsaSetupType.Manual &&
                      psaSetup?.assignedPsas &&
                      psaSetup?.assignedPsas.length > 0;

                    if (isManualSetupProjectWithDevices) {
                      // Small delay to allow events to propagate to listener and back so user won't see empty device list
                      await new Promise((r) => setTimeout(r, 2000));
                    }
                    navigate(`/projects/${id}`);
                  })
                  .catch((error) => {
                    console.error(error);
                    showErrorNotification(
                      'Error creating project',
                      'An error occurred while creating the project'
                    );
                  })
                  .finally(() => setLoading(false));
              },
              (validationErrors, values) => {
                console.error(validationErrors, values);
              }
            );

            handleSubmit();
          }}
        />
      )}
    >
      <Paper shadow="xs" radius={0} withBorder>
        <Box px="xl" pb="lg" pt="sm">
          <Title fw={700} order={4} mt="md">
            Create new project
          </Title>
          <Stepper
            translate="no"
            active={activeStepIdx}
            onStepClick={setActiveStepIdx}
            allowNextStepsSelect={false}
            pt={16}
            size={'xs'}
            w={'fit-content'}
            styles={{
              separator: {
                width: '10rem',
              },
            }}
          >
            {stepperConfiguration.map((step) => {
              const stepStyles = {
                color: undefined as string | undefined,
                completedIcon: undefined as ReactElement | undefined,
              };

              // Check if any field in this step has an error
              const isStepValid = !hasNestedErrors(form.errors, step.id);

              if (!isStepValid) {
                stepStyles.color = 'red';
                stepStyles.completedIcon = <IconCircleX size={'75%'} />;
              }

              return (
                <Stepper.Step
                  key={step.id}
                  label={step.label}
                  color={stepStyles.color}
                  completedIcon={stepStyles.completedIcon}
                  styles={{
                    stepLabel: {
                      fontWeight: 700,
                      color: stepStyles.color,
                    },
                  }}
                ></Stepper.Step>
              );
            })}
          </Stepper>
        </Box>
      </Paper>

      {/* Main content */}
      <Box key={activeStep.id} style={{ flex: 1, overflow: 'auto' }}>
        <Box px="md" pt={28} pb={28}>
          <ActiveStepBody form={form} />
        </Box>
      </Box>

      {/* Footer */}
      <Paper withBorder radius={0} py="md" px="xl">
        <Group justify="">
          {activeStepIdx > 0 && (
            <Button variant="default" onClick={() => previousStep()}>
              <IconArrowLeft size={16} stroke={2.5} style={{ marginRight: rem(5) }} />
              Previous
            </Button>
          )}

          <Space flex={1} />

          <ConfirmButtonGroup
            key={isFinalStep ? 'final' : 'next'}
            confirmBtnText={isFinalStep ? 'Save project' : 'Next'}
            disabled={form.submitting || Object.keys(form.errors).length > 0}
            confirmButtonType={isFinalStep ? 'submit' : 'button'}
            isLoading={loading}
            onConfirm={() => {
              if (!isFinalStep) {
                nextStep();
              }
            }}
            onAbort={() => {
              if (form.isDirty()) {
                openModal({
                  text: "Are you sure you want to cancel? You'll lose all progress.",
                  confirmGroupProps: {
                    confirmBtnText: 'Yes, cancel',
                    abortBtnText: 'No',
                    onConfirm: () => {
                      closeModal();
                      navigate('/projects');
                    },
                    onAbort: () => {
                      closeModal();
                    },
                  },
                });
              } else {
                navigate('/projects');
              }
            }}
          />
        </Group>
      </Paper>
    </Stack>
  );
};
