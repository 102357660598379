import { AllProjectCreationFlows } from '@/components/Project/CreateProjectFlow/createProjectConstants';
import {
  Box,
  Button,
  Container,
  Flex,
  Group,
  Image,
  Paper,
  Radio,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMobileDevice } from '@/hooks/useMobileDevice';
import { getProjectCreationFlowImages } from '@/utils/ImageHelpers';

export const CreateProjectSelectDeviceType = () => {
  const navigate = useNavigate();
  const isMobile = useMobileDevice();

  const [selectedFlow, setSelectedFlow] = useState<AllProjectCreationFlows | null>(null);

  const PROJECT_CREATION_TRACKING_VALUE_MAP = {
    [AllProjectCreationFlows.default]: 'default',
    [AllProjectCreationFlows.psa]: 'psa',
  } as const;

  // TODO: I'd prefer to re-use the styling of the other page, rather than copy-pasting it here...
  // TODO: But it's connected to the form so workaround for time being

  return (
    <Stack
      h="100%"
      gap={0}
      style={{
        overflow: 'hidden',
        minHeight: `calc(100vh - var(--app-shell-header-offset, 0rem) + var(--app-shell-padding))`,
        maxHeight: `calc(100vh - var(--app-shell-header-offset, 0rem) + var(--app-shell-padding))`,
      }}
    >
      <Paper shadow="xs" radius={0} withBorder>
        <Box px="xl" pb="lg" pt="sm">
          <Title fw={700} order={4} mt="md">
            Create new project
          </Title>
        </Box>
      </Paper>

      {/* Main content */}
      <Box style={{ flex: 1, overflow: 'auto' }}>
        <Box px="md" pt={64} pb={28}>
          <Container size={'md'}>
            <Stack gap={50}>
              <Stack gap="xs">
                <Title order={5} mb={'xs'}>
                  Select device type
                </Title>
                <Text>Select the device type your project will include</Text>
              </Stack>
              <Flex gap="md" align="stretch" direction={isMobile ? 'column' : 'row'}>
                <DeviceTypeSelectionCard
                  checked={selectedFlow === AllProjectCreationFlows.default}
                  title="All other products"
                  flowType={AllProjectCreationFlows.default}
                  onClick={() => setSelectedFlow(AllProjectCreationFlows.default)}
                  disabled={false}
                />
                <DeviceTypeSelectionCard
                  checked={selectedFlow === AllProjectCreationFlows.psa}
                  title="PSA"
                  flowType={AllProjectCreationFlows.psa}
                  onClick={() => setSelectedFlow(AllProjectCreationFlows.psa)}
                  disabled={false}
                />
              </Flex>
            </Stack>
          </Container>
        </Box>
      </Box>

      {/* Footer */}
      <Paper withBorder radius={0} py="md" px="xl">
        <Group justify="">
          <Space flex={1} />
          <Button
            variant="default"
            onClick={() => {
              navigate('/projects');
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={selectedFlow == null}
            onClick={() => {
              if (selectedFlow) {
                if (window._paq) {
                  window._paq.push([
                    'trackEvent',
                    'CreateProject',
                    `${PROJECT_CREATION_TRACKING_VALUE_MAP[selectedFlow]} flow selected`,
                  ]);
                }
                navigate(
                  selectedFlow === AllProjectCreationFlows.psa
                    ? '/projects/create/psa'
                    : '/projects/create/default'
                );
              }
            }}
          >
            Start
          </Button>
        </Group>
      </Paper>
    </Stack>
  );
};

const DeviceTypeSelectionCard = ({
  flowType,
  title,
  checked,
  onClick,
  disabled,
}: {
  flowType: AllProjectCreationFlows;
  title: string;
  checked: boolean;
  onClick: () => void;
  disabled: boolean;
}) => {
  const images = getProjectCreationFlowImages(flowType);

  return (
    <Radio.Card
      bg={'var(--mantine-color-body)'}
      opacity={disabled ? 0.7 : 1}
      radius={'md'}
      px="lg"
      py={50}
      withBorder
      styles={{
        card: {
          cursor: disabled ? 'not-allowed' : 'pointer',
          borderColor: checked ? 'var(--mantine-primary-color-filled)' : '',
          borderWidth: 2,
        },
      }}
      disabled={disabled}
      checked={checked}
      onClick={() => !disabled && onClick()}
    >
      <Stack align="stretch" gap="sm" w={'100%'} h={'100%'}>
        <Group
          justify="center"
          align="center"
          p="lg"
          gap="lg"
          style={{
            height: 128,
            minHeight: 128,
            maxHeight: 128,
            overflow: 'hidden',
          }}
        >
          {images?.map((src) => (
            <Image
              key={src}
              src={src}
              h={'100%'}
              w={100}
              alt=""
              style={{
                filter: disabled ? 'grayscale(1)' : '',
              }}
              fallbackSrc="https://placehold.co/350x200?text=Placeholder"
            />
          ))}
        </Group>
        <Stack gap={'sm'}>
          <Group justify="center">
            <Title order={5}>
              {title}
              {disabled && ' - Coming soon!'}
            </Title>
          </Group>
        </Stack>
      </Stack>
    </Radio.Card>
  );
};
