import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export const daysLeftInWeek = (startDate: Date, endDate: Date): number => {
  const currentStart = dayjs(startDate);
  const currentEnd = dayjs(endDate);

  if (currentEnd.diff(currentStart, 'day') > 6) throw Error('Date range is longer than a week!');

  let endOfWeek;
  if (dayjs(currentEnd).weekday() !== 7) {
    endOfWeek = currentEnd; // Last day is before Sunday
  } else {
    endOfWeek = currentStart.weekday(7); // Sunday of the current week
  }
  return endOfWeek.diff(currentStart, 'day') + 1; // Include the current day}
};

export interface Week {
  startDate: Date;
  endDate: Date;
}
export const getWeeklyRanges = (startDate: Week['startDate'], endDate: Week['endDate']): Week[] => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  if (end.isBefore(start)) throw new Error('End date is before start date!');

  let currentStart = start;
  const weeks: Week[] = [];

  // Handle case where startDate is already a Sunday (single-day week)
  if (currentStart.weekday() === 0) {
    weeks.push({
      startDate: currentStart.toDate(),
      endDate: currentStart.toDate(),
    });
    currentStart = currentStart.add(1, 'day'); // Move to Monday
  }

  while (currentStart.isBefore(end) || currentStart.isSame(end, 'day')) {
    let weekEnd = currentStart.weekday(7); // Sunday of the current week

    if (weekEnd.isAfter(end)) weekEnd = end; // Ensure we don't go past endDate

    weeks.push({
      startDate: currentStart.toDate(),
      endDate: weekEnd.toDate(),
    });

    currentStart = weekEnd.add(1, 'day'); // Move to the next Monday
  }

  return weeks;
};

export const getLocalDateStr = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DD');
};
