import { Device, DeviceType, PsaDeviceModel } from '@/models/Device';
import { EquipmentType, PowerSource } from '@/models/enums/DeviceEnums';
import { GenericDeviceMarker } from './GenericDeviceMarker/GenericDeviceMarker';
import { ArrowBoardMarker } from './ArrowBoardMarker/ArrowBoardMarker';
import { BarrierMarker } from './IntellitagMarkers/BarrierMarker';
import { RoadSignMarker } from './IntellitagMarkers/RoadSignMarker';
import { PortableSiteAlarmMarker } from './PortableSiteAlarmMarker/PortableSiteAlarmMarker';
import { PoweredEquipmentMarker } from './PoweredEquipmentMarker/PoweredEquipmentMarker';

type Props = {
  device: Device;
};

export const DeviceMarkerWrapper = ({ device }: Props) => {
  if (device.deviceType == DeviceType.IntellitagV1) {
    if (device.currentEquipmentType == EquipmentType.Sign) {
      return <RoadSignMarker attachmentRef={device.attachmentRef} />;
    } else if (device.currentEquipmentType == EquipmentType.Barrier) {
      return <BarrierMarker />;
    }
  }
  if (device.deviceType == DeviceType.IntelliTagLite) {
    if (device.currentEquipmentType == EquipmentType.Barrier) {
      return <BarrierMarker />;
    }
  }
  if (device.deviceType == DeviceType.PSAV1) {
    return <PortableSiteAlarmMarker deviceModel={device.deviceModel as PsaDeviceModel} />;
  }
  if (device.deviceType == DeviceType.ArrowBoardV1)
    return <ArrowBoardMarker arrowDirection={device.arrowDirection} />;
  if (device.deviceType == DeviceType.SmartCableV1 && device.poweredEquipment !== null)
    return (
      <PoweredEquipmentMarker
        poweredBy={PowerSource.SmartCable}
        equipment={device.poweredEquipment}
      />
    );
  if (device.deviceType == DeviceType.SmartSolarV1)
    return (
      <PoweredEquipmentMarker
        poweredBy={PowerSource.SmartSolar}
        equipment={device.poweredEquipment}
      />
    );
  return <GenericDeviceMarker deviceType={device.deviceType} />;
};
