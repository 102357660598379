import { Group, Text } from '@mantine/core';
import { SnoozeBadge } from '@/components/Badge';
import { formatDateTimeString } from '@/utils/utils';

interface SnoozeStatusProps {
  isSnoozed: boolean;
  snoozedFrom: Date | string | null | undefined;
  snoozedUntil: Date | string | null | undefined;
  typeOfSnooze: 'Active' | 'Scheduled';
}

/**
 * Handles both active snoozes and scheduled snoozes
 */
const SnoozeStatus = ({
  isSnoozed,
  snoozedFrom,
  snoozedUntil,
  typeOfSnooze,
}: SnoozeStatusProps) => {
  // Skip rendering if no snooze data
  if (!isSnoozed && !snoozedFrom && !snoozedUntil) {
    return null;
  }

  // Convert snoozedUntil to date if it exists
  const snoozedUntilDate = snoozedUntil ? new Date(snoozedUntil) : null;

  // Convert snoozedFrom to date if it exists
  const snoozedFromDate = snoozedFrom ? new Date(snoozedFrom) : null;

  // Calculate duration
  let duration = '8h'; // Default duration
  if (snoozedFromDate && snoozedUntilDate) {
    const hours = Math.round(
      (snoozedUntilDate.getTime() - snoozedFromDate.getTime()) / (1000 * 60 * 60)
    );
    duration = `${hours}h`;
  }

  if (typeOfSnooze === 'Active') {
    return (
      <Group>
        <SnoozeBadge />
        <Text size="sm" c="dimmed">
          Snoozed until {formatDateTimeString(snoozedUntil?.toString())}
        </Text>
      </Group>
    );
  }

  if (typeOfSnooze === 'Scheduled') {
    return (
      <Text size="sm" mt="xs" c="dimmed">
        Alarms will snooze at {formatDateTimeString(snoozedFrom?.toString())} for {duration}
      </Text>
    );
  }

  return null;
};

export default SnoozeStatus;
