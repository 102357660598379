import { Button, Title } from '@mantine/core';
import { IconBarrierBlock } from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const FallbackComponent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <IconBarrierBlock
        size={48}
        stroke={1}
        color="var(--mantine-color-ramuddenOrange-9)"
        style={{ transform: 'rotate(-135deg)' }}
      />
      <Title mt={'sm'} order={4}>
        Something went wrong!
      </Title>
      <Button mt={'lg'} variant="default" onClick={() => navigate('/projects')}>
        Go to All projects page
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center' as const,
    padding: '1rem',
  },
};

export default FallbackComponent;
