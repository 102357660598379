import { BarChart, BarChartProps } from '@mantine/charts';

interface Props {
  id?: string;
  series: { name: string; label: string; color: string; stackId?: string }[];
  data: BarChartProps['data'];
  withLegend: BarChartProps['withLegend'];
  legendProps?: BarChartProps['legendProps'];
}

export const Co2BarChart = ({ id, withLegend, legendProps, series, data }: Props) => {
  return (
    <BarChart
      id={id}
      h={250}
      w={350}
      mih={undefined}
      unit=" kg"
      withLegend={withLegend}
      legendProps={legendProps}
      mt={withLegend ? 0 : 'md'}
      dataKey="week"
      series={series ?? []}
      data={data}
    />
  );
};
