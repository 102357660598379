import {
  IconArrowAutofitContent,
  IconCurling,
  IconHelpHexagon,
  IconRouter,
  IconShadow,
  IconPlugConnected,
  IconSolarPanel2,
  IconLetterLSmall,
} from '@tabler/icons-react';
import { DeviceType } from '../../../../models/Device';
import { Center } from '@mantine/core';

type Props = {
  size?: number;
  deviceType: DeviceType;
};

export const DeviceDefaultImage = ({ deviceType, size = 40 }: Props) => {
  const map = {
    [DeviceType.IntellitagV1]: (
      <IconRouter size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
    ),
    [DeviceType.IntelliTagV2]: (
      <IconRouter size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
    ),
    [DeviceType.IntelliTagLite]: (
      <>
        <span style={{ position: 'relative' }}>
          <IconRouter size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
          <Center
            pos="absolute"
            bottom={'10%'}
            right={'-5%'}
            bg="black"
            style={{ borderRadius: '50%', zIndex: 1 }}
          >
            <IconLetterLSmall color="white" size={size / 2.5} stroke={4} />
          </Center>
        </span>
      </>
    ),
    [DeviceType.ArrowBoardV1]: <IconArrowAutofitContent size={size} stroke={1.5} />,
    [DeviceType.SmartCableV1]: (
      <IconPlugConnected size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
    ),
    [DeviceType.PSAV1]: <IconCurling size={size} stroke={1.5} />,
    [DeviceType.SmartSolarV1]: <IconSolarPanel2 size={size} stroke={1.5} />,
    [DeviceType.BeaconTracker]: (
      <IconShadow size={size} style={{ transform: 'rotate(-90deg)' }} stroke={1.5} />
    ),
    [DeviceType.Unknown]: <IconHelpHexagon size={size} stroke={1.5} />,
  };
  return map[deviceType] || map[DeviceType.Unknown];
};
