import { Center, Image } from '@mantine/core';
import { PoweredEquipment, PowerSource } from '@/models/enums/DeviceEnums';
import { POWERED_EQUIPMENT_IMAGE_MAP } from '@/constants/imageConstants.ts';
import { PowerIcon } from './PowerIcon/PowerIcon';

const commonPowerIconBadgeStyles = {
  bg: 'white',
  bd: 'solid black 1px',
  style: { borderRadius: '50%' },
};

interface Props {
  poweredBy: PowerSource;
  equipment: PoweredEquipment;
}

export const PoweredEquipmentMarker = ({ poweredBy, equipment }: Props) => {
  return (
    <>
      {equipment === PoweredEquipment.Other || equipment === PoweredEquipment.Unknown ? (
        <Center {...commonPowerIconBadgeStyles} p="4px">
          <PowerIcon size={40} poweredBy={poweredBy} />
        </Center>
      ) : (
        <div style={{ position: 'relative' }}>
          <Center {...commonPowerIconBadgeStyles} pos="absolute" bottom={0} right={0} p="2px">
            <PowerIcon size={24} poweredBy={poweredBy} />
          </Center>
          <Image
            src={POWERED_EQUIPMENT_IMAGE_MAP[equipment]}
            alt=""
            maw={60}
            mah={60}
            w="auto"
            h="auto"
          />
        </div>
      )}
    </>
  );
};
