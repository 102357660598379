import circleAlert from '../../../../assets/report/StatusIcons/alert-circle.png';
import circleCheck from '../../../../assets/report/StatusIcons/circle-check.png';
import circleDotted from '../../../../assets/report/StatusIcons/circle-dotted.png';
import squareGray from '../../../../assets/report/StatusIcons/square-gray.png';

export const STATUS_ICONS = {
  ALERT: circleAlert,
  CHECK: circleCheck,
  DOTTED: circleDotted,
  SNOOZED: squareGray,
} as const;

export const ICON_SIZE = {
  DEFAULT: '20px',
} as const;
