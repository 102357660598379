// Base interface for all event data - simplified
export interface BaseEventData {
  DeviceId: string;
  Timestamp: string;
}

// Keep the DeviceEventType enum as is
export enum DeviceEventType {
  // Base device events
  AssignedToSetup = 'AssignedToSetupEvent',
  UnassignedFromSetup = 'UnassignedFromSetupEvent',
  AssignedToProject = 'AssignedToProjectEvent',
  UnassignedFromProject = 'UnassignedFromProjectEvent',
  BatteryReplacedEvent = 'BatteryReplacedEvent',

  // PSA events
  PowerUp = 'PowerUpEvent',
  PowerDown = 'PowerDownEvent',
  WarningReceived = 'WarningReceivedEvent',
  WarningTriggered = 'WarningTriggeredEvent',
  IncursionReceived = 'IncursionReceivedEvent',
  IncursionTriggered = 'IncursionTriggeredEvent',
  ResetReceived = 'ResetReceivedEvent',
  ResetTriggered = 'ResetTriggeredEvent',
  ModemReset = 'ModemResetEvent',

  // PSA & Smart Cable events
  Disconnected = 'DisconnectedEvent',

  // IntelliTag events
  SignFallenOver = 'SignFallenOverEvent',
  BarrierCollision = 'BarrierCollisionEvent',
  OutOfBattery = 'OutOfBatteryEvent',
  SensorDataUpdated = 'SensorDataUpdatedEvent',

  // ArrowBoard events
  ArrowChanged = 'ArrowChangedEvent',

  // Fallback
  Unknown = 'Unknown',
}

// Base interface for device events
export interface BaseDeviceEvent {
  id: string;
  deviceId: string;
  timestamp: string;
  eventType: string;
  referenceId: string;
}

// Generic device event - no need for complex type parameters anymore
export interface DeviceEvent extends BaseDeviceEvent {
  type: DeviceEventType;
  eventData: BaseEventData;
}

// Type guard function to check if eventType is valid
export const isValidEventType = (eventType: string): eventType is DeviceEventType => {
  return Object.values(DeviceEventType).includes(eventType as DeviceEventType);
};
