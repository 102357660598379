import { Center, Flex, Group, Paper, Stack, Text } from '@mantine/core';
import { NumberCard } from './NumberCard/NumberCard';
import { BarChart, AreaChart } from '@mantine/charts';
import { DeviceImageWrapper } from '../Map/InfoCard/DeviceInfo/DeviceImageWrapper';
import { Device, DeviceType } from '../../models/Device';
import { AlarmStatus, EquipmentType } from '../../models/enums/DeviceEnums';
import { DeviceRow } from '../../models/SupervisionReport';
import { getDaysBetweenDates, WEEKDAYS_SHORT } from '../../utils/utils';
import { IconChartBarOff } from '@tabler/icons-react';
import { InfoIcon } from '../../shared/IconButton/InfoIcon/InfoIcon';
import { PdfDeviceAlarmEvents } from '../../workers/pdf/utils/alarmEventUtils';
import dayjs from '@/config/dayjsConfig';
import { getLocalDateStr } from '@/utils/dates/dates';
import { calculateMedian, ChartColors } from '@/utils/ChartHelper';
import { useMemo, useCallback, ReactNode } from 'react';
import { StatisticSection } from '../Project/SupervisionReport/constants/enums';
import { StatisticsFilter } from '../Project/SupervisionReport/components/ExportReportModal';

interface Props {
  deviceRows: DeviceRow[];
  startDate: Date;
  endDate: Date;
  filterStats: StatisticsFilter;
}

interface CountResult {
  attachmentRefCounts: Record<string, number>;
  equipmentTypeCounts: Record<number, number>;
}

interface DeviceAlarmPeriod {
  start: Date;
  end: Date | null;
}

interface DeviceAlarmChartConfig {
  segmentWidth: number;
  chartWidth: number;
  segments: number;
  imgPositions: number[];
}

export const StatisticsDashboard = ({ deviceRows, startDate, endDate, filterStats }: Props) => {
  // Memoize statisticsWeek calculation
  const statisticsWeek = useMemo(() => {
    return getDaysBetweenDates(startDate, endDate, WEEKDAYS_SHORT);
  }, [startDate, endDate]);
  // Memoize device uptime calculation function
  const calculateDeviceUptime = useCallback(
    (
      logs: { timeStamp: string; alarmStatus: AlarmStatus }[],
      targetDateStr: string,
      startDate: Date
    ): { online: number; downtime: number } => {
      const HOURS_IN_DAY = 24;
      const targetDate = dayjs(targetDateStr);
      const startOfDay = dayjs(targetDate).startOf('day');
      const endOfDay = dayjs(targetDate).endOf('day');

      const sortedLogs = logs.slice().sort((a, b) => dayjs(a.timeStamp).diff(dayjs(b.timeStamp)));

      // Find all logs before target date
      const logsBeforeDate = sortedLogs.filter((log) => dayjs(log.timeStamp).isBefore(startOfDay));

      // Find the latest log before this day
      const lastLogBeforeDate =
        logsBeforeDate.length > 0 ? logsBeforeDate[logsBeforeDate.length - 1] : null;

      // Find logs for the target date
      const logsForDate = sortedLogs.filter((log) => {
        const logDate = dayjs(log.timeStamp);
        return logDate.isSameOrAfter(startOfDay) && logDate.isSameOrBefore(endOfDay);
      });

      // Special handling for first day in range
      if (targetDate.isSame(startDate, 'day')) {
        const firstLog = sortedLogs[0];
        if (!firstLog || dayjs(firstLog.timeStamp).diff(startDate, 'hour') > 8) {
          return { online: 0, downtime: 0 };
        }
      }

      // If no logs for this day and no previous log, don't include this device
      if (logsForDate.length === 0) {
        return lastLogBeforeDate
          ? lastLogBeforeDate.alarmStatus === AlarmStatus.Alarming
            ? { online: HOURS_IN_DAY, downtime: HOURS_IN_DAY }
            : { online: HOURS_IN_DAY, downtime: 0 }
          : { online: 0, downtime: 0 };
      }

      let downtime = 0;
      let lastLogTime = startOfDay;
      let currentStatus = lastLogBeforeDate ? lastLogBeforeDate.alarmStatus : null;

      // Process each log for the day
      for (const log of logsForDate) {
        const logTime = dayjs(log.timeStamp);
        const hoursBetween = logTime.diff(lastLogTime, 'hour');

        if (currentStatus === AlarmStatus.Alarming) {
          downtime += hoursBetween;
        }

        lastLogTime = logTime;
        currentStatus = log.alarmStatus;
      }

      // Handle the remaining time until end of day
      const remainingHours = endOfDay.diff(lastLogTime, 'hour');
      if (currentStatus === AlarmStatus.Alarming) {
        downtime += remainingHours;
      }

      return {
        online: HOURS_IN_DAY,
        downtime,
      };
    },
    []
  );

  // Memoize device and equipment type counts - use empty defaults when deviceRows is undefined
  const result = useMemo<CountResult>(() => {
    if (!deviceRows) {
      return { attachmentRefCounts: {}, equipmentTypeCounts: {} };
    }
    return deviceRows.reduce<CountResult>(
      (acc, obj) => {
        acc.attachmentRefCounts[obj.attachmentRef] =
          (acc.attachmentRefCounts[obj.attachmentRef] || 0) + 1;
        acc.equipmentTypeCounts[obj.currentEquipmentType] =
          (acc.equipmentTypeCounts[obj.currentEquipmentType] || 0) + 1;
        return acc;
      },
      { attachmentRefCounts: {}, equipmentTypeCounts: {} }
    );
  }, [deviceRows]);

  // Memoize dayMap
  const dayMap = useMemo(() => {
    return statisticsWeek.reduce((a, d) => ({ ...a, [d]: 0 }), {} as Record<string, number>);
  }, [statisticsWeek]);

  // Memoize calculated signDist array
  const signDist = useMemo(() => {
    return Object.entries(result.attachmentRefCounts).map(([key, value]) => ({
      device: {
        attachmentRef: key,
        deviceType: DeviceType.IntellitagV1,
        currentEquipmentType: EquipmentType.Sign,
      } as Device,
      value: value,
    }));
  }, [result.attachmentRefCounts]);

  // Memoize the alarm data processing
  const { signMap, alarmToAddressedTime, processedDayMap } = useMemo(() => {
    // Default empty values
    if (!deviceRows) {
      return {
        signMap: new Map<string, number>(),
        alarmToAddressedTime: [],
        processedDayMap: { ...dayMap },
      };
    }

    const deviceAlarms = new Map<string, DeviceAlarmPeriod[]>();
    const signMap = new Map<string, number>();
    const alarmToAddressedTime: number[] = [];
    const processedDayMap = { ...dayMap };

    // Calculate device alarm events
    const processAlarmEvents = (rows: DeviceRow[]) => {
      const deviceEvents = new Map<string, PdfDeviceAlarmEvents>();

      rows.forEach((row) => {
        let prevAlarmStatus: AlarmStatus | null = null;
        let currentAlarmStart: Date | null = null;

        // Initialize device entry if not exists
        const deviceKey = row.referenceId || row.name;
        const deviceEvent: PdfDeviceAlarmEvents = {
          referenceId: row.referenceId || row.name,
          name: row.name,
          attachmentRef: row.attachmentRef,
          currentEquipmentType: row.currentEquipmentType,
          events: [],
        };
        deviceEvents.set(deviceKey, deviceEvent);

        // Process all logs chronologically
        const sortedLogs = row.entries
          .flatMap((entry) => entry.logs)
          .sort((a, b) => dayjs(a.timeStamp).diff(dayjs(b.timeStamp)));

        sortedLogs.forEach((log, index) => {
          const logDate = dayjs(log.timeStamp).toDate();

          // Track alarm periods for uptime calculation
          if (
            log.alarmStatus === AlarmStatus.Alarming &&
            prevAlarmStatus !== AlarmStatus.Alarming
          ) {
            currentAlarmStart = logDate;
          } else if (
            log.alarmStatus === AlarmStatus.OK &&
            prevAlarmStatus === AlarmStatus.Alarming &&
            currentAlarmStart
          ) {
            const deviceKey = row.referenceId || row.name;
            const deviceAlarmPeriods = deviceAlarms.get(deviceKey) || [];
            deviceAlarmPeriods.push({
              start: currentAlarmStart,
              end: logDate,
            });
            deviceAlarms.set(deviceKey, deviceAlarmPeriods);
            currentAlarmStart = null;
          }

          // Handle first log
          if (index === 0) {
            prevAlarmStatus = log.alarmStatus;
            if (log.alarmStatus === AlarmStatus.Alarming) {
              deviceEvent.events.push({
                alarmReported: log.timeStamp,
                alarmAddressed: '',
              });
            }
            return;
          }

          // Handle status changes
          if (log.alarmStatus !== prevAlarmStatus) {
            if (log.alarmStatus === AlarmStatus.Alarming) {
              deviceEvent.events.push({
                alarmReported: log.timeStamp,
                alarmAddressed: '',
              });
            } else if (
              log.alarmStatus === AlarmStatus.OK &&
              prevAlarmStatus === AlarmStatus.Alarming
            ) {
              const lastEventIndex = deviceEvent.events.findIndex(
                (event) => event.alarmAddressed === ''
              );
              if (lastEventIndex !== -1) {
                deviceEvent.events[lastEventIndex].alarmAddressed = log.timeStamp;
              }
            }
          }
          prevAlarmStatus = log.alarmStatus;
        });

        // Handle still active alarms
        if (currentAlarmStart) {
          const deviceKey = row.referenceId || row.name;
          const deviceAlarmPeriods = deviceAlarms.get(deviceKey) || [];
          deviceAlarmPeriods.push({
            start: currentAlarmStart,
            end: null,
          });
          deviceAlarms.set(deviceKey, deviceAlarmPeriods);
        }
      });

      // Process events for statistics
      deviceEvents.forEach((device) => {
        if (device.events.length) {
          device.events.forEach((event) => {
            const weekday = WEEKDAYS_SHORT[dayjs(event.alarmReported).toDate().getDay()];
            processedDayMap[weekday] += 1;

            if (event.alarmAddressed) {
              const timeBetween = dayjs(event.alarmAddressed).diff(
                dayjs(event.alarmReported),
                'hour'
              );
              alarmToAddressedTime.push(timeBetween);
            }
          });
        }

        // Update sign map
        if (!signMap.has(device.attachmentRef)) {
          signMap.set(device.attachmentRef, 0);
        }
        const alarmCount = device.events.length;
        signMap.set(device.attachmentRef, (signMap.get(device.attachmentRef) ?? 0) + alarmCount);
      });
    };

    processAlarmEvents(deviceRows);

    return { signMap, alarmToAddressedTime, processedDayMap };
  }, [deviceRows, dayMap]);

  // Memoize number of signs calculation
  const numbOfSigns = useMemo(() => {
    return Object.values(result.attachmentRefCounts).reduce((a, b) => a + b, 0);
  }, [result.attachmentRefCounts]);

  // Memoize device daily stats calculation
  const deviceDailyStats = useMemo(() => {
    if (!deviceRows) {
      return new Map<string, Map<string, { online: number; downtime: number }>>();
    }

    const stats = new Map<string, Map<string, { online: number; downtime: number }>>();

    // First, initialize the map for all dates
    let currentDate = dayjs(startDate);
    while (currentDate.isSameOrBefore(endDate)) {
      const dateStr = getLocalDateStr(currentDate.toDate());
      stats.set(dateStr, new Map());
      currentDate = currentDate.add(1, 'day');
    }

    // Process all devices once
    deviceRows.forEach((row) => {
      const logs = row.entries
        .flatMap((entry) => entry.logs)
        .map((log) => ({
          timeStamp: log.timeStamp,
          alarmStatus: log.alarmStatus,
        }));

      // Calculate statistics for each day in the date range
      let currentDate = dayjs(startDate);
      while (currentDate.isSameOrBefore(endDate)) {
        const dateStr = getLocalDateStr(currentDate.toDate());
        const { online, downtime } = calculateDeviceUptime(logs, dateStr, startDate);

        // Only store if the device was active (online > 0)
        if (online > 0) {
          stats.get(dateStr)!.set(row.name, { online, downtime });
        }

        currentDate = currentDate.add(1, 'day');
      }
    });

    return stats;
  }, [deviceRows, startDate, endDate, calculateDeviceUptime]);

  // Memoize weekday uptime calculation
  const weekdayUptime = useMemo(() => {
    const uptime: { [weekday: string]: { total: number; count: number } } = {};

    deviceDailyStats.forEach((deviceStats, dateStr) => {
      const date = dayjs(dateStr);
      const weekday = WEEKDAYS_SHORT[date.day()];

      if (!uptime[weekday]) {
        uptime[weekday] = { total: 0, count: 0 };
      }

      // Calculate average uptime for all devices on this day
      let dayTotal = 0;
      let deviceCount = 0;

      deviceStats.forEach(({ online, downtime }) => {
        if (online === 0) return;

        const deviceUptimePercentage = Math.max(
          0,
          Math.min(100, ((online - downtime) / online) * 100)
        );
        dayTotal += deviceUptimePercentage;
        deviceCount++;
      });

      if (deviceCount > 0) {
        const dayAverage = dayTotal / deviceCount;
        uptime[weekday].total += dayAverage;
        uptime[weekday].count += 1;
      }
    });

    return uptime;
  }, [deviceDailyStats]);

  // Memoize uptime data for chart
  const uptimeData = useMemo(() => {
    return statisticsWeek.map((dayName) => {
      const data = weekdayUptime[dayName];
      // Only show uptime if we have actual data for that day
      const avgUptime = data && data.count > 0 ? data.total / data.count : null;
      // Return null for uptime when we don't have data instead of assuming 100%
      return { day: dayName, uptime: avgUptime?.toFixed(2) || null, max: 100.0 };
    });
  }, [statisticsWeek, weekdayUptime]);

  // Memoize sign data for chart
  const signData = useMemo(() => {
    return Array.from(signMap, ([name, value]) => ({ name, value }));
  }, [signMap]);

  // Memoize chart configuration
  const chartConfig = useMemo<DeviceAlarmChartConfig>(() => {
    const segments = signData.length;
    const segmentWidth = 94;
    const chartWidth = segmentWidth * segments;
    const imgPositions = [];

    // Create positions for every sign in list
    for (let step = 0; step < segments; step++) {
      imgPositions.push(segmentWidth / 2 - 12 + segmentWidth * step);
    }

    return {
      segmentWidth,
      chartWidth,
      segments,
      imgPositions,
    };
  }, [signData.length]);

  // Memoize alarms per day data
  const alarmsPerDay = useMemo(() => {
    return Object.entries(processedDayMap).map(([key, value]) => ({
      day: key,
      value: value,
    }));
  }, [processedDayMap]);

  // Memoize average time calculation
  const avgTime = useMemo(() => {
    return alarmToAddressedTime.length ? calculateMedian(alarmToAddressedTime).toFixed(1) : '-';
  }, [alarmToAddressedTime]);

  // Memoize total number of alarms
  const totalAlarms = useMemo(() => {
    return alarmsPerDay.map((d) => d.value).reduce((sum, a) => sum + a, 0);
  }, [alarmsPerDay]);

  // Implement virtualization for large charts by only rendering visible items
  const renderSignIcons = useMemo(() => {
    return signDist.map((d, i) => (
      <div
        key={i}
        style={{
          left: chartConfig.imgPositions[i],
          display: 'inline-block',
          position: 'absolute',
        }}
      >
        <DeviceImageWrapper width="24px" iconSize={24} baseDevice={d.device} />
      </div>
    ));
  }, [signDist, chartConfig.imgPositions]);

  // Guard clauses with early returns converted to conditional rendering
  let content: ReactNode;

  if (!deviceRows) {
    content = null;
  } else if (statisticsWeek.length <= 1) {
    content = (
      <Center>
        <Stack align="center">
          <IconChartBarOff />
          <Text c={'gray.7'}>Please choose more than one day to show statistics</Text>
        </Stack>
      </Center>
    );
  } else {
    content = (
      <div id="stats" style={{ width: 'fit-content' }}>
        <Group wrap="nowrap" align="stretch">
          <NumberCard
            hide={!filterStats[StatisticSection.AllSigns]}
            key={StatisticSection.AllSigns}
            data={[
              {
                value: numbOfSigns,
                explanation: 'Signs',
              },
            ]}
          />

          <Paper
            withBorder
            p="xl"
            pt="sm"
            key={StatisticSection.Uptime}
            display={!filterStats[StatisticSection.Uptime] ? 'none' : undefined}
          >
            <Group align="center" justify="space-between" mb="md">
              <Text c="gray.6">Uptime (%)</Text>
              <InfoIcon
                textBoxWidth={300}
                text="Uptime is calculated as available time minus downtime, divided by available time (24h * number of devices) per day. Days with insufficient data are not shown."
              />
            </Group>
            <AreaChart
              h={200}
              w={400}
              data={uptimeData}
              dataKey="day"
              series={[{ name: 'uptime', color: 'teal.6' }]}
              yAxisProps={{ domain: [0, 100], padding: { top: 30 } }}
              connectNulls={false}
              tooltipAnimationDuration={200}
              unit="%"
            />
          </Paper>
          <Paper
            w="fit-content"
            withBorder
            p="xl"
            pt="md"
            key={StatisticSection.SignsUsed}
            display={!filterStats[StatisticSection.SignsUsed] ? 'none' : undefined}
          >
            <Text c="gray.6" mb="md">
              Signs used
            </Text>
            <Flex gap="md" direction="column" wrap="wrap" align="center">
              {signDist
                .sort((a, b) => b.value - a.value)
                .map((obj) => (
                  <Group key={obj.device.attachmentRef}>
                    <Text>{result.attachmentRefCounts[obj.device.attachmentRef]}</Text>
                    <DeviceImageWrapper width="24px" iconSize={24} baseDevice={obj.device} />
                  </Group>
                ))}
            </Flex>
          </Paper>
        </Group>
        <Group w="100%" mt="lg" align="stretch" wrap="nowrap">
          <Paper
            withBorder
            p="xl"
            pt="sm"
            key={StatisticSection.AccAlarmsPerDay}
            display={!filterStats[StatisticSection.AccAlarmsPerDay] ? 'none' : undefined}
          >
            <Group align="center" justify="space-between" mb="md">
              <Text c="gray.6">Accumulated alarms per day</Text>
              <InfoIcon
                textBoxWidth={200}
                text="Accumulated alarms per day is the distribution of alarm events during a specific day of the week."
              />
            </Group>
            <BarChart
              w={330}
              h={150}
              barChartProps={{ barSize: 10 }}
              data={alarmsPerDay}
              dataKey="day"
              series={[{ name: 'value', color: ChartColors[0] }]}
              tickLine="y"
              barProps={{ radius: 10 }}
            />
          </Paper>
          <NumberCard
            hide={!filterStats[StatisticSection.FallenOverEvents]}
            key={StatisticSection.FallenOverEvents}
            data={[
              {
                value: totalAlarms,
                explanation: 'Fallen over events',
              },
            ]}
          />
          <NumberCard
            hide={!filterStats[StatisticSection.AdressedEvents]}
            key={StatisticSection.AdressedEvents}
            data={[
              {
                value: alarmToAddressedTime.length,
                explanation: 'Adressed events',
              },
            ]}
          />
        </Group>
        <Group w="fit-content" mt="lg" align="stretch" wrap="nowrap">
          <NumberCard
            hide={!filterStats[StatisticSection.MedianAdressedTime]}
            key={StatisticSection.MedianAdressedTime}
            data={[
              {
                value: `${avgTime} h`,
                explanation: 'Median time between fallen and adressed',
              },
            ]}
          />
          <Paper
            withBorder
            p="xl"
            pt="sm"
            w="fit-content"
            key={StatisticSection.AccAlarmsPerSign}
            display={!filterStats[StatisticSection.AccAlarmsPerSign] ? 'none' : undefined}
          >
            <Text c={'gray.6'} mb={'md'}>
              Accumulated alarms per sign
            </Text>
            <BarChart
              h={150}
              w={chartConfig.chartWidth}
              data={signData}
              barChartProps={{ barSize: 10 }}
              dataKey="sign"
              series={[{ name: 'value', color: ChartColors[2] }]}
              tickLine="y"
              withXAxis={false}
              withYAxis={false}
              withBarValueLabel
              barProps={{ radius: 10 }}
              yAxisProps={{ padding: { top: 30 } }}
            />
            <div
              style={{
                width: chartConfig.chartWidth,
                position: 'relative',
                marginTop: '12px',
              }}
            >
              {renderSignIcons}
            </div>
          </Paper>
        </Group>
      </div>
    );
  }

  return <>{content}</>;
};
