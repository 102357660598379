import jsPDF from 'jspdf';
import { LegendItem } from '@/models/SupervisionReport';
import { PdfBaseProps } from '@/models/PdfExport';
import { DEFAULT_PDF_ASSETS, PDF_CONSTANTS } from '@/constants/pdfConstants';

export interface PdfDocumentOptions {
  projectName: string;
  depot: string;
  startDate: string;
  endDate: string;
  contactPerson?: PdfBaseProps['contactPerson'];
}

export function createPdfDocument(): jsPDF {
  return new jsPDF({
    orientation: 'landscape',
    compress: true,
    putOnlyUsedFonts: true,
  });
}

export function addStatisticPage(doc: jsPDF, statsImg: string, options: PdfDocumentOptions) {
  addHeader(doc, {
    projectName: options.projectName,
    title: 'Intellitag Statistics',
    startDate: options.startDate,
    endDate: options.endDate,
    sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
  });
  const height = 160;
  doc.addImage({
    imageData: statsImg,
    x: PDF_CONSTANTS.SIDE_MARGIN,
    y: 40,
    width: (height / 3) * 4,
    height: height,
    compression: 'FAST',
  });
  doc.addPage();
}

// Add cover page
export function addCoverPage(doc: jsPDF, options: PdfDocumentOptions) {
  const TITLE_Y = 90;

  // Add cover page with optimized image settings
  if (DEFAULT_PDF_ASSETS.coverBackground) {
    doc.addImage({
      imageData: DEFAULT_PDF_ASSETS.coverBackground,
      format: 'PNG',
      x: 0,
      y: 0,
      width: doc.internal.pageSize.getWidth(),
      height: doc.internal.pageSize.getHeight(),
      compression: 'FAST',
      alias: 'cover-bg',
    });
  }
  if (DEFAULT_PDF_ASSETS.primaryLogo) {
    doc.addImage({
      imageData: DEFAULT_PDF_ASSETS.primaryLogo,
      format: 'PNG',
      x: 5,
      y: doc.internal.pageSize.getHeight() - 20,
      width: PDF_CONSTANTS.LOGO.WIDTH * 1.5,
      height: PDF_CONSTANTS.LOGO.HEIGHT * 1.5,
      compression: 'FAST',
      alias: 'primary-logo',
    });
  }
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.BOLD, 'bold');
  doc.setFontSize(28);
  doc.setTextColor(PDF_CONSTANTS.COLORS.SAND);
  doc.text(options.projectName, 8, TITLE_Y);
  doc.setFontSize(20);
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.NORMAL, 'normal');
  if (options.depot) doc.text(`Depot: ${options.depot}`, 8, TITLE_Y + 12);
  doc.text(`${options.startDate} - ${options.endDate}`, 8, TITLE_Y + (options.depot ? 22 : 12));

  // Always add a blank page after cover page to ensure content starts on page 2
  doc.addPage();
}

export function addLogo(doc: jsPDF, pageWidth: number) {
  if (DEFAULT_PDF_ASSETS.primaryLogo) {
    doc.addImage(
      DEFAULT_PDF_ASSETS.primaryLogo,
      'PNG',
      pageWidth - PDF_CONSTANTS.LOGO.WIDTH - PDF_CONSTANTS.SIDE_MARGIN,
      4,
      PDF_CONSTANTS.LOGO.WIDTH,
      PDF_CONSTANTS.LOGO.HEIGHT,
      undefined,
      'FAST'
    );
  }
}

// Add last page
export function addLastPage(doc: jsPDF, contactPerson: PdfBaseProps['contactPerson']) {
  const LOGO_WIDTH = PDF_CONSTANTS.LOGO.WIDTH * 5;
  const LOGO_HEIGHT = PDF_CONSTANTS.LOGO.HEIGHT * 5;
  const LOGO_X = doc.internal.pageSize.getWidth() / 2 - LOGO_WIDTH / 2;
  const LOGO_Y =
    doc.internal.pageSize.getHeight() / 2 - LOGO_HEIGHT / 2 - (contactPerson?.name ? 20 : 0);

  doc.addPage();
  doc.setFillColor(PDF_CONSTANTS.COLORS.YELLOW);
  doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');
  if (DEFAULT_PDF_ASSETS.primaryLogoWhite) {
    doc.addImage({
      imageData: DEFAULT_PDF_ASSETS.primaryLogoWhite,
      format: 'PNG',
      x: LOGO_X,
      y: LOGO_Y,
      width: LOGO_WIDTH,
      height: LOGO_HEIGHT,
      compression: 'FAST',
      alias: 'primary-logo-white',
    });
  }

  if (!contactPerson?.name) return;

  const TEXT_X = LOGO_X + 16;
  const TEXT_Y = doc.internal.pageSize.getHeight() - 60;
  doc.setTextColor('black');
  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.HEADING);
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.BOLD, 'bold');
  doc.text('Contact person', TEXT_X, TEXT_Y);

  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.SUBHEADING);
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.NORMAL, 'normal');
  doc.text(`Name: ${contactPerson.name}`, TEXT_X, TEXT_Y + 8);

  if (contactPerson.phone) {
    doc.text(`Phone: ${contactPerson.phone}`, TEXT_X, TEXT_Y + 16);
  }

  if (contactPerson.email) {
    doc.text(`Email: ${contactPerson.email}`, TEXT_X, TEXT_Y + (contactPerson.phone ? 24 : 16));
  }
}

interface HeaderOptions {
  projectName: string;
  title: string;
  startDate: string;
  endDate: string;
  sideMargin?: number;
  topMargin?: number;
}

export function addHeader(doc: jsPDF, options: HeaderOptions) {
  const SUB_HEADER_SIDE_MARGIN = 4;
  const SUB_HEADER_Y = PDF_CONSTANTS.TOP_MARGIN + 13.5;
  const SUB_HEADER_TEXT_Y = SUB_HEADER_Y + PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG / 2;
  const PAGE_WIDTH = doc.internal.pageSize.getWidth();
  const LEFT_POS = PDF_CONSTANTS.SIDE_MARGIN;
  const RIGHT_POS = PAGE_WIDTH - PDF_CONSTANTS.SIDE_MARGIN;
  const HEADER_Y = PDF_CONSTANTS.SPACING.HEADER_TOP + 6;

  // Client name and date range
  doc.setTextColor('black');
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.BOLD, 'bold');
  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.TITLE);

  // Client name (left aligned)
  doc.text(options.projectName, LEFT_POS, HEADER_Y);

  // Logo (right aligned) - with caching
  doc.addImage({
    imageData: DEFAULT_PDF_ASSETS.primaryLogo,
    format: 'PNG',
    x: RIGHT_POS - PDF_CONSTANTS.LOGO.WIDTH * 1.4,
    y: PDF_CONSTANTS.SPACING.HEADER_TOP - 6,
    width: PDF_CONSTANTS.LOGO.WIDTH * 1.4,
    height: PDF_CONSTANTS.LOGO.HEIGHT * 1.4,
    compression: 'FAST',
    alias: 'header-logo',
  });

  // SUB HEADING
  // BACKGROUND
  doc.setFillColor(PDF_CONSTANTS.COLORS.GREEN_PRIMARY);
  doc.rect(
    LEFT_POS,
    SUB_HEADER_Y,
    PAGE_WIDTH - (options.sideMargin || PDF_CONSTANTS.SIDE_MARGIN) * 2,
    PDF_CONSTANTS.HEIGHTS.SUB_HEADER_BG,
    'F'
  );

  // TEXT
  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.SUBHEADING);
  doc.setTextColor('white');
  // Left Text (Title)
  doc.text(options.title, LEFT_POS + SUB_HEADER_SIDE_MARGIN, SUB_HEADER_TEXT_Y, {
    baseline: 'middle',
  });
  // Right text (Date range)
  doc.text(
    `${options.startDate} - ${options.endDate}`,
    RIGHT_POS - SUB_HEADER_SIDE_MARGIN,
    SUB_HEADER_TEXT_Y,
    {
      align: 'right',
      baseline: 'middle',
    }
  );
}

export function createDocumentName(
  projectName: string,
  startDate: string,
  endDate: string,
  reportType?: string
): string {
  const documentTitle = `${projectName} ${startDate} ${endDate}${reportType ? ` ${reportType}` : ''}`;
  return `${documentTitle.split(' ').join('_')}.pdf`;
}

export function addLegend(doc: jsPDF, items: LegendItem[], pageHeight: number) {
  const ICON_SIZE = 4;
  const ICON_TEXT_GAP = 4;
  let currentX = PDF_CONSTANTS.SIDE_MARGIN;
  const y = pageHeight - PDF_CONSTANTS.SIDE_MARGIN;

  items.forEach((item, index) => {
    if (item.img) {
      doc.addImage({
        imageData: item.img,
        format: 'PNG',
        x: currentX,
        y: y - ICON_SIZE,
        width: ICON_SIZE,
        height: ICON_SIZE,
        compression: 'FAST',
        alias: `legend-icon-${index}`,
      });
    }
    doc.text(item.text, currentX + ICON_SIZE + ICON_TEXT_GAP, y, { baseline: 'bottom' });
    currentX += ICON_SIZE + ICON_TEXT_GAP + doc.getTextWidth(item.text) + 10;
  });
}

export function addCo2Graphs(doc: jsPDF, props: { img: string; totalCo2: string }) {
  const { img, totalCo2 } = props;
  const y_pos = 60;
  const height = 80;
  const width = (height / 2) * 3;
  const pageWidth = doc.internal.pageSize.getWidth();

  // Add graph image
  doc.addImage({
    imageData: img,
    x: pageWidth / 2 - width / 2,
    y: y_pos,
    width: width,
    height: height,
  });

  // Add graph headings
  doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.HEADING);
  doc.setTextColor(0, 0, 0);
  doc.text(`CO2 emissions per week`, pageWidth / 2, y_pos - 4, {
    align: 'center',
  });

  // Add total CO2 avoided heading and number
  doc.text(`Total CO2 avoided:`, pageWidth / 2 - 16, y_pos + height + 30, {
    align: 'center',
  });
  doc.setFont(PDF_CONSTANTS.FONT.FAMILY.NORMAL, 'normal');
  doc.text(`${totalCo2} kg`, pageWidth / 2 + 12, y_pos + height + 30, {
    align: 'left',
  });
}

export function applyPageFooter(
  doc: jsPDF,
  fixedLegendItems?: LegendItem[],
  includeStatistics?: boolean,
  getLegendItems?: (pageNum: number) => LegendItem[]
): void {
  const totalPages = doc.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Iterate over pages 2 to totalPages-1 (exclude cover and last page)
  for (let i = 2; i < totalPages; i++) {
    doc.setPage(i);
    const pageNumber = i - 1; // Adjust page number (cover page not counted)
    const adjustedTotalPages = totalPages - 2; // Exclude cover and last page

    doc.setFontSize(PDF_CONSTANTS.FONT.SIZES.BODY);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Page ${pageNumber} of ${adjustedTotalPages}`,
      pageWidth - PDF_CONSTANTS.SIDE_MARGIN - 20,
      pageHeight - PDF_CONSTANTS.SIDE_MARGIN,
      { align: 'right' }
    );

    let legendItems: LegendItem[] = [];
    if (getLegendItems) {
      legendItems = getLegendItems(i);
    } else if (fixedLegendItems) {
      legendItems = fixedLegendItems;
    }

    if (includeStatistics && i == 2) legendItems = [];
    addLegend(doc, legendItems, pageHeight);
  }
}

export function drawTableBorders(
  doc: jsPDF,
  tableStartY: number,
  tableWidth: number,
  tableEndY: number,
  isLastPage: boolean
): void {
  // Set draw color and line width
  doc.setDrawColor(220, 220, 220);
  doc.setLineWidth(0.5);

  // Draw top line
  doc.line(
    PDF_CONSTANTS.SIDE_MARGIN,
    tableStartY,
    PDF_CONSTANTS.SIDE_MARGIN + tableWidth,
    tableStartY
  );
  // Draw left side line
  doc.line(PDF_CONSTANTS.SIDE_MARGIN, tableStartY, PDF_CONSTANTS.SIDE_MARGIN, tableEndY);
  // Draw right side line
  doc.line(
    PDF_CONSTANTS.SIDE_MARGIN + tableWidth,
    tableStartY,
    PDF_CONSTANTS.SIDE_MARGIN + tableWidth,
    tableEndY
  );
  // Draw bottom line only on last page
  if (isLastPage) {
    doc.line(
      PDF_CONSTANTS.SIDE_MARGIN,
      tableEndY,
      PDF_CONSTANTS.SIDE_MARGIN + tableWidth,
      tableEndY
    );
  }
}

/**
 * Standard legend item types for PDF reports
 */
export enum LegendItemType {
  OK,
  Fallen,
  NoLog,
  Snoozed,
  BatteryOK,
  PowerlessDisconnected,
}

/**
 * Generate standard legend items based on specified types
 * @param types Array of legend item types to include
 * @param statusIcons Object containing status icon images
 * @returns Array of LegendItem objects
 */
export function getStandardLegendItems(
  types: LegendItemType[],
  statusIcons?: {
    check?: string;
    alert?: string;
    dotted?: string;
    snoozed?: string;
  }
): LegendItem[] {
  const items: LegendItem[] = [];

  for (const type of types) {
    switch (type) {
      case LegendItemType.OK:
      case LegendItemType.BatteryOK:
        items.push({ text: 'OK', img: statusIcons?.check || '' });
        break;
      case LegendItemType.Fallen:
        items.push({ text: 'Fallen', img: statusIcons?.alert || '' });
        break;
      case LegendItemType.PowerlessDisconnected:
        items.push({ text: 'Powerless/Disconnected', img: statusIcons?.alert || '' });
        break;
      case LegendItemType.NoLog:
        items.push({ text: 'No data', img: statusIcons?.dotted || '' });
        break;
      case LegendItemType.Snoozed:
        items.push({ text: 'Snoozed', img: statusIcons?.snoozed || '' });
        break;
    }
  }

  return items;
}
