import { forwardRef, useImperativeHandle, useState, useMemo } from 'react';
import { Stack, Group, Text, Center } from '@mantine/core';
import { PdfExportRef, PdfGenerationResult } from '@/models/PdfExport';
import {
  DeviceRow,
  BatteryDeviceStatus,
  BatteryMonitorReportDto,
} from '@/models/SupervisionReport';
import {
  AlarmStatus,
  PoweredEquipmentNames,
  PoweredEquipmentIcons,
} from '@/models/enums/DeviceEnums';
import dayjs from '@/config/dayjsConfig';
import { DashboardHeader } from '../common/DashboardHeader';
import { DashboardTable, DashboardTableColumn } from '../common/DashboardTable';
import { STATUS_ICONS } from '../../constants/statusIcons';
import { ContactPerson } from '@/models/Project';
import { AlarmEventsDrawer, DeviceData } from '../common/AlarmEventsDrawer';
import { useAlarmDrawer } from '../../hooks/useAlarmDrawer';
import { AlarmLog, prepareLogsForDrawer } from '../../utils/alarmDrawerUtils';
import useTimeSegments from '@/hooks/useTimeSegments';
import { SnoozeDuration } from '@/models/SnoozeDurationsDto';

// Extract this outside of the component to avoid recreating it on each render
const convertToDashboardRow = (
  row: DeviceRow,
  startDate: string,
  endDate: string
): BatteryMonitorReportDto['rows'][0] => {
  return {
    referenceId: row.referenceId,
    name: row.name,
    deviceId: row.deviceId,
    attachmentRef: row.attachmentRef,
    poweredEquipment: row.poweredEquipment,
    entries: row.entries,
    events: row.entries.flatMap((entry) =>
      entry.logs
        .filter((log) => {
          const logDate = dayjs(log.timeStamp);
          const start = dayjs(startDate);
          const end = dayjs(endDate);
          return (
            (logDate.isAfter(start) || logDate.isSame(start)) &&
            (logDate.isBefore(end) || logDate.isSame(end))
          );
        })
        .map((log) => ({
          timestamp: log.timeStamp,
          eventType:
            log.alarmStatus === AlarmStatus.Alarming
              ? BatteryDeviceStatus.POWERLESS
              : BatteryDeviceStatus.OK,
        }))
    ),
  };
};
export interface BatteryMonitorDashboardProps {
  data?: { rows: DeviceRow[]; intervals: string[] };
  reportStartDate: Date;
  reportEndDate: Date;
  projectName: string;
  contactPerson?: ContactPerson;
  depot: string;
  countryCode: string;
  customerName: string;
  headerTitle: string;
  createPDF: (params: {
    rows: { rows: BatteryMonitorReportDto['rows'] };
    projectName: string;
    reportStartDate: Date;
    reportEndDate: Date;
    contactPerson?: ContactPerson;
    depot: string;
    countryCode: string;
    customerName: string;
  }) => Promise<void>;
  snoozeDurations: SnoozeDuration[];
}

const BatteryMonitorDashboard = forwardRef<PdfExportRef, BatteryMonitorDashboardProps>(
  (props, ref) => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [showSnoozed, setShowSnoozed] = useState(true);

    const {
      isDrawerOpen,
      selectedDeviceData,
      openDrawer: handleOpenDrawer,
      closeDrawer: handleCloseDrawer,
    } = useAlarmDrawer<DeviceRow>();

    const timeSegments = useTimeSegments(props.data?.intervals, 24, props.countryCode);

    // Memoize filtered rows to avoid recalculating on every render
    const filteredRows = useMemo(() => {
      return props.data?.rows ?? [];
    }, [props.data?.rows]);

    // Memoize unique days
    const uniqueDays = useMemo(() => {
      return (
        props.data?.intervals
          .map((interval) => dayjs(interval).format('YYYY-MM-DD'))
          .filter((date, index, self) => self.indexOf(date) === index) ?? []
      );
    }, [props.data?.intervals]);

    // Memoize dashboard rows conversion
    const dashboardRows = useMemo(() => {
      return filteredRows.map((row) =>
        convertToDashboardRow(
          row,
          props.reportStartDate.toISOString(),
          props.reportEndDate.toISOString()
        )
      );
    }, [filteredRows, props.reportStartDate, props.reportEndDate]);

    const handleRowClick = (row: DeviceRow) => {
      handleOpenDrawer(row);
    };

    const getLogsForDrawer = (): AlarmLog[] => {
      return prepareLogsForDrawer(selectedDeviceData, (_, index) => {
        const segment = timeSegments[index];
        return segment ? segment.date : '';
      });
    };

    // Pre-calculate status for each cell
    const statusCache = useMemo(() => {
      const cache = new Map<string, string>();

      dashboardRows.forEach((row) => {
        const deviceId = row.deviceId;
        const events = row.events || [];

        uniqueDays.forEach((day) => {
          const cacheKey = `${deviceId}-${day}`;

          const dayStart = dayjs.utc(day).startOf('day');
          const dayEnd = dayjs.utc(day).endOf('day');

          const eventsInDay = events.filter((event) => {
            const eventTime = dayjs.utc(event.timestamp);
            return eventTime.isSameOrAfter(dayStart) && eventTime.isSameOrBefore(dayEnd);
          });

          if (eventsInDay.length === 0) {
            cache.set(cacheKey, STATUS_ICONS.DOTTED);
          } else {
            const hasAlarm = eventsInDay.some(
              (event) => event.eventType === BatteryDeviceStatus.POWERLESS
            );
            cache.set(cacheKey, hasAlarm ? STATUS_ICONS.ALERT : STATUS_ICONS.CHECK);
          }
        });
      });

      return cache;
    }, [dashboardRows, uniqueDays]);

    useImperativeHandle(
      ref,
      () => ({
        exportPDF: async (): Promise<PdfGenerationResult> => {
          await props.createPDF({
            rows: { rows: dashboardRows },
            projectName: props.projectName,
            reportStartDate: props.reportStartDate,
            reportEndDate: props.reportEndDate,
            contactPerson: props.contactPerson,
            depot: props.depot,
            countryCode: props.countryCode,
            customerName: props.customerName,
          });

          return {
            success: true,
          };
        },
        getBatteryMonitorRows: () => dashboardRows,
      }),
      [dashboardRows, props]
    );

    const getPoweredEquipmentText = (equipment?: number): React.ReactNode => {
      if (equipment === undefined || equipment === null) return <Text>-</Text>;
      const text = PoweredEquipmentNames[equipment as keyof typeof PoweredEquipmentNames] || '-';
      const icon = PoweredEquipmentIcons[equipment as keyof typeof PoweredEquipmentIcons];

      return (
        <Group gap="xs" wrap="nowrap" justify="center">
          {icon ? (
            <Center>
              <img src={icon} alt={text} style={{ width: '20px', height: '20px' }} />
            </Center>
          ) : (
            <Text>{text}</Text>
          )}
        </Group>
      );
    };

    const legendItems = [
      { text: 'OK', img: STATUS_ICONS.CHECK },
      { text: 'Powerless', img: STATUS_ICONS.ALERT },
      { text: 'No Logs', img: STATUS_ICONS.DOTTED },
      { text: 'Snoozed', img: STATUS_ICONS.SNOOZED },
    ];
    const renderAttachment = () => {
      if (!selectedDeviceData) return null;
      return getPoweredEquipmentText(selectedDeviceData.poweredEquipment);
    };

    // Memoize column definitions
    const columns = useMemo(
      (): DashboardTableColumn<DeviceRow>[] => {
        const getStatusForDay = (row: DeviceRow, date: string) => {
          const cacheKey = `${row.deviceId}-${date}`;
          const statusIcon = statusCache.get(cacheKey) || STATUS_ICONS.DOTTED;

          return (
            <Center>
              <img
                src={statusIcon}
                alt={
                  statusIcon === STATUS_ICONS.CHECK
                    ? 'OK'
                    : statusIcon === STATUS_ICONS.ALERT
                      ? 'Alarming'
                      : 'No Logs'
                }
                style={{ width: '20px', height: '20px' }}
              />
            </Center>
          );
        };

        return [
          {
            header: 'Reference Id',
            minWidth: '120px',
            render: (row) => row.referenceId,
          },
          {
            header: 'Additional info',
            minWidth: '120px',
            render: (row) => row.name,
          },
          {
            header: 'Powered Equipment',
            minWidth: '100px',
            render: (row) => getPoweredEquipmentText(row.poweredEquipment),
          },
          ...uniqueDays.map((day) => ({
            header: (
              <Stack py={10}>
                <Text fw={700} size="sm" style={{ whiteSpace: 'nowrap' }}>
                  {dayjs(day).format('ddd, D/M')}
                </Text>
              </Stack>
            ),
            minWidth: '60px',
            render: (row: DeviceRow) => getStatusForDay(row, day),
          })),
        ];
      },
      [uniqueDays, statusCache] // statusCache is now a dependency instead of getStatusForDay
    );

    return (
      <Stack>
        <DashboardHeader
          title={props.headerTitle}
          interval="24h"
          legendItems={legendItems}
          showSnoozed={showSnoozed}
          onShowSnoozedChange={setShowSnoozed}
        />

        <DashboardTable<DeviceRow>
          rows={filteredRows}
          columns={columns}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          onRowClick={handleRowClick}
          snoozeDurations={props.snoozeDurations}
          intervals={props.data?.intervals ?? []}
          showSnoozed={showSnoozed}
        />

        <AlarmEventsDrawer
          isOpen={isDrawerOpen}
          onClose={handleCloseDrawer}
          deviceData={selectedDeviceData as unknown as DeviceData}
          allLogs={getLogsForDrawer()}
          reportStartDate={props.reportStartDate}
          reportEndDate={props.reportEndDate}
          countryCode={props.countryCode}
          renderAttachment={renderAttachment}
        />
      </Stack>
    );
  }
);

export default BatteryMonitorDashboard;
