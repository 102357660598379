import {
  Badge,
  Button as MantineButton,
  Center,
  Group,
  Loader,
  SegmentedControl,
  Stack,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { APIProvider, useApiIsLoaded } from '@vis.gl/react-google-maps';
import { GoogleMap } from '../components/Map/GoogleMap';
import { useCallback, useMemo, useState } from 'react';
import { FilterOptions, MapFilterDrawer } from '../components/Map/FilterDrawer/MapFilterDrawer';
import { IconX, IconChevronDown } from '@tabler/icons-react';
import { useSessionStorage } from '@mantine/hooks';
import { DeviceTypeNames } from '../models/Device';
import { Button } from '@/components/Button/Button';

export type ViewMode = 'devices' | 'projects';

export const MapPage = () => {
  const apiIsLoaded = useApiIsLoaded();
  const [viewMode, setViewMode] = useState<ViewMode>('projects');
  const theme = useMantineTheme();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useSessionStorage<FilterOptions>({
    key: 'mapFilters',
    defaultValue: {
      devices: {
        deviceTypes: [],
        assignmentStatus: [],
        alertingStatus: [],
      },
      projects: {
        showActiveProjects: true,
        showDemoProjects: false,
        showClosedProjects: false,
      },
    },
  });

  const getFilterBadges = useCallback((): Array<{ text: string; type: string }> => {
    const badges: Array<{ text: string; type: string }> = [];

    if (viewMode === 'projects') {
      if (filters.projects.showActiveProjects) {
        badges.push({ text: 'Active', type: 'active' });
      }
      if (filters.projects.showDemoProjects) {
        badges.push({ text: 'Demo', type: 'demo' });
      }
      if (filters.projects.showClosedProjects) {
        badges.push({ text: 'Closed', type: 'closed' });
      }

      // If no filters are applied
      if (badges.length === 0) {
        badges.push({ text: 'All projects', type: 'default' });
      }
    } else {
      // Device types
      filters.devices.deviceTypes.forEach((type) => {
        badges.push({ text: DeviceTypeNames[type], type: `deviceType-${type}` });
      });

      // Assignment status
      if (filters.devices.assignmentStatus.includes('assigned')) {
        badges.push({ text: 'Assigned', type: 'assigned' });
      }
      if (filters.devices.assignmentStatus.includes('unassigned')) {
        badges.push({ text: 'Unassigned', type: 'unassigned' });
      }

      // Alerting status
      filters.devices.alertingStatus.forEach((status) => {
        switch (status) {
          case 'alarm':
            badges.push({ text: 'Alarming', type: 'alarm' });
            break;
          case 'blueAlarm':
            badges.push({ text: 'Alerting', type: 'blueAlarm' });
            break;
          case 'outOfBattery':
            badges.push({ text: 'Out of battery', type: 'outOfBattery' });
            break;
          case 'criticalBattery':
            badges.push({ text: 'Critical battery', type: 'criticalBattery' });
            break;
          case 'lowBattery':
            badges.push({ text: 'Low battery', type: 'lowBattery' });
            break;
        }
      });

      // If no filters are applied
      if (badges.length === 0) {
        badges.push({ text: 'All devices', type: 'default' });
      }
    }

    return badges;
  }, [filters, viewMode]);

  const handleOpenFilterDrawer = () => {
    setIsFilterDrawerOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setIsFilterDrawerOpen(false);
  };

  const handleFiltersChange = (newFilters: FilterOptions) => {
    setFilters(newFilters);
  };

  // Calculate the total number of active filters
  const activeFiltersCount =
    viewMode === 'projects'
      ? (filters.projects.showActiveProjects ? 1 : 0) +
        (filters.projects.showDemoProjects ? 1 : 0) +
        (filters.projects.showClosedProjects ? 1 : 0)
      : filters.devices.deviceTypes.length +
        filters.devices.alertingStatus.length +
        (filters.devices.assignmentStatus?.length || 0);

  // Handle removing a filter when clicking the X on a badge
  const handleRemoveFilter = useCallback(
    (filterType: string) => {
      if (viewMode === 'projects') {
        if (filterType === 'active') {
          setFilters({
            ...filters,
            projects: {
              ...filters.projects,
              showActiveProjects: false,
            },
          });
        } else if (filterType === 'demo') {
          setFilters({
            ...filters,
            projects: {
              ...filters.projects,
              showDemoProjects: false,
            },
          });
        } else if (filterType === 'closed') {
          setFilters({
            ...filters,
            projects: {
              ...filters.projects,
              showClosedProjects: false,
            },
          });
        }
      } else {
        if (filterType.startsWith('deviceType-')) {
          const deviceTypeId = Number(filterType.replace('deviceType-', ''));
          setFilters({
            ...filters,
            devices: {
              ...filters.devices,
              deviceTypes: filters.devices.deviceTypes.filter((type) => type !== deviceTypeId),
            },
          });
        } else if (filterType === 'assigned' || filterType === 'unassigned') {
          setFilters({
            ...filters,
            devices: {
              ...filters.devices,
              assignmentStatus: filters.devices.assignmentStatus.filter(
                (status) => status !== filterType
              ),
            },
          });
        } else {
          // Handle alert status filters
          const alertStatuses = [
            'alarm',
            'blueAlarm',
            'outOfBattery',
            'criticalBattery',
            'lowBattery',
          ];
          if (alertStatuses.includes(filterType)) {
            setFilters({
              ...filters,
              devices: {
                ...filters.devices,
                alertingStatus: filters.devices.alertingStatus.filter(
                  (status) => status !== filterType
                ),
              },
            });
          }
        }
      }
    },
    [filters, setFilters, viewMode]
  );

  const renderBadges = useMemo(() => {
    return getFilterBadges().map((badge, index) => (
      <Badge
        key={`${badge.type}-${index}`}
        variant="light"
        size="xl"
        color="black"
        bg="var(--mantine-color-primaryGreenLight-1)"
        tt="capitalize"
        fw={300}
        rightSection={
          badge.type !== 'default' && (
            <MantineButton
              style={{
                cursor: 'pointer',
                background: 'transparent',
                padding: 0,
              }}
              onClick={() => {
                handleRemoveFilter(badge.type);
              }}
            >
              <IconX size={18} color="black" />
            </MantineButton>
          )
        }
      >
        {badge.text}
      </Badge>
    ));
  }, [getFilterBadges, handleRemoveFilter]);

  return (
    <>
      {apiIsLoaded ? (
        <Center>
          <Loader mt="xl" size="md"></Loader>
        </Center>
      ) : (
        <Stack gap={0}>
          <Group
            p="md"
            bg="white"
            justify="space-between"
            style={{ borderBottom: `1px solid ${theme.colors.dividerGrey[0]}` }}
          >
            <Group>
              <Title size="h4" order={2}>
                Filters:
              </Title>
              {renderBadges}
            </Group>
            <Group>
              <SegmentedControl
                data={[
                  { label: 'Projects', value: 'projects' },
                  { label: 'Devices', value: 'devices' },
                ]}
                value={viewMode}
                onChange={(value) => setViewMode(value as ViewMode)}
              />
              <Button
                variant="primary"
                onClick={handleOpenFilterDrawer}
                _rightIcon={IconChevronDown}
              >
                {activeFiltersCount > 0 ? `Open filter (${activeFiltersCount})` : 'Open filter'}
              </Button>
            </Group>
          </Group>

          <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
            <GoogleMap viewMode={viewMode} filters={filters} />
          </APIProvider>

          <MapFilterDrawer
            isOpen={isFilterDrawerOpen}
            onClose={handleCloseFilterDrawer}
            viewMode={viewMode}
            filters={filters}
            onFiltersChange={handleFiltersChange}
          />
        </Stack>
      )}
    </>
  );
};
