import { ActionIcon, Checkbox, Group, Paper, Stack, TextInput, Title } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import parsePhoneNumberFromString from 'libphonenumber-js/min';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AddManualContact } from '@/components/Project/AddManualContact/AddManualContact';
import { Button } from '@/components/Button/Button';

/**
 * @deprecated - upgrade to {@link AddManualContact} if possible.
 */
export const AddRecipiant = (props: {
  width: string;
  onSubmit: (props: { name: string; phone: string | undefined; email: string | undefined }) => void;
  onClose: () => void;
}) => {
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
      phone: '',
      email: '',
      consent: false, // Added for consent
    },
    validate: {
      name: isNotEmpty('You must provide a name'),
      phone: (value, values) => {
        if (!value && !values.email) {
          return 'Please provide either a valid phone number or email address';
        }
        if (value) {
          const phoneNumber = parsePhoneNumberFromString(value);
          return phoneNumber && phoneNumber.isValid() ? null : 'Invalid phone number format';
        }
        return null;
      },
      email: (value, values) => {
        if (!value && !values.phone) {
          return 'Please provide either a valid phone number or email address';
        }
        if (value) {
          const emailValidator = isEmail('Invalid email format');
          return emailValidator(value);
        }
        return null;
      },
      consent: (value) => (value ? null : 'You must confirm consent to add this recipient'), // Consent validation
    },
    validateInputOnBlur: true,
  });

  return (
    <>
      <Paper bg={'gray.0'} w={props.width} shadow="xs" p={'md'}>
        <form
          onSubmit={form.onSubmit((values) => {
            props.onSubmit({
              name: values.name,
              phone: values.phone ? values.phone : undefined,
              email: values.email ? values.email : undefined,
            });
          })}
        >
          <Group justify="space-between">
            <Title order={5}>Customer Contact Details</Title>
            <ActionIcon variant="subtle" onClick={() => props.onClose()}>
              <IconX />
            </ActionIcon>
          </Group>
          <Stack mt={'md'} gap={'sm'}>
            <TextInput
              placeholder="Name*"
              withAsterisk
              key={form.key('name')}
              {...form.getInputProps('name')}
            />
            <TextInput
              placeholder="Phone number"
              key={form.key('phone')}
              {...form.getInputProps('phone')}
            />
            <TextInput
              placeholder="Email address"
              key={form.key('email')}
              {...form.getInputProps('email')}
            />

            {/* Consent Checkbox */}
            <Checkbox
              label="I confirm that I have obtained consent from this individual."
              key={form.key('consent')}
              {...form.getInputProps('consent', { type: 'checkbox' })}
            />

            <Group justify="flex-end" mt="md">
              <Button variant="primary" type="submit">
                Add
              </Button>
            </Group>
          </Stack>
        </form>
      </Paper>
    </>
  );
};
