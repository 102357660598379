import { ReportType } from '@/models/enums/ReportType';
import { DeviceRow } from '../../../models/SupervisionReport';
import {
  AlarmStatus,
  EquipmentType,
  PoweredEquipmentNames,
} from '../../../models/enums/DeviceEnums';
import { formatTimeByCountry } from '../../../utils/utils';
import dayjs from '@/config/dayjsConfig';

interface AlarmLog {
  timeStamp: string;
  alarmStatus: AlarmStatus;
}

export interface PdfAlarmEvent {
  alarmReported: string;
  alarmAddressed: string;
}

export interface PdfDeviceAlarmEvents {
  referenceId: string;
  name: string;
  attachmentRef: string;
  currentEquipmentType: EquipmentType;
  poweredEquipment?: number;
  events: PdfAlarmEvent[];
}

/**
 * Represents a row in the alarm events table
 * For battery monitors, the attachment property will be a string representation of the equipment number
 * For intellitags, it will be a string like "Barrier" or "Sign"
 */
export interface PdfTableRow {
  referenceId: string;
  name: string;
  attachment: string;
  alarmReported: string;
  alarmAddressed: string;
}

export function processAlarmEvents(rows: DeviceRow[]): PdfDeviceAlarmEvents[] {
  // Use a composite key to distinguish devices
  const deviceEvents = new Map<string, PdfDeviceAlarmEvents>();
  const deviceLogs = new Map<string, AlarmLog[]>();

  // Accumulate device details and logs from each row
  rows.forEach((row) => {
    const deviceKey = `${row.referenceId}-${row.attachmentRef}`;
    if (!deviceEvents.has(deviceKey)) {
      deviceEvents.set(deviceKey, {
        referenceId: row.referenceId,
        name: row.name,
        attachmentRef: row.attachmentRef,
        currentEquipmentType: row.currentEquipmentType,
        poweredEquipment: row.poweredEquipment,
        events: [],
      });
    }
    if (!deviceLogs.has(deviceKey)) {
      deviceLogs.set(deviceKey, []);
    }
    const logs = row.entries.flatMap((entry) => entry.logs);
    deviceLogs.get(deviceKey)?.push(...logs);
  });

  // Process accumulated logs for each device
  deviceLogs.forEach((logs, key) => {
    logs.sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
    const device = deviceEvents.get(key);
    if (!device || logs.length === 0) return;

    let lastAlarmStatus = logs[0].alarmStatus;
    if (logs[0].alarmStatus === AlarmStatus.Alarming) {
      device.events.push({
        alarmReported: logs[0].timeStamp,
        alarmAddressed: '',
      });
    }

    for (let i = 1; i < logs.length; i++) {
      const log = logs[i];
      if (log.alarmStatus === AlarmStatus.Alarming && lastAlarmStatus !== AlarmStatus.Alarming) {
        device.events.push({
          alarmReported: log.timeStamp,
          alarmAddressed: '',
        });
      } else if (log.alarmStatus === AlarmStatus.OK && lastAlarmStatus === AlarmStatus.Alarming) {
        const lastEvent = device.events[device.events.length - 1];
        if (lastEvent && !lastEvent.alarmAddressed) {
          lastEvent.alarmAddressed = log.timeStamp;
        }
      }
      lastAlarmStatus = log.alarmStatus;
    }
  });

  return Array.from(deviceEvents.values())
    .filter((device) => device.events.length > 0)
    .map((device) => ({
      ...device,
      events: device.events.sort(
        (a, b) => new Date(b.alarmReported).getTime() - new Date(a.alarmReported).getTime()
      ),
    }))
    .sort((a, b) => a.referenceId.localeCompare(b.referenceId));
}

export function formatTimeWithDate(dateString: string, countryCode: string): string {
  if (!dateString) return '-';

  // Set locale based on country code
  const locale = countryCode === 'SE' ? 'sv' : 'en';

  const date = dayjs(dateString).locale(locale);
  const dateFormatted = date.format('ddd M/D');
  const timeFormatted = formatTimeByCountry(dateString, countryCode);

  return `${dateFormatted}, ${timeFormatted}`;
}

export function generateTableBody(
  deviceAlarms: PdfDeviceAlarmEvents[],
  countryCode: string,
  reportType?: ReportType
): PdfTableRow[] {
  const tableBody: PdfTableRow[] = [];

  const isBatteryMonitor =
    reportType === ReportType.SmartCables || reportType === ReportType.SmartSolar;

  deviceAlarms.forEach((device) => {
    if (device.events.length > 0) {
      let attachmentValue = '';
      // Handle different attachment/equipment types based on device type
      if (isBatteryMonitor && 'poweredEquipment' in device) {
        // For battery monitor devices, use powered equipment
        const poweredEquipment = device.poweredEquipment as keyof typeof PoweredEquipmentNames;
        if (poweredEquipment !== undefined && poweredEquipment !== null) {
          attachmentValue = String(poweredEquipment);
        } else {
          attachmentValue = '-';
        }
      } else {
        if (device.currentEquipmentType === EquipmentType.Barrier) {
          attachmentValue = EquipmentType.Barrier.toString();
        } else if (device.currentEquipmentType === EquipmentType.Sign) {
          attachmentValue = device.attachmentRef || 'Sign';
        } else {
          attachmentValue = device.attachmentRef || 'Unknown';
        }
      }

      // Add first event with device info
      tableBody.push({
        referenceId: device.referenceId,
        name: device.name,
        attachment: attachmentValue,
        alarmReported: formatTimeWithDate(device.events[0].alarmReported, countryCode),
        alarmAddressed: device.events[0].alarmAddressed
          ? formatTimeWithDate(device.events[0].alarmAddressed, countryCode)
          : '-',
      });

      // Add subsequent events without device info
      device.events.slice(1).forEach((event) => {
        tableBody.push({
          referenceId: '',
          name: '',
          attachment: '',
          alarmReported: formatTimeWithDate(event.alarmReported, countryCode),
          alarmAddressed: event.alarmAddressed
            ? formatTimeWithDate(event.alarmAddressed, countryCode)
            : '-',
        });
      });
    }
  });

  return tableBody;
}
