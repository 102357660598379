export enum WorkOrderSystem {
  MicroBizz = 'MicroBizz',
}

export interface PointData {
  type: string;
  coordinates: [number, number];
}

export enum PsaSetupType {
  Geozone = 1,
  Manual = 2,
}
