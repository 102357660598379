import { usePdfWorker } from '@/contexts/PdfWorker/usePdfWorker';
import { Progress, Button, Stack, Text, Group } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAlertCircle, IconX } from '@tabler/icons-react';
import { useEffect, useRef } from 'react';

export function PdfExportProgressBar() {
  const { status, error, progress, cancelGeneration, cancelRequested } = usePdfWorker();
  const notificationIdRef = useRef<string | null>(null);
  const previousStatusRef = useRef(status);

  // Reset and initialize notification when status changes
  useEffect(() => {
    // If we're transitioning to a new generation
    if (status === 'generating' && previousStatusRef.current !== 'generating') {
      // Close any existing notification to start fresh
      if (notificationIdRef.current) {
        notifications.hide(notificationIdRef.current);
        notificationIdRef.current = null;
      }
    }

    // Store current status for next comparison
    previousStatusRef.current = status;
  }, [status]);

  // Handle notification display based on status
  useEffect(() => {
    if (status === 'generating') {
      // Calculate progress value
      const progressValue = progress?.progress || 0;
      const progressPercent = Math.round(progressValue);

      // Create progress content
      const progressContent = (
        <Stack gap="xs">
          <Text size="sm">{progress?.message || 'Processing...'}</Text>
          <Progress value={progressValue} size="sm" radius="xl" animated />
          <Text size="xs" c="dimmed" ta="right">
            {progressPercent}%
          </Text>
          {!cancelRequested && (
            <Group justify="flex-end" mt="xs">
              <Button
                size="xs"
                variant="subtle"
                color="gray"
                leftSection={<IconX size={14} />}
                onClick={cancelGeneration}
                disabled={cancelRequested}
              >
                Cancel
              </Button>
            </Group>
          )}
        </Stack>
      );

      // Show or update the progress notification
      if (notificationIdRef.current) {
        notifications.update({
          id: notificationIdRef.current,
          title: cancelRequested ? 'Cancelling PDF Generation' : 'Generating PDF',
          message: progressContent,
          color: cancelRequested ? 'gray' : 'blue',
          withCloseButton: false,
          autoClose: false,
        });
      } else {
        // Create new notification for progress
        const id = `pdf-generation-${Date.now()}`;
        notificationIdRef.current = id;

        notifications.show({
          id: id,
          title: 'Generating PDF',
          message: progressContent,
          color: 'linkBlue',
          withCloseButton: false,
          autoClose: false,
        });
      }
    } else if (status === 'completed') {
      // Update existing notification if available, else create new
      if (notificationIdRef.current) {
        notifications.update({
          id: notificationIdRef.current,
          title: 'PDF Generated Successfully',
          message: 'Your PDF has been generated successfully',
          color: 'successGreen',
          autoClose: 5000,
          withCloseButton: true,
          onClose: () => {
            notificationIdRef.current = null;
          },
        });
      } else {
        const id = `pdf-completion-${Date.now()}`;
        notificationIdRef.current = id;

        notifications.show({
          id: id,
          title: 'PDF Generated Successfully',
          message: 'Your PDF has been generated successfully',
          color: 'successGreen',
          autoClose: 5000,
          onClose: () => {
            notificationIdRef.current = null;
          },
        });
      }
    } else if (status === 'error') {
      // Update existing notification if available, else create new
      if (notificationIdRef.current) {
        notifications.update({
          id: notificationIdRef.current,
          title: 'Error Generating PDF',
          message: error || 'An error occurred during PDF generation',
          color: 'dangerRed',
          autoClose: 5000,
          withCloseButton: true,
          onClose: () => {
            notificationIdRef.current = null;
          },
        });
      } else {
        const id = `pdf-error-${Date.now()}`;
        notificationIdRef.current = id;

        notifications.show({
          id: id,
          title: 'Error Generating PDF',
          message: error || 'An error occurred during PDF generation',
          color: 'dangerRed',
          icon: <IconAlertCircle size={16} />,
          autoClose: 5000,
          onClose: () => {
            notificationIdRef.current = null;
          },
        });
      }
    } else if (status === 'idle' && !cancelRequested) {
      // Only hide notification if we're idle and not in the middle of cancellation
      if (notificationIdRef.current) {
        notifications.hide(notificationIdRef.current);
        notificationIdRef.current = null;
      }
    }
  }, [status, progress, error, cancelGeneration, cancelRequested]);

  // Cleanup effect when component unmounts
  useEffect(() => {
    return () => {
      if (notificationIdRef.current) {
        notifications.hide(notificationIdRef.current);
        notificationIdRef.current = null;
      }
    };
  }, []);

  // This component doesn't render anything visible - it just manages notifications
  return null;
}
