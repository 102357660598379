import { BADGE_STATES } from '@/components/Badge/Badge';
import { DeviceModel } from '@/models/Device';
import { BatteryStatus, PoweredEquipment } from '@/models/enums/DeviceEnums';

export default function getBadgeStateFromBatteryStatus(batteryStatus: BatteryStatus) {
  const BatteryStatusBadgeStateMap = {
    [BatteryStatus.OUT_OF_BATTERY]: BADGE_STATES.OFFLINE,
    [BatteryStatus.CRITICAL]: BADGE_STATES.CRITICAL,
    [BatteryStatus.LOW]: BADGE_STATES.WARNING,
    [BatteryStatus.OK]: BADGE_STATES.OK,
    [BatteryStatus.DISCONNECTED]: BADGE_STATES.OFFLINE,
  };

  if (batteryStatus == null) return BADGE_STATES.OFFLINE;
  return BatteryStatusBadgeStateMap[batteryStatus];
}

export const BATTERY_THRESHOLDS_POWERED_EQUIPMENT = {
  [PoweredEquipment.TrafficLight]: {
    [BatteryStatus.OUT_OF_BATTERY]: 11.4,
    [BatteryStatus.CRITICAL]: 11.5,
    [BatteryStatus.LOW]: 11.8,
  },
  [PoweredEquipment.SequentialLight]: {
    [BatteryStatus.OUT_OF_BATTERY]: 11.4,
    [BatteryStatus.CRITICAL]: 11.5,
    [BatteryStatus.LOW]: 11.8,
  },
  [PoweredEquipment.WarningLight]: {
    [BatteryStatus.OUT_OF_BATTERY]: 10.2,
    [BatteryStatus.CRITICAL]: 11.1,
    [BatteryStatus.LOW]: 11.4,
  },
  [PoweredEquipment.Buffer]: {
    [BatteryStatus.OUT_OF_BATTERY]: 10.2,
    [BatteryStatus.CRITICAL]: 11.1,
    [BatteryStatus.LOW]: 11.4,
  },
  [PoweredEquipment.Other]: {
    [BatteryStatus.OUT_OF_BATTERY]: 10.2,
    [BatteryStatus.CRITICAL]: 11.1,
    [BatteryStatus.LOW]: 11.4,
  },
  [PoweredEquipment.Unknown]: {
    [BatteryStatus.OUT_OF_BATTERY]: -1,
    [BatteryStatus.CRITICAL]: -1,
    [BatteryStatus.LOW]: -1,
  },
};

export const BATTERY_THRESHOLDS_TELTONIKA = {
  [DeviceModel.Tat100]: {
    [BatteryStatus.OUT_OF_BATTERY]: -1,
    [BatteryStatus.CRITICAL]: 5.2,
    [BatteryStatus.LOW]: 5.6,
  },
  [DeviceModel.Tat141]: {
    [BatteryStatus.OUT_OF_BATTERY]: -1,
    [BatteryStatus.CRITICAL]: 5.2,
    [BatteryStatus.LOW]: 5.6,
  },
  [DeviceModel.Tat140]: {
    [BatteryStatus.OUT_OF_BATTERY]: -1,
    [BatteryStatus.CRITICAL]: 5.2,
    [BatteryStatus.LOW]: 5.6,
  },
  [DeviceModel.Fmc130]: {
    [BatteryStatus.OUT_OF_BATTERY]: -1,
    [BatteryStatus.CRITICAL]: 11.6,
    [BatteryStatus.LOW]: 11.8,
  },
  [DeviceModel.Fmc13A]: {
    [BatteryStatus.OUT_OF_BATTERY]: -1,
    [BatteryStatus.CRITICAL]: 11.6,
    [BatteryStatus.LOW]: 11.8,
  },
  [DeviceModel.Unknown]: {
    [BatteryStatus.OUT_OF_BATTERY]: 0,
    [BatteryStatus.CRITICAL]: 0,
    [BatteryStatus.LOW]: 0,
  },
};
