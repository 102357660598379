import { IPublicClientApplication } from '@azure/msal-browser';
import QueryKeys from '../../queryKeys';
import { SupervisionReportFilters } from '../../filters';

export const SUPERVISION_REPORT_PATHS = {
  base: '/supervisionReport',
  arrowboard: '/supervisionReport/arrowboard',
  smartCable: '/supervisionReport/smartcable',
  smartSolar: '/supervisionReport/smartsolar',
} as const;

export interface SupervisionReportBaseParams {
  workZoneId: string;
  startTime: string;
  endTime: string;
  interval?: string;
}

export interface UseSupervisionReportConfigParams extends SupervisionReportBaseParams {
  instance: IPublicClientApplication;
  type: 'intellitags' | 'arrowboards' | 'smartcables' | 'smartsolar';
}

export const useSupervisionReportConfig = ({
  instance,
  workZoneId,
  startTime,
  endTime,
  interval,
  type,
}: UseSupervisionReportConfigParams) => {
  const pageNumber = 1;
  const pageSize = 1000;

  const queryParams = {
    workZoneId,
    startTime,
    endTime,
    ...(interval && { interval }),
  };

  return {
    queryKey: QueryKeys.SupervisionReports.list(pageNumber, pageSize, {
      type,
      ...queryParams,
    } as SupervisionReportFilters),
    queryParams,
    instance,
    enabled: !!workZoneId,
  };
};
