import { ArrowDirection } from '@/models/Device';
import { Stack } from '@mantine/core';
import Left from '@/assets/ArrowBoardIcons/arrow_board_left.png';
import Right from '@/assets/ArrowBoardIcons/arrow_board_right.png';
import Bar from '@/assets/ArrowBoardIcons/arrow_board_bar_only.png';
import LeftRight from '@/assets/ArrowBoardIcons/arrow_board_double_arrow.png';
import Off from '@/assets/ArrowBoardIcons/arrow_board_off.png';
import Unknown from '@/assets/ArrowBoardIcons/arrow_board_unkown.png';

type Props = {
  arrowDirection: ArrowDirection;
};

export const ArrowBoardMarker = ({ arrowDirection }: Props) => {
  const imgMap = {
    [ArrowDirection.Bar]: Bar,
    [ArrowDirection.Left]: Left,
    [ArrowDirection.Right]: Right,
    [ArrowDirection.LeftRight]: LeftRight,
    [ArrowDirection.Off]: Off,
    [ArrowDirection.Unknown]: Unknown,
  };

  const arrow = (
    <img
      src={imgMap[arrowDirection]}
      alt=""
      style={{
        zIndex: '5',
        maxWidth: '60px',
        maxHeight: '60px',
        width: 'auto',
        height: 'auto',
        border: '1px solid white',
      }}
    />
  );
  return (
    <>
      <Stack gap={0} align="center">
        {arrow}
        {wagon}
      </Stack>
    </>
  );
};

const frame = (
  <>
    <div
      style={{
        height: '40px',
        width: '28px',
        position: 'absolute',
        left: '6px',
        border: '4px solid orange',
      }}
    ></div>
  </>
);

const wheel = (
  <>
    <div>
      <div
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: 'orange',
        }}
      ></div>
      <div
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: 'black',
        }}
      ></div>
    </div>
  </>
);

const wagon = (
  <div
    style={{
      position: 'absolute',
      top: '40px',
      width: '40px',
      height: '44px',
    }}
  >
    <div
      style={{
        left: 0,
        position: 'absolute',
        bottom: 0,
      }}
    >
      {wheel}
    </div>
    {frame}
    <div
      style={{
        right: 0,
        position: 'absolute',
        bottom: 0,
      }}
    >
      {wheel}
    </div>
  </div>
);
