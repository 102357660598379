import { useEffect, useState } from 'react';
import {
  IconLogout,
  IconX,
  IconBuildingWarehouse,
  IconLock,
  IconLayoutSidebarLeftCollapseFilled,
  IconLayoutSidebarLeftExpandFilled,
  IconFileCertificate,
  IconMap2,
  IconDevicesSearch,
} from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Anchor,
  Button,
  Divider,
  Group,
  Menu,
  Modal,
  Space,
  Stack,
  UnstyledButton,
} from '@mantine/core';
import { UserButton } from './UserButton';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import BugReport from './BugReport';
import { useDisclosure } from '@mantine/hooks';
import { IconBug, IconCloudUpload } from '@tabler/icons-react';
import useMyUserAccesses from '../../data/hooks/UserAccess/useMyUserAccess';
import { useMobileDevice } from '../../hooks/useMobileDevice';
import { FlexSpacer } from '../../shared/FlexSpacer';
import { useAuthUtils } from '../../shared/authUtils.tsx';
import { MODAL_ZINDEX } from '@/constants/styling.ts';
import logoWithName from '@/assets/R_L1_gul_neg_RGB.svg';
import logoOnlyMark from '@/assets/favicon.svg';
import styles from './NavbarSegmented.module.css';
import { UserAccess } from '@/models/UserAccess';

interface Props {
  toggleNavbar: () => void;
  toggleMinimize: (value: boolean) => void;
  minimize: boolean;
}

interface LinkData {
  link: string;
  label: string;
  icon: React.ComponentType<{ size?: string; color?: string }>;
  requiresAccess?: keyof UserAccess;
}

const linkData: LinkData[] = [
  { link: '/projects', label: 'All projects', icon: IconBuildingWarehouse },
  { link: '/map', label: 'Map', icon: IconMap2 },
  { link: '/devices', label: 'Devices', icon: IconDevicesSearch },
  {
    link: '/bulk-provision-devices',
    label: 'Provisioning',
    icon: IconCloudUpload,
    requiresAccess: 'hasDeviceProvisioningAccess',
  },
];

function getActiveMatch(active: string, link: string) {
  if (active === '/' || link === '/') {
    return link === active;
  } else {
    return active.startsWith(link);
  }
}

export function NavbarSegmented({ toggleNavbar, toggleMinimize, minimize }: Props) {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const { instance } = useMsal();
  const { userAccesses } = useMyUserAccesses({ instance: instance });
  const [opened, { open, close }] = useDisclosure(false);
  const isMobile = useMobileDevice();
  const navigate = useNavigate();

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  const { handleLogout } = useAuthUtils();

  const handleLinkClick = (link: string) => {
    navigate(link);
    toggleNavbar();
    setActive(link);
  };

  const links = linkData
    .map(({ link, label, icon: Icon, requiresAccess }) => {
      if (requiresAccess && !userAccesses?.[requiresAccess]) {
        return null;
      }

      return (
        <Link
          to={link}
          className={`${styles.link} ${minimize ? styles.mini : ''}`}
          key={link}
          data-active={getActiveMatch(active, link) || undefined}
          onClick={() => handleLinkClick(link)}
        >
          <Icon size={'24px'} color="white"></Icon>
          <Space w={minimize ? 0 : 'sm'} />
          {minimize ? null : label}
        </Link>
      );
    })
    .filter(Boolean);

  return (
    <Stack className="links" gap={'sm'} px={minimize ? 0 : 'md'} py={'md'}>
      {!isMobile && (
        <Group justify={minimize ? 'center' : 'start'} mt={minimize ? 6 : 0}>
          <Anchor href="/projects">
            <img
              src={minimize ? logoOnlyMark : logoWithName}
              width={minimize ? 28 : 170}
              style={{ position: 'relative', left: minimize ? 0 : '-6px' }}
            />
          </Anchor>
        </Group>
      )}
      {links}
      <FlexSpacer />
      <Stack gap={'xs'}>
        {!isMobile && (
          <Button
            className={styles.btnHover}
            fw={500}
            justify="start"
            variant="subtle"
            c={'white'}
            onClick={() => toggleMinimize(!minimize)}
            leftSection={
              minimize ? (
                <IconLayoutSidebarLeftExpandFilled
                  style={{ marginLeft: minimize ? '14px' : '0px' }}
                />
              ) : (
                <IconLayoutSidebarLeftCollapseFilled />
              )
            }
          >
            {minimize ? null : 'Collapse'}
          </Button>
        )}

        <Modal.Root opened={opened} onClose={close} centered zIndex={MODAL_ZINDEX}>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>
              <Modal.Title fw={600}>Bug Report</Modal.Title>
              <UnstyledButton onClick={close}>
                <IconX />
              </UnstyledButton>
            </Modal.Header>
            <Modal.Body>
              <BugReport onSubmit={close} />
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
        <Button
          className={styles.btnHover}
          fw={500}
          justify="start"
          onClick={open}
          variant="subtle"
          c={'white'}
          leftSection={<IconBug style={{ marginLeft: minimize ? '14px' : '0px' }} />}
        >
          {minimize ? null : 'Report Bug'}
        </Button>
        <Divider mb="md" mt={'md'} color="lightgrey" />
        <Menu position={isMobile ? 'top-end' : 'right-end'} offset={20}>
          <Menu.Target>
            <div>
              <UserButton minimized={minimize} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {userAccesses?.isAdmin && (
              <Menu.Item p="sm" component={Link} to="/access-management">
                <Group>
                  <IconLock size={14} />
                  Access Management
                </Group>
              </Menu.Item>
            )}
            <Menu.Item p="sm" component={Link} to="/privacy-policy">
              <Group>
                <IconFileCertificate size={14} />
                Privacy Policy
              </Group>
            </Menu.Item>
            <Menu.Item p="sm" onClick={handleLogout}>
              <Group>
                <IconLogout size={14} />
                Logout
              </Group>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Stack>
    </Stack>
  );
}
