import { useMutation, useQueryClient } from '@tanstack/react-query';
import postData from '../../api/postData';
import { IPublicClientApplication } from '@azure/msal-browser';
import { UpdateSetup } from '@/models/Setups';

const path = `/setup/update-setup-information`;

const useUpdateSetup = (instance: IPublicClientApplication, workzoneId: string) => {
  const queryClient = useQueryClient();
  const { mutateAsync, mutate, isPending, isError } = useMutation({
    mutationFn: (params: UpdateSetup) => {
      return postData<UpdateSetup>(path, params, instance);
    },
    onError: (error) => {
      console.error(`Error updating setup`, error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['setup', workzoneId] });
    },
  });

  return {
    mutateAsync,
    mutate,
    isPending,
    isError,
  };
};

export default useUpdateSetup;
