import { useCallback } from 'react';
import { usePdfWorker } from '@/contexts/PdfWorker/usePdfWorker';
import { PdfGenerationData } from '@/models/PdfExport';
import { ReportType } from '@/models/enums/ReportType';
import { ArrowboardRow, BatteryMonitorRow, DeviceRow } from '@/models/SupervisionReport';
import primaryLogo from '@/assets/report/logo-primary.png';
import primaryLogoWhite from '@/assets/report/logo-primary-white.png';
import coverBackground from '@/assets/report/cover-page.png';
import {
  convertImageMapForWorker,
  convertStatusIconsToBase64,
  convertAssetsToBase64,
} from '@/utils/base64Utils';

/**
 * Hook for exporting PDF reports from monitoring data
 */
export const useSupervisionReportExport = () => {
  const { generatePdf, status, isGenerating } = usePdfWorker();

  /**
   * Generate a combined PDF report with multiple report types
   */
  const exportCombinedPdf = useCallback(
    async (
      props: {
        projectName: string;
        depot: string;
        customerName: string;
        reportStartDate: Date;
        reportEndDate: Date;
        contactPerson?: {
          name: string;
          email: string;
          phone: string;
        };
        countryCode: string;
        includeStatistics?: boolean;
        reportTypes: ReportType[];
        intellitagProps?: {
          timeStamps: string[];
          intervalInHours: number;
          rows: DeviceRow[];
          showSnoozed: boolean;
          imageMap: Map<string, HTMLImageElement>;
        };
        arrowboardProps?: {
          rows: ArrowboardRow[];
        };
        smartCableProps?: {
          rows: {
            rows: BatteryMonitorRow[];
          };
        };
        smartSolarProps?: {
          rows: {
            rows: BatteryMonitorRow[];
          };
        };
      },
      statsImg?: string
    ) => {
      try {
        // Convert all images with base64Utils
        const allImages = {};
        if (props.intellitagProps?.imageMap) {
          const map = await convertImageMapForWorker(props.intellitagProps.imageMap);
          Object.assign(allImages, map);
        }

        // Get status icons and assets
        const statusIcons = await convertStatusIconsToBase64();
        const assets = await convertAssetsToBase64(primaryLogo, primaryLogoWhite, coverBackground);

        // Prepare data for all report types
        const intellitagRows = props.intellitagProps?.rows || [];
        const arrowboardRows = props.arrowboardProps?.rows || [];
        const smartCableRows = props.smartCableProps?.rows?.rows || [];
        const smartSolarRows = props.smartSolarProps?.rows?.rows || [];

        // Prepare data for worker
        const workerData: PdfGenerationData = {
          // Common properties
          projectName: props.projectName,
          depot: props.depot,
          customerName: props.customerName,
          reportStartDate: props.reportStartDate,
          reportEndDate: props.reportEndDate,
          contactPerson: props.contactPerson,
          countryCode: props.countryCode,
          includeStatistics: props.includeStatistics,

          // Report types to include
          reportTypes: props.reportTypes,

          // Set a combined report flag to indicate this is a combined report
          isCombinedReport: true,

          // Statistics image if needed
          statsImg: props.includeStatistics ? statsImg : undefined,

          // Unit-specific data
          rows: intellitagRows,
          timeStamps: props.intellitagProps?.timeStamps || [],
          intervalInHours: props.intellitagProps?.intervalInHours || 24,
          showSnoozed: true,
          arrowboardRows,
          smartCableRows,
          smartSolarRows,

          // Assets and icons
          imageMap: allImages,
          assets,
          statusIcons,
        };

        const result = await generatePdf(workerData);

        if (!result.success) {
          throw new Error(result.error || 'Failed to generate PDF');
        }

        return { success: true };
      } catch (error) {
        console.error('Error creating combined PDF:', error);
        return {
          success: false,
          error: error instanceof Error ? error.message : 'Unknown error',
        };
      }
    },
    [generatePdf]
  );

  /**
   * Generate a single PDF report with one report type
   */
  const exportSinglePdf = useCallback(
    async (data: PdfGenerationData, reportType: ReportType) => {
      try {
        // Get status icons and assets if they don't already exist
        const statusIcons = data.statusIcons || (await convertStatusIconsToBase64());
        const assets =
          data.assets ||
          (await convertAssetsToBase64(primaryLogo, primaryLogoWhite, coverBackground));

        // Ensure that required properties are set
        const workerData: PdfGenerationData = {
          ...data,
          statusIcons,
          assets,
          imageMap: data.imageMap || {},
          includeStatistics: data.includeStatistics || false,
          reportType,
          reportTypes: [reportType],
          intervalInHours: data.intervalInHours || 24,
          showSnoozed: data.showSnoozed ?? true,
        };

        // Generate PDF with worker
        const result = await generatePdf(workerData);

        if (!result.success) {
          throw new Error(result.error || 'Failed to generate PDF');
        }

        return { success: true };
      } catch (error) {
        console.error(`Error creating ${reportType} PDF:`, error);
        return {
          success: false,
          error: error instanceof Error ? error.message : 'Unknown error',
        };
      }
    },
    [generatePdf]
  );

  return {
    exportCombinedPdf,
    exportSinglePdf,
    status,
    isGenerating,
  };
};
