import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button as MantineButton,
  Drawer,
  Group,
  Paper,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { AdvancedMarker, Pin, useMapsLibrary } from '@vis.gl/react-google-maps';
import DeviceList from '@/components/Device/DeviceList';
import LightMap from '@/components/Map/LightMap';
import { DeviceMarker } from '@/components/Map/DeviceMarker/DeviceMarker.tsx';
import { getWorkZoneMiddlePoint } from '@/services/helpers';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import classes from './ProjectOverview.module.css';
import {
  ManualCenterPoint,
  EditAndCreateProjectModal,
} from '../CreateProjectModal/EditAndCreateProjectModal';
import { LocationMode } from '@/shared/types.tsx';
import { WorkZone } from '@/models/Project.ts';
import { Device, DeviceTypeNames } from '@/models/Device.ts';
import useSetManualCenter from '@/data/hooks/Project/useSetManualCenterHook.ts';
import { Point } from 'geojson';
import { IconArrowLeft, IconCalendar, IconEdit } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { NoMapData } from '@/shared/NoMapData.tsx';
import { DatePickerInput } from '@mantine/dates';
import { FlexSpacer } from '@/shared/FlexSpacer';
import dayjs from 'dayjs';
import { Setup } from '@/models/Setups';
import { EditGeozoneModal } from '../EditGeozoneModal/EditGeozoneModal';
import useUpdateSetup from '@/data/hooks/Setups/useUpdateSetupHook';
import { Polygon } from '@/components/Map/Polygon';
import { DeviceEventsSidebar } from '@/components/Device/DeviceEvents/DeviceEventsSidebar';
import { Button } from '@/components/Button/Button';

interface Props {
  workZone: WorkZone;
  devices: Device[];
  initialSelectedDevice?: string | null;
  timeTravelDate: Date | null;
  loading?: boolean;
  onTimeTravel: (date: Date | null) => void;
  setup?: Setup;
}

export const ProjectOverview = ({
  workZone,
  devices,
  initialSelectedDevice,
  timeTravelDate,
  loading = false,
  onTimeTravel,
  setup,
}: Props) => {
  const theme = useMantineTheme();
  const { instance } = useMsal();
  const isMobile = useMobileDevice();
  const [openModal, setOpenModal] = useState(false);
  const [showEditGeozoneModal, setShowEditGeozoneModal] = useState(false);
  const middlePoint = useMemo(() => getWorkZoneMiddlePoint(workZone), [workZone]);
  const [selectedDevice, setSelectedDevice] = useState<string | null>(
    initialSelectedDevice ?? null
  );
  // Device Events feature additions
  const [showDeviceEvents, setShowDeviceEvents] = useState<boolean>(!!initialSelectedDevice);
  const [hoveredDeviceId, setHoveredDeviceId] = useState<string | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const { mutate: mutateSetup } = useUpdateSetup(instance, workZone.id);
  // Device Events configuration
  const DEVICE_LIST_WIDTH = 25;
  const SIDEBAR_WIDTH = 25;
  const MAP_WIDTH_WITH_DRAWER = 50;
  const MAP_WIDTH_WITHOUT_DRAWER = 75;

  const handleDeviceSelectedClick = useCallback(
    (deviceId: string) => {
      if (deviceId === selectedDevice && !isMobile) {
        setSelectedDevice(null);
        setShowDeviceEvents(false);
      } else {
        setSelectedDevice(deviceId);
        setShowDeviceEvents(true);
      }
    },
    [selectedDevice, isMobile]
  );

  const handleCloseEvents = () => {
    setShowDeviceEvents(false);
  };

  const handleDeviceHover = (deviceId: string | null) => {
    setHoveredDeviceId(deviceId);
  };

  const pin = useMemo(
    () => (
      <AdvancedMarker
        draggable
        key={'workzone-pin'}
        position={
          workZone.center
            ? {
                lat: workZone.center?.coordinates[1],
                lng: workZone.center?.coordinates[0],
              }
            : null
        }
      >
        <Pin background="#173232" borderColor="#173232" glyphColor="#fff" />
      </AdvancedMarker>
    ),
    [workZone.center]
  );

  const renderDeviceMarkers = useCallback(
    (map: google.maps.Map | null) => {
      const markers = devices.map((device, index) => (
        <DeviceMarker
          key={`marker-${index}`}
          baseDevice={device}
          map={map}
          onClick={() => {
            handleDeviceSelectedClick(device.id);
          }}
          visibleAtZoomLevel="always"
          isSelected={device.id === selectedDevice}
          isHovered={device.id === hoveredDeviceId}
        />
      ));

      return loading ? [] : markers.length ? markers : timeTravelDate !== null ? [] : [pin];
    },
    [
      devices,
      handleDeviceSelectedClick,
      loading,
      pin,
      selectedDevice,
      timeTravelDate,
      hoveredDeviceId,
    ]
  );

  const renderGeozone = useCallback(
    (map: google.maps.Map | null) => {
      if (!setup?.geozone) return null;

      const geozonePath = setup.geozone.coordinates[0].map(([lng, lat]) => ({ lat, lng }));

      return (
        <>
          <Polygon
            paths={geozonePath}
            fillColor={theme.colors.successGreen[0]}
            strokeColor={theme.colors.successGreen[0]}
            fillOpacity={0.1}
            strokeWeight={2}
          />
          {geozonePath.map((position, index) => (
            <AdvancedMarker key={`geozone-vertex-${index}`} position={position} map={map}>
              <Box
                w="10px"
                h="10px"
                bg="white"
                bd="3px solid var(--mantine-color-successGreen-0)"
                pos="absolute"
                style={{
                  transform: 'translate(-50%, -50%)',
                  borderRadius: '50%',
                }}
              />
            </AdvancedMarker>
          ))}
        </>
      );
    },
    [setup?.geozone, theme.colors.successGreen]
  );

  const coreLib = useMapsLibrary('core');

  useEffect(() => {
    if (!coreLib) return;
    const bounds = new coreLib.LatLngBounds();
    devices.forEach((dev) => {
      if (dev.position) {
        bounds.extend({ lng: dev.position.coordinates[0], lat: dev.position.coordinates[1] });
      }
    });

    if (setup?.geozone) {
      setup.geozone.coordinates[0].forEach((coord) => {
        bounds.extend({ lng: coord[0], lat: coord[1] });
      });
    }

    if (devices.length || setup?.geozone) {
      setBounds(bounds);
    }
  }, [coreLib, devices, setup?.geozone]);

  const [address, setAddress] = useState<string | null>();
  const { mutate: setManualCenterPoint } = useSetManualCenter(instance);

  const handleUpdateProjectCenter = (centerPoint: ManualCenterPoint | null, mode: LocationMode) => {
    if (mode == LocationMode.Manual) {
      if (!centerPoint) return;
      const center: Point = {
        type: 'Point',
        coordinates: [centerPoint.position.lng, centerPoint.position.lat],
      };
      setManualCenterPoint({ id: workZone.id, center: center, isManual: true });
    } else if (mode == LocationMode.Automatic) {
      setManualCenterPoint({ id: workZone.id, center: null, isManual: false });
    }
  };

  const handleUpdateGeozone = (updatedPath: google.maps.LatLngLiteral[]) => {
    const newGeozone: { type: 'Polygon'; coordinates: number[][][] } = {
      type: 'Polygon',
      coordinates: [updatedPath.map((p) => [p.lng, p.lat])],
    };

    // Close the Polygon
    newGeozone.coordinates[0].push(newGeozone.coordinates[0][0]);

    if (setup) {
      mutateSetup({
        geozone: newGeozone,
        scenario: setup.scenario,
        setupId: setup.id,
      });
    }
  };

  const geoLib = useMapsLibrary('geocoding');
  useEffect(() => {
    if (!geoLib || !middlePoint) return;
    const GeoCoder = new geoLib.Geocoder();
    const request: google.maps.GeocoderRequest = {
      location: {
        lng: middlePoint.coordinates[1],
        lat: middlePoint.coordinates[0],
      },
    };

    GeoCoder.geocode(request).then((res) => {
      if (res.results[0]) {
        setAddress(res.results[0].formatted_address);
      } else {
        setAddress('No address found');
      }
    });
  }, [geoLib, middlePoint]);

  const geoLocationBox = (
    <Paper
      className={isMobile ? classes.fixedBottom : classes.fixedRight}
      w={isMobile ? '100%' : '300px'}
      px="md"
      py="sm"
      shadow="md"
    >
      {middlePoint ? (
        <>
          <Text size="sm" fw={400}>
            {address}
          </Text>
          <Text opacity={0.5} fw={200} size="xs">
            {middlePoint?.coordinates[0]}"N, {middlePoint?.coordinates[1]}"E
          </Text>
        </>
      ) : (
        <Text opacity={0.5} fw={200} size="xs">
          Project center point will be calculated from devices GPS data
        </Text>
      )}
    </Paper>
  );

  const deviceTypeMap = Object.values(DeviceTypeNames).reduce(
    (a, d) => ({ ...a, [d]: 0 }),
    {} as Record<string, number>
  );
  devices.forEach((d) => (deviceTypeMap[DeviceTypeNames[d.deviceType]] += 1));

  // Get selected device name for the events sidebar
  const selectedDeviceName = selectedDevice
    ? devices.find((d) => d.id === selectedDevice)?.currentName || 'Device'
    : '';

  // Grid layout for the responsive design
  const gridTemplateColumns = !isMobile
    ? showDeviceEvents
      ? `${DEVICE_LIST_WIDTH}% ${SIDEBAR_WIDTH}% ${MAP_WIDTH_WITH_DRAWER}%`
      : `${DEVICE_LIST_WIDTH}% 0% ${MAP_WIDTH_WITHOUT_DRAWER}%`
    : '1fr';

  return (
    <Box pos="relative">
      <Group bg="white" px="md" py="sm" mb={2}>
        {Object.entries(deviceTypeMap)
          .filter((v) => v[1] > 0)
          .map(([key, value]) => (
            <span key={key}>
              <Title order={5} display="inline-block" mr="xs">
                {value}
              </Title>
              <Text size="sm" display="inline-block">
                {key}
              </Text>
            </span>
          ))}
        <FlexSpacer />
        <DatePickerInput
          leftSection={<IconCalendar size={18} />}
          placeholder="Now"
          leftSectionPointerEvents="none"
          w={timeTravelDate ? 180 : 'fit-content'}
          value={timeTravelDate}
          onChange={(val) => {
            if (val && val.toDateString() === new Date().toDateString()) {
              return onTimeTravel(null);
            }
            if (val) {
              const utcDate = new Date(
                Date.UTC(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0)
              );
              return onTimeTravel(utcDate);
            }
            onTimeTravel(val);
          }}
          maxDate={new Date()}
          minDate={dayjs(workZone.startDate).toDate()}
          clearable
          className={`datePicker-override ${timeTravelDate && 'selected'}`}
        />
      </Group>

      {isMobile && (
        <Button variant="secondary" onClick={open} isFloating icon={IconArrowLeft}>
          Open device list
        </Button>
      )}

      <Box
        style={{
          display: 'grid',
          gridTemplateColumns,
          transition: 'grid-template-columns 0.3s ease',
          height: 'calc(100vh - 250px)',
          width: '100%',
        }}
      >
        {!isMobile && (
          <Box h="100%" style={{ overflow: 'hidden' }}>
            <DeviceList
              showFilters={true}
              loading={loading}
              light={false}
              padding="md"
              bg="white"
              devices={devices}
              isSnoozed={workZone.isSnoozed}
              closedProject={workZone.isClosed}
              countryCode={workZone.countryCode}
              selectedDevice={selectedDevice}
              timeTravelActive={!!timeTravelDate}
              setSelectedDevice={handleDeviceSelectedClick}
              onDeviceHover={handleDeviceHover}
            />
          </Box>
        )}

        {!isMobile && (
          <Box h="100%" style={{ overflow: 'hidden' }}>
            {selectedDevice && (
              <DeviceEventsSidebar
                deviceId={selectedDevice}
                onClose={handleCloseEvents}
                title="Device Events"
                visible={showDeviceEvents}
                isMobile={false}
                projectStartDate={workZone.startDate}
                timeTravelDate={timeTravelDate}
                projectId={workZone.id}
              />
            )}
          </Box>
        )}
        <Box h="100%" pos="relative">
          {workZone.isClosed ? (
            <NoMapData height="calc(100vh - 250px)" />
          ) : (
            <LightMap
              height="calc(100vh - 250px)"
              renderDeviceMarkers={renderDeviceMarkers}
              renderGeozone={renderGeozone}
              disableDefaultUI={isMobile}
              selectedDevice={devices.find((d) => d.id === selectedDevice)}
              bounds={bounds}
            />
          )}
        </Box>

        {/* Mobile Device List Drawer */}
        {isMobile && (
          <Drawer opened={opened} onClose={close} size="100%">
            <DeviceList
              light={false}
              showFilters={true}
              bg="white"
              devices={devices}
              isSnoozed={workZone.isSnoozed}
              countryCode={workZone.countryCode}
              selectedDevice={selectedDevice}
              setSelectedDevice={handleDeviceSelectedClick}
              onDeviceHover={handleDeviceHover}
            />
          </Drawer>
        )}

        {/* Mobile Device Events Drawer - Full screen with back button */}
        {isMobile && selectedDevice && (
          <DeviceEventsSidebar
            deviceId={selectedDevice}
            onClose={handleCloseEvents}
            title={`Events for ${selectedDeviceName}`}
            visible={showDeviceEvents}
            isMobile={true}
            projectStartDate={workZone.startDate}
            timeTravelDate={timeTravelDate}
          />
        )}
      </Box>

      {workZone.isClosed || timeTravelDate !== null ? null : setup?.geozone ? (
        <MantineButton
          variant="default"
          pos="absolute"
          top={isMobile ? '55px' : '64px'}
          right="10px"
          miw="fit-content"
          onClick={() => setShowEditGeozoneModal(true)}
          leftSection={<IconEdit size={18} />}
          mr={60}
        >
          Edit Geozone
        </MantineButton>
      ) : (
        geoLocationBox
      )}

      <EditAndCreateProjectModal
        isOpen={openModal}
        close={() => setOpenModal(false)}
        onChange={(point, mode) => {
          handleUpdateProjectCenter(point, mode);
        }}
        onSubmit={() => {}}
      />
      {setup?.geozone && showEditGeozoneModal && (
        <EditGeozoneModal
          isOpen={showEditGeozoneModal}
          close={() => setShowEditGeozoneModal(false)}
          onSubmit={handleUpdateGeozone}
          setupId={setup.id}
          geozone={setup.geozone}
          countryCode={workZone.countryCode}
        />
      )}
    </Box>
  );
};
