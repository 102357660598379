import { BatteryMonitorPdfGenerator } from './batteryMonitorPdfGenerator';

/**
 * SmartCable PDF Generator class
 * Handles generation of PDF reports for SmartCable devices
 */
export class SmartCablePdfGenerator extends BatteryMonitorPdfGenerator {
  /**
   * Provide PDF title
   */
  protected getTitle(): string {
    return 'Smart Cable';
  }
}
