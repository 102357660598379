import { useState, useEffect } from 'react';
import { Modal, Group, Stack, Text, Divider } from '@mantine/core';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { IconCalendar, IconClock, IconZzz } from '@tabler/icons-react';

import { useDisclosure } from '@mantine/hooks';
import { getDateOffset } from '@/utils/snoozeUtils';
import { Button } from '@/components/Button/Button';

const durations = ['1h', '2h', '4h', '8h', '12h'];

type DatePickerValue = Date | Date[] | [Date | null, Date | null] | null;

interface DateFormatterParams {
  date: DatePickerValue;
  locale: string;
  format: string;
}

interface SnoozeButtonProps {
  projectId: string;
  snoozeProject: (params: { id: string; snoozedUntil: Date; snoozedFrom: Date }) => void;
  isPwa?: boolean;
  disabled?: boolean;
}

const SnoozeButton = ({ projectId, snoozeProject, isPwa = false, disabled }: SnoozeButtonProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedDuration, setSelectedDuration] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedTimeStr, setSelectedTimeStr] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [durationError, setDurationError] = useState<string | null>(null);

  // Format date display with "Today" for current date
  const formatDateValue = (input: DateFormatterParams): string => {
    const { date } = input;
    if (!date) return '';

    if (Array.isArray(date)) return '';

    const today = new Date();
    if (
      date instanceof Date &&
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return 'Today';
    }

    return date instanceof Date ? date.toLocaleDateString() : '';
  };

  useEffect(() => {
    if (opened) {
      // Reset state when modal opens
      setSelectedDuration('');
      setSelectedDate(new Date());
      setDurationError(null);

      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      setSelectedTimeStr(`${hours}:${minutes}`);
      setError(null);
    }
  }, [opened]);

  const getStartDate = (): Date => {
    if (selectedDate) {
      // Use selected date with time
      const resultDate = new Date(selectedDate);

      if (selectedTimeStr) {
        const [hours, minutes] = selectedTimeStr.split(':');
        if (hours && minutes) {
          resultDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        }
      }

      return resultDate;
    }

    // Fallback to now
    return new Date();
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeStr(event.currentTarget.value);
    validateDateTime(selectedDate, event.currentTarget.value);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    validateDateTime(date, selectedTimeStr);
  };

  // Validate that selected date and time are not in the past
  // Allows a 5-minute buffer to account for the time spent with the modal open
  const validateDateTime = (date: Date | null, timeStr: string) => {
    if (!date) return;

    const selectedDateTime = new Date(date);
    if (timeStr) {
      const [hours, minutes] = timeStr.split(':');
      if (hours && minutes) {
        selectedDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      }
    }

    const now = new Date();

    // Calculate a buffer time 5 minutes in the past
    const bufferTime = new Date(now);
    bufferTime.setMinutes(now.getMinutes() - 5);

    if (selectedDateTime < bufferTime) {
      setError('Cannot select a time in the past');
    } else {
      setError(null);
    }
  };

  const handleSnooze = () => {
    const startDate = getStartDate();
    const now = new Date();

    // Apply the same 5-minute buffer for final validation
    const bufferTime = new Date(now);
    bufferTime.setMinutes(now.getMinutes() - 5);

    // Final validation before submitting
    if (startDate < bufferTime) {
      setError('Please select a time in the future');
      return;
    }
    if (selectedDuration === '') {
      setDurationError('Please select a duration');
      return;
    }

    snoozeProject({
      id: projectId,
      snoozedFrom: startDate,
      snoozedUntil: getDateOffset(selectedDuration, startDate),
    });

    close();
  };

  // Modal props change based on if it's used in a PWA
  const modalProps = isPwa
    ? {
        fullScreen: true,
        transitionProps: { transition: 'slide-up' as const, duration: 300 },
        styles: {
          inner: { padding: 0 },
          content: {
            position: 'absolute' as const,
            bottom: 0,
            width: '100%',
            height: 'auto', // Use auto height instead of maxHeight
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      }
    : {
        centered: true,
      };

  return (
    <>
      <Button
        variant={isPwa ? 'secondary' : 'primary'}
        icon={IconZzz}
        onClick={open}
        disabled={disabled}
      >
        Snooze alarms
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text size="lg" fw={700}>
            Snooze alarms
          </Text>
        }
        padding="md"
        {...modalProps}
      >
        <Divider mb="md" />
        <Stack gap="md">
          {/* Start snooze from */}
          <Stack gap="xs">
            <Text fw={600}>Start snooze from:</Text>
            <Text size="sm" c="dimmed">
              When do you want to snooze alarms?
            </Text>

            <Group grow align="flex-start">
              <DatePickerInput
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                leftSection={<IconCalendar size={16} />}
                clearable={false}
                defaultDate={new Date()}
                minDate={new Date()}
                valueFormatter={formatDateValue}
                styles={(theme) => ({
                  input: {
                    '&:focus': {
                      borderColor: theme.colors.primaryGreen[5],
                    },
                  },
                  day: {
                    '&[dataSelected]': {
                      backgroundColor: theme.colors.primaryGreen[5],
                    },
                    '&[dataSelected]:hover': {
                      backgroundColor: theme.colors.primaryGreen[6],
                    },
                  },
                })}
              />

              <TimeInput
                label="Time"
                value={selectedTimeStr}
                onChange={handleTimeChange}
                leftSection={<IconClock size={16} />}
                error={error}
                withSeconds={false}
                styles={(theme) => ({
                  input: {
                    '&:focus': {
                      borderColor: theme.colors.primaryGreen[5],
                    },
                  },
                })}
              />
            </Group>
          </Stack>

          {/* Duration */}
          <Stack gap="xs">
            <Group gap="xs">
              <Text fw={600}>Duration</Text>
              <Text c="dangerRed">*</Text>
            </Group>
            <Text size="sm" c="dimmed">
              For how long do you want to snooze alarms?
            </Text>

            {durationError && (
              <Text size="xs" c="dangerRed">
                {durationError}
              </Text>
            )}

            {isPwa ? (
              // PWA version - show 4 buttons in top row and 12h separately
              <>
                <Group grow>
                  {durations.slice(0, 4).map((duration) => (
                    <Button
                      key={duration}
                      variant={selectedDuration === duration ? 'primary' : 'secondary'}
                      onClick={() => {
                        setSelectedDuration(duration);
                        setDurationError(null);
                      }}
                    >
                      {duration}
                    </Button>
                  ))}
                </Group>

                {/* Add the 12h button separately in PWA mode */}
                <Button
                  variant={selectedDuration === '12h' ? 'primary' : 'secondary'}
                  fullWidth
                  onClick={() => {
                    setSelectedDuration('12h');
                    setDurationError(null);
                  }}
                >
                  12h
                </Button>
              </>
            ) : (
              // Desktop version - show all 5 buttons in one row
              <Group grow>
                {durations.map((duration) => (
                  <Button
                    key={duration}
                    variant={selectedDuration === duration ? 'primary' : 'secondary'}
                    onClick={() => {
                      setSelectedDuration(duration);
                      setDurationError(null);
                    }}
                  >
                    {duration}
                  </Button>
                ))}
              </Group>
            )}
          </Stack>

          <Button variant="primary" fullWidth onClick={handleSnooze}>
            OK
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default SnoozeButton;
