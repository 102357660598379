import { z } from 'zod';
import dayjs from 'dayjs';
import { useMsal } from '@azure/msal-react';
import { useForm, zodResolver } from '@mantine/form';
import { useCallback, useEffect, useState } from 'react';
import { DatePickerInput, DatesRangeValue } from '@mantine/dates';
import { Fieldset, Flex, Group, NumberInput, Paper, Stack, Text, Title } from '@mantine/core';
import * as htmlToImage from 'html-to-image';

import {
  calculateCo2Avoided,
  CalculateCarbonAvoidedOutput,
} from '@/utils/co2Calculation/co2Calculation';
import { getWeeklyRanges, Week } from '@/utils/dates/dates';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import { PdfExportProgressBar } from '@/components/Project/SupervisionReport/components/PdfExportProgressBar/PdfExportProgressBar';
import { notifications } from '@mantine/notifications';
import { Co2BarChart } from './Co2BarChart/Co2BarChart';
import { WorkZone } from '@/models/Project';
import useDepots from '@/data/hooks/Depots/useDepots';
import { createCo2PDF, PdfProps } from '../SupervisionReport/utils/exportPdfCo2';
import { TabTitle } from '@/components/TabTitle/TabTitle';
import { Button } from '@/components/Button/Button';
import { IconDownload } from '@tabler/icons-react';

const schema = z.object({
  distance: z.number({ invalid_type_error: 'This field is required' }).gt(0),
  physicalSupervisionVisit: z.number({ invalid_type_error: 'This field is required' }).gt(0),
  heavyTrucks: z.number({ invalid_type_error: 'This field is required' }).gte(0),
  lightTrucks: z.number({ invalid_type_error: 'This field is required' }).gte(0),
  cars: z.number({ invalid_type_error: 'This field is required' }).gte(0),
  dateRange: z.array(z.date()).length(2, 'You must select a start and end date'),
  digitalSupervisionVisits: z.record(
    z.number({ invalid_type_error: 'This field is required' }).gte(0)
  ),
});

interface Props {
  project: WorkZone;
}

export const ProjectCo2CalculatorTab = ({ project }: Props) => {
  const { instance } = useMsal();
  const { depotsNameMap } = useDepots(instance);

  const [selectedWeeks, setSelectedWeeks] = useState<Week[]>([]);
  const [co2Data, setCo2Data] = useState<CalculateCarbonAvoidedOutput>();
  const [pendingExport, setPendingExport] = useState(false);

  const isMobile = useMobileDevice();

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      distance: null,
      physicalSupervisionVisit: null,
      heavyTrucks: 0,
      lightTrucks: 0,
      cars: 0,
      dateRange: undefined as DatesRangeValue | undefined,
      digitalSupervisionVisits: {} as Record<string, number | null>,
    },
    validate: zodResolver(schema),
  });

  const handleDateChange = (selectedDateRange: DatesRangeValue) => {
    form.setFieldValue('dateRange', selectedDateRange);

    if (selectedDateRange?.[0] && selectedDateRange?.[1]) {
      const weeks = getWeeklyRanges(selectedDateRange[0] as Date, selectedDateRange[1] as Date);
      setSelectedWeeks(weeks);
      const dynamicWeekInputs = Object.fromEntries(
        Array.from({ length: weeks.length }, (_, i) => [`week-${i + 1}`, null])
      );
      form.setFieldValue('digitalSupervisionVisits', dynamicWeekInputs);
    } else {
      form.setFieldValue('digitalSupervisionVisits', {});
    }
  };

  const handleSubmit = useCallback(
    async (values: typeof form.values) => {
      if (!selectedWeeks[0].startDate || !selectedWeeks[0].endDate) return;

      if (
        values.distance !== null &&
        values.physicalSupervisionVisit !== null &&
        values.heavyTrucks !== null &&
        values.lightTrucks !== null &&
        values.cars !== null &&
        selectedWeeks[0]
      ) {
        const digitalSupervisionVisitsList = Object.values(values.digitalSupervisionVisits).map(
          (value) => value
        );

        setCo2Data(
          calculateCo2Avoided({
            distanceDepotToWorksite: values.distance,
            physicalSupervisionVisitsPerWeek: values.physicalSupervisionVisit,
            amountTmaCars: values.heavyTrucks,
            amountLightTrucks: values.lightTrucks,
            amountCars: values.cars,
            selectedWeeks: selectedWeeks.map((week, index) => ({
              ...week,
              digitalSupervisionVisits: digitalSupervisionVisitsList[index] || 0,
            })),
          })
        );
      }
    },
    [selectedWeeks, form]
  );

  // Update results whenever form values change
  useEffect(() => {
    if (form.isValid()) handleSubmit(form.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values, form.isValid()]);

  const handleExport = () => {
    setPendingExport(true);
  };

  const barChart = document.getElementById('bar-chart');

  const exportPdf = useCallback(async () => {
    if (!barChart) return new Promise(() => '');
    const chartImg = await htmlToImage.toPng(barChart);

    const props: PdfProps = {
      projectName: project.name,
      depot: depotsNameMap.get(project.depotId),
      reportStartDate: selectedWeeks[0].startDate,
      reportEndDate: selectedWeeks[selectedWeeks.length - 1].endDate,
      countryCode: project.countryCode,
      customerName: project.customerName ?? '',
      barChartImg: chartImg,
      totalCo2: co2Data?.totalCo2Avoided.toString() || '???',
    };

    try {
      await createCo2PDF(props);
    } catch (error) {
      console.error('Error exporting PDF:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to export PDF report',
        color: 'red',
      });
    } finally {
      setPendingExport(false);
    }
  }, [
    barChart,
    co2Data?.totalCo2Avoided,
    depotsNameMap,
    project.countryCode,
    project.customerName,
    project.depotId,
    project.name,
    selectedWeeks,
  ]);

  useEffect(() => {
    if (pendingExport) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          exportPdf();
        }, 10);
      });
    }
  }, [pendingExport, exportPdf]);

  return (
    <>
      <Flex direction={isMobile ? 'column' : 'row'} align="top" gap="md">
        <Paper p="md" flex={2}>
          <TabTitle mb="md">Enter supervision data</TabTitle>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <Flex direction={isMobile ? 'column' : 'row'} align="top" gap="md">
              <Fieldset legend="Estimations if no digital products" flex={1}>
                <NumberInput
                  {...form.getInputProps('distance')}
                  required
                  min={0}
                  label="Distance from depot to worksite (km)"
                  description="One-way distance"
                  placeholder="Distance (km)"
                  mt="sm"
                  mb="lg"
                />
                <NumberInput
                  {...form.getInputProps('physicalSupervisionVisit')}
                  required
                  min={1}
                  label="Worksite checks per week"
                  description="If not using digital products"
                  placeholder="Worksite checks per week"
                  mb="lg"
                />
                <NumberInput
                  {...form.getInputProps('heavyTrucks')}
                  required
                  min={0}
                  label="TMA/HGV trucks used"
                  description="Per worksite check"
                  placeholder="Amount of TMA/HGV trucks"
                  mb="lg"
                />
                <NumberInput
                  {...form.getInputProps('lightTrucks')}
                  required
                  min={0}
                  label="Light trucks used (e.g. van or pickup)"
                  description="Per worksite check"
                  placeholder="Amount of light trucks"
                  mb="lg"
                />
                <NumberInput
                  {...form.getInputProps('cars')}
                  required
                  min={0}
                  label="Cars used (e.g. Caddy)"
                  description="Per worksite check"
                  placeholder="Amount of cars"
                />
              </Fieldset>

              <Fieldset legend="Actual digital supervision" flex={1}>
                <DatePickerInput
                  {...form.getInputProps('dateRange')}
                  type="range"
                  required
                  label="Project duration"
                  description="Between which dates did the project run?"
                  placeholder="Select date interval"
                  maxDate={new Date()}
                  value={form.values.dateRange}
                  onChange={handleDateChange}
                  mt="sm"
                  mb="lg"
                />
                <Stack>
                  {form.values.dateRange?.[0] &&
                    form.values.dateRange?.[1] &&
                    Object.keys(form.values.digitalSupervisionVisits).map((key, index) => {
                      return (
                        <NumberInput
                          {...form.getInputProps(`digitalSupervisionVisits.${key}`)}
                          key={key}
                          required
                          min={0}
                          label={`Worksite checks (${dayjs(selectedWeeks[index].startDate).format('MMM D')} - ${dayjs(selectedWeeks[index].endDate).format('MMM D')})`}
                          placeholder="Worksite checks done"
                        />
                      );
                    })}
                </Stack>
              </Fieldset>
            </Flex>
          </form>
        </Paper>

        <Paper p="md" flex={1}>
          <Stack gap="xl">
            <Stack gap="xs">
              <TabTitle>CO2 avoided by using digital supervision</TabTitle>
              {form.isValid() && co2Data && (
                <Text>{`${dayjs(co2Data.selectedWeeks[0].startDate).format('D MMM')} - ${dayjs(co2Data.selectedWeeks[co2Data.selectedWeeks.length - 1].endDate).format('D MMM')}`}</Text>
              )}
            </Stack>

            {form.isValid() && co2Data ? (
              <>
                <Stack>
                  <Stack gap="xs" align="center">
                    <Title order={4} size="h5" ta="center">
                      CO2 emissions per week
                    </Title>
                    <Co2BarChart
                      id="bar-chart"
                      withLegend
                      series={[
                        { name: 'regular', label: 'Not using digital', color: 'ramuddenBlue' },
                        {
                          name: 'digital',
                          label: 'Using digital',
                          color: 'ramuddenOrange',
                          stackId: 'stack',
                        },
                        {
                          name: 'co2Avoided',
                          label: 'CO2 avoided',
                          color: 'ramuddenYellow',
                          stackId: 'stack',
                        },
                      ]}
                      data={co2Data.selectedWeeks.map((week) => ({
                        week: `${dayjs(week.startDate).format('MMM D')} - ${dayjs(week.endDate).format('MMM D')}`,
                        regular: week.co2WithPhysical,
                        digital: week.co2WithDigital,
                        co2Avoided: week.co2Avoided,
                      }))}
                    />
                  </Stack>
                </Stack>

                <Group gap="sm" justify="center">
                  <Title order={4} size="h5" ta="center">
                    Total CO2 avoided:
                  </Title>
                  <Text size="lg" ta="center">
                    {`${co2Data.totalCo2Avoided} kg`}
                  </Text>
                </Group>

                <Group justify="right">
                  <Button
                    variant="primary"
                    icon={IconDownload}
                    onClick={() => {
                      if (window._paq) {
                        window._paq.push(['trackEvent', 'Project', 'CO2 report exported']);
                      }
                      handleExport();
                    }}
                  >
                    Export report
                  </Button>
                </Group>
              </>
            ) : (
              <Stack>
                <Text size="lg" ta="center" c="dimmedText">
                  Please fill the form to the left to see the graphs
                </Text>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Flex>
      {pendingExport && <PdfExportProgressBar />}
    </>
  );
};
