// Extend to get type help, but any string is valid too
type FeatureFlagKey = 'project:create:flow:psa' | 'project:create:flow:psa:geozone';

export const getFeatureFlag = (key: FeatureFlagKey | (string & object)) => {
  // TODO implementation details TBC, env vars are used for time being
  switch (key) {
    case 'project:create:flow:psa':
      return import.meta.env.VITE_USE_PSA_PROJECT_FLOW_AVAILABLE === 'true';
    case 'project:create:flow:psa:geozone':
      return import.meta.env.VITE_USE_PSA_PROJECT_GEOZONE_AVAILABLE === 'true';
    default:
      return false;
  }
};
