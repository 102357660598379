import { AccessDenied } from '@/pages/AccessDenied';
import { Paper, Tabs } from '@mantine/core';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  value: string;
  accessDenied?: boolean;
}

export const ProjectTabPanel = ({ children, value, accessDenied = false }: Props) => {
  if (accessDenied)
    return (
      <Tabs.Panel value={value}>
        <AccessDenied />
      </Tabs.Panel>
    );
  return (
    <Tabs.Panel mx="md" value={value}>
      <Paper p="md" mt="md">
        {children}
      </Paper>
    </Tabs.Panel>
  );
};
