import { notifications } from '@mantine/notifications';

export const showErrorNotification = (title: string, message: string) => {
  notifications.show({
    color: 'red',
    autoClose: 5000,
    position: 'bottom-center',
    title,
    message,
  });
};

export const handleError = (error: unknown) => {
  if (!isBackendError(error)) {
    showErrorNotification('An unknown error occurred Error', 'Unknown');
    return;
  }
  switch (error.status) {
    case 401:
      // Handled by the auth provider
      break;
    case 403:
      showErrorNotification('403 Forbidden', 'You do not have permission to access this resource');
      break;

    case 404:
      showErrorNotification('404 Not Found', 'The requested page or resource was not found');
      break;

    case 500:
      showErrorNotification('500 Internal Server Error', 'Something went wrong on the server');
      break;

    default:
      showErrorNotification(`${error.status} ${error.title}`, error.detail);
      break;
  }
};

type BackendError = { status: number; title: string; detail: string };

export function isBackendError(error: unknown): error is BackendError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    typeof (error as { status?: unknown }).status === 'number' &&
    'title' in error &&
    typeof (error as { title?: unknown }).title === 'string' &&
    'detail' in error &&
    typeof (error as { detail?: unknown }).detail === 'string'
  );
}
