// @/utils/base64Utils.ts
import { STATUS_ICONS } from '@/components/Project/SupervisionReport/constants/statusIcons';
import { PdfAssets } from '@/constants/pdfConstants';
import { StatusIcons } from '@/models/PdfExport';

const iconCache = new Map<string, string>();

/**
 * Converts an image URL to base64 format with caching
 */
export const imageToBase64 = async (imageSrc: string): Promise<string> => {
  // Check cache first
  if (iconCache.has(imageSrc)) {
    return iconCache.get(imageSrc)!;
  }

  // If no cached value, load and convert the image
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) throw new Error('Could not get canvas context');

        // Calculate size with maximum limit for performance
        // A higher value will result in slower pdf generation
        const MAX_SIZE = 1000;
        let width = img.naturalWidth;
        let height = img.naturalHeight;

        if (width > height && width > MAX_SIZE) {
          height = (height * MAX_SIZE) / width;
          width = MAX_SIZE;
        } else if (height > MAX_SIZE) {
          width = (width * MAX_SIZE) / height;
          height = MAX_SIZE;
        }

        canvas.width = Math.round(width);
        canvas.height = Math.round(height);
        ctx.drawImage(img, 0, 0, width, height);

        const dataUrl = canvas.toDataURL('image/png', 0.8);
        iconCache.set(imageSrc, dataUrl);
        resolve(dataUrl);
      } catch (error) {
        reject(error);
      }
    };

    img.onerror = () => reject(new Error(`Failed to load image: ${imageSrc}`));
    img.src = imageSrc;
  });
};

/**
 * Helper function to convert icons to base64 with caching
 */
export const convertIconToBase64Cached = async (iconSrc: string): Promise<string> => {
  if (iconCache.has(iconSrc)) {
    return iconCache.get(iconSrc)!;
  }
  const base64 = await imageToBase64(iconSrc);
  iconCache.set(iconSrc, base64);
  return base64;
};

/**
 * Converts an HTMLImageElement to base64 format
 */
export const htmlImageToBase64 = async (img: HTMLImageElement): Promise<string> => {
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas context');

    const MAX_SIZE = 800;
    let width = img.naturalWidth || img.width;
    let height = img.naturalHeight || img.height;

    if (width > height && width > MAX_SIZE) {
      height = (height * MAX_SIZE) / width;
      width = MAX_SIZE;
    } else if (height > MAX_SIZE) {
      width = (width * MAX_SIZE) / height;
      height = MAX_SIZE;
    }

    canvas.width = Math.round(width);
    canvas.height = Math.round(height);
    ctx.drawImage(img, 0, 0, width, height);

    return canvas.toDataURL('image/png', 0.8);
  } catch (error) {
    console.error('Error converting HTMLImageElement to base64:', error);
    throw error;
  }
};

/**
 * Converts an image map to a format that can be used by the worker
 */
export const convertImageMapForWorker = async (
  imageMap: Map<string, HTMLImageElement>
): Promise<Record<string, string>> => {
  const result: Record<string, string> = {};
  const convertPromises: Promise<void>[] = [];

  // Process all images in parallel
  for (const [key, img] of imageMap.entries()) {
    const promise = (async () => {
      try {
        const base64 = await htmlImageToBase64(img);
        result[key] = base64;
      } catch (error) {
        console.error(`Failed to convert image for key ${key}:`, error);
      }
    })();

    convertPromises.push(promise);
  }

  // Wait for all conversions to complete
  await Promise.all(convertPromises);
  return result;
};

/**
 * Clears the image cache
 */
export const clearImageCache = (): void => {
  iconCache.clear();
};

/**
 * Converts status icons to base64 for PDF generation
 * Returns exactly the StatusIcons type that is expected
 */
export const convertStatusIconsToBase64 = async (): Promise<StatusIcons> => {
  // Convert the four icons needed for the StatusIcons type
  return {
    check: await imageToBase64(STATUS_ICONS.CHECK),
    alert: await imageToBase64(STATUS_ICONS.ALERT),
    dotted: await imageToBase64(STATUS_ICONS.DOTTED),
    snoozed: await imageToBase64(STATUS_ICONS.SNOOZED),
  };
};

/**
 * Converts report assets to base64 format
 * Returns exactly the PdfAssets type that is expected
 */
export const convertAssetsToBase64 = async (
  primaryLogo: string,
  primaryLogoWhite: string,
  coverBackground: string
): Promise<PdfAssets> => {
  return {
    primaryLogo: await imageToBase64(primaryLogo),
    primaryLogoWhite: await imageToBase64(primaryLogoWhite),
    coverBackground: await imageToBase64(coverBackground),
  };
};

/**
 * Prepares all image assets needed for PDF generation
 */
export const prepareImagesForPdf = async (
  primaryLogo: string,
  primaryLogoWhite: string,
  coverBackground: string,
  intellitagImageMap?: Map<string, HTMLImageElement>
): Promise<{
  statusIcons: StatusIcons;
  assets: PdfAssets;
  imageMap: Record<string, string>;
}> => {
  // Run all image preparation tasks in parallel for better performance
  const [statusIcons, assets, imageMap] = await Promise.all([
    convertStatusIconsToBase64(),
    convertAssetsToBase64(primaryLogo, primaryLogoWhite, coverBackground),
    intellitagImageMap ? convertImageMapForWorker(intellitagImageMap) : Promise.resolve({}),
  ]);

  return {
    statusIcons,
    assets,
    imageMap,
  };
};
