import { Button, Checkbox, Popover, Stack, Text } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import { useEffect, useState, useMemo } from 'react';

// Types
export type CheckboxOptions<ValueType> = {
  label: string;
  value: ValueType;
  checked: boolean;
  category?: string;
};

export type FilterGroupType<ValueType> = {
  name?: string;
  options: CheckboxOptions<ValueType>[];
};

export type FilterBase<T, ValueType> = {
  [K in keyof T]?: FilterGroupType<ValueType>;
};

export type FilterState<ValueType> = {
  [key: string]: ValueType[];
};

type Props<T, ValueType> = {
  filters: FilterState<ValueType>;
  setFilters: (filters: FilterState<ValueType>) => void;
  getFilterGroups: (filters: FilterState<ValueType>) => FilterBase<T, ValueType>;
};

export function FilterButton<T, ValueType>({
  filters,
  setFilters,
  getFilterGroups,
}: Props<T, ValueType>) {
  // Get filter groups based on current filters
  const filterGroups = useMemo(() => getFilterGroups(filters), [filters, getFilterGroups]);

  // Parse filter entries from filter groups
  const [filterEntries, setFilterEntries] = useState<
    { key: string; group: FilterGroupType<ValueType> | undefined }[]
  >([]);

  useEffect(() => {
    const entries = Object.keys(filterGroups).map((key) => ({
      key,
      group: filterGroups[key as keyof T],
    }));
    setFilterEntries(entries);
  }, [filterGroups]);

  // Calculate number of active filters
  const filtersActive = useMemo(() => {
    let count = 0;
    filterEntries.forEach(({ group }) => {
      if (group?.options) {
        group.options.forEach((option) => {
          if (option.checked) {
            count++;
          }
        });
      }
    });
    return count;
  }, [filterEntries]);

  // Handle checkbox changes
  const handleFilterChange = (changedOption: CheckboxOptions<ValueType>) => {
    const { value, checked, category } = changedOption;

    if (!category) return;

    const newFilters = { ...filters };

    // Handle alarmStatus specially (only one option at a time)
    // Handle other filter categories
    if (checked) {
      // Add value to category array
      if (!newFilters[category]) {
        newFilters[category] = [];
      }

      if (!newFilters[category].includes(value)) {
        newFilters[category] = [...newFilters[category], value];
      }
    } else {
      // Remove value from category array
      if (newFilters[category]) {
        newFilters[category] = newFilters[category].filter((v) => v !== value);
      }
    }

    setFilters(newFilters);
  };

  // Clear all filters
  const clearAllFilters = () => {
    const clearedFilters: FilterState<ValueType> = {};
    Object.keys(filters).forEach((key) => {
      clearedFilters[key] = [];
    });
    setFilters(clearedFilters);
  };

  return (
    <Popover width={200} position="bottom-end">
      <Popover.Target>
        <Button
          className={filtersActive ? 'mantineBtn-override' : ''}
          miw={115}
          variant="default"
          fw={300}
          leftSection={<IconFilter size={18} />}
        >
          Filter {!!filtersActive && `(${filtersActive})`}
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Stack>
          {/* Render each filter group */}
          {filterEntries.map(({ key, group }) => {
            if (!group) return null;

            return (
              <Stack gap="xs" key={key}>
                {group.name && <Text size="sm">{group.name}</Text>}

                {group.options.map((opt) => (
                  <Checkbox
                    key={`${opt.label}-${opt.value}`}
                    label={opt.label}
                    checked={opt.checked}
                    onChange={(event) => {
                      handleFilterChange({
                        value: opt.value as ValueType,
                        label: opt.label,
                        checked: event.currentTarget.checked,
                        category: key,
                      });
                    }}
                  />
                ))}
              </Stack>
            );
          })}

          {/* Clear all button */}
          {!!filtersActive && (
            <Button
              variant="transparent"
              fw={300}
              size="sm"
              ml="auto"
              c="black"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={clearAllFilters}
            >
              Clear all filters
            </Button>
          )}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
