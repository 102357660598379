import {
  Title,
  Group,
  Stack,
  Drawer,
  Text,
  Input,
  Center,
  Loader,
  Tabs,
  Switch,
  Collapse,
} from '@mantine/core';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { WorkZoneCardComponent } from '@/components/WorkZoneList/WorkZoneCard';
import { WorkZone } from '../models/Project.ts';
import useInfProjectList from '@/data/hooks/Project/useInfProjectListHook.ts';
import useProjectDevices from '@/data/hooks/Device/useProjectDevicesHook.ts';
import { ProjectDrawer } from '@/components/Drawer/ProjectDrawer.tsx';
import { IconFilter, IconLayoutDashboard, IconMenu2, IconPlus } from '@tabler/icons-react';
import { useMediaQuery } from 'react-responsive';
import { ProjectListView } from '@/components/Project/ProjectList/ProjectListView.tsx';
import { NoProjectsFound } from '@/components/Project/NoProjectsFound/NoProjectsFound.tsx';
import useDepots from '@/data/hooks/Depots/useDepots.tsx';
import { CustomMultiSelect } from '@/shared/MultiSelect/CustomMultiSelect.tsx';
import { useNavigate } from 'react-router-dom';
import { useDisclosure, useSessionStorage } from '@mantine/hooks';
import useMyUserAccesses from '@/data/hooks/UserAccess/useMyUserAccess.ts';
import { AccessDenied } from '@/pages/AccessDenied';
import { getFeatureFlag } from '@/hooks/useFeatureFlag.ts';
import { Button } from '@/components/Button/Button.tsx';

export default function Home() {
  const smallScreen = useMediaQuery({ query: '(max-width: 760px)' });
  const { instance } = useMsal();
  const [drawer, setDrawer] = useState<WorkZone | null>(null);
  const [tab, setTab] = useSessionStorage<'card' | 'list'>({
    key: 'tab',
    defaultValue: 'card',
  });
  const navigate = useNavigate();
  const {
    projects,
    isLoading: loading,
    search,
    handleSearchChange,
    setShowOnlyDemoProjects,
    setDepot: setDepotCodes,
    showOnlyDemoProjects,
    depot: depotCodes,
    showOnlyClosedProjects,
    setShowOnlyClosedProjects,
  } = useInfProjectList(instance);

  const projectIds = projects.map((wz) => wz.id) || [];
  const { projectDevices, isLoading } = useProjectDevices({ instance, projectIds });
  const { depots, depotsNameMap } = useDepots(instance);
  const [opened, { toggle }] = useDisclosure(false);

  const shouldUsePsaProjectFlow = getFeatureFlag('project:create:flow:psa');
  const handleDrawerClose = () => {
    setDrawer(null);
  };

  const { hasProjectAccess, isLoading: userAccessLoading } = useMyUserAccesses({ instance });

  const projectCardList = projects.map((wz) => {
    const devices = projectDevices[wz.id];
    return (
      <WorkZoneCardComponent
        isLoading={isLoading}
        key={wz.id}
        workZone={wz}
        depotName={depotsNameMap.get(wz.depotId)}
        devices={devices}
        onClick={() => setDrawer(wz)}
      />
    );
  });

  const handleCreateProjectClick = () => {
    shouldUsePsaProjectFlow ? navigate('/projects/create') : navigate('/projects/create/default');
  };

  if (userAccessLoading) {
    return (
      <Center style={{ height: '70vh' }}>
        <Loader />
      </Center>
    );
  }

  if (!hasProjectAccess) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack bg="white" px="md" pt="md" pb="xl">
        <Group justify="space-between">
          <Title order={3}>All Projects</Title>
          <Button variant="primary" onClick={handleCreateProjectClick} icon={IconPlus}>
            {smallScreen ? null : 'Create new project'}
          </Button>
        </Group>

        <Group>
          <Input
            size="md"
            w={smallScreen ? '100%' : 'auto'}
            placeholder="Search for project"
            value={search}
            miw={265}
            onChange={(event) => handleSearchChange(event.currentTarget.value)}
          />

          <Group w={smallScreen ? '100%' : 'auto'} wrap="nowrap">
            <CustomMultiSelect
              width="265px"
              values={depots.filter((d) => depotCodes.includes(d.id)).map((d) => d.name)}
              data={depots.map((d) => d.name)}
              onChange={(values) => {
                setDepotCodes(depots.filter((d) => values.includes(d.name)).map((d) => d.id));
              }}
            />
            {smallScreen && <Button variant="secondary" onClick={toggle} icon={IconFilter} />}
          </Group>
        </Group>

        <Collapse in={smallScreen ? opened : true}>
          <Group>
            <Switch
              checked={showOnlyClosedProjects}
              onChange={(event) => setShowOnlyClosedProjects(event.currentTarget.checked)}
              label="Show closed projects"
            />

            <Switch
              label="Show demo projects"
              labelPosition="right"
              checked={showOnlyDemoProjects}
              onChange={(event) => setShowOnlyDemoProjects(event.currentTarget.checked)}
            ></Switch>
          </Group>
        </Collapse>
      </Stack>

      <Tabs value={tab}>
        <Tabs.List px="md" bg="white">
          <Tabs.Tab
            value="card"
            leftSection={<IconLayoutDashboard size="20px" />}
            onClick={() => setTab('card')}
          >
            <Text>Card</Text>
          </Tabs.Tab>
          <Tabs.Tab
            value="list"
            leftSection={<IconMenu2 size="20px" />}
            onClick={() => setTab('list')}
          >
            <div
              onClick={() => {
                if (window._paq) {
                  window._paq.push(['trackEvent', 'Home portal', 'List view selected']);
                }
              }}
            >
              <Text>List</Text>
            </div>
          </Tabs.Tab>
          <div style={{ flexGrow: 1 }}></div>
          <Text size="sm" fw={500}>
            Ordered by:
          </Text>
          <Text size="sm" ml="xs">
            Last created
          </Text>
        </Tabs.List>
        <Tabs.Panel value="card">
          {loading ? (
            <Center mt="xl">
              <Loader />
            </Center>
          ) : (
            <Group align="stretch" justify={smallScreen ? 'center' : 'start'} p="md">
              {projectCardList.length ? projectCardList : <NoProjectsFound />}
            </Group>
          )}
        </Tabs.Panel>
        <Tabs.Panel value="list">
          <ProjectListView
            projects={projects}
            projectDevices={projectDevices}
            depotNameMap={depotsNameMap}
            isLoading={loading}
            onClick={(project) => setDrawer(project)}
          />
        </Tabs.Panel>
      </Tabs>

      <Drawer
        position="right"
        opened={!!drawer}
        onClose={handleDrawerClose}
        title="Project Summary"
        bg="blue"
        overlayProps={{ backgroundOpacity: 0.2 }}
        zIndex={202}
      >
        {drawer && (
          <ProjectDrawer
            project={drawer}
            projectDevices={projectDevices[drawer.id]}
            depotName={depotsNameMap.get(drawer.depotId)}
          />
        )}
      </Drawer>
    </>
  );
}
