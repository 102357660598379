export const MapConstants = Object.freeze({
  DEVICE_ZOOM: 12,
  PROJECT_ZOOM: 9,
  MAX_DEVICE_RENDER: 1000,
});

export const CountryCodeToCountryNameMap = {
  AU: 'Australia',
  CA: 'Canada',
  FI: 'Finland',
  DE: 'Germany',
  NO: 'Norway',
  SE: 'Sweden',
  GB: 'Great Britain',
};

export const LeftHandDrivingCountries = [
  'IN',
  'ID',
  'PK',
  'PD',
  'JP',
  'TH',
  'GB',
  'TH',
  'ZA',
  'KE',
  'UG',
  'MY',
  'MZ',
  'NP',
  'AU',
  'LK',
  'MW',
  'ZM',
  'ZW',
  'PG',
  'HK',
  'SG',
  'IE',
  'NZ',
  'JM',
  'NA',
  'BW',
  'LS',
  'TT',
  'TL',
  'MU',
  'CY',
  'SZ',
  'FJ',
  'GY',
  'BT',
  'SB',
  'MO',
  'SR',
  'MV',
  'MT',
  'BN',
  'BS',
  'BB',
  'WS',
  'LC',
  'KI',
  'GD',
  'VC',
  'TO',
  'VI',
  'SC',
  'AG',
  'IM',
  'DM',
  'KY',
  'BM',
  'KN',
  'TC',
  'VG',
  'CK',
  'AI',
  'TV',
  'NR',
  'MS',
  'FK',
  'NU',
  'TK',
];
