import { ForwardedRef } from 'react';
import { PdfExportRef, DashboardPdfProps } from '../../../../models/PdfExport';
import { DashboardComponent } from '../../../../models/Dashboard';
import { useComponentExporter } from './useComponentExporter';
import { ReportType } from '../../../../models/enums/ReportType';

export function useDashboard<T, P extends DashboardPdfProps>(
  ref: ForwardedRef<PdfExportRef>,
  props: DashboardComponent<T>,
  pdfProps: P,
  type: ReportType,
  additionalMethods?: Partial<PdfExportRef>
) {
  // Common dashboard logic
  useComponentExporter(
    ref,
    props,
    {
      ...{
        imageMap: new Map(),
        rows: [],
        timeStamps: [],
        intervalInHours: 8,
        showSnoozed: false,
      },
      ...pdfProps, // Spread pdfProps last to override defaults
    },
    type,
    additionalMethods
  );

  // Common null check
  if (!props.data) {
    return { shouldRender: false };
  }

  return { shouldRender: true };
}
