import { useContext, useState } from 'react';
import { Stack, Text } from '@mantine/core';
import { BarcodeScanner } from 'react-barcode-scanner';
import 'react-barcode-scanner/polyfill';

import styles from './styles.module.css';
import { PWAContext } from '../../../../providers/PWAProvider.tsx';
import { isValidQrcode } from '../../../../utils/qrcodeValidator.ts';
import { parseQRCode } from '../../../../utils/qrcodeParser.ts';

type DetectedBarcode = {
  boundingBox: DOMRectReadOnly;
  cornerPoints: { x: number; y: number }[];
  format: string;
  rawValue: string;
};

export type QRCode = {
  imei: string;
  referenceId: string;
  version: string;
};

interface Props {
  handleConnection: (refId: string) => void;
  trackingId: string;
  text?: string;
}

export const Scanner = ({ text, trackingId, handleConnection }: Props) => {
  const { setMessage, message, offline } = useContext(PWAContext);
  const [previousCode, setPreviousCode] = useState<DetectedBarcode | null>(null);

  const onCapture = (detected: DetectedBarcode[]) => {
    if (offline || !detected.length) return;
    if (window._paq) {
      window._paq.push(['trackEvent', trackingId, 'Barcode scanned']);
    }

    detected.forEach((barcode) => {
      if (previousCode && previousCode.rawValue === barcode.rawValue) return;

      setPreviousCode(barcode);
      const qrCodeData = parseQRCode(barcode.rawValue);
      if (qrCodeData && isValidQrcode(qrCodeData)) {
        const { referenceId } = qrCodeData;
        handleConnection(referenceId);
      } else {
        setMessage({
          text: 'The QR code you scanned is not a valid device',
          title: 'Invalid QR code',
          type: 'error',
        });
      }
    });
  };

  return (
    <Stack justify="center" gap={0}>
      {text && (
        <Text ta="center" size="sm" c="greyText">
          {text}
        </Text>
      )}
      <BarcodeScanner
        className={`${styles.scanner} ${message && styles[message.type]}`}
        options={{ formats: ['qr_code'] }}
        onCapture={onCapture}
      />
    </Stack>
  );
};
