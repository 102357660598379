import { Device, DeviceModel, DeviceType } from '@/models/Device';
import { BATTERY_THRESHOLDS_POWERED_EQUIPMENT, BATTERY_THRESHOLDS_TELTONIKA } from './battery';
import { BatteryStatus, BatteryThresholds } from '@/models/enums/DeviceEnums';

export const ChartColors = [
  'var(--mantine-color-ramuddenBlue-9)',
  'var(--mantine-color-ramuddenOrange-9)',
  'var(--mantine-color-ramuddenYellow-9)',
  'var(--mantine-color-ramuddenBeige-9)',
  'var(--mantine-color-sand-0)',
  'var(--mantine-color-deepGreen-9)',
  'var(--mantine-color-primaryGreen-6)',
  'var(--mantine-color-primaryGreenLight-9)',
  'var(--mantine-color-deepGreen-2)',
];

export function calculateMedian(values: number[]): number {
  if (values.length === 0) {
    throw new Error('Input array is empty');
  }

  // Sorting values, preventing original array
  // from being mutated.
  values = [...values].sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  return values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2;
}

export const getDeviceModelBatteryThresholds = (device: Device | undefined): BatteryThresholds => {
  if (!device) return { low: 0, critical: 0, outOfBattery: 0 };

  if (
    [DeviceType.SmartCableV1, DeviceType.SmartSolarV1].includes(device.deviceType) &&
    device.poweredEquipment
  ) {
    return {
      low: BATTERY_THRESHOLDS_POWERED_EQUIPMENT[device.poweredEquipment][BatteryStatus.LOW],
      critical:
        BATTERY_THRESHOLDS_POWERED_EQUIPMENT[device.poweredEquipment][BatteryStatus.CRITICAL],
      outOfBattery:
        BATTERY_THRESHOLDS_POWERED_EQUIPMENT[device.poweredEquipment][BatteryStatus.OUT_OF_BATTERY],
    };
  }

  if (device.deviceType == DeviceType.IntellitagV1) {
    //Same battery thresholds for all Intellitag v1, TAT100, TAT141, TAT140
    return {
      low: BATTERY_THRESHOLDS_TELTONIKA[DeviceModel.Tat100][BatteryStatus.LOW],
      critical: BATTERY_THRESHOLDS_TELTONIKA[DeviceModel.Tat100][BatteryStatus.CRITICAL],
      outOfBattery: BATTERY_THRESHOLDS_TELTONIKA[DeviceModel.Tat100][BatteryStatus.OUT_OF_BATTERY],
    };
  }

  return { low: -1, critical: -1, outOfBattery: -1 };
};

export const getMinValue = (v1: number, v2: number): number => {
  return v1 <= v2 ? v1 : v2;
};
