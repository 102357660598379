import { useQuery } from '@tanstack/react-query';
import { IPublicClientApplication } from '@azure/msal-browser';
import { buildQueryString, prepareHeaders } from '../../api/apiHelpers';
import { useState } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';
import { isBoundsWithinPreviousBounds } from '../../../services/helpers';
import { Mapbounds } from '../../../shared/types';
import { Geozone } from '@/models/Setups';

const baseUrl = import.meta.env.VITE_API_BASE_PATH;

const path = `/setup/geozone/intersecting`;
const ENLARGE_BOUNDS_BY = 0.15;

const useIntersectingGeozones = (instance: IPublicClientApplication, countryCode: string) => {
  const [bounds, setBounds] = useState<Mapbounds>({
    northEastLongitude: 0,
    northEastLatitude: 0,
    southWestLongitude: 0,
    southWestLatitude: 0,
  });
  const { data, isPending, isError, isSuccess } = useQuery<Geozone[]>({
    queryKey: ['setups', bounds],
    queryFn: async () => {
      const queryString = buildQueryString({ ...bounds, countryCode });
      const response = await fetch(`${baseUrl}${path}${queryString}`, {
        mode: 'cors',
        method: 'GET',
        headers: await prepareHeaders(instance),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });

  const handleBoundsChange = useDebouncedCallback((updatedBounds: Mapbounds) => {
    const isWithin = isBoundsWithinPreviousBounds(updatedBounds, bounds);
    if (!isWithin) {
      setBounds({
        northEastLatitude: updatedBounds.northEastLatitude + ENLARGE_BOUNDS_BY,
        northEastLongitude: updatedBounds.northEastLongitude + ENLARGE_BOUNDS_BY,
        southWestLatitude: updatedBounds.southWestLatitude - ENLARGE_BOUNDS_BY,
        southWestLongitude: updatedBounds.southWestLongitude - ENLARGE_BOUNDS_BY,
      });
    }
  }, 500);

  return {
    data,
    isPending,
    isError,
    isSuccess,
    handleBoundsChange,
  };
};

export default useIntersectingGeozones;
