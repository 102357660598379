import {
  Anchor,
  Breadcrumbs,
  Center,
  Group,
  Loader,
  Paper,
  Stack,
  Tabs,
  Text,
  Title,
  Image,
} from '@mantine/core';
import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconExternalLink, IconMap2, IconQrcode } from '@tabler/icons-react';

import { LabelValueText } from '@/shared/LabelValueText.tsx';
import DeviceLogsTable from '@/components/Device/DeviceLogsTable.tsx';
import useDevice from '@/data/hooks/Device/useDeviceHook.ts';
import useDeviceReplaceBattery from '@/data/hooks/Device/useDeviceReplaceBatteryHook.ts';
import { formatDateTimeString, generateQrCode } from '@/utils/utils.tsx';
import { useMobileDevice } from '@/hooks/useMobileDevice.tsx';
import { FlexSpacer } from '@/shared/FlexSpacer.tsx';
import { EquipmentTypeNames } from '@/models/enums/DeviceEnums.ts';
import { BatteryManagement } from '@/components/Device/BatteryManagement/BatteryManagement.tsx';
import {
  Device,
  DeviceModel,
  DeviceModelNames,
  DeviceType,
  DeviceTypeNames,
} from '@/models/Device.ts';
import { DeviceImageWrapper } from '@/components/Map/InfoCard/DeviceInfo/DeviceImageWrapper.tsx';
import { getPoweredEquipmentImage } from '@/utils/ImageHelpers.ts';
import { Button } from '@/components/Button/Button';

export const DeviceDetailsPage = () => {
  const { instance } = useMsal();
  const isMobile = useMobileDevice();
  const { deviceId } = useParams<{ deviceId: string }>();
  const [activeTab, setActiveTab] = useState<string | null>('overview');

  const navigate = useNavigate();
  const { mutateAsync: replaceDeviceBattery } = useDeviceReplaceBattery(instance);

  const { device, isLoading: deviceLoading } = useDevice({
    instance: instance,
    deviceId: deviceId!,
  });

  const crumbs = [
    { title: 'All projects', href: '/projects' },
    { title: 'Devices', href: '/devices' },
    { title: device?.currentName, href: `/projects/${device?.id}` },
  ].map((item, index) => (
    <Anchor size="h2" href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  const handleCreateQrCode = async () => {
    try {
      if (device) {
        generateQrCode(device);
      }
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  const getAttachmentLabel = (device: Device | null) => {
    if (device?.deviceType === DeviceType.ArrowBoardV1) return 'Arrow direction';
    return 'Attached to';
  };

  const getAttachmentContent = (device: Device | null) => {
    if (!device) return { value: '-', image: null };

    switch (device.deviceType) {
      case DeviceType.IntellitagV1:
        if (device.currentEquipmentType) {
          return {
            value: <DeviceImageWrapper baseDevice={device} />,
          };
        }
        return { value: '-', image: null };

      case DeviceType.ArrowBoardV1:
        if (device.arrowDirection) {
          return {
            value: <DeviceImageWrapper baseDevice={device} />,
          };
        }
        return { value: '-', image: null };

      case DeviceType.SmartSolarV1:
      case DeviceType.SmartCableV1:
        if (device.poweredEquipment) {
          return {
            value: (
              <Stack w="25px">
                <Image src={getPoweredEquipmentImage(device.poweredEquipment)} />
              </Stack>
            ),
          };
        }
        return { value: '-', image: null };

      default:
        return { value: '-', image: null };
    }
  };

  if (deviceLoading)
    return (
      <Center mt="xl">
        <Loader />
      </Center>
    );

  return (
    <>
      <Breadcrumbs pl="xl" bg="white" separator=">" separatorMargin="md" pt="md">
        {crumbs}
      </Breadcrumbs>
      <Stack bg="white" px="xl" pt="md" pb="xl">
        <Group align="center">
          <Title size="h3" order={2}>
            {device?.referenceId}
          </Title>
          <FlexSpacer />
          <Button
            variant="secondary"
            icon={IconQrcode}
            fullWidth={isMobile}
            onClick={handleCreateQrCode}
          >
            Generate QR Code
          </Button>
          {!!device?.position && (
            <Button
              variant="secondary"
              icon={IconMap2}
              fullWidth={isMobile}
              onClick={() => {
                navigate({
                  pathname: '/map',
                  search: `?device=${device.id}`,
                });
              }}
            >
              View on Map
            </Button>
          )}
        </Group>
        <Group miw="300px" mb="sm">
          <LabelValueText
            label="IMEI"
            value={device?.imei}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="Device Type"
            value={DeviceTypeNames[device?.deviceType ?? DeviceType.Unknown]}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="Model"
            value={DeviceModelNames[device ? device.deviceModel : DeviceModel.Unknown]}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
          <LabelValueText
            label="Latest update"
            value={formatDateTimeString(device?.latestUpdate)}
            fwLabel={500}
            textSize="sm"
            direction="column"
          />
        </Group>
      </Stack>
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List px="md" bg="white">
          <Tabs.Tab value="overview">
            <Text>Overview</Text>
          </Tabs.Tab>
          <Tabs.Tab value="report">
            <Text>History Log</Text>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="overview">
          <Stack p="md" bg="white" mt="md" mx="md">
            <Group justify="space-between">
              <Title order={4}>Current Project Allocation</Title>
              {device?.workZoneId && (
                <Button
                  variant="secondary"
                  icon={IconExternalLink}
                  onClick={() => {
                    navigate(`/projects/${device?.workZoneId}`);
                  }}
                >
                  Go to project
                </Button>
              )}
            </Group>
            {device?.workZoneId ? (
              <Group align="flex-start">
                <Stack miw="150px" h="50px">
                  <LabelValueText
                    label="Project name"
                    value={device?.workZoneName}
                    fwLabel={500}
                    textSize="sm"
                    direction="column"
                  />
                </Stack>
                <Stack miw="150px" h="50px">
                  <LabelValueText
                    label="Equipment type"
                    value={
                      device?.currentEquipmentType
                        ? EquipmentTypeNames[device.currentEquipmentType]
                        : ''
                    }
                    fwLabel={500}
                    textSize="sm"
                    direction="column"
                  />
                </Stack>
                {device?.deviceType !== DeviceType.PSAV1 && (
                  <Stack miw="150px" h="50px">
                    <LabelValueText
                      label={getAttachmentLabel(device)}
                      value={getAttachmentContent(device).value}
                      fwLabel={500}
                      textSize="sm"
                      direction="column"
                    />
                    {getAttachmentContent(device).image}
                  </Stack>
                )}
                <Stack miw="150px" h="50px">
                  <LabelValueText
                    label="Additional info"
                    value={device?.currentName}
                    fwLabel={500}
                    textSize="sm"
                    direction="column"
                  />
                </Stack>
              </Group>
            ) : (
              <Text>Device is not assigned to a project</Text>
            )}
          </Stack>
          <Paper bg="white" p="md" m="md">
            <BatteryManagement
              device={device}
              onLogBatteryChange={(id) => replaceDeviceBattery({ deviceId: id })}
            />
          </Paper>
        </Tabs.Panel>

        <Tabs.Panel mx="md" value="report">
          <Paper bg="white" p="md" my="md">
            <DeviceLogsTable deviceId={device?.id ?? ''} />
          </Paper>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
