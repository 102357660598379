import {
  AdvancedMarker,
  Pin,
  useAdvancedMarkerRef,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';
import LightMap from './LightMap';
import { AsyncInput } from '../../shared/AsyncInput';
import { useState } from 'react';
import { Overlay, Space, Stack, Text, Title, Box } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { ManualCenterPoint } from '../Project/CreateProjectModal/EditAndCreateProjectModal';
import { IconMap2 } from '@tabler/icons-react';

interface Props {
  mapOverlay?: boolean;
  onUpdate: (value: ManualCenterPoint | null) => void;
  height?: string;
}

export const AutoCompleteGoogleMap = ({ mapOverlay, onUpdate, height = '300px' }: Props) => {
  const [position, setPosition] = useState<{
    lat: number;
    lng: number;
  } | null>();
  const placeLib = useMapsLibrary('places');
  const geoLib = useMapsLibrary('geocoding');
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [override, setOverride] = useState<string>();

  const fetchAddresses = (query: string): Promise<{ label: string; id: string }[]> => {
    if (!placeLib) {
      return new Promise((reject) => reject([{ label: 'Google Maps Error', id: '0' }]));
    }

    const AutoCompleteService = new placeLib.AutocompleteService();
    return AutoCompleteService.getPlacePredictions({ input: query }).then((res) => {
      return res.predictions.map((obj) => {
        return { label: obj.description, id: obj.place_id };
      });
    });
  };

  const getLatLng = (data: { label: string; id: string } | null) => {
    if (!geoLib || data == null) {
      setPosition(null);
      onUpdate(null);
      return;
    }

    const GeoCoder = new geoLib.Geocoder();
    GeoCoder.geocode({ placeId: data.id }).then((res) => {
      const lat = res.results[0].geometry.location.lat();
      const lng = res.results[0].geometry.location.lng();
      setPosition({
        lat: lat,
        lng: lng,
      });
      onUpdate({ address: data.label, position: { lat: lat, lng: lng } });
    });
  };

  interface DragEvent {
    latLng: { lat: () => number; lng: () => number };
  }

  marker?.addListener('dragend', (event: DragEvent) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    if (!geoLib) {
      return;
    }
    const Geo = new geoLib.Geocoder();
    Geo.geocode({ location: { lat: lat, lng: lng } }).then((res) => {
      setAddressFromPin(res.results[0].formatted_address, lat, lng);
    });
  });

  const setAddressFromPin = useDebouncedCallback((address: string, lat: number, lng: number) => {
    const position = { lat: lat, lng: lng };
    setPosition(position);
    onUpdate({ address: address, position: position });
    setOverride(address);
  }, 500);

  const pin = (
    <AdvancedMarker
      draggable
      ref={markerRef}
      position={
        position
          ? {
              lat: position.lat,
              lng: position.lng,
            }
          : null
      }
    >
      <Pin background={'#173232'} borderColor={'#173232'} glyphColor={'#fff'} />
    </AdvancedMarker>
  );

  return (
    <Box pos="relative">
      <AsyncInput
        onChange={(obj) => getLatLng(obj)}
        fetchData={fetchAddresses}
        placeholder="Search address"
        label="Address"
        valueOveride={override}
      />
      <Space h={8} />
      {mapOverlay && (
        <>
          <Overlay color="#fff" backgroundOpacity={0.35} blur={2} zIndex={100} />
          <Box
            pos="absolute"
            top="60%"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)', zIndex: 300 }}
            w="100%"
          >
            <Stack align="center" gap="xs">
              <IconMap2 size={38} />
              <Title order={4}>Automatic location</Title>
              <Text size="sm" c="dimmed" fw={300}>
                Location will be calculated based on all devices
              </Text>
            </Stack>
          </Box>
        </>
      )}
      <LightMap width="100%" height={height} defaultZoom={6} markers={[pin]} />
    </Box>
  );
};
