import { getFeatureFlag } from '@/hooks/useFeatureFlag';
import { DeviceModel } from '@/models/Device';
import { PsaSetupType } from '@/models/enums/ProjectEnums';
import { ScenarioType } from '@/models/enums/ScenarioEnums';
import { z } from 'zod';

export enum AllProjectCreationFlows {
  default = '0',
  psa = '1',
}

export const isProjectCreationFlowAvailable = (flow: AllProjectCreationFlows) => {
  if (flow === AllProjectCreationFlows.default) return true;
  if (flow === AllProjectCreationFlows.psa) return getFeatureFlag('project:create:flow:psa');

  return false;
};

export const IS_GEOZONE_SETUP_AVAILABLE = getFeatureFlag('project:create:flow:psa:geozone');

const employeeSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Invalid email address').nullish(),
  phone: z.string().min(1, 'Phone number is required').nullish(),
  external: z.boolean(),
  externalUserId: z.string().optional(),
});

export type EmployeeSchema = z.infer<typeof employeeSchema>;

const notificationContactSchema = employeeSchema.merge(
  z.object({
    timeZoneId: z.string(),
    timeFrames: z.array(
      z.object({
        start: z.string(),
        end: z.string(),
        daysOfWeek: z.array(z.number().int().min(0).max(6)),
      })
    ),
    emailEnabled: z.boolean(),
    smsEnabled: z.boolean(),
    batteryAlarmNotificationEnabled: z.boolean(),
    // ? these shouldn't really be required...
    WorkZoneId: z.string(),
    id: z.string(),
  })
);

export const assignedPsaSchema = z.object({
  id: z.string(),
  referenceId: z.string(),
  deviceModel: z.nativeEnum(DeviceModel), // todo - enforce psa models?
});

// Having each "step" with it's own validation object means we can validate an entire step at once, and not validate future steps too soon
export const createProjectFlowFormSchema = z.object({
  informationStep: z
    .object({
      name: z
        .string()
        .min(1, 'Project name is required')
        .max(100, 'Project name must not exceed 100 characters'),
      isDemoProject: z.boolean(),
      countryCode: z.string().min(1, 'Country is required'),
      depotId: z.string().min(1, 'Depot is required'),
      customerName: z.string().min(1, 'Customer is required'),
      startDate: z.string({ required_error: 'Start date is required' }).datetime(),
      endDate: z.string().datetime().nullish(),
    })
    .superRefine((data, ctx) => {
      if (data.endDate) {
        if (data.endDate < data.startDate) {
          ctx.addIssue({
            message: 'End date cannot be before start date',
            code: 'custom',
            path: ['endDate'],
          });
        }
      }
    }),
  notificationStep: z.object({
    alarmContacts: z.array(notificationContactSchema),
  }),

  psaSetupStep: z
    .object({
      scenario: z.nativeEnum(ScenarioType, {
        invalid_type_error: 'Please select a scenario',
        required_error: 'Please select a scenario',
      }), // TODO: prevent "not allowed" scenarios here?

      // TODO: enforce that geozone is set IF setupType = 'geozone', disallow manual setup if geozone is set
      setupType: z.nativeEnum(PsaSetupType, {
        invalid_type_error: 'Please select a setup type',
        required_error: 'Please select a setup type',
      }),

      assignedPsas: z.array(assignedPsaSchema),

      geozonePath: z
        .array(
          z.object({
            lat: z.number(),
            lng: z.number(),
          })
        )
        .nullable(),
    })
    .superRefine((data, ctx) => {
      if (data.setupType === PsaSetupType.Geozone && !data.geozonePath) {
        ctx.addIssue({
          message: 'Geozone must contain at least 3 points',
          code: 'custom',
          path: ['geozonePath'],
        });
      }
    })
    .nullable(),
});

export type CreateProjectFlowFormValues = z.infer<typeof createProjectFlowFormSchema>;
