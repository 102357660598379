import { UserOptions, Styles, CellDef } from 'jspdf-autotable';
import {
  PDF_CONSTANTS,
  PDF_TABLE_CONSTANTS,
  PDF_TABLE_STYLES,
  PdfAssets,
} from '@/constants/pdfConstants';
import { HeaderCell } from '@/models/PdfExport';
import { addHeader } from '../workers/pdf/utils/pdfCommon';

type OverflowType = 'linebreak' | 'ellipsize' | 'visible' | 'hidden';

interface ColumnStyle extends Partial<Styles> {
  cellWidth?: number;
  minCellWidth?: number;
  maxCellWidth?: number;
  overflow?: OverflowType;
}

const generateColumnStyles = (
  daysInWeek: number,
  columnsPerDay: number
): Record<string, Partial<Styles>> => {
  const columnStyles: Record<string, ColumnStyle> = {
    0: {
      cellWidth: PDF_TABLE_CONSTANTS.TEXT_FIELDS_CELL_WIDTH + 7,
      overflow: 'ellipsize',
      halign: 'left',
      lineWidth: { right: 0, left: 0.5 },
      cellPadding: PDF_TABLE_CONSTANTS.CELL_PADDING,
    },
    1: {
      cellWidth: PDF_TABLE_CONSTANTS.TEXT_FIELDS_CELL_WIDTH + 9.5,
      overflow: 'ellipsize',
      halign: 'center',
      lineWidth: { right: 0 },
      cellPadding: PDF_TABLE_CONSTANTS.CELL_PADDING,
    },
    2: {
      cellWidth: PDF_TABLE_CONSTANTS.TEXT_FIELDS_CELL_WIDTH + 1.5,
      halign: 'center',
      lineWidth: { right: 0.5 },
      cellPadding: PDF_TABLE_CONSTANTS.CELL_PADDING,
    },
  };

  // Add timestamp column styles
  for (let i = 0; i < columnsPerDay * daysInWeek; i++) {
    columnStyles[i + PDF_TABLE_CONSTANTS.FIXED_COLUMNS] = {
      cellWidth: PDF_TABLE_CONSTANTS.DYNAMIC_TIMESTAMP_WIDTH,
      halign: 'center',
      lineWidth: {
        right: (i + 1) % columnsPerDay === 0 ? 0.5 : 0,
        bottom: 0,
      },
      cellPadding: PDF_TABLE_CONSTANTS.CELL_PADDING,
      minCellWidth: PDF_TABLE_CONSTANTS.DYNAMIC_TIMESTAMP_WIDTH,
      maxCellWidth: PDF_TABLE_CONSTANTS.DYNAMIC_TIMESTAMP_WIDTH,
    };
  }

  return columnStyles;
};

export const generateTableConfig = (
  head: HeaderCell[][],
  body: CellDef[][],
  margins: { left: number; right: number; top: number },
  daysInWeek: number,
  columnsPerDay: number,
  headerInfo?: {
    title: string;
    projectName: string;
    startDate: string;
    endDate: string;
    assets?: PdfAssets;
  }
): UserOptions => {
  const config: UserOptions = {
    head,
    body,
    margin: margins,
    headStyles: {
      ...PDF_TABLE_STYLES.HEAD,
      fillColor: PDF_CONSTANTS.COLORS.GREEN_PRIMARY,
      lineColor: [255, 255, 255],
    },
    bodyStyles: {
      ...PDF_TABLE_STYLES.BODY,
      lineWidth: 0.5,
    },
    alternateRowStyles: {
      ...PDF_TABLE_STYLES.ALTERNATE_ROW,
      fillColor: [245, 245, 245],
    },
    theme: 'grid',
    columnStyles: generateColumnStyles(daysInWeek, columnsPerDay),
  };

  config.willDrawCell = (data) => {
    if (data.cell.section === 'body' && data.row.index === data.table.body.length - 1) {
      data.cell.styles.lineWidth = {
        bottom: 0.5,
        top: 0,
        left: data.column.index === 0 ? 0.5 : 0,
        right:
          daysInWeek > 0 &&
          (data.column.index - PDF_TABLE_CONSTANTS.FIXED_COLUMNS + 1) % columnsPerDay === 0
            ? 0.5
            : 0,
      };
    }
  };

  if (headerInfo) {
    const originalDidDrawPage = config.didDrawPage;
    config.didDrawPage = (data) => {
      if (originalDidDrawPage) {
        originalDidDrawPage(data);
      }

      addHeader(data.doc, {
        projectName: headerInfo.projectName,
        title: headerInfo.title,
        startDate: headerInfo.startDate,
        endDate: headerInfo.endDate,
        sideMargin: PDF_CONSTANTS.SIDE_MARGIN,
      });
    };
  }

  return config;
};

export const splitIntoWeeks = (
  data: Array<Array<string | number | null>>,
  columnsPerDay: number
): Array<Array<Array<string | number | null>>> => {
  const weeks: Array<Array<Array<string | number | null>>> = [];
  const columnsPerWeek = columnsPerDay * 7;
  const fixedColumns = data.map((row) => row.slice(0, PDF_TABLE_CONSTANTS.FIXED_COLUMNS));

  for (let i = PDF_TABLE_CONSTANTS.FIXED_COLUMNS; i < data[0].length; i += columnsPerWeek) {
    const weekData = data.map((row) => {
      const weekPortion = row.slice(i, i + columnsPerWeek);
      return [...fixedColumns[data.indexOf(row)], ...weekPortion];
    });
    weeks.push(weekData);
  }

  return weeks;
};

interface ColumnsPerPageOptions {
  daysPerPage?: number;
  timestampsPerDay: number;
}

export const columnsPerPage = (
  timeStampHeaders: HeaderCell[],
  timeHeaders: HeaderCell[],
  options: ColumnsPerPageOptions
): Array<HeaderCell[][]> => {
  const pages: Array<HeaderCell[][]> = [];
  const fixedHeaders = [
    { content: 'Reference ID', rowSpan: 2 },
    { content: 'Additional info', rowSpan: 2 },
    { content: 'Attachment', rowSpan: 2 },
  ];

  // Default to all days if daysPerPage is not specified
  const daysPerPage = options.daysPerPage || timeStampHeaders.length;
  const { timestampsPerDay } = options;

  // Iterate through days
  for (let i = 0; i < timeStampHeaders.length; i += daysPerPage) {
    const pageTimestampHeaders = timeStampHeaders.slice(i, i + daysPerPage);

    // Calculate time headers based on timestamps per day
    const startTimeIndex = i * timestampsPerDay;
    const pageTimeHeaders = timeHeaders.slice(
      startTimeIndex,
      startTimeIndex + daysPerPage * timestampsPerDay
    );

    pages.push([[...fixedHeaders, ...pageTimestampHeaders], [...pageTimeHeaders]]);
  }

  return pages;
};
