import { ControlPosition, useMap } from '@vis.gl/react-google-maps';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import type { PropsWithChildren } from 'react';

type FixedMapControlProps = PropsWithChildren<{
  position: ControlPosition;
}>;

/**
 * Fixed version of `MapControl`, which prevents the div containing the controls from blocking the UI underneath it.
 * All interactive elements inside this must be given `pointer-events: auto` to be clickable.
 * @param param0
 * @returns
 */
export const FixedMapControl: FunctionComponent<FixedMapControlProps> = ({
  children,
  position,
}) => {
  const controlContainer = useMemo(() => {
    const d = document.createElement('div');
    d.style.pointerEvents = 'none';
    return d;
  }, []);
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const controls = map.controls[position];

    controls.push(controlContainer);

    return () => {
      const controlsArray = controls.getArray();
      // controlsArray could be undefined if the map is in an undefined state (e.g. invalid API-key, see #276
      if (!controlsArray) return;

      const index = controlsArray.indexOf(controlContainer);
      controls.removeAt(index);
    };
  }, [controlContainer, map, position]);

  return createPortal(children, controlContainer);
};
