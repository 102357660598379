import { EquipmentType, EquipmentTypeNames } from '../../../../../models/enums/DeviceEnums';
import { getAllSigns } from '../../../../../utils/signs';
import {
  Box,
  Center,
  Input,
  InputWrapper,
  SegmentedControl,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { getDeviceInfoDescription, getDeviceInfoLabel } from '../../../../../copy';
import { SignRecognition } from '../../../../../shared/SignRecognition';
import { Device, DeviceType, DeviceTypeNames } from '../../../../../models/Device';
import { useEffect, useState } from 'react';
import { IconBarrierBlock } from '@tabler/icons-react';

interface Props {
  device: Device;
  country: string;
  onChange: (data: IntellitagSubmitData) => void;
}

export interface IntellitagSubmitData {
  equipmentType: EquipmentType;
  attachmentRef: string | null;
  currentName: string | null;
}

export const IntellitagInput = ({ device, country, onChange }: Props) => {
  const signs = Object.keys(getAllSigns(country ?? ''));
  const [selectedEquipment, setSelectedEquipment] = useState(
    device.deviceType === DeviceType.IntelliTagLite ? EquipmentType.Barrier : EquipmentType.Sign
  );
  const [attachment, setAttachment] = useState<string | null>(null);
  const [currentName, setCurrentName] = useState<string | null>(null);

  useEffect(() => {
    const handleChange = () => {
      const ref = attachment && getAllSigns(country ?? '')[attachment];
      onChange({
        equipmentType: selectedEquipment,
        attachmentRef: ref ?? null,
        currentName: currentName,
      });
    };
    handleChange();
  }, [selectedEquipment, attachment, currentName, country, onChange]);

  return (
    <Stack>
      <Box w={'100%'}>
        <Text size="sm" fw={600}>
          Device Type
        </Text>
        <Text fw={300}>{DeviceTypeNames[device.deviceType]}</Text>
        <Text size="xs" c={'gray.6'}>
          {device.referenceId}
        </Text>
      </Box>
      {device.deviceType !== DeviceType.IntelliTagLite && (
        <SegmentedControl
          defaultValue={EquipmentTypeNames[selectedEquipment]}
          data={[EquipmentTypeNames[EquipmentType.Sign], EquipmentTypeNames[EquipmentType.Barrier]]}
          onChange={(value) => {
            if (value == EquipmentTypeNames[EquipmentType.Sign]) {
              setSelectedEquipment(EquipmentType.Sign);
            } else {
              setAttachment(null);
              setSelectedEquipment(EquipmentType.Barrier);
            }
          }}
          fullWidth
        />
      )}
      {selectedEquipment == EquipmentType.Sign ? (
        <Stack>
          <Select
            label="Select Sign"
            ta={'left'}
            data={signs}
            value={attachment}
            onChange={(value) => {
              setAttachment(value);
            }}
            searchable
            clearable
            placeholder="Search for sign by name"
            nothingFoundMessage="No sign with that name found..."
          />
          <SignRecognition
            selectedSign={attachment}
            setSelectedSign={(id) => {
              setAttachment(id);
            }}
            countryCode={country ?? ''}
          />
        </Stack>
      ) : (
        <Center>
          <IconBarrierBlock color="gray" size={72} />
        </Center>
      )}
      <InputWrapper
        w={'100%'}
        label={getDeviceInfoLabel(country ?? '')}
        description={getDeviceInfoDescription(country ?? '')}
      >
        <Input onChange={(event) => setCurrentName(event.currentTarget.value)} />
      </InputWrapper>
    </Stack>
  );
};
