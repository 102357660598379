import { Group, Text, Drawer, Table, ScrollArea, Box } from '@mantine/core';
import { ReactNode } from 'react';
import { EquipmentType } from '../../../../../models/enums/DeviceEnums';
import { STATUS_ICONS } from '../../constants/statusIcons';
import { AlarmLog, formatDrawerTime, groupAlarmsByPeriod } from '../../utils/alarmDrawerUtils';

export interface DeviceData {
  referenceId: string;
  name: string;
  deviceId: string;
  attachment?: ReactNode;
  currentEquipmentType?: EquipmentType;
  attachmentRef?: string;
}

export interface AlarmEventsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  deviceData: DeviceData | null;
  allLogs: AlarmLog[];
  reportStartDate: Date | string;
  reportEndDate: Date | string;
  countryCode: string;
  renderAttachment?: (deviceData: DeviceData) => ReactNode;
}

export const AlarmEventsDrawer = ({
  isOpen,
  onClose,
  deviceData,
  allLogs,
  reportStartDate,
  reportEndDate,
  countryCode,
  renderAttachment,
}: AlarmEventsDrawerProps) => {
  if (!deviceData) return null;

  // Get periods of alarms (each period represents a transition from OK to Alarming and back to OK)
  const sortedPeriods = groupAlarmsByPeriod(allLogs);

  // Format report date range for display
  const formattedStartDate = formatDrawerTime(
    typeof reportStartDate === 'string' ? reportStartDate : reportStartDate.toISOString(),
    countryCode
  );
  const formattedEndDate = formatDrawerTime(
    typeof reportEndDate === 'string' ? reportEndDate : reportEndDate.toISOString(),
    countryCode
  );

  // Format display time for alarm logs
  const formatAlarmTime = (log: AlarmLog | null) => {
    if (!log) return 'Not addressed';
    return formatDrawerTime(log.timeStamp, countryCode);
  };

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      title={`Alarm Events - ${deviceData.name}`}
      position="right"
      size="xl"
      padding="md"
    >
      <ScrollArea h={600}>
        <Text mb="md">
          Report Period: {formattedStartDate} - {formattedEndDate}
        </Text>
        <Table striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th bg="primaryGreen.6" c="white" ta="center">
                Reference ID
              </Table.Th>
              <Table.Th bg="primaryGreen.6" c="white" ta="center">
                Additional Info
              </Table.Th>
              <Table.Th bg="primaryGreen.6" c="white" ta="center">
                Attachment
              </Table.Th>
              <Table.Th bg="primaryGreen.6" c="white" ta="center">
                Alarm Reported
              </Table.Th>
              <Table.Th bg="primaryGreen.6" c="white" ta="center">
                Alarm Addressed
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {sortedPeriods.map((period, idx) => (
              <Table.Tr key={idx}>
                <Table.Td ta="center" valign="middle">
                  {deviceData.referenceId}
                </Table.Td>
                <Table.Td ta="center" valign="middle">
                  {deviceData.name}
                </Table.Td>
                <Table.Td ta="center" valign="middle">
                  <Group gap="xs" align="center" justify="center" w="100%">
                    {renderAttachment ? renderAttachment(deviceData) : deviceData.attachment}
                  </Group>
                </Table.Td>
                <Table.Td ta="center" valign="middle">
                  <Group gap="xs" align="center" justify="flex-start" w="100%" ml={25}>
                    <Box w={20} style={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}>
                      <img src={STATUS_ICONS.ALERT} alt="Alert" width="20px" />
                    </Box>
                    <Text size="sm" c="dangerRed">
                      {formatAlarmTime(period.firstReported)}
                    </Text>
                  </Group>
                </Table.Td>
                <Table.Td ta="center" valign="middle">
                  <Group gap="xs" align="center" justify="flex-start" w="100%" pl={10}>
                    {period.lastAddressed ? (
                      <>
                        <Box
                          w={20}
                          style={{ flexShrink: 0, display: 'flex', alignItems: 'center' }}
                        >
                          <img src={STATUS_ICONS.CHECK} alt="OK" width="20px" />
                        </Box>
                        <Text size="sm" c="successGreen">
                          {formatAlarmTime(period.lastAddressed)}
                        </Text>
                      </>
                    ) : (
                      <Box ml={30}>
                        <Text size="sm" c="grey">
                          Not addressed
                        </Text>
                      </Box>
                    )}
                  </Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Drawer>
  );
};
