import { Image } from '@mantine/core';
import { ArrowDirection } from '@/models/Device';
import { ARROW_DIRECTION_IMAGE_MAP } from '@/constants/imageConstants';

interface ArrowIconProps {
  arrowDirection: ArrowDirection;
  size?: number;
}

export const ArrowIcon = ({ arrowDirection, size }: ArrowIconProps) => {
  const iconSrc =
    ARROW_DIRECTION_IMAGE_MAP[arrowDirection] || ARROW_DIRECTION_IMAGE_MAP[ArrowDirection.Off];

  return <Image src={iconSrc} w={size} />;
};
