export enum StatisticSection {
  AllSigns = 'All signs',
  Uptime = 'Uptime',
  SignsUsed = 'Signs used',
  AccAlarmsPerDay = 'Accumulated alarms per day',
  FallenOverEvents = 'Fallen over events',
  AdressedEvents = 'Adressed events',
  MedianAdressedTime = 'Median adressed time',
  AccAlarmsPerSign = 'Accumulated alarms per sign',
}
