import { AlarmStatus } from '@/models/enums/DeviceEnums';

import { BatteryDeviceStatus } from '@/models/SupervisionReport';

import { STATUS_ICONS } from '../constants/statusIcons';

export function getStatusIcon(status: BatteryDeviceStatus | AlarmStatus | number | string): string {
  switch (status) {
    case BatteryDeviceStatus.OK:
    case AlarmStatus.OK:
      return STATUS_ICONS.CHECK;
    case BatteryDeviceStatus.POWERLESS:
    case AlarmStatus.Alarming:
      return STATUS_ICONS.ALERT;
    case BatteryDeviceStatus.NO_LOGS:
    case 0:
    default:
      return STATUS_ICONS.DOTTED;
  }
}
