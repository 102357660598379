import TrafficLightIcon from '@/assets/PoweredEquipment/traffic-light.png';
import BufferIcon from '@/assets/PoweredEquipment/buffer.png';
import SequentialLightIcon from '@/assets/PoweredEquipment/sequential-lights.png';
import WarningLightIcon from '@/assets/PoweredEquipment/warning-light.png';

export enum BatteryStatus {
  OK = 1,
  LOW = 2,
  CRITICAL = 3,
  OUT_OF_BATTERY = 4,
  DISCONNECTED = 5,
}

export interface BatteryThresholds {
  low: number;
  critical: number;
  outOfBattery: number;
}

export const BatteryStatusNames = {
  [BatteryStatus.OUT_OF_BATTERY]: 'Out of battery',
  [BatteryStatus.CRITICAL]: 'Critical',
  [BatteryStatus.LOW]: 'Low',
  [BatteryStatus.OK]: 'Ok',
  [BatteryStatus.DISCONNECTED]: 'Disconnected',
};

export enum AlarmStatus {
  OK = 1,
  Alarming = 2,
  Off = 3,
}

export enum AlarmType {
  // ! UNUSED
  // SignFallenOver = 1,
  // LowBattery = 2,
  // BarrierCollision = 3,
  // OutOfBattery = 4,
  // Disconnected = 5,
  // ! UNUSED
  PsaRedAlarm = 6,
  PsaBlueAlert = 7,
}

export enum LifeCycleStatus {
  Unassigned = 1,
  Assigned = 2,
  Deprovisioned = 3,
}

export const AlarmStatusNames: Record<AlarmStatus, string> = {
  [AlarmStatus.OK]: 'OK',
  [AlarmStatus.Alarming]: 'Alarming',
  [AlarmStatus.Off]: 'Off',
};

export const AlarmTypeNames: Record<AlarmType, string> = {
  [AlarmType.PsaRedAlarm]: 'Alarming',
  [AlarmType.PsaBlueAlert]: 'Alarming',
};

export enum EquipmentType {
  Sign = 1,
  Barrier = 2,
}

export const EquipmentTypeNames = {
  [EquipmentType.Sign]: 'Sign',
  [EquipmentType.Barrier]: 'Barrier',
};

export enum PoweredEquipment {
  Unknown = 0,
  TrafficLight = 1,
  Buffer = 2,
  SequentialLight = 3,
  WarningLight = 4,
  Other = 999,
}

export const PoweredEquipmentNames = {
  [PoweredEquipment.Unknown]: 'Unknown',
  [PoweredEquipment.TrafficLight]: 'Traffic Light',
  [PoweredEquipment.Buffer]: 'Buffer',
  [PoweredEquipment.SequentialLight]: 'Sequential Lights',
  [PoweredEquipment.WarningLight]: 'Warning Light',
  [PoweredEquipment.Other]: 'Other',
};

export const PoweredEquipmentIcons = {
  [PoweredEquipment.TrafficLight]: TrafficLightIcon,
  [PoweredEquipment.Buffer]: BufferIcon,
  [PoweredEquipment.SequentialLight]: SequentialLightIcon,
  [PoweredEquipment.WarningLight]: WarningLightIcon,
  [PoweredEquipment.Other]: WarningLightIcon,
} as const;

export enum PowerSource {
  SmartCable = 'smart cable',
  SmartSolar = 'smart solar',
}
