import { useEffect, useState } from 'react';
import { getZoomLevel } from '@/utils/markers/markers';

export type ZoomLevel = 'global' | 'mid' | 'close' | 'always';

interface Props {
  map: google.maps.Map | null;
  zoomLevel: ZoomLevel | ZoomLevel[];
}

export const useMapZoomLevel = ({ map, zoomLevel }: Props) => {
  const [isAtZoomLevel, setIsAtZoomLevel] = useState(() => {
    if (zoomLevel === 'always') return true;

    if (map) {
      const currentZoom = map.getZoom();
      return currentZoom
        ? (Array.isArray(zoomLevel) && zoomLevel.includes(getZoomLevel(currentZoom))) ||
            getZoomLevel(currentZoom) === zoomLevel
        : false;
    }
    return false;
  });

  useEffect(() => {
    if (zoomLevel === 'always') return;

    if (map) {
      const listener = map.addListener('zoom_changed', () => {
        const currentZoom = map.getZoom();
        if (!currentZoom) return;

        if (
          (Array.isArray(zoomLevel) && zoomLevel.includes(getZoomLevel(currentZoom))) ||
          getZoomLevel(currentZoom) === zoomLevel
        ) {
          setIsAtZoomLevel(true);
        } else {
          setIsAtZoomLevel(false);
        }
      });

      return () => {
        listener.remove();
      };
    }
  }, [zoomLevel, map]);

  return { isAtZoomLevel };
};
