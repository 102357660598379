import { DeviceEventType } from '../../../models/DeviceEvent';
import {
  IconAlertCircle,
  IconCheck,
  IconBatteryOff,
  IconPower,
  IconRouter,
  IconRouterOff,
  IconRefresh,
  IconDirectionSign,
  IconCircle,
  IconPlugConnectedX,
  IconBatteryCharging,
} from '@tabler/icons-react';

export interface EventTypeConfig {
  icon: JSX.Element;
  displayName: string;
}

export const eventConfig: Record<DeviceEventType, EventTypeConfig> = {
  [DeviceEventType.AssignedToProject]: {
    icon: <IconRouter style={{ transform: 'rotate(-90deg)' }} size={20} stroke={1.5} />,
    displayName: 'Added to project',
  },
  [DeviceEventType.UnassignedFromProject]: {
    icon: <IconRouterOff style={{ transform: 'rotate(-90deg)' }} size={20} stroke={1.5} />,
    displayName: 'Removed from project',
  },
  [DeviceEventType.AssignedToSetup]: {
    icon: <IconRouter style={{ transform: 'rotate(-90deg)' }} size={20} stroke={1.5} />,
    displayName: 'Added to setup',
  },
  [DeviceEventType.UnassignedFromSetup]: {
    icon: <IconRouterOff style={{ transform: 'rotate(-90deg)' }} size={20} stroke={1.5} />,
    displayName: 'Removed from setup',
  },
  [DeviceEventType.PowerUp]: {
    icon: <IconPower size={20} stroke={1.5} />,
    displayName: 'Power up',
  },
  [DeviceEventType.PowerDown]: {
    icon: <IconPower size={20} stroke={1.5} />,
    displayName: 'Power down',
  },
  [DeviceEventType.WarningReceived]: {
    icon: <IconAlertCircle size={20} stroke={1.5} />,
    displayName: 'Warning received',
  },
  [DeviceEventType.WarningTriggered]: {
    icon: <IconAlertCircle size={20} stroke={1.5} />,
    displayName: 'Warning triggered',
  },
  [DeviceEventType.IncursionReceived]: {
    icon: <IconAlertCircle size={20} stroke={1.5} />,
    displayName: 'Incursion received',
  },
  [DeviceEventType.IncursionTriggered]: {
    icon: <IconAlertCircle size={20} stroke={1.5} />,
    displayName: 'Incursion triggered',
  },
  [DeviceEventType.ResetReceived]: {
    icon: <IconCheck size={20} stroke={1.5} />,
    displayName: 'Reset received',
  },
  [DeviceEventType.ResetTriggered]: {
    icon: <IconCheck size={20} stroke={1.5} />,
    displayName: 'Reset triggered',
  },
  [DeviceEventType.ModemReset]: {
    icon: <IconCheck size={20} stroke={1.5} />,
    displayName: 'Modem reset',
  },
  [DeviceEventType.Disconnected]: {
    icon: <IconPlugConnectedX size={20} stroke={1.5} />,
    displayName: 'Disconnected',
  },
  [DeviceEventType.SignFallenOver]: {
    icon: <IconAlertCircle size={20} stroke={1.5} />,
    displayName: 'Sign fallen over',
  },
  [DeviceEventType.BarrierCollision]: {
    icon: <IconAlertCircle size={20} stroke={1.5} />,
    displayName: 'Barrier collision',
  },
  [DeviceEventType.OutOfBattery]: {
    icon: <IconBatteryOff size={20} stroke={1.5} />,
    displayName: 'Out of battery',
  },
  [DeviceEventType.SensorDataUpdated]: {
    icon: <IconRefresh size={20} stroke={1.5} />,
    displayName: 'Update sent',
  },
  [DeviceEventType.ArrowChanged]: {
    icon: <IconDirectionSign size={20} stroke={1.5} />,
    displayName: 'Arrow changed',
  },
  [DeviceEventType.BatteryReplacedEvent]: {
    icon: <IconBatteryCharging size={20} stroke={1.5} />,
    displayName: 'Battery change logged',
  },
  [DeviceEventType.Unknown]: {
    icon: <IconCircle size={20} stroke={1.5} color="var(--mantine-color-greyText-1)" />,
    displayName: 'Unknown event',
  },
};

// Group event types by category for better organization
export const eventTypeGroups = [
  {
    title: 'Project events',
    types: [DeviceEventType.AssignedToProject, DeviceEventType.UnassignedFromProject],
  },
  {
    title: 'Setup events',
    types: [DeviceEventType.AssignedToSetup, DeviceEventType.UnassignedFromSetup],
  },
  {
    title: 'Power events',
    types: [DeviceEventType.PowerUp, DeviceEventType.PowerDown],
  },
  {
    title: 'Warning events',
    types: [
      DeviceEventType.WarningReceived,
      DeviceEventType.WarningTriggered,
      DeviceEventType.IncursionReceived,
      DeviceEventType.IncursionTriggered,
    ],
  },
  {
    title: 'Status events',
    types: [
      DeviceEventType.SignFallenOver,
      DeviceEventType.BarrierCollision,
      DeviceEventType.OutOfBattery,
      DeviceEventType.SensorDataUpdated,
      DeviceEventType.ArrowChanged,
    ],
  },
  {
    title: 'Battery events',
    types: [DeviceEventType.BatteryReplacedEvent],
  },
];
