import { Button, Flex, Group, Loader, Stack, Text } from '@mantine/core';
import OngoingInstallationDeviceList from '../components/OngoingInstallationDeviceList';
import { useContext, useState } from 'react';
import { PWAContext } from '../providers/PWAProvider';
import useProjectDevices from '../../data/hooks/Device/useProjectDevicesHook';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import PwaPageHeading from '../components/PwaPageHeading';
import { AlarmStatus, BatteryStatus } from '../../models/enums/DeviceEnums';
import { Device } from '../../models/Device';
import { IconTrashX } from '@tabler/icons-react';
import useUnassignDevicesFromProject from '../../data/hooks/Device/useUnassignDevicesFromProjectHook';
import HalfPanel from '../components/HalfPanel';

interface Props {
  filter: 'All Devices' | 'Alarms' | 'Battery';
}

export const PwaDeviceListPage = ({ filter }: Props) => {
  const { instance } = useMsal();
  const [showMassUnassignConfirm, setShowMassUnassignConfirm] = useState(false);
  const { project, setMessage } = useContext(PWAContext);
  const { projectId } = useParams<{ projectId: string }>();
  const { projectDevices, isLoading } = useProjectDevices({
    instance,
    projectIds: [projectId ? projectId : ''],
  });

  let deviceList: Device[] = [];
  if (project && projectDevices[project.id]) deviceList = projectDevices[project.id];
  if (filter === 'Alarms')
    deviceList = deviceList.filter((device) => device.alarmStatus === AlarmStatus.Alarming);
  else if (filter === 'Battery')
    deviceList = deviceList.filter(
      (device) =>
        device.batteryStatus === BatteryStatus.OUT_OF_BATTERY ||
        device.batteryStatus === BatteryStatus.CRITICAL ||
        device.batteryStatus === BatteryStatus.LOW
    );

  const { mutateAsync: unassignAllDevices } = useUnassignDevicesFromProject(instance);

  const handleMassUnassign = async () => {
    if (!project) {
      setMessage({
        title: 'Failed to remove devices',
        text: `Something went wrong when removing all devices from the project`,
        type: 'error',
      });
      return;
    }
    setShowMassUnassignConfirm(false);
    unassignAllDevices({ workZoneId: project.id })
      .then(() => {
        setMessage({
          title: 'Successfully removed all devices',
          text: 'All devices are now removed from this project',
          type: 'success',
        });
      })
      .catch(() =>
        setMessage({
          title: 'Failed to remove devices',
          text: `Something went wrong when removing all devices from the project ${project?.name}`,
          type: 'error',
        })
      );
  };

  return (
    <>
      <Stack flex={1} style={{ overflow: 'auto' }} mb="lg" gap="sm">
        <PwaPageHeading
          rightSection={
            <>
              {deviceList.length === 0 ? (
                <Text size="sm" c="greyText">
                  No devices
                </Text>
              ) : (
                <Group gap="xs">
                  <Text fw={600}>{`${deviceList.length}`}</Text>
                  <Text size="sm" c="greyText">
                    devices (0 new)
                  </Text>
                </Group>
              )}{' '}
            </>
          }
        >
          {filter}
        </PwaPageHeading>

        <Button
          onClick={() => setShowMassUnassignConfirm(true)}
          size="md"
          variant="default"
          leftSection={<IconTrashX />}
          disabled={!project || deviceList.length === 0}
        >
          Remove all devices
        </Button>

        {isLoading ? (
          <Flex flex={1} align="center" justify="center">
            <Loader />
          </Flex>
        ) : (
          <Stack style={{ overflow: 'auto' }} h="100%">
            <OngoingInstallationDeviceList
              devices={deviceList}
              isSnoozed={project?.isSnoozed}
              countryCode={project?.countryCode ?? ''}
            />
          </Stack>
        )}
      </Stack>

      <HalfPanel
        confirmColor="red"
        label="Remove all devices from this project"
        open={showMassUnassignConfirm}
        onClose={() => setShowMassUnassignConfirm(!showMassUnassignConfirm)}
        onConfirm={handleMassUnassign}
        title="Remove all devices"
      >
        <Text>Are you sure you want to remove all devices from this project?</Text>
      </HalfPanel>
    </>
  );
};
