import { IconAlertCircle, IconBattery2, IconBattery1, IconBattery } from '@tabler/icons-react';
import {
  AlarmStatus,
  AlarmStatusNames,
  BatteryStatus,
  BatteryStatusNames,
} from '@/models/enums/DeviceEnums';

const alarmIconMap = {
  [BatteryStatusNames[BatteryStatus.OUT_OF_BATTERY]]: {
    icon: IconBattery,
    color: 'white',
    backgroundColor: 'rgb(158, 158, 158)',
  },
  [AlarmStatusNames[AlarmStatus.Alarming]]: {
    icon: IconAlertCircle,
    color: 'white',
    backgroundColor: 'rgb(255, 0, 0)',
  },
  BLUE_ALARM: { icon: IconAlertCircle, color: 'white', backgroundColor: 'rgb(var(--linkBlue))' },
  [BatteryStatusNames[BatteryStatus.CRITICAL]]: {
    icon: IconBattery1,
    color: 'white',
    backgroundColor: 'rgb(255, 0, 0)',
  },
  [BatteryStatusNames[BatteryStatus.LOW]]: {
    icon: IconBattery2,
    color: 'black',
    backgroundColor: 'rgb(248, 220, 2)',
  },
};

type AlertType = 'OUT_OF_BATTERY' | 'CRITICAL' | 'LOW' | 'Alarming' | 'BLUE_ALARM';

interface Props {
  icon?: AlertType;
  customColor?: string;
}

export const AlarmIcon = ({ icon, customColor }: Props) => {
  if (!icon) return null;
  const { icon: Icon, color, backgroundColor } = alarmIconMap[icon];

  return (
    <Icon
      size={24}
      color={color}
      style={{
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        borderRadius: '50%',
        justifyContent: 'center',
        zIndex: 20,
        backgroundColor: customColor || backgroundColor,
      }}
    />
  );
};
