import { MODAL_ZINDEX } from '@/constants/styling';
import { useMobileDevice } from '@/hooks/useMobileDevice';
import {
  ConfirmButtonGroup,
  ConfirmButtonGroupProps,
} from '@/shared/ConfirmButtonGroup/ConfirmButtonGroup';
import { Modal, ModalRoot, Stack, Text } from '@mantine/core';

type Props = {
  opened: boolean;
  setOpened: (next: boolean) => void;
  text: string;
} & ConfirmButtonGroupProps;

export const ModalConfirm = ({ opened, setOpened, text, ...confirmButtonProps }: Props) => {
  const isMobile = useMobileDevice();
  return (
    <>
      <ModalRoot
        opened={opened}
        onClose={() => setOpened(false)}
        fullScreen={isMobile}
        size={'auto'}
        centered
        zIndex={MODAL_ZINDEX}
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Body>
            <Stack p="sm" gap="lg">
              <Text>{text}</Text>
              <ConfirmButtonGroup {...confirmButtonProps} />
            </Stack>
          </Modal.Body>
        </Modal.Content>
      </ModalRoot>
    </>
  );
};
