import { prepareHeaders } from '@/data/api/apiHelpers';
import { DeviceLog } from '@/models/DeviceLog';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useQuery } from '@tanstack/react-query';

interface Params {
  projectId: string;
  dateTime: string;
  instance: IPublicClientApplication;
}

const baseUrl = import.meta.env.VITE_API_BASE_PATH;
const path = `/deviceLog/latest`;

const useDeviceLogsByProjectId = ({ instance, projectId, dateTime }: Params) => {
  const {
    data: deviceLogs,
    isLoading,
    isError,
  } = useQuery<DeviceLog[]>({
    queryKey: ['device-logs', { projectId }, { dateTime }],
    refetchOnWindowFocus: false,
    enabled: !!dateTime,
    queryFn: async () => {
      const response = await fetch(
        `${baseUrl}${path}?projectId=${projectId}&dateTime=${dateTime}`,
        {
          mode: 'cors',
          method: 'GET',
          headers: await prepareHeaders(instance),
        }
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });
  return {
    deviceLogs,
    isLoading,
    isError,
  };
};

export default useDeviceLogsByProjectId;
