import { useState } from 'react';
import { CloseButton, Group, InputBase, ScrollArea, SegmentedControl, Text } from '@mantine/core';
import { EquipmentType } from '../../../models/enums/DeviceEnums.ts';
import { Sign } from '../../../models/Sign.ts';
import SignList from '../../../pwa/components/SignList/SignList.tsx';

interface Props {
  countryCode: string;
  equipmentType: EquipmentType;
  setEquipmentType: (equipmentType: EquipmentType) => void;
  selectedSign: Sign | null;
  setSelectedSign: (sign: Sign | null) => void;
}

export const IntellitagInput = ({
  countryCode,
  equipmentType,
  setEquipmentType,
  selectedSign,
  setSelectedSign,
}: Props) => {
  const [searchString, setSearchString] = useState<string>('');

  return (
    <>
      <Group grow>
        <SegmentedControl
          value={equipmentType.toString()}
          onChange={(type) => {
            setEquipmentType(
              type === EquipmentType.Sign.toString() ? EquipmentType.Sign : EquipmentType.Barrier
            );
          }}
          fullWidth
          data={[
            {
              label: 'Sign',
              value: EquipmentType.Sign.toString(),
            },
            {
              label: 'Barrier',
              value: EquipmentType.Barrier.toString(),
            },
          ]}
        />
      </Group>
      {equipmentType === EquipmentType.Sign && (
        <>
          <Text c="gray.6" size="xs">
            Please select a sign type. Search or browse below.
          </Text>
          <Group gap={10}>
            <InputBase
              size="md"
              rightSection={
                searchString !== '' && (
                  <CloseButton
                    size="sm"
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => setSearchString('')}
                    aria-label="Clear value"
                  />
                )
              }
              value={searchString}
              onChange={(event) => setSearchString(event.currentTarget.value)}
              flex={1}
              placeholder="Search sign"
            />
          </Group>
          <ScrollArea h={'20vh'}>
            <SignList
              handleClick={setSelectedSign}
              selected={selectedSign}
              searchString={searchString}
              countryCode={countryCode}
            />
          </ScrollArea>
        </>
      )}
    </>
  );
};
