import { useMantineTheme } from '@mantine/core';
import { useMap } from '@vis.gl/react-google-maps';
import { Dispatch, SetStateAction, useRef, useEffect } from 'react';

export const PolygonEditingComponent = ({
  drawingLib,
  isDrawing,
  setIsDrawing,
  onCompleted,
}: {
  drawingLib: google.maps.DrawingLibrary;
  isDrawing: boolean;
  setIsDrawing: Dispatch<SetStateAction<boolean>>;
  onCompleted: (path: google.maps.LatLngLiteral[]) => void;
  handleBoundsChange?: () => void;
}) => {
  const map = useMap();
  const theme = useMantineTheme();
  const onCompletedRef = useRef(onCompleted);
  const drawingManager = useRef(
    new drawingLib.DrawingManager({
      drawingControl: false,
      polygonOptions: {
        editable: true,
        draggable: true,
        strokeColor: theme.colors.successGreen[0],
        fillColor: theme.colors.successGreen[0],
        geodesic: false,
      },
    })
  ).current;

  useEffect(() => {
    if (isDrawing) {
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    } else {
      drawingManager.setDrawingMode(null);
    }
  }, [drawingManager, isDrawing]);

  useEffect(() => {
    if (map) {
      drawingManager.setMap(map);

      const listener = drawingManager.addListener(
        'overlaycomplete',
        (e: { type: 'polygon'; overlay: google.maps.Polygon }) => {
          setIsDrawing(false);
          const overlay = e.overlay;
          if (overlay) {
            const newPath = overlay.getPath()?.getArray();
            const newPathArr = newPath.map((point) => ({ lat: point.lat(), lng: point.lng() }));
            onCompletedRef.current?.(newPathArr);
            overlay.setMap(null);
          }
        }
      );

      return () => {
        drawingManager.setMap(null);
        google.maps.event.removeListener(listener);
      };
    }
  }, [map, drawingManager, setIsDrawing]);

  return null;
};
