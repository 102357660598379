import { Stack, Card, Group, Text, Alert, Center, Loader } from '@mantine/core';
import { BatteryBadge, StatusBadge } from '../Badge';
import { useMsal } from '@azure/msal-react';
import { BatteryStatus } from '../../models/enums/DeviceEnums';
import useInfDeviceLogList from '../../data/hooks/DeviceLog/useInfDeviceLogListHook';
import React from 'react';
import useDevice from '../../data/hooks/Device/useDeviceHook.ts';
import { DeviceType } from '../../models/Device.ts';
import { ArrowBoardDeviceLogTableRow } from './ArrowBoard/ArrowBoardDeviceLogTableRow.tsx';
import { IntelliTagDeviceLogTableRow } from './IntelliTag/IntelliTagDeviceLogTableRow.tsx';
import { SmartCableDeviceLogTableRow } from './SmartCable/SmartCableDeviceLogTableRow.tsx';
import { SmartSolarDeviceLogTableRow } from './SmartSolar/SmartSolarDeviceLogTableRow.tsx';
import { formatDateTimeString } from '../../utils/utils.tsx';

interface DeviceLogsTableProps {
  deviceId: string;
}

const DeviceLogsTable = (props: DeviceLogsTableProps) => {
  const { instance } = useMsal();
  const deviceId = props.deviceId;
  const { device: device } = useDevice({ instance, deviceId });
  const {
    deviceLogs,
    isFetching,
    isFetchingNextPage,
    isError: error,
  } = useInfDeviceLogList({
    instance,
    deviceId: props.deviceId,
  });

  if (isFetching && !isFetchingNextPage) {
    return (
      <Center mt="xl">
        <Loader />
      </Center>
    );
  }
  if (!deviceLogs.length) {
    return <Text>No data has been recorded</Text>;
  }

  return (
    <div>
      <Stack gap="md">
        {error ? (
          <Alert title="Error" color="red">
            {error.toString()}
          </Alert>
        ) : (
          deviceLogs.map((log, index) => (
            <Card bg={index % 2 ? 'white' : 'var(--mantine-color-lightGrey-1)'} key={index}>
              <Group>
                <Text fw={500}>{formatDateTimeString(log.timestamp)}</Text>
                <Group gap={'xs'}>
                  {/* TODO: set type when available in devicelogs API */}
                  <StatusBadge size="md" status={log.alarmStatus} type={null} />
                  <BatteryBadge
                    batteryStatus={log.batteryStatus == BatteryStatus.OK ? null : log.batteryStatus}
                  />
                </Group>
              </Group>
              <Text m="xs" size="sm">
                <strong>Project: </strong>
                {log.workZoneName ?? '-'}
              </Text>
              {device?.deviceType === DeviceType.ArrowBoardV1 && (
                <>{ArrowBoardDeviceLogTableRow(log)}</>
              )}

              {device?.deviceType === DeviceType.IntellitagV1 && (
                <>{IntelliTagDeviceLogTableRow(log)}</>
              )}

              {device?.deviceType === DeviceType.SmartCableV1 && (
                <>{SmartCableDeviceLogTableRow(log)}</>
              )}

              {device?.deviceType === DeviceType.SmartSolarV1 && (
                <>{SmartSolarDeviceLogTableRow(log)}</>
              )}

              {!device?.deviceType && (
                <Group p="apart" gap="m" m="xs">
                  <Text size="sm">
                    <strong>Battery (V): </strong>
                    {log.batteryVoltage ?? '-'}
                  </Text>
                </Group>
              )}
            </Card>
          ))
        )}
      </Stack>
    </div>
  );
};

export default React.memo(DeviceLogsTable);
