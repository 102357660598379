import { createTheme } from '@mantine/core';

export default createTheme({
  primaryColor: 'primaryGreen',
  black: '#1A1A1A',
  fontFamily: "'Söhne Web', 'Söhne', system-ui, -apple-system, sans-serif",
  components: { Text: { styles: { root: { fontWeight: 300 } } } }, // Setting default font weight to Text component to align with design

  /* Typography Scale */
  fontSizes: {
    xs: '12px', // Extra small
    sm: '14px', // Small
    md: '16px', // Body
    lg: '20px', // H5
    xl: '24px', // H4
    xxl: '32px', // H3
    xxxl: '40px', // H2
    display: '64px', // Display
  },

  lineHeights: {
    xs: '16px', // Extra small
    sm: '20px', // Small
    md: '24px', // Body
    lg: '24px', // H5
    xl: '32px', // H4
    xxl: '40px', // H3
    xxxl: '48px', // H2
    display: '80px', // Display
  },

  headings: {
    fontFamily: "'Söhne Web', 'Söhne', system-ui, -apple-system, sans-serif",
    sizes: {
      h1: { fontSize: '64px', lineHeight: '80px' }, // Display
      h2: { fontSize: '40px', lineHeight: '48px' },
      h3: { fontSize: '32px', lineHeight: '40px' },
      h4: { fontSize: '24px', lineHeight: '32px' },
      h5: { fontSize: '20px', lineHeight: '24px' },
    },
  },
  // Color 7 is the default
  colors: {
    primaryGreen: [
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#398582',
      '#2E6B69', // default
      '#285958',
      '#fff',
      '#fff',
    ],
    primaryGreenLight: [
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0', // default
      '#E2F0F0',
      '#E2F0F0',
      '#E2F0F0',
    ],
    greenHover: [
      '#1F4746',
      '#1F4746',
      '#1F4746',
      '#1F4746',
      '#1F4746',
      '#1F4746',
      '#1F4746', // default
      '#1F4746',
      '#1F4746',
      '#1F4746',
    ],
    deepGreen: [
      '#e8ebeb',
      '#d1d6d6',
      '#b9c2c2',
      '#a2adad',
      '#8b9999',
      '#748484',
      '#5d7070', // default
      '#455b5b',
      '#2e4747',
      '#173232',
    ],
    mainYellow: [
      '#fffce0',
      '#fff8ca',
      '#fff099',
      '#ffe862',
      '#ffe136',
      '#ffdc18',
      '#ffda00', // default
      '#e3c100',
      '#c9ab00',
      '#ae9300',
    ],
    sand: [
      '#fffaeb',
      '#fffbed',
      '#fffbef',
      '#fffcf1',
      '#fffcf3',
      '#fffdf5',
      '#fffdf7', // default
      '#fffef9',
      '#fffefb',
      '#fffffd',
    ],
    sandBg: [
      '#f8f6f1',
      '#edebe4',
      '#dbd4c5',
      '#c8bda1',
      '#b9a984',
      '#af9c70',
      '#ab9664', // default
      '#958254',
      '#857348',
      '#74633a',
    ],
    greyText: [
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757',
      '#575757', // default
      '#575757',
      '#575757',
      '#575757',
    ],
    dimmedText: [
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E', // default
      '#8E8E8E',
      '#8E8E8E',
      '#8E8E8E',
    ],
    lightGrey: [
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8', // default
      '#F8F8F8',
      '#F8F8F8',
      '#F8F8F8',
    ],
    dividerGrey: [
      '#BFBFBF',
      '#BFBFBF',
      '#BFBFBF',
      '#BFBFBF',
      '#BFBFBF',
      '#BFBFBF',
      '#BFBFBF', // default
      '#BFBFBF',
      '#BFBFBF',
      '#BFBFBF',
    ],
    successGreen: [
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19',
      '#008a19', // default
      '#008a19',
      '#008a19',
      '#008a19',
    ],
    warningYellow: [
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00',
      '#ffae00', // default
      '#ffae00',
      '#ffae00',
      '#ffae00',
    ],
    dangerRed: [
      '#ff999a',
      '#ff7f81',
      '#ff6668',
      '#ff4c4f',
      '#ff3236',
      '#ff191d',
      '#ff0004', // default
      '#e50003',
      '#cc0003',
      '#b20002',
    ],
    linkBlue: [
      '#228BE7',
      '#228BE7',
      '#228BE7',
      '#228BE7',
      '#228BE7',
      '#228BE7',
      '#228BE7', // default
      '#228BE7',
      '#228BE7',
      '#228BE7',
    ],
    ramuddenBlue: [
      '#EFF4FB',
      '#DEE7F3',
      '#668ab8',
      '#4c77ad',
      '#3263a1',
      '#195095',
      '#003D8A', // default
      '#00367c',
      '#00306e',
      '#002a60',
    ],
    ramuddenOrange: [
      '#F16238',
      '#F16238',
      '#F16238',
      '#F16238',
      '#F16238',
      '#F16238',
      '#F16238', // default
      '#F16238',
      '#F16238',
      '#F16238',
    ],
    ramuddenRed: [
      '#3d1E28',
      '#3d1E28',
      '#3d1E28',
      '#3d1E28',
      '#3d1E28',
      '#3d1E28',
      '#3d1E28', // default
      '#3d1E28',
      '#3d1E28',
      '#3d1E28',
    ],
    ramuddenBeige: [
      '#FFE5AD',
      '#FFE5AD',
      '#FFE5AD',
      '#FFE5AD',
      '#FFE5AD',
      '#FFE5AD',
      '#FFE5AD', // default
      '#FFE5AD',
      '#FFE5AD',
      '#FFE5AD',
    ],
    ramuddenYellow: [
      '#FFE137',
      '#FFE137',
      '#FFE137',
      '#FFE137',
      '#FFE137',
      '#FFE137',
      '#FFE137',
      '#FFE137', // default
      '#FFE137',
      '#FFE137',
      '#FFE137',
    ],
  },
  spacing: {
    none: '0px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  defaultRadius: 'sm',
  radius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
  },
});
