import { useQuery } from '@tanstack/react-query';
import fetchDataById, { APIResponsePoint } from '../../api/fetchDataById';
import QueryKeys from '../../queryKeys';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Setup } from '@/models/Setups';

interface Params {
  instance: IPublicClientApplication;
  setupId: string;
  enabled: boolean;
}
const path = `/setup`;

const useSetup = ({ instance, setupId, enabled }: Params) => {
  const { data, isLoading, isError } = useQuery<APIResponsePoint<Setup>>({
    queryKey: QueryKeys.Setups.detail(setupId),
    queryFn: () => fetchDataById(path, setupId, instance),
    refetchInterval: 60000,
    enabled: enabled,
  });

  return {
    setup: data?.item,
    isLoading,
    isError,
  };
};

export default useSetup;
